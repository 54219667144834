import styled from 'styled-components'
import SectionIcon from './SectionIcon'
import React from 'react'
import { useBreakpoints } from '../../../utils/SizeUtils'

export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
  }[Keys]

export type SectionProps = RequireAtLeastOne<
  {
    label?: string
    icon?: string
    children?: React.ReactNode
    iconColor?: 'primary' | 'grey' | 'warning' | 'success'
    mobileBreakpoint?: number
  },
  'label' | 'icon'
>

export function Section({ icon, label, children, iconColor, mobileBreakpoint }: SectionProps) {
  const { isDesktop, isMobile } = useBreakpoints(mobileBreakpoint)

  return (
    <>
      {isDesktop && (
        <DesktopLayout>
          {label && (
            <div className={'labelWrapper'}>
              <SectionTitle>{label}</SectionTitle>
            </div>
          )}

          <div className={'iconContentWrapper'}>
            {icon && (
              <div>
                <SectionIcon icon={icon} iconColor={iconColor} />
              </div>
            )}

            <div className={'contentWrapper'}>{children}</div>
          </div>
        </DesktopLayout>
      )}

      {isMobile && (
        <MobileLayout>
          {(label || icon) && (
            <div className={'iconLabelWrapper'}>
              {icon && (
                <div>
                  <SectionIcon icon={icon} iconColor={iconColor} />
                </div>
              )}

              {label && (
                <div className={'labelWrapper'}>
                  <SectionTitle>{label}</SectionTitle>
                </div>
              )}
            </div>
          )}

          <div>{children}</div>
        </MobileLayout>
      )}
    </>
  )
}

const SectionTitle = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: var(--theme-grey-s4);
`

const DesktopLayout = styled.div`
  /* Label container */
  .labelWrapper {
    margin-bottom: 8px;
  }

  /* Icon + Content */
  .iconContentWrapper {
    display: flex;
    column-gap: 14px;

    .contentWrapper {
      flex-grow: 1;
      width: 0;
    }
  }
`

const MobileLayout = styled.div`
  .iconLabelWrapper {
    display: flex;
    column-gap: 14px;
    margin-bottom: 12px;
    align-items: center;

    .labelWrapper {
      flex-grow: 1;
      width: 0;
    }
  }
`
