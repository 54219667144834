import { spacesApi } from '@/api/spacesApi/spacesAPIclient'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNotifications } from '@valuecase/ui-components'
import { useCallback } from 'react'

interface GenerateTemplatePayload {
  useCase: 'sales' | 'onboarding'
  context?: string
  companyWebsite: string
}

export const useGenerateTemplate = () => {
  const queryClient = useQueryClient()
  const { success, error: notifyError } = useNotifications()

  const mutation = useMutation(
    (payload: GenerateTemplatePayload) =>
      spacesApi.post('/templates/generate/ai', { data: payload }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['readTemplates'])
        success('Template generated')
      },
      onError: () => {
        notifyError('Error generating template')
      },
    },
  )

  const generateTemplate = useCallback(
    async (payload: GenerateTemplatePayload) => {
      try {
        const response = await mutation.mutateAsync(payload)
        return response.data // Assuming the API response contains the data in a 'data' field
      } catch (error) {
        console.error('Failed to generate template:', error)
        throw error
      }
    },
    [mutation],
  )

  return {
    generateTemplate,
    isGenerating: mutation.isLoading,
    error: mutation.error,
    success: mutation.isSuccess,
  }
}
