import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { spacesApi } from '../../api/spacesApi/spacesAPIclient'
import { useAuthState } from '../../auth/auth'
import { customPropertiesResponseSchema } from '@valuecase/common'

export function useGetTenantCustomProperties() {
  const authState = useAuthState()
  const [tenantId, set_tenantId] = useState<string | undefined>()

  const getTenantCustomPropertiesQueryFn = async () => {
    return customPropertiesResponseSchema.parse(
      (await spacesApi.get(`/tenants/${tenantId}/variables`)).data,
    )
  }

  useEffect(() => {
    if (authState.authorized) {
      set_tenantId(authState.tenant)
    }
  }, [authState])

  const query = useQuery({
    queryKey: ['tenant-custom-properties'],
    queryFn: getTenantCustomPropertiesQueryFn,
    enabled: !!tenantId, //query won't fire up until tenantId exists. See: https://tanstack.com/query/v4/docs/react/guides/dependent-queries
  })

  return {
    isLoading: query.isLoading,
    isReloading: query.isRefetching && query.isStale,
    isError: query.isError,
    error: query.error,
    customProperties: query.data,
    isFetched: query.isFetched,
  }
}
