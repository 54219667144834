import { useReadUsers } from '@/modules/userManagement/hooks/useReadUsers'
import { TeamWithMembers } from '@valuecase/common'
import { Dialog, DialogContent, DialogTitle } from '@valuecase/ui-components'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'
import { useCallback, useMemo } from 'react'
import useSelectedMembers from '../hooks/useSelectedMembers'
import useUpdateTeam from '../hooks/useUpdateTeam'
import UserSelection from './UserSelection'

const AddMembersToTeamDialog = ({
  team,
  open,
  onOpenChange,
}: {
  team: TeamWithMembers
  open: boolean
  onOpenChange: (open: boolean) => void
}) => {
  const { users } = useReadUsers()
  const visibleUsers = useMemo(() => {
    if (!users) return []
    return users.filter((user) => !team.memberIds.includes(user.id))
  }, [users, team.memberIds])
  const { selectedMembers, toggleMember, resetSelectedMembers } = useSelectedMembers()
  const updateTeam = useUpdateTeam()

  const onHandleClose = useCallback(() => {
    resetSelectedMembers()
    onOpenChange(false)
  }, [resetSelectedMembers, onOpenChange])

  const handleAddMembers = useCallback(async () => {
    const selectedMemberIds = [...selectedMembers].map((member) => member.id)
    await updateTeam.mutateAsync({
      ...team,
      memberIds: [...team.memberIds, ...selectedMemberIds],
    })
    onHandleClose()
  }, [onHandleClose, selectedMembers, team, updateTeam])

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className='flex flex-col items-center p-8 pt-16 '>
        <div className='flex flex-col gap-2'>
          <DialogTitle>Add members to team {team.name}</DialogTitle>
        </div>

        <div className='flex flex-col w-full gap-8'>
          <div className='flex flex-col gap-2 h-full'>
            <UserSelection
              selectedMembers={selectedMembers}
              toggleMember={toggleMember}
              users={visibleUsers ?? []}
            />
          </div>
          <div>
            <Button2 leadingIcon='add' className='w-full' onClick={handleAddMembers}>
              Add
            </Button2>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default AddMembersToTeamDialog
