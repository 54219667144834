import { useMemo, useRef, useState } from 'react'
import { Button, ModalControls, useOverlay } from '@valuecase/ui-components'
import { SimpleModal } from '@valuecase/ui-components'
import styled from 'styled-components'
import { useChangeSpaceOwner } from '../../../modules/spaces/hooks/useChangeSpaceOwner'
import { useNotifications } from '../../../utils/Notifications/Notifications'
import SelectNewSpaceOwner from '../../users/SelectNewSpaceOwner'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'

interface Props {
  spaceId: string
  spaceName: string
  currentSpaceOwner?: {
    id: string
    fullName?: string
  }
}

const Header = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  p {
    font-style: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    font-weight: normal;
    color: var(--theme-grey-s4);
    b {
      font-weight: 700;
      color: var(--theme-grey-s5);
    }
  }
  h2 {
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 33px;
    text-align: center;
    color: var(--theme-grey-s6);
    margin-bottom: 8px;
  }
`

const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 55px 0;
  span {
    width: 100%;
  }
  p.confirmation {
    font-style: normal;
    font-size: 14px;
    line-height: 150%;
    text-align: center;
    font-weight: normal;
    color: var(--theme-grey-s4);
    b {
      font-weight: 700;
      color: var(--theme-grey-s5);
    }
  }
`

const CancelButton = styled.button`
  color: var(--theme-grey-s6);
  border: 1px solid var(--theme-grey-s6);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 12px 10px 10px;
  gap: 6px;
  background: white;
  border-radius: 8px;
  flex: 1 1 0px;
  width: 0;
  font-size: 12px;
  font-weight: 500;
  height: 36px;
  &:hover {
    background: var(--theme-grey-s1);
  }
`

const ButtonRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 48px;
  width: 100%;
`
function ChangeSpaceOwnerModal({ spaceId, spaceName, currentSpaceOwner }: Props) {
  const controls = useRef<ModalControls | null>(null)

  const [isShowingConfirmation, setIsShowingConfirmation] = useState(false)
  const { changeSpaceOwnerAsync, isLoading } = useChangeSpaceOwner()
  const { asyncNotification } = useNotifications()
  const { trackEvent } = useTrackEvent()

  const [selectedUser, setSelectedUser] = useState<{ id: string; name: string } | undefined>(
    undefined,
  )

  return (
    <SimpleModal
      controlsRef={controls}
      header={
        <Header>
          <h2>{currentSpaceOwner ? 'Change space owner' : 'Set space owner'}</h2>
          {isShowingConfirmation ? (
            <p>
              <b>{spaceName}</b> will now be owned by
            </p>
          ) : currentSpaceOwner ? (
            <p>
              <b>{currentSpaceOwner.fullName ?? `[User with ID ${currentSpaceOwner.id}]`}</b> will
              no longer be the owner of <b>{spaceName}</b>. Please select another user to take over.
            </p>
          ) : (
            <p>Please select a user to take over.</p>
          )}
        </Header>
      }
      body={
        <Body>
          {isShowingConfirmation ? (
            <p className={'confirmation'}>
              <b>{selectedUser?.name}</b>
            </p>
          ) : (
            <SelectNewSpaceOwner
              currentSpaceOwnerId={currentSpaceOwner?.id}
              onNewSpaceOwnerSelected={setSelectedUser}
            />
          )}
          <ButtonRow>
            <Button
              style={'gray-outlined'}
              onClick={() => {
                controls.current?.close()
              }}
              label={'Cancel'}
              icon={'close-circle-outline'}
            />
            <Button
              onClick={async () => {
                if (!selectedUser) return
                if (!isShowingConfirmation) {
                  setIsShowingConfirmation(true)
                  return
                }
                await asyncNotification(
                  changeSpaceOwnerAsync({ spaceId, ownerId: selectedUser.id }),
                  'Owner successfully changed',
                )

                trackEvent({
                  event: 'spaces-change_owner',
                  eventProperties: {
                    spaceId,
                  },
                })
                controls.current?.close()
              }}
              cssStyle={{ flex: '1 1 0px', width: '0', padding: '10px 12px 10px 12px' }} // The px is needed for IE11
              label={currentSpaceOwner ? 'Change owner' : 'Set owner'}
              icon={'shuffle-outline'}
            />
          </ButtonRow>
        </Body>
      }
    />
  )
}

interface ChangeSpaceOwnerModalHookProps {
  show: (
    spaceId: string,
    spaceName: string,
    currentSpaceOwner?: { id: string; fullName?: string },
  ) => void
}
export function useChangeSpaceOwnerModalHook(): ChangeSpaceOwnerModalHookProps {
  const overlay = useOverlay()

  return {
    show: useMemo(() => {
      return (spaceId, spaceName, currentSpaceOwner) => {
        overlay.show(
          <ChangeSpaceOwnerModal
            spaceId={spaceId}
            currentSpaceOwner={currentSpaceOwner}
            spaceName={spaceName}
          />,
        )
      }
    }, [overlay]),
  }
}
