import { useSpaceFilter } from '@/modules/spaces/hooks/useSpaceFiltering'
import useReadMyTeams from '@/modules/teams/hooks/useReadMyTeams'
import { useCurrentTenantQuery } from '@/modules/tenant/hooks/useReadTenant'
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuContentScrollable,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Input2,
  Highlight,
} from '@valuecase/ui-components'
import React, { useCallback, useMemo, useState } from 'react'

const SpaceByTeamsFilter: React.FC = () => {
  const { tenant } = useCurrentTenantQuery()
  const { myTeams } = useReadMyTeams()
  const {
    collaboratorAccess,
    companyTeamAccess,
    myTeamAccess,
    teamIds,
    setTeamIds,
    setCollaboratorAccess,
    setMyTeamAccess,
    setCompanyTeamAccess,
  } = useSpaceFilter()

  const [searchTerm, setSearchTerm] = useState('')

  const teamFilterLabel = useMemo(() => {
    if (myTeamAccess) {
      return 'My Teams'
    }

    if (collaboratorAccess) {
      return 'Collaborator access'
    }

    if (companyTeamAccess) {
      return tenant?.payload?.tenantName ?? 'Everyone in the company'
    }

    if (!teamIds) {
      return 'Any team'
    }

    if (teamIds?.length === 1) {
      const team = myTeams?.find((team) => team.id === teamIds[0])
      return team?.name ?? 'Team (1)'
    }

    if (teamIds?.length > 1) {
      return `Teams (${teamIds?.length})`
    }

    return ''
  }, [
    collaboratorAccess,
    companyTeamAccess,
    myTeamAccess,
    myTeams,
    teamIds,
    tenant?.payload?.tenantName,
  ])

  const updateTeamSelection = useCallback(
    (teamId: string) => {
      if (!teamIds || teamIds?.length === 0) {
        setTeamIds?.([teamId])
        setCollaboratorAccess?.(false)
        setCompanyTeamAccess?.(false)
        return
      }

      if (teamIds.includes(teamId)) {
        const newTeamIds = teamIds?.filter((id) => id !== teamId)
        if (!newTeamIds?.length) {
          setTeamIds?.(null)
        } else {
          setTeamIds?.(teamIds?.filter((id) => id !== teamId))
        }
        return
      } else {
        setTeamIds?.([...teamIds, teamId])
      }
    },
    [setCollaboratorAccess, setCompanyTeamAccess, setTeamIds, teamIds],
  )

  const handleCollaboratorAccessChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        setCompanyTeamAccess?.(false)
        setTeamIds?.(null)
        setMyTeamAccess?.(false)
        setCollaboratorAccess?.(true)
      } else {
        setCollaboratorAccess?.(false)
      }
    },
    [setCollaboratorAccess, setCompanyTeamAccess, setMyTeamAccess, setTeamIds],
  )

  const handleCompanyTeamAccessChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        setCollaboratorAccess?.(false)
        setTeamIds?.(null)
        setMyTeamAccess?.(false)
        setCompanyTeamAccess?.(true)
      } else {
        setCompanyTeamAccess?.(false)
      }
    },
    [setCollaboratorAccess, setCompanyTeamAccess, setMyTeamAccess, setTeamIds],
  )

  const handleMyTeamAccessChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        setCollaboratorAccess?.(false)
        setTeamIds?.(null)
        setCompanyTeamAccess?.(false)
        setMyTeamAccess?.(true)
      } else {
        setMyTeamAccess?.(false)
      }
    },
    [setCollaboratorAccess, setCompanyTeamAccess, setMyTeamAccess, setTeamIds],
  )

  const handleAnyTeamAccessChange = useCallback(
    (checked: boolean) => {
      if (checked) {
        setCollaboratorAccess?.(false)
        setTeamIds?.(null)
        setCompanyTeamAccess?.(false)
        setMyTeamAccess?.(false)
      }
    },
    [setCollaboratorAccess, setCompanyTeamAccess, setMyTeamAccess, setTeamIds],
  )

  const filteredTeams = useMemo(() => {
    if (!searchTerm) {
      return myTeams
    }
    return myTeams?.filter((team) => team.name.toLowerCase().includes(searchTerm.toLowerCase()))
  }, [myTeams, searchTerm])

  const handleOnOpenChange = useCallback(
    async (isOpen: boolean) => {
      if (isOpen) {
        setSearchTerm('')
      }
    },
    [setSearchTerm],
  )

  return (
    <DropdownMenu onOpenChange={handleOnOpenChange}>
      <DropdownMenuTrigger asChild>
        <DropdownMenuButton icon='person'>{teamFilterLabel}</DropdownMenuButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='w-72' align='start'>
        <div className='p-1'>
          <Input2
            type='text'
            placeholder='Search teams'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()} // Prevent dropdown from capturing key events
            trailingIcon='search'
          />
        </div>
        <DropdownMenuSeparator />

        <DropdownMenuCheckboxItem
          checked={!teamIds?.length && !myTeamAccess && !collaboratorAccess && !companyTeamAccess}
          onCheckedChange={handleAnyTeamAccessChange}
        >
          Any team
        </DropdownMenuCheckboxItem>
        <DropdownMenuSeparator />
        <DropdownMenuCheckboxItem
          checked={myTeamAccess || false}
          onCheckedChange={handleMyTeamAccessChange}
        >
          My Teams
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          checked={collaboratorAccess || false}
          onCheckedChange={handleCollaboratorAccessChange}
        >
          Other teams (Collaborator access)
        </DropdownMenuCheckboxItem>
        <DropdownMenuCheckboxItem
          checked={companyTeamAccess || false}
          onCheckedChange={handleCompanyTeamAccessChange}
        >
          Everyone in {tenant?.payload?.tenantName ?? 'company'}
        </DropdownMenuCheckboxItem>
        {filteredTeams && filteredTeams?.length > 0 && <DropdownMenuSeparator />}
        <DropdownMenuContentScrollable>
          {filteredTeams?.map((team) => {
            return (
              <DropdownMenuCheckboxItem
                key={team?.id}
                checked={teamIds?.includes(team.id)}
                onCheckedChange={() => updateTeamSelection(team.id)}
                descriptionVariant={'stacked'}
              >
                <Highlight searchTerm={searchTerm}>{team.name}</Highlight>
              </DropdownMenuCheckboxItem>
            )
          })}
        </DropdownMenuContentScrollable>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default SpaceByTeamsFilter
