import { MutableRefObject, useEffect, useRef, useState } from 'react'

import { flip, offset } from '@floating-ui/react-dom'
import { autoUpdate, shift, useFloating } from '@floating-ui/react-dom-interactions'
import classNames from 'classnames'
import { darken } from 'polished'
import styled from 'styled-components'
import ZIndeces from '../../../../global-styles/ZIndices'
import { IonIcon } from '../../../../Icons/IonIcon'
import { BasePortal } from '../../Portal/BasePortal'
import FormElementContainer from '../FormElementContainer'
import { InputIconPosition, InputPopupRenderFunction, InputSize } from '../types.form'
import { useValidation, Validatable } from '../Validations'

export interface TextAreaProps extends Validatable<string> {
  value?: string
  onInput?: (newValue: string) => void
  readonly?: boolean
  fullWidth?: boolean
  placeholder?: string
  name?: string
  type?: string
  disabled?: boolean
  size?: InputSize
  icon?: string
  iconPosition?: InputIconPosition
  onIconClick?: () => void
  className?: string
  onBlur?: () => void
  onFocus?: () => void
  autoFocus?: boolean
  refFromParent?: MutableRefObject<HTMLInputElement | null> // used to trigger focus from parent
  popup?: InputPopupRenderFunction
  maxHeight?: number
}

/**
 *
 * @deprecated Use Textarea from ui folder instead
 */
export function TextareaDeprecated({
  value = '',
  onInput = () => undefined,
  readonly = false,
  fullWidth = true,
  placeholder = '',
  name,
  disabled = false,
  validators,
  validationMode,
  onValidation,
  size = InputSize.MEDIUM,
  icon,
  iconPosition = 'start',
  onIconClick,
  className,
  enableValidation,
  onFocus,
  onBlur,
  autoFocus,
  refFromParent,
  popup,
  maxHeight = 68,
}: TextAreaProps) {
  const elRef = useRef<HTMLTextAreaElement | null>(null)
  const [input, setInput] = useState<HTMLInputElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [width, setWidth] = useState(300)

  useEffect(() => {
    if (input) {
      const observer = new ResizeObserver(() => {
        setWidth(input.getBoundingClientRect().width)
      })
      observer.observe(input)

      return () => {
        observer.disconnect()
      }
    }
  }, [input])

  const { validationErrors } = useValidation<string, HTMLInputElement>({
    ref: inputRef,
    value,
    validators,
    validationMode,
    onValidation,
    enableValidation,
  })
  const [focussed, setFocussed] = useState(false)

  const onInputHandler = (e: any) => {
    e.preventDefault()
    onInput(e.target.value)
  }

  const popupContent = popup?.({ inputWidth: width, value, focussed })
  const isPopupShown = !!popupContent

  useEffect(() => {
    if (elRef.current) {
      elRef.current.style.height = 'inherit'
      elRef.current.style.height = `${Math.min(elRef.current.scrollHeight - 16, maxHeight)}px`
    }
  }, [value]) /* eslint-disable-line */ /* TODO @ fix exhaustive-deps */

  useEffect(() => {
    if (elRef.current) {
      elRef.current.style.height = `36px`
    }
  }, [])

  const {
    x: popupX,
    y: popupY,
    reference: inputWrapperRef,
    floating: popupRef,
    strategy: popupStrategy,
  } = useFloating({
    open: isPopupShown,
    whileElementsMounted: autoUpdate,
    strategy: 'absolute',
    placement: 'bottom',
    middleware: [offset(8), flip(), shift()],
  })

  return (
    <FormElementContainer $fullWidth={fullWidth}>
      <FormElementContainer $fullWidth={fullWidth} ref={inputWrapperRef}>
        {icon && (
          <IconContainer
            $sizeSetting={size}
            $isDisabled={disabled}
            $hasError={validationErrors.length > 0}
            $iconPosition={iconPosition}
            $allowPointerEvents={!!onIconClick}
            onClick={() => {
              onIconClick?.()
            }}
          >
            <IonIcon name={icon} />
          </IconContainer>
        )}

        <StyledTextarea
          autoFocus={autoFocus}
          $iconPresent={!!icon}
          ref={elRef}
          $iconPosition={iconPosition}
          value={value}
          onInput={onInputHandler}
          readOnly={readonly}
          placeholder={placeholder}
          className={classNames(className, {
            error: validationErrors.length > 0,
          })}
          name={name}
          disabled={disabled}
          $sizeSetting={size}
          $fullWidth={fullWidth}
          onBlur={() => {
            onBlur?.()
            setFocussed(false)
          }}
          onFocus={() => {
            onFocus?.()
            setFocussed(true)
          }}
          id='textarea'
        />

        {isPopupShown && (
          <BasePortal portalId={'input-popup'} zIndex={ZIndeces.inputPopup}>
            <PopupCard
              onMouseDown={(e: any) => {
                e.preventDefault()
              }}
              style={{
                position: popupStrategy,
                left: `${popupX}px`,
                top: `${popupY}px`,
              }}
              ref={popupRef}
            >
              {popupContent}
            </PopupCard>
          </BasePortal>
        )}
      </FormElementContainer>

      {validationErrors?.length > 0 && (
        <ErrorContainer $sizeSetting={size}>
          {validationErrors?.length > 0 && <StyledError>{validationErrors.join(', ')}</StyledError>}
        </ErrorContainer>
      )}
    </FormElementContainer>
  )
}

const PopupCard = styled.div`
  pointer-events: auto;
  border: 1px solid var(--theme-grey-s2);
  box-shadow:
    0px 6px 16px -4px rgba(0, 0, 0, 0.1),
    0px 2px 6px -2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
`

interface InputLook {
  fontSize: number
  height: number
  paddingX: number
  iconSize: number
  iconPaddingLeft: number
}

const SizeLooks: { [key in InputSize]: InputLook } = {
  [InputSize.LARGE]: {
    fontSize: 14,
    height: 44,
    paddingX: 24,
    iconSize: 24,
    iconPaddingLeft: 12,
  },
  [InputSize.MEDIUM]: {
    fontSize: 12,
    height: 36,
    paddingX: 20,
    iconSize: 20,
    iconPaddingLeft: 12,
  },
  [InputSize.SMALL]: {
    fontSize: 10,
    height: 30,
    paddingX: 16,
    iconSize: 16,
    iconPaddingLeft: 10,
  },
}

interface StyledTextareaProps {
  $sizeSetting: InputSize
  $fullWidth: boolean
  $iconPresent: boolean
  $iconPosition: InputIconPosition
}

const StyledTextarea = styled.textarea<StyledTextareaProps>`
  ${({ $sizeSetting: sizeSetting, $fullWidth: fullWidth }) => {
    const look = SizeLooks[sizeSetting]
    return `
      resize: none;
      font-size: ${look.fontSize}px;
      padding: 0 ${look.paddingX}px;
      ${fullWidth ? 'display: block; width: 100%;' : ''}
      padding-top: 8px;
      padding-bottom: 8px;
    `
  }}

  border-radius: 8px;
  line-height: 150%;
  margin: 0 0;

  outline: none;
  border: 1px solid var(--theme-grey-s2);
  color: var(--theme-grey-s6);
  background: var(--theme-light);

  transition:
    0.2s ease box-shadow,
    0.2s ease border;

  &::placeholder {
    color: var(--theme-grey-s4);
  }

  &:hover:not(:read-only) {
    outline: none;
    box-shadow: 0 0 0 4px var(--theme-primary-s2);
  }

  &:focus:not(:read-only) {
    outline: none;
    box-shadow: 0 0 0 4px var(--theme-primary-s2);
    border-color: var(--theme-primary-s4);
  }

  &:focus:read-only {
    border-color: var(--theme-primary-s3);
  }

  &:disabled {
    cursor: not-allowed;
    box-shadow: none;
    background: var(--theme-grey-s1);
    border-color: var(--theme-grey-s1);
    color: var(--theme-grey-s4);

    &::placeholder {
      color: var(--theme-grey-s3);
    }
  }

  &:disabled:hover {
    cursor: not-allowed;
    background: var(--theme-grey-s1);
    border-color: var(--theme-grey-s1);
    box-shadow: none;
  }

  &.error {
    border-color: var(--theme-warning-s5);
  }

  &.error:hover {
    box-shadow: 0 0 0 4px var(--theme-warning-s2);
    border-color: var(--theme-warning-s5);
  }

  &.error:focus {
    box-shadow: 0 0 0 4px var(--theme-warning-s2);
    border-color: var(--theme-warning-s5);
  }

  ${({ $sizeSetting: sizeSetting, $iconPresent: iconPresent, $iconPosition: iconPosition }) => {
    if (iconPresent) {
      const look = SizeLooks[sizeSetting]
      return `
        ${iconPosition === 'end' ? 'padding-right' : 'padding-left'}: ${12 + look.iconSize + 8}px;
      `
    }
    return ''
  }}
`

const ErrorContainer = styled.span<{ $sizeSetting: InputSize }>`
  display: inline-block;
  width: 100%;

  padding-top: 8px;
  font-size: 12px;

  font-weight: 500;
  line-height: 150%;
  color: var(--theme-warning-s5);

  ${({ $sizeSetting: sizeSetting }) => {
    const look = SizeLooks[sizeSetting]
    return `
      font-size: ${look.fontSize}px;
    `
  }}
`

const StyledError = styled.span`
  margin-right: 16px;
`

interface IconContainerProps {
  $sizeSetting: InputSize
  $hasError: boolean
  $isDisabled: boolean
  $iconPosition: InputIconPosition
  $allowPointerEvents: boolean
}

const IconContainer = styled.span<IconContainerProps>`
  display: grid;
  place-items: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: ${({ $allowPointerEvents }) => ($allowPointerEvents ? 'auto' : 'none')};

  ${({ $allowPointerEvents: allowPointerEvents }) => (allowPointerEvents ? 'cursor: pointer;' : '')}

  .ionicon {
    transition: color 0.2s ease;
    color: var(--theme-primary-s3);
  }

  ${({
    $sizeSetting: sizeSetting,
    $isDisabled: isDisabled,
    $hasError: hasError,
    $iconPosition: iconPosition,
    theme,
  }) => {
    const look = SizeLooks[sizeSetting]
    return `
      width: ${look.iconSize}px;
      height: ${look.iconSize}px;
      font-size: ${look.iconSize}px;
      ${
        iconPosition === 'end'
          ? `right: ${look.iconPaddingLeft}px`
          : `left: ${look.iconPaddingLeft}px`
      };
      
      .ionicon {
        ${isDisabled ? 'color: var(--theme-grey-s2)' : ''}
        ${hasError ? `color: ${darken(0.05, theme.warning.s2)}` : ''}
      } 
    `
  }}
`
