import {
  ProblemDetailSuperType,
  GetUserProfileApiResponse,
  UpdateUserProfileApiResponse,
  UpdateUserProfileBody,
} from '@valuecase/seller-backend'
import { ClientProblemDetailSuperType, createTypeSafeAxios } from '@tectonique/api-standards-client'
import axios from 'axios'
import { addAccessTokenRequestInterceptor } from '../../auth/auth'

const coreUserProfileApi = axios.create()
addAccessTokenRequestInterceptor(coreUserProfileApi)

const { verbs } = createTypeSafeAxios<ProblemDetailSuperType | ClientProblemDetailSuperType>(
  coreUserProfileApi,
)

export const userProfileApi = {
  getUserProfile: () =>
    verbs.get<GetUserProfileApiResponse, undefined>('/dashboard/api/user-profile'),

  updateUserProfile: (profile: UpdateUserProfileBody) =>
    verbs.put<UpdateUserProfileApiResponse, UpdateUserProfileBody, unknown>(
      '/dashboard/api/user-profile',
      profile,
    ),
}
