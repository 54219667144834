import styled from 'styled-components'

const Item = styled.div<{ $isHighlighted: boolean }>`
  cursor: pointer;
  display: flex;
  ${({ $isHighlighted: isHighlighted }) => {
    if (isHighlighted) {
      return 'box-shadow: -2px 0px 0px 0px #2A4CFA;'
    }
  }}

  padding-left: 16px;
`
const Label = styled.div<{ $isHighlighted: boolean }>`
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 140%;
  font-stretch: 100;

  ${({ $isHighlighted: isHighlighted, theme }) => {
    if (isHighlighted) {
      return `color: ${theme.primary.s5};`
    } else return `color: ${theme.grey.s4};`
  }}

  &:hover {
    color: ${({ theme, $isHighlighted: isHighlighted }) =>
      isHighlighted ? theme.primary.s5 : theme.primary.s4};
  }
`
export const S = { Item, Label }
// color: ${({ theme }) => theme.primary.s5};
