import { FC, useState } from 'react'
import { HeaderFooterScrollableModal } from '@valuecase/ui-components'
import TemplateFromSpaceCreator from './TemplateFromSpaceCreator'
import { ModalControls } from '@valuecase/ui-components'

export interface TemplateFromSpaceCreatorModalProps {
  controlsRef: React.MutableRefObject<ModalControls | null>
  spaceId: string
}

const TemplateFromSpaceCreatorModal: FC<TemplateFromSpaceCreatorModalProps> = ({
  controlsRef,
  spaceId,
}) => {
  const [modalIsPersistent, setModalIsPersistent] = useState<boolean>(false)

  return (
    <HeaderFooterScrollableModal
      persistent={modalIsPersistent}
      controlsRef={controlsRef}
      wide={true}
      needExplicitHeight={{ min: '300px', preferred: '656px' }}
    >
      <TemplateFromSpaceCreator setIsModalPersistent={setModalIsPersistent} spaceId={spaceId} />
    </HeaderFooterScrollableModal>
  )
}

export default TemplateFromSpaceCreatorModal
