import SalesforceLogo from '../../assets/salesforceLogo.svg?react'
import { IntegrationBadge } from './IntegrationBadge'

export const SalesforceBadge = () => {
  return (
    <IntegrationBadge
      LogoSvg={SalesforceLogo}
      backgroundColor='#00a1e033'
      color='#00a1e0'
      text='Connected'
    />
  )
}
