import { SettingsStackRow, SettingsStackRowProps } from './SettingsStackRow'

interface SettingsStackProps {
  title?: string
  description?: string
  data: SettingsStackRowProps[]
}

export function SettingsStack({ title, description, data }: SettingsStackProps) {
  return (
    <div className='flex flex-col w-full'>
      <div className={'flex flex-row justify-between py-11'}>
        <div className={'flex flex-col w-140'}>
          {title && <h1 className={'text-2xl font-bold'}>{title}</h1>}
          {description && <p className={'text-sm text-grey-s5 mt-2 leading-6'}>{description}</p>}
        </div>
      </div>
      {data.map((rowDataProps, index) => (
        <SettingsStackRow
          key={index}
          title={rowDataProps.title}
          description={rowDataProps.description}
          image={rowDataProps.image}
          rightChild={rowDataProps.rightChild}
          leftChild={rowDataProps.leftChild}
        />
      ))}
    </div>
  )
}
