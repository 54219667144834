import { Button } from '@valuecase/ui-components'
import { useRef } from 'react'
import { useNotifications } from '../../utils/Notifications/Notifications'
import { useCompanyVisualSettings } from './hooks/useCompanyVisualSettings'
import { useDeleteUploadedImage } from './hooks/useDeleteUploadedImage'
import { SettingsInfo } from './SettingsInfo'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import { useSignedImageUrl } from '@/api/spacesApi/signedS3Images'
import { useS3Upload } from '@/api/s3'

export const FaviconSetting = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const { success } = useNotifications()
  const { upload } = useS3Upload()
  const { show } = useDeleteUploadedImage()
  const { trackEvent } = useTrackEvent()

  const { companySettings, updateCompanySettings, isQueryFetched } = useCompanyVisualSettings({
    onUpdateSuccess: () => {
      success('Favicon updated')
    },
  })

  const { imageUrl: faviconUrl } = useSignedImageUrl(companySettings?.favicon)

  function handleUploadClick() {
    fileInputRef?.current?.click()
  }

  async function handleUploadFavicon(file: File) {
    const uploadedFile = await upload(file)
    trackEvent({
      event: 'settings-favicon-upload_finished',
      eventProperties: {
        indexPageName: 'Company Settings',
      },
    })
    const faviconImageData = {
      source: 'S3',
      s3Key: uploadedFile.s3ObjectKey,
    }

    updateCompanySettings({ favicon: faviconImageData })
  }

  function deleteFavicon() {
    show(() => updateCompanySettings({ favicon: null }), 'favicon')
  }

  return (
    <div className='flex justify-between w-full items-center'>
      <SettingsInfo
        title='Favicon'
        description='Upload a 32x32 pixel PNG to display in browser tabs.'
      />
      {isQueryFetched && !companySettings?.favicon && (
        <div>
          <Button
            icon='cloud-upload-outline'
            iconPosition='right'
            label='Upload'
            onClick={() => {
              trackEvent({
                event: 'settings-favicon-upload_click',
                eventProperties: {
                  indexPageName: 'Company Settings',
                },
              })
              handleUploadClick()
            }}
          />

          <input
            ref={fileInputRef}
            type={'file'}
            style={{ display: 'none' }}
            multiple={false}
            accept={'image/*'}
            onInput={(e) => {
              e.preventDefault()
              const file = (e.target as HTMLInputElement)?.files?.[0]
              if (file) {
                handleUploadFavicon(file)
              }
            }}
          />
        </div>
      )}

      {isQueryFetched && companySettings?.favicon && (
        <div className='flex gap-3 items-center'>
          <div
            className={
              'flex items-center justify-center p-2 rounded-lg border-solid border-[1px] border-grey-s2'
            }
          >
            <img src={faviconUrl} alt='favicon' className='w-4 h-4' />
          </div>

          <Button
            icon='trash-outline'
            iconPosition='right'
            style='flat-warning'
            circle={true}
            onClick={() => {
              trackEvent({
                event: 'settings-favicon-remove',
                eventProperties: {
                  indexPageName: 'Company Settings',
                },
              })
              deleteFavicon()
            }}
          />
          <Button
            icon='create-outline'
            iconPosition='right'
            label='Change'
            onClick={() => {
              trackEvent({
                event: 'settings-favicon-change_click',
                eventProperties: {
                  indexPageName: 'Company Settings',
                },
              })
              handleUploadClick()
            }}
          />
          <input
            ref={fileInputRef}
            type={'file'}
            style={{ display: 'none' }}
            multiple={false}
            accept={'image/png'}
            onInput={(e) => {
              e.preventDefault()
              const file = (e.target as HTMLInputElement)?.files?.[0]
              if (file) {
                handleUploadFavicon(file)
              }
            }}
          />
        </div>
      )}
    </div>
  )
}
