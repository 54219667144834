import { useMemo } from 'react'
import lottie, { AnimationItem } from 'lottie-web'
import { LottieShowOnceByCoordsFunction, LottieShowOnceOverFunction } from './Lottie.types'
import { useLottieWrapper } from './Lottie.wrapper'
import { computeAnimationRectangle, createDisposableAnimationElement } from './Lottie.utils'

export interface LottieHook {
  showOnceByCoords: LottieShowOnceByCoordsFunction
  showOnceOver: LottieShowOnceOverFunction
}

export function useLottie(animationData: any): LottieHook {
  const lottieWrapper = useLottieWrapper()

  const getNewAnimation = useMemo<(element: HTMLDivElement) => AnimationItem>(
    () => (element) => {
      return lottie.loadAnimation({
        container: element,
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: animationData,
      })
    },
    [animationData],
  )

  const showOnceByCoords = useMemo<LottieShowOnceByCoordsFunction>(
    () => (x, y, props) => {
      return new Promise((resolve) => {
        const { width, height } = props
        const element = createDisposableAnimationElement(x, y, width, height)

        lottieWrapper.appendChild(element)

        const animation = getNewAnimation(element)
        animation.setSpeed(props.speed ?? 1)

        animation.addEventListener('complete', () => {
          animation.destroy()
          lottieWrapper.removeChild(element)
          resolve()
        })

        if (props.delay) {
          setTimeout(() => {
            animation.play()
          }, props.delay)
        } else {
          animation.play()
        }
      })
    },
    [lottieWrapper, getNewAnimation],
  )

  const showOnceOver = useMemo<LottieShowOnceOverFunction>(
    () => (reference, props) => {
      const { x, y } = computeAnimationRectangle(reference, props)
      return showOnceByCoords(x, y, props)
    },
    [showOnceByCoords],
  )

  return {
    showOnceByCoords,
    showOnceOver,
  }
}
