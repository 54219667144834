import {
  EmptyState,
  EmptyStateHeader,
  EmptyStateTitle,
  EmptyStateDescription,
} from '@valuecase/ui-components'
import React from 'react'
import CalendarChecked from './assets/calendar-checked.svg?react'
import List from './assets/list.svg?react'
import Cafe from './assets/cafe.svg?react'

export const OverdueTasksEmptyState: React.FC = () => (
  <EmptyState className='p-4 gap-4'>
    <CalendarChecked className='w-16 h-16' />
    <EmptyStateHeader>
      <EmptyStateTitle>No overdue tasks</EmptyStateTitle>
      <EmptyStateDescription>Good job, you did not miss a deadline.</EmptyStateDescription>
    </EmptyStateHeader>
  </EmptyState>
)

export const NextUnscheduledTasksEmptyState: React.FC = () => (
  <EmptyState className='p-4 gap-4'>
    <List className='w-16 h-16' />
    <EmptyStateHeader>
      <EmptyStateTitle>Next unscheduled tasks</EmptyStateTitle>
      <EmptyStateDescription>
        Add an action plan with a task to one of your spaces, the next unscheduled task will appear
        here.
      </EmptyStateDescription>
    </EmptyStateHeader>
  </EmptyState>
)

export const UpcomingTasksEmptyState: React.FC = () => (
  <EmptyState className='p-4 gap-4'>
    <Cafe className='w-16 h-16' />
    <EmptyStateHeader>
      <EmptyStateTitle>No upcoming due dates</EmptyStateTitle>
      <EmptyStateDescription>
        Assign a due date in one of your action plans, and it will appear here.
      </EmptyStateDescription>
    </EmptyStateHeader>
  </EmptyState>
)

export const AllTasksEmtpyState: React.FC = () => (
  <EmptyState className='p-4 gap-4'>
    <List className='w-16 h-16' />
    <EmptyStateHeader>
      <EmptyStateTitle>No tasks</EmptyStateTitle>
      <EmptyStateDescription>
        Add a task to one of your action plans, and it will appear here.
      </EmptyStateDescription>
    </EmptyStateHeader>
  </EmptyState>
)
