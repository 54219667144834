import { SidebarItemStructure } from '../types.nav'
import { useEffect, useMemo, useState, useCallback } from 'react'
import Badges from '../components/Badges/BadgesContainer'
import { useNavigate } from '@tanstack/react-router'
import { useHasPermission } from '../../../../auth/permissions'
import { useActivityFeedCount } from '../../../activityFeed/hooks/useActivityFeed'
import { ActivityBadge } from '../components/Badges/ActivityBadge'
import { useLDflags } from '../../../../launchdarkly/Launchdarkly'
import HomeBadge from '@/modules/navigation/sidebar/components/Badges/HomeBadge'
import { useCurrentTenantQuery } from '../../../tenant/hooks/useReadTenant'
import { useCreateCustomerPortalSession } from '../../../../modules/billing/useCreateCustomerPortalSession'

export function useSidebarItemStructure(): Array<SidebarItemStructure> {
  const nav = useNavigate()
  const { flags } = useLDflags()
  const canManageTemplates = useHasPermission('TEMPLATES_MANAGE')
  const { unreadCount } = useActivityFeedCount()
  const [unreadActivitiesCount, setUnreadActivitiesCount] = useState(0)

  const hasUserManagePermission = useHasPermission('USERS_MANAGE')
  const hasTenantSettingsPermission = useHasPermission('TENANT_SETTINGS_MANAGE')

  const canViewAssetLibrary = useHasPermission('ASSET_LIBRARY_VIEW')
  const canManageSpaces = useHasPermission('SPACES_ALL_ADMINISTRATION')

  useEffect(() => {
    if (!unreadCount) {
      return
    }
    setUnreadActivitiesCount(unreadCount.unreadActivitiesCount)
  }, [unreadCount])

  const { tenant } = useCurrentTenantQuery()

  const { createCustomerPortalSession } = useCreateCustomerPortalSession()

  const handleCustomerPortalRedirect = useCallback(() => {
    if (tenant?.payload?.hasBillingDetails) {
      createCustomerPortalSession()
    }
  }, [tenant?.payload?.hasBillingDetails, createCustomerPortalSession])

  const hasActionPlanAnalytics = useMemo(() => {
    return (
      flags &&
      flags['dashboard-action-plan-analytics'] &&
      (canManageSpaces || hasTenantSettingsPermission)
    )
  }, [flags, canManageSpaces, hasTenantSettingsPermission])

  return useMemo<Array<SidebarItemStructure>>(() => {
    const sidebar: Array<SidebarItemStructure> = []
    sidebar.push({
      label: (
        <div className={'flex gap-2 items-center'}>
          <span>Spaces</span>
          <Badges>
            <HomeBadge />
          </Badges>
        </div>
      ),
      redirectTo: () => nav({ to: '/spaces' }),
      location: 'spaces',
      id: 'dashboard-sidebar-spaces',
      iconName: 'documents',
    })
    if (canManageTemplates) {
      sidebar.push({
        label: 'Templates',
        iconName: 'grid',
        redirectTo: () => nav({ to: '/templates' }),
        location: 'templates',
        id: 'dashboard-sidebar-templates',
      })
    }
    if (flags && flags['task-management']) {
      sidebar.push({
        label: 'Tasks',
        iconName: 'checkbox',
        location: null,
        id: 'dashboard-sidebar-tasks',
        redirectTo: () => nav({ to: '/tasks' }),
        childrenItems: [
          {
            label: 'Dashboard',
            redirectTo: () => nav({ to: '/tasks' }),
            location: 'taskDashboard',
            id: 'dashboard-sidebar-task-dashboard',
          },
          {
            label: 'List',
            redirectTo: () => nav({ to: '/tasks/list' }),
            location: 'taskList',
            id: 'dashboard-sidebar-task-list',
          },
        ],
      })
    }
    if (canViewAssetLibrary && flags && flags['asset-management']) {
      sidebar.push({
        label: 'Asset Library',
        iconName: 'folder-open',
        redirectTo: () => nav({ to: '/assetLibrary' }),
        location: 'assetLibrary',
        id: 'dashboard-sidebar-asset-library',
      })
    }
    sidebar.push({
      label: 'Activity Feed',
      iconName: 'list',
      redirectTo: () => nav({ to: '/activityFeed' }),
      location: 'activityFeed',
      id: 'dashboard-sidebar-activity-feed',
      badges: (
        <Badges>
          <ActivityBadge count={unreadActivitiesCount} />
        </Badges>
      ),
    })
    if (
      hasActionPlanAnalytics ||
      (flags && flags['dashboard-analytics-page'] && hasTenantSettingsPermission)
    ) {
      const analyticsSidebarItem: SidebarItemStructure = {
        label: 'Analytics',
        iconName: 'bar-chart',
        location: 'analytics',
        id: 'dashboard-sidebar-analytics',
        redirectTo: () => {
          return hasActionPlanAnalytics
            ? nav({ to: '/analytics/actionPlanAnalytics' })
            : nav({ to: '/analytics/teamActivityAnalytics' })
        },
        childrenItems: [],
      }
      if (hasActionPlanAnalytics) {
        analyticsSidebarItem.childrenItems?.push({
          label: 'Action Plan',
          redirectTo: () => nav({ to: '/analytics/actionPlanAnalytics' }),
          location: 'actionPlanAnalytics',
          id: 'dashboard-sidebar-action-plan-analytics',
        })
      }
      if (flags && flags['dashboard-analytics-page'] && hasTenantSettingsPermission) {
        analyticsSidebarItem.childrenItems?.push({
          label: 'Team Activity',
          redirectTo: () => nav({ to: '/analytics/teamActivityAnalytics' }),
          location: 'teamActivityAnalytics',
          id: 'dashboard-sidebar-team-activity-analytics',
        })
      }

      sidebar.push(analyticsSidebarItem)
    }
    sidebar.push({
      label: 'My Settings',
      iconName: 'person-circle',
      location: null,
      id: 'dashboard-sidebar-my-settings',
      redirectTo: () => nav({ to: '/mySettings/profileAccount' }),
      childrenItems: [
        {
          label: 'Profile Account',
          redirectTo: () => nav({ to: '/mySettings/profileAccount' }),
          location: 'profileAccount',
          id: 'dashboard-sidebar-profile-account',
        },
        {
          label: 'Mail Notifications',
          redirectTo: () => nav({ to: '/mySettings/mailNotifications' }),
          location: 'mailNotifications',
          id: 'dashboard-sidebar-mail-notifications',
        },
        {
          label: 'Integrations',
          redirectTo: () => nav({ to: '/mySettings/integrations' }),
          location: 'integrations',
          id: 'dashboard-sidebar-integrations',
        },
      ],
    })

    if (hasUserManagePermission || hasTenantSettingsPermission) {
      const childrenItems = []
      if (hasTenantSettingsPermission) {
        childrenItems.push({
          label: 'Company Settings',
          redirectTo: () => nav({ to: '/administration/companySettings' }),
          location: 'companySettings',
          id: 'dashboard-sidebar-company-settings',
        })
      }
      if (hasUserManagePermission) {
        childrenItems.push({
          label: 'User Management',
          redirectTo: () => nav({ to: '/administration/userManagement' }),
          location: 'userManagement',
          id: 'dashboard-sidebar-user-management',
        })
      }
      if (hasTenantSettingsPermission && flags && flags['teams-management']) {
        childrenItems.push({
          label: 'Team Management',
          redirectTo: () => nav({ to: '/administration/teamManagement' }),
          location: 'teamManagement',
          id: 'dashboard-sidebar-team-management',
        })
      }

      if (flags && flags['space-custom-properties'] && hasTenantSettingsPermission) {
        childrenItems.push({
          label: 'Space Properties',
          redirectTo: () => nav({ to: '/administration/spaceProperties' }),
          location: 'spaceProperties',
          id: 'dashboard-sidebar-space-properties',
        })
      }
      if (tenant?.payload?.hasBillingDetails && hasTenantSettingsPermission) {
        childrenItems.push({
          label: 'Billing',
          location: null,
          id: 'dashboard-sidebar-customer-portal',
          redirectTo: handleCustomerPortalRedirect,
        })
      }

      sidebar.push({
        label: 'Administration',
        iconName: 'settings',
        location: null,
        id: 'dashboard-sidebar-administration',
        redirectTo: () => nav({ to: '/administration/companySettings' }),
        childrenItems: childrenItems,
      })
    }

    return sidebar
  }, [
    canManageTemplates,
    flags,
    canViewAssetLibrary,
    unreadActivitiesCount,
    hasUserManagePermission,
    hasTenantSettingsPermission,
    nav,
    hasActionPlanAnalytics,
    tenant?.payload?.hasBillingDetails,
    handleCustomerPortalRedirect,
  ])
}
