import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useOverlay } from '../../Overlay/Overlay'
import classNames from 'classnames'
import { useBreakpoints } from '../../../../utils/SizeUtils'

export interface ModalControlsCloseOptions {
  ignorePersistence?: boolean
}

export interface ModalControls {
  close: (options?: ModalControlsCloseOptions) => void
}

export interface BaseModalNeedExplicitHeight {
  min: string
  preferred: string
}

export interface BaseModalProps {
  children?: React.ReactNode
  wide?: boolean
  maxWidth?: string
  persistent?: boolean
  controlsRef?: React.MutableRefObject<ModalControls | null> | ((controls: ModalControls) => void)
  needExplicitHeight?: BaseModalNeedExplicitHeight
  className?: string
  clickOutsideToClose?: boolean
  onCloseCallback?: () => void
}

const ModalControlsContext = React.createContext<ModalControls | null>(null)

/**
 * @deprecated use Dialog
 */
export function useModalControls(): ModalControls {
  const modalControls = useContext(ModalControlsContext)

  if (!modalControls) {
    throw new Error(
      'useModalControls() is used inside a sub tree without a parent BaseModal/SimpleModal/ComplexModal.',
    )
  }

  return modalControls
}

/**
 * @deprecated use Dialog
 */
export default function BaseModal({
  children,
  wide = false,
  maxWidth,
  persistent = false,
  controlsRef,
  needExplicitHeight,
  className,
  clickOutsideToClose = true,
  onCloseCallback,
}: BaseModalProps) {
  const overlay = useOverlay()
  const [error, setError] = useState(false)
  const errorTimeoutRef = useRef<NodeJS.Timeout | null>(null)
  const { isMobile } = useBreakpoints()

  const close = useMemo<(options?: ModalControlsCloseOptions) => void>(() => {
    return (options) => {
      onCloseCallback && onCloseCallback()
      if (persistent && options?.ignorePersistence !== true) {
        if (errorTimeoutRef.current) {
          clearTimeout(errorTimeoutRef.current)
          errorTimeoutRef.current = null
        }

        setError(true)
        errorTimeoutRef.current = setTimeout(() => {
          setError(false)
        }, 100)
      } else {
        if (errorTimeoutRef.current) {
          clearTimeout(errorTimeoutRef.current)
          errorTimeoutRef.current = null
          setError(false)
        }

        overlay.hide()
      }
    }
  }, [persistent, overlay, onCloseCallback])

  const controls = useMemo<ModalControls>(
    () => ({
      close,
    }),
    [close],
  )

  useEffect(() => {
    if (controlsRef !== undefined) {
      if (typeof controlsRef === 'function') {
        controlsRef(controls)
      } else {
        controlsRef.current = controls
      }
    }
  }, [controls, controlsRef])

  return (
    <BaseModalBackground
      onMouseDown={(e) => {
        e.stopPropagation()
        if (clickOutsideToClose) {
          close()
        }
      }}
    >
      <BaseModalCard
        style={{
          height: needExplicitHeight
            ? `clamp(${needExplicitHeight.min}, ${needExplicitHeight.preferred}, calc(100% - 16px))`
            : undefined,
          maxWidth,
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
        onMouseDown={(e) => {
          e.stopPropagation()
        }}
        className={`${classNames({
          error,
          mobile: isMobile,
          wide,
        })}${className ? ` ${className}` : ''}`}
      >
        <ModalControlsContext.Provider value={controls}>{children}</ModalControlsContext.Provider>
      </BaseModalCard>
    </BaseModalBackground>
  )
}

const BaseModalBackground = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(48, 50, 58, 0.5);
  display: grid;
  place-items: center;
  pointer-events: all;
`

const BaseModalCard = styled.div`
  width: 100%;
  width: calc(100% - 16px);
  max-width: 554px;

  max-height: 100%;
  margin-top: 8px;
  margin-bottom: 8px;

  background-color: var(--theme-light);
  box-shadow:
    0px 2px 8px rgba(0, 0, 0, 0.15),
    0px 0px 28px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: relative;

  overflow-x: visible;
  overflow-y: auto;
  transition: transform 0.08s ease;
  transform-origin: center;

  &.wide {
    max-width: 976px;
  }

  &.mobile {
    min-width: calc(100% - 16px);
    max-width: calc(100% - 16px);
    height: calc(100% - 16px) !important;
  }

  &.error {
    transform: scale(1.03);
  }
`
