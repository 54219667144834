import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useEffect, useState } from 'react'
import { useReadAvailableSpaceOwners } from '../../spaces/hooks/useReadAvailableSpaceOwners'
import { GenericChartContainer } from './GenericChartContainer'
import { ChartProps } from './types'
import { useGetChart } from './useGetChart'

export type SellerAnalytics = {
  id: string
  numberOfSpaces: number
  name: string
  picture?: string
  daysSinceLastSpaceCreated: number | null
  buyerVisits: number
  shareNoVisits: number
  avgVisitsPerSpace: number
}

type SellersTableProps = {
  data: SellerAnalytics[]
}

function getInitials(name: string) {
  const split = name.split(' ')
  if (split.length >= 2) {
    return `${split[0].charAt(0)}${split[1].charAt(0)}`
  } else {
    return `${split[0].charAt(0)}${split[0].charAt(1)}`
  }
}

const columnHelper = createColumnHelper<SellerAnalytics>()
const columns = [
  columnHelper.accessor('name', {
    header: 'Name',
    cell: (info) => {
      const initials = getInitials(info.row.original.name).toLowerCase()

      let picture = /^[a-z]{1,2}$/.test(initials)
        ? `https://s.gravatar.com/avatar/21ac5ea4cf2a19ad776ac70b8671f274?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2F${encodeURIComponent(
            initials,
          )}.png`
        : // If we don't have 1-2 English-alphabet initials, default to blank image
          'https://s.gravatar.com/avatar/00000000000000000000000000000000?d=blank&f=y'
      if (info.row.original.picture) {
        picture = info.row.original.picture
      }
      return (
        <div className='flex gap-3 items-center'>
          <img src={picture} className='rounded-full w-10 h-10 border-[1px] border-grey-s2' />
          <p className='font-medium'>{info.getValue()}</p>
        </div>
      )
    },
  }),
  columnHelper.accessor('numberOfSpaces', {
    header: 'Spaces created',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('daysSinceLastSpaceCreated', {
    header: 'Days since last space created',
    cell: (info) => {
      const value = info.getValue()
      return (
        <p className={`${value === null ? 'text-grey-s4' : 'text-grey-s6'}`}>
          {value === null ? '-' : value}
        </p>
      )
    },
  }),
  columnHelper.accessor('buyerVisits', {
    header: 'Buyer visits',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor('shareNoVisits', {
    header: 'Share of spaces without visits',
    cell: (info) => `${(info.getValue() * 100).toFixed(0)}%`,
  }),
  columnHelper.accessor('avgVisitsPerSpace', {
    header: 'Visits per space',
    cell: (info) =>
      info.getValue() ? (info.getValue() as number).toFixed(2).replace('.00', '') : '0',
  }),
]

function SellersTable({ data }: SellersTableProps) {
  const table = useReactTable({
    columns: columns,
    data,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <div className='w-full text-xs'>
      <table className='w-full'>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id} className='h-12 border-b border-grey-s2'>
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} className='text-left font-bold text-grey-s6'>
                    <p>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </p>
                  </th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody className='[&>*:last-child]:border-none'>
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className='border-b border-grey-s2 h-16'>
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export function SellerAnalytics({ range }: ChartProps) {
  const [data, setData] = useState<SellerAnalytics[]>([])
  const { chartData, isLoading } = useGetChart<SellerAnalytics[]>('seller-analytics', range)
  const { availableSpaceOwners } = useReadAvailableSpaceOwners()

  useEffect(() => {
    if (chartData && availableSpaceOwners) {
      let activeUsersData: SellerAnalytics[] = []

      for (const sellerData of chartData) {
        const findUserFromAuth0 = availableSpaceOwners.find((u) => u.id.includes(sellerData.id))

        if (findUserFromAuth0) {
          activeUsersData.push({
            ...sellerData,
            name: `${findUserFromAuth0.firstName} ${findUserFromAuth0.lastName}`,
            picture: findUserFromAuth0.picture,
          })
        }
      }

      activeUsersData = activeUsersData.sort((a, b) => b.numberOfSpaces - a.numberOfSpaces)

      setData(activeUsersData)
    }
  }, [chartData, availableSpaceOwners])

  return (
    <GenericChartContainer title='Statistics by user' isLoading={isLoading} fixedHeight={false}>
      <SellersTable data={data} />
    </GenericChartContainer>
  )
}
