import { ReactNode, useMemo, useState } from 'react'
import { DataTable, Hairline, Input, InputSize, Spinner } from '@valuecase/ui-components'
import {
  ColumnDef,
  ColumnHelper,
  PaginationState,
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Tabs, TabsList, TabsTrigger } from '@valuecase/ui-components/src/components/ui/tabs'
import { cn } from '@/utils/Cn'

interface IntegrationStepProps<T> {
  items: T[]
  selectedItem?: T
  tabs: readonly string[]
  activeTab: string
  // TODO couple selected tab and item type together or make type available on row items to remove
  // this second source of truth
  itemType: string
  setActiveTab: (value: string) => void
  selectItem: (item: T, itemType: string) => void
  onSearchItems: (searchTerm: string) => void
  searchTerm: string
  initialLoadComplete: boolean
  searchStatus: 'loading' | 'error' | 'idle'
  searchErrorMessage?: string
  renderColumns: (columnHelper: ColumnHelper<T>) => ColumnDef<T, any>[]
  searchPlaceholder: string
  footerText: ReactNode
  sorting: SortingState
  onSortingChange: React.Dispatch<React.SetStateAction<SortingState>>
}

/**
 * A generic way for integrations to shows a list of items and allow a user to select an item
 * from that list.
 * @param items The items to show in the list.
 * @param selectedItem The currently selected item.
 * @param selectItem The callback to call when an item is selected.
 * @param onSearchItems The callback to call when the user searches for items.
 * @param searchTerm The current search term.
 * @param initialLoadComplete Whether the initial load of items has completed.
 * @param searchStatus The current status of the search.
 * @param searchErrorMessage The error message to show if the search failed.
 * @param renderColumns The function to call to render the columns for the table.
 * @param searchPlaceholder The placeholder to show in the search input.
 * @constructor
 * @template T The type of the items.
 */
export function IntegrationItemSelector<T>({
  items,
  tabs,
  activeTab,
  setActiveTab,
  selectItem,
  itemType,
  onSearchItems,
  searchTerm,
  initialLoadComplete,
  searchStatus,
  searchErrorMessage,
  renderColumns,
  searchPlaceholder,
  footerText,
  sorting,
  onSortingChange,
}: IntegrationStepProps<T>) {
  const columnHelper = useMemo(() => createColumnHelper<T>(), [])
  const columns = useMemo(() => renderColumns(columnHelper), [columnHelper, renderColumns])
  const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 100,
  })
  const table = useReactTable<T>({
    data: items,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange,
    onPaginationChange: setPagination,
    state: { sorting, pagination: { pageIndex, pageSize } },
  })
  const hasTabs = tabs.length > 1

  if (!initialLoadComplete) {
    return (
      <div className='flex justify-center items-center p-8 h-[406px]'>
        <Spinner />
      </div>
    )
  }

  return (
    <div
      className='bg-grey-s1 h-[406px] -mx-8 flex flex-col'
      style={{
        width: 'calc(100% + 64px)',
      }}
    >
      <Hairline className='min-h-[1px]' />
      <div className='px-8 py-6'>
        <div className={'flex flex-wrap mb-4 gap-2'}>
          <div className={'flex flex-col gap-2 w-full'}>
            {hasTabs && (
              <div className='mx-auto'>
                <Tabs value={activeTab} onValueChange={setActiveTab}>
                  <TabsList defaultValue={'deals'}>
                    {tabs.map((t) => (
                      <TabsTrigger key={t} value={t}>
                        {t}
                      </TabsTrigger>
                    ))}
                  </TabsList>
                </Tabs>
              </div>
            )}
            <div className='flex w-full'>
              <Input
                icon='search-outline'
                placeholder={searchPlaceholder}
                value={searchTerm ?? ''}
                onInput={(newValue) => {
                  onSearchItems(newValue)
                }}
                statusIndicator={searchStatus}
                statusIndicatorErrorMessage={searchErrorMessage}
                size={InputSize.LARGE}
              />
            </div>
          </div>
        </div>
        <DataTable
          table={table}
          rowClickHandler={(row) => {
            selectItem(row.original, itemType)
          }}
          tableWrapperClassName={cn('overflow-y-auto rounded-lg border border-grey-s2', {
            'max-h-[270px]': !hasTabs && !footerText,
            'max-h-[258px]': !hasTabs && footerText,
            'max-h-[248px]': hasTabs && !footerText,
            'max-h-[220px]': hasTabs && footerText,
          })}
          resetFiltersClickHandler={() => onSearchItems('')}
        />
        {footerText && <div className='text-xs font-normal text-grey-s4 mt-3'>{footerText}</div>}
      </div>
      <Hairline className='mt-auto min-h-[1px]' />
    </div>
  )
}
