import { cn } from '@valuecase/ui-components'
import { FC, ReactNode } from 'react'

export const ActionPlanAnalyticsTableHeader: FC<{
  startLabel?: ReactNode
  className?: string
}> = ({ startLabel, className }) => {
  return (
    <div
      className={cn(
        'sticky top-0 z-10 grid grid-cols-subgrid col-span-full text-xs font-bold',
        className,
      )}
    >
      <div className='py-2 px-4 flex items-center'>{startLabel}</div>
      <div className='py-2 px-4'>
        <span>Count of Spaces</span>
        <span className='block font-normal text-xxs leading-[14px] text-grey-s5'>Total</span>
      </div>
      <div className='py-2 px-4'>
        <span>Completed</span>
        <span className='block font-normal text-xxs leading-[14px] text-grey-s5'>Total %</span>
      </div>
      <div className='py-2 px-4'>
        <span>Completion Days</span>
        <span className='block font-normal text-xxs leading-[14px] text-grey-s5'>
          Average days to complete
        </span>
      </div>
      <div className='py-2 px-4'>
        <span>Quickest Quartile</span>
        <span className='block font-normal text-xxs leading-[14px] text-grey-s5'>
          Days to complete
        </span>
      </div>
      <div className='py-2 px-4'>
        <span>Slowest Quartile</span>
        <span className='block font-normal text-xxs leading-[14px] text-grey-s5'>
          Days to complete
        </span>
      </div>
      <div className=''></div>
    </div>
  )
}
