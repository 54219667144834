import {
  Children,
  cloneElement,
  isValidElement,
  MouseEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import { BaseDropdown, DropdownProps } from '../BaseDropdown/BaseDropdown'

export interface AutoDropdownProps extends Omit<Omit<DropdownProps, 'opened'>, 'onTriggerClick'> {
  disabled?: boolean
  onTriggerClick?: (isOpen: boolean) => void
}

export function AutoDropdown({
  trigger,
  children,
  onChange,
  middleware,
  additionalMiddleware,
  placements,
  disabled,
  onTriggerClick,
}: AutoDropdownProps) {
  const [opened, setOpened] = useState(false)

  useEffect(() => {
    onChange?.(opened)
  }, [opened]) /* eslint-disable-line */ /* TODO @ fix exhaustive-deps */

  const mapChildren = (children?: ReactNode): ReactNode => {
    return Children.map(children, (child) => {
      if (!isValidElement(child)) {
        return child
      }
      if (child.props.onClick || child.props.children) {
        return cloneElement(child, {
          onClick: child.props.onClick
            ? function (e: MouseEvent<HTMLElement>) {
                child.props.onClick(e)

                if (!e.defaultPrevented) {
                  setOpened(false)
                }
              }
            : undefined,
          children: child.props.children ? mapChildren(child.props.children) : undefined,
        } as any)
      }
      return child
    })
  }

  return (
    <BaseDropdown
      opened={opened}
      onChange={(v) => {
        setOpened(v)
      }}
      trigger={trigger}
      middleware={middleware}
      additionalMiddleware={additionalMiddleware}
      placements={placements}
      onTriggerClick={(e) => {
        e.stopPropagation()
        const newState = !opened

        if (!disabled) {
          setOpened(newState)
        }
        if (onTriggerClick) {
          onTriggerClick(newState)
        }
      }}
    >
      {mapChildren(children)}
    </BaseDropdown>
  )
}
