import { useLDflags } from '@/launchdarkly/Launchdarkly'
import { Switch } from '@valuecase/ui-components'
import { useMemo } from 'react'
import { SettingsInfo } from './SettingsInfo'
import useToggleEngagementScoreForTenant from './hooks/useToggleEngagementScoreForTenant'

const EngagementScoreSettings = () => {
  const { flags } = useLDflags()
  const { toggleEngagementScoreForTenant, isLoading } = useToggleEngagementScoreForTenant()

  const engagementScoreEnabled = useMemo(() => flags?.['space-engagement-scoring'], [flags])

  const handleToggle = (newValue: boolean) => toggleEngagementScoreForTenant({ enable: newValue })

  return (
    <div className='flex justify-between w-full items-start'>
      <SettingsInfo
        title='Engagement Score'
        description='Enable or disable the display of the engagement score for your company.'
      />
      <div className='relative flex'>
        <Switch checked={engagementScoreEnabled} onChange={handleToggle} disabled={isLoading} />
      </div>
    </div>
  )
}

export default EngagementScoreSettings
