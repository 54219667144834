import { UpdateAllUserNotificationSettingsBody } from '@valuecase/seller-backend'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'

export async function updateUserNotificationSettingsFn(
  notificationSettings: UpdateAllUserNotificationSettingsBody,
) {
  const res = await spacesApi
    .patch(`/sellers/sellerNotificationsSettings`, notificationSettings)
    .then((res) => res.data)
  return res
}

export function useUpdateUserNotificationSettings() {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: updateUserNotificationSettingsFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['readUserNotificationSettings'] })
    },
  })
  return {
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    updateUserNotificationSettings: (notificationSettings: UpdateAllUserNotificationSettingsBody) =>
      mutation.mutate(notificationSettings),
    updateUserNotificationSettingsAsync: (
      notificationSettings: UpdateAllUserNotificationSettingsBody,
    ) => mutation.mutateAsync(notificationSettings),
  }
}
