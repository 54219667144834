import { HeaderFooterScrollableModal, ModalControls } from '@valuecase/ui-components'
import { FC, useState } from 'react'
import TemplateEditor from './TemplateEditor'

export type EditTemplateData = {
  id: string
  title: string
  description?: string
  icon: string
  rootNodeId?: string
  teamIds?: string[]
}

export interface TemplateEditorModalProps {
  controlsRef: React.MutableRefObject<ModalControls | null>
  templateData: EditTemplateData
}

const TemplateEditorModal: FC<TemplateEditorModalProps> = ({ controlsRef, templateData }) => {
  const [modalIsPersistent, setModalIsPersistent] = useState<boolean>(false)

  return (
    <HeaderFooterScrollableModal
      persistent={modalIsPersistent}
      controlsRef={controlsRef}
      wide={true}
      needExplicitHeight={{ min: '300px', preferred: '656px' }}
    >
      <TemplateEditor setIsModalPersistent={setModalIsPersistent} templateData={templateData} />
    </HeaderFooterScrollableModal>
  )
}

export default TemplateEditorModal
