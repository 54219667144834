import React, { useEffect, useMemo, useState } from 'react'
import {
  NotificationsSettings,
  useReadUserNotificationSettings,
} from '../profileAccount/hooks/useReadUserNotificationSettings'
import { useUpdateUserNotificationSettings } from '../profileAccount/hooks/useUpdateUserNotificationSettings'
import { SettingsStack } from '../../ui-components/SettingsStack/SettingsStack'
import { Switch } from '@valuecase/ui-components'
import { useNotifications } from '../../utils/Notifications/Notifications'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import { useLDflags } from '@/launchdarkly/Launchdarkly'

const MailNotifications = () => {
  const [mixpanel_pageVisitedTracked, set_mixpanel_pageVisitedTracked] = useState(false)
  const { flags } = useLDflags()
  const { trackEvent } = useTrackEvent()
  const { notificationSettings, error: readNotificationSettingsError } =
    useReadUserNotificationSettings()
  const {
    updateUserNotificationSettingsAsync,
    error: updateNotificationSettingsError,
    isLoading: isUpdateLoading,
  } = useUpdateUserNotificationSettings()
  const { success } = useNotifications()

  const update = useMemo(
    () => async (field: keyof NotificationsSettings, newValue: boolean) => {
      if (!notificationSettings) return
      await updateUserNotificationSettingsAsync({
        ...notificationSettings,
        [field]: newValue,
      })
      success('Notification settings were updated successfully.')
    },
    [success, notificationSettings, updateUserNotificationSettingsAsync],
  )

  const digestSettings = useMemo(() => {
    if (!(flags?.['seller-digest-mails'] ?? false)) {
      return []
    }

    return [
      {
        title: 'File upload in files block',
        description:
          'Get an immediate notification when a customer uploads a file to a files block.',
        rightChild: (
          <Switch
            checked={notificationSettings?.buyerFilesBlockUpload}
            onChange={(newValue) => {
              trackEvent({
                event: `notifications-${newValue ? 'enable' : 'disable'}`,
                eventProperties: {
                  indexPageName: 'Mail Notifications',
                  mailNotificationType: 'Buyer File block file upload',
                },
              })
              update('buyerFilesBlockUpload', newValue)
            }}
          />
        ),
      },
      {
        title: 'Daily Digest',
        description: 'Stay up to date with a daily summary of all the activities in your spaces.',
        rightChild: (
          <Switch
            checked={notificationSettings?.dailyDigest}
            onChange={(newValue) => {
              trackEvent({
                event: `notifications-${newValue ? 'enable' : 'disable'}`,
                eventProperties: {
                  indexPageName: 'Mail Notifications',
                  mailNotificationType: 'Daily Digest',
                },
              })
              update('dailyDigest', newValue)
            }}
          />
        ),
      },
      {
        title: 'Weekly Digest',
        description: 'Stay up to date with a weekly summary of all the activities in your spaces.',
        rightChild: (
          <Switch
            checked={notificationSettings?.weeklyDigest}
            onChange={(newValue) => {
              trackEvent({
                event: `notifications-${newValue ? 'enable' : 'disable'}`,
                eventProperties: {
                  indexPageName: 'Mail Notifications',
                  mailNotificationType: 'Weekly Digest',
                },
              })
              update('weeklyDigest', newValue)
            }}
          />
        ),
      },
    ]
  }, [
    flags,
    notificationSettings?.buyerFilesBlockUpload,
    notificationSettings?.dailyDigest,
    notificationSettings?.weeklyDigest,
    trackEvent,
    update,
  ])

  //mixpanel track  page visit
  useEffect(() => {
    if (!mixpanel_pageVisitedTracked) {
      trackEvent({
        event: 'dashboard-visit',
        eventProperties: {
          indexPageName: 'Mail Notifications page',
        },
      }).finally(() => {
        set_mixpanel_pageVisitedTracked(true)
      })
    }
  }, [mixpanel_pageVisitedTracked, trackEvent, set_mixpanel_pageVisitedTracked])

  return (
    <SettingsStack
      data={[
        {
          title: 'First Buyer Visit',
          description:
            'Never miss a chance to connect with potential buyers - we will let you know when someone visits a space for the first time.',
          rightChild: (
            <Switch
              checked={notificationSettings?.firstVisit}
              onChange={(newValue) => {
                trackEvent({
                  event: `notifications-${newValue ? 'enable' : 'disable'}`,
                  eventProperties: {
                    indexPageName: 'Mail Notifications',
                    mailNotificationType: 'First Buyer Visit',
                  },
                })
                update('firstVisit', newValue)
              }}
            />
          ),
        },
        {
          title: 'New comment',
          description: 'React fast and get notified when someone leaves a comment in your space.',
          rightChild: (
            <Switch
              checked={notificationSettings?.newComments}
              onChange={(newValue) => {
                trackEvent({
                  event: `notifications-${newValue ? 'enable' : 'disable'}`,
                  eventProperties: {
                    indexPageName: 'Mail Notifications',
                    mailNotificationType: 'New comment',
                  },
                })
                update('newComments', newValue)
              }}
            />
          ),
        },
        {
          title: 'Action Plan Activity',
          description:
            'Stay on top of your action plans with email updates on all buyer activities. To manage email notifications for your stakeholders, enter the settings inside your spaces. ',
          rightChild: (
            <Switch
              checked={notificationSettings?.actionPlanActivities}
              onChange={(newValue) => {
                trackEvent({
                  event: `notifications-${newValue ? 'enable' : 'disable'}`,
                  eventProperties: {
                    indexPageName: 'Mail Notifications',
                    mailNotificationType: 'Action Plan Activity',
                  },
                })
                update('actionPlanActivities', newValue)
              }}
            />
          ),
        },
        ...digestSettings,
      ]}
      title={'E-Mail Notifications'}
      description={
        'Never miss an important update from your spaces. Customize your preferences to receive notifications on the things that are most relevant to you.'
      }
    />
  )
}

export default MailNotifications
