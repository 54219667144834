import styled from 'styled-components'

export const ActivityTopbarIndicatorContainer = styled.div`
  background-color: ${({ theme }) => theme.primary.s2};
  border-radius: 4px;
  padding: 4px 4px;
  margin-left: 8px;
  min-width: 24px;

  p {
    color: ${({ theme }) => theme.primary.s5};
    margin: 0;
    padding: 0;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
  }
`
export function ActivityBadge({ count }: { count: number }) {
  if (count === 0) {
    return null
  }

  return (
    <ActivityTopbarIndicatorContainer>
      <p>+{count}</p>
    </ActivityTopbarIndicatorContainer>
  )
}
