import { ReactNode } from 'react'
import { cn } from '../../lib'
import { Chip, ChipClose, ChipLabel } from '../../components/ui/chip'
import { AvatarThumbnail, UserAvatarProps } from '../../components/ui'
import { getCompanyInitials } from '@valuecase/common'

export function UserChipList({ children, className }: { children: ReactNode; className?: string }) {
  return <div className={cn('flex flex-wrap gap-2', className)}>{children}</div>
}

export function UserChip({
  name,
  photoUrl,
  avatarVariant,
  onRemove,
}: {
  name: string
  photoUrl?: string
  avatarVariant: UserAvatarProps['variant']
  onRemove?: () => void
}) {
  return (
    <Chip>
      {photoUrl ? (
        <AvatarThumbnail shape={'CIRCLE'} type={'image'} imageUrl={photoUrl} size={'SMALL'} />
      ) : (
        <AvatarThumbnail
          shape={'CIRCLE'}
          type={'user'}
          initials={getCompanyInitials(name)}
          variant={avatarVariant}
          size={'SMALL'}
        />
      )}
      <ChipLabel>{name}</ChipLabel>
      {onRemove && <ChipClose onClick={onRemove} />}
    </Chip>
  )
}
