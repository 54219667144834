import { useQuery } from '@tanstack/react-query'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'

type FilterType = 'own' | 'all'

export function activityFeedQueryFn(filter: FilterType, page: number, perPage: number) {
  return () =>
    spacesApi
      .post(`/activity/all-across-spaces`, {
        filter,
        skip: page * perPage,
        take: perPage,
      })
      .then((res) => res.data)
}

export function useActivityFeed(showOwnSpaces: boolean, page: number, perPage: number) {
  const filter = showOwnSpaces ? 'own' : 'all'
  const queryKey = ['activityFeed', filter, page]
  const query = useQuery({
    queryKey,
    queryFn: activityFeedQueryFn(filter, page, perPage),
    retry: 1,
    staleTime: 1000 * 10, // 10 seconds
  })

  return {
    isLoading: query.isLoading,
    isReloading: query.isRefetching && query.isStale,
    isError: query.isError,
    refetch: query.refetch,
    error: query.error,
    activities: query.data,
    isFetched: query.isFetched,
  }
}

export function activityFeedIndicatorQueryFn() {
  return () => spacesApi.get(`/activity/own-unread-count`).then((res) => res.data)
}

export function useActivityFeedCount() {
  const queryKey = ['activityFeedCount']
  const query = useQuery({
    queryKey,
    queryFn: activityFeedIndicatorQueryFn(),
    retry: 1,
    staleTime: 1000 * 10, // 10 seconds
    refetchInterval: 1000 * 30, // 30 seconds
  })

  return {
    isLoading: query.isLoading,
    isReloading: query.isRefetching && query.isStale,
    isError: query.isError,
    refetch: query.refetch,
    error: query.error,
    unreadCount: query.data,
    isFetched: query.isFetched,
  }
}
