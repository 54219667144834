import { Button, ModalControls, SimpleModal, useOverlay } from '@valuecase/ui-components'
import { useRef } from 'react'
import { PrivacyPolicyImprintEditModal } from './PrivacyPolicyImprintEditModal'
import { SettingsInfo } from './SettingsInfo'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'

function Description() {
  return (
    <p className='max-w-[350px] text-sm text-grey-s5 leading-6'>
      Ensure compliance with data privacy regulations by uploading your privacy policy and imprint
      to the footer of your spaces, which is also required to enable personalized tracking of buyer
      activities.{' '}
      <a
        href='https://help.valuecase.de/en/articles/77746-set-your-privacy-policy-and-imprint'
        target='_blank'
        rel='noreferrer'
        className='font-bold text-primary-s5'
      >
        Read more in our help center.
      </a>
    </p>
  )
}

export const PrivacyPolicyImprintSetting = () => {
  const overlay = useOverlay()
  const controls = useRef<ModalControls | null>(null)
  const { trackEvent } = useTrackEvent()

  function handleEditClick() {
    trackEvent({
      event: 'settings-privacy_imprint-open_modal',
      eventProperties: {
        indexPageName: 'Company Settings',
      },
    })
    overlay.show(
      <SimpleModal
        controlsRef={controls}
        additionalStyleClasses={{
          layout: 'spacecreationflow-modal',
        }}
        body={<PrivacyPolicyImprintEditModal />}
        maxWidth='978px'
        wide={true}
      />,
    )
  }

  return (
    <div className='flex justify-between w-full items-start'>
      <SettingsInfo title='Privacy Policy & Imprint' description={<Description />} />

      <div className='flex'>
        <Button icon='create-outline' iconPosition='right' label='Edit' onClick={handleEditClick} />
      </div>
    </div>
  )
}
