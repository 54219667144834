import { Tooltip, TooltipContent, TooltipTrigger } from './tooltip'
import {
  Children,
  cloneElement,
  Fragment,
  isValidElement,
  ReactNode,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { cn } from '../../lib'

export function OneLineTruncatedText({
  children,
  textOnOverflow,
  className,
}: {
  /**
   * The text to display.
   */
  children: ReactNode
  /**
   * Optional text to display in the tooltip when the text overflows.
   * If not provided, the string provided for the text prop will be displayed in the tooltip.
   */
  textOnOverflow?: ReactNode
  className?: string
}) {
  const textRef = useRef<HTMLParagraphElement>(null)
  const [showTooltip, setShowTooltip] = useState<false | undefined>(false)
  const _textOnOverflow = useMemo(() => textOnOverflow ?? children, [children, textOnOverflow])

  useLayoutEffect(() => {
    const element = textRef.current
    if (!element?.scrollHeight || !element?.clientHeight) {
      setShowTooltip(false)
      return
    }

    // Check if the text is overflowing by comparing scrollHeight and clientHeight to show a tooltip
    if (element.scrollHeight > element.clientHeight) {
      setShowTooltip(undefined) // undefined means that the tooltip component will decide whether to show or not, so it shows it on hover
      return
    }

    setShowTooltip(false)
  }, [children])

  return (
    <Tooltip open={showTooltip}>
      <TooltipTrigger asChild>
        <p
          ref={textRef}
          className={cn(
            'max-h-8 line-clamp-1 overflow-hidden overflow-ellipsis break-all',
            className,
          )}
        >
          {children}
        </p>
      </TooltipTrigger>
      <TooltipContent align='start' className={'max-w-56 overflow-ellipsis break-words'}>
        {_textOnOverflow}
      </TooltipContent>
    </Tooltip>
  )
}

interface HighlightProps {
  children: ReactNode
  searchTerm: string
}

/**
 * Highlight the search term in the text of the children of this component.
 */
export const Highlight: React.FC<HighlightProps> = ({ children, searchTerm }) => {
  /**
   * Applies highlighting to the search term in the text by wrapping it in a <mark> tag.
   */
  const applyHighlight = (text: string): ReactNode => {
    if (!searchTerm.trim()) {
      return text // No highlighting if there's no search query
    }
    const regex = new RegExp(`(${searchTerm})`, 'gi')
    const parts = text.split(regex)
    return (
      <span>
        {parts.map((part, i) =>
          regex.test(part) ? <mark key={i}>{part}</mark> : <span key={i}>{part}</span>,
        )}
      </span>
    )
  }

  /**
   * Recursively process the children of the component to apply highlighting.
   */
  const processNodeForHighlighting = (node: ReactNode): ReactNode => {
    if (typeof node === 'string') {
      return applyHighlight(node)
    }

    if (typeof node === 'number') {
      return node // Numbers don't need highlighting
    }

    if (isValidElement<{ children: ReactNode }>(node) && node.props.children) {
      // Recursively process the children of the element
      return cloneElement<{ children: ReactNode }>(node, {
        children: Children.map(node.props.children, processNodeForHighlighting),
      })
    } else if (isValidElement(node)) {
      // If the element doesn't have children, return it as is
      return node
    }

    if (Array.isArray(node)) {
      // If it's an array, recursively process each item
      return node.map((item, index) => (
        <Fragment key={index}>{processNodeForHighlighting(item)}</Fragment>
      ))
    }

    if (node && typeof node === 'object' && Symbol.iterator in node) {
      // If it's an iterable, recursively process each item
      return Array.from(node).map((item, index) => (
        <Fragment key={index}>{processNodeForHighlighting(item)}</Fragment>
      ))
    }

    // Handle other types (boolean, null, undefined) by returning them as is
    return node
  }

  return <>{processNodeForHighlighting(children)}</>
}
