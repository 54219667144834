import styled from 'styled-components'

const EmbeddViewer = styled.div``

const IFrameWrapper = styled.div<{ $maxHeight?: number }>`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  ${({ $maxHeight: maxHeight }) => {
    if (maxHeight) {
      return `top: calc((${maxHeight}px - 56.25%))`
    }
  }}
`

const LoomWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
`

const LoomIframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-style: hidden;
`
export const S = { LoomIframe, LoomWrapper, EmbeddViewer, IFrameWrapper }
