import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import { ComponentPropsWithoutRef, ElementRef, forwardRef, ReactNode } from 'react'
import { cn } from '../../lib/utils'

const TooltipProvider = TooltipPrimitive.Provider

const RadixTooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = forwardRef<
  ElementRef<typeof TooltipPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={8}
    side='bottom'
    className={cn(
      'z-50 overflow-hidden rounded-md bg-grey-s6 px-2 py-1 text-xs',
      'text-white font-medium animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out',
      'data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2',
      'data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2',
      'data-[side=top]:slide-in-from-bottom-2',
      className,
    )}
    {...props}
  >
    {children}
  </TooltipPrimitive.Content>
))
TooltipContent.displayName = TooltipPrimitive.Content.displayName

type TooltipProps = React.ComponentPropsWithoutRef<typeof RadixTooltip> & {
  children: ReactNode
}

const Tooltip = ({ children, ...props }: TooltipProps) => (
  <TooltipProvider delayDuration={200} skipDelayDuration={400}>
    <RadixTooltip {...props}>{children}</RadixTooltip>
  </TooltipProvider>
)

Tooltip.displayName = 'Tooltip'

export { Tooltip, TooltipTrigger, TooltipContent }
