import { cn } from '../../lib/utils'
import { InputHTMLAttributes, forwardRef } from 'react'

interface LabelRadioInputProps extends InputHTMLAttributes<HTMLInputElement> {
  screenReaderText?: string
}

// Wraps a label around a radio input
export const LabelRadioInput = forwardRef<HTMLLabelElement, LabelRadioInputProps>(
  ({ screenReaderText, className, ...props }, ref) => {
    return (
      <label ref={ref} className={cn('flex items-center justify-center', className)}>
        <input type='radio' className='cursor-pointer' {...props} />
        {!!screenReaderText && <span className='sr-only'>{screenReaderText}</span>}
      </label>
    )
  },
)
LabelRadioInput.displayName = 'LabelRadioInput'
