import { MenuDropdownItem, ModalControls, SimpleModal, useOverlay } from '@valuecase/ui-components'
import { useRef } from 'react'
import { TemplateArchivingFlow, TemplateArchivingFlowProps } from './TemplateArchivingFlow'

export function TemplateArchivingTrigger(props: TemplateArchivingFlowProps) {
  const overlay = useOverlay()
  const controls = useRef<ModalControls | null>(null)
  function onClick() {
    overlay.show(
      <SimpleModal
        controlsRef={controls}
        header={
          <h2 className={'color-grey-s2 font-extrabold text-2xl my-6 text-center'}>
            {props.isCurrentlyArchived ? 'Unarchive Template' : 'Archive Template'}
          </h2>
        }
        body={
          <TemplateArchivingFlow
            {...props}
            onClose={() => {
              controls.current?.close()
            }}
          />
        }
        maxWidth='554px'
        wide={true}
      />,
    )
  }

  return (
    <MenuDropdownItem
      LeftIcon={'file-tray-outline'}
      label={props.isCurrentlyArchived ? 'Unarchive template' : 'Archive template'}
      onClick={onClick}
    />
  )
}
