import React, { useCallback, useImperativeHandle, useRef } from 'react'
import BaseModal, { BaseModalNeedExplicitHeight, ModalControls } from '../base/BaseModal'
import styled from 'styled-components'
import {
  ScrollByFunction,
  useOverflowDetectorAndScrollControls,
} from '../../Overflow/DetectOverflow/OverflowDetectorAndScrollControls'
import { Button } from '../../Button'
import { DirectionOverflow } from '../../Overflow/DetectOverflow/OverflowDetector'

export interface HeaderFooterScrollableModalContentOverflow {
  scrollBy: ScrollByFunction
  horizontal: DirectionOverflow
  vertical: DirectionOverflow
}

export interface HeaderFooterScrollableModalProps {
  showBack?: boolean
  onBack?: () => void
  wide?: boolean
  persistent?: boolean
  header?: React.ReactNode
  footer?: React.ReactNode
  children?: React.ReactNode
  needExplicitHeight?: BaseModalNeedExplicitHeight
  contentOverflowRef?: React.Ref<HeaderFooterScrollableModalContentOverflow>
  controlsRef?: React.Ref<ModalControls>
  clickOutsideToClose?: boolean
}

/**
 * @deprecated use Dialog
 */
export function HeaderFooterScrollableModal({
  showBack,
  onBack,
  wide,
  persistent,
  header,
  footer,
  children,
  needExplicitHeight,
  contentOverflowRef,
  controlsRef,
  clickOutsideToClose = true,
}: HeaderFooterScrollableModalProps) {
  const internalControlsRef = useRef<ModalControls | null>(null)

  const controlsRefCallback = useCallback<(controls: ModalControls) => void>(
    (controls) => {
      internalControlsRef.current = controls
      if (controlsRef) {
        if (typeof controlsRef === 'function') {
          controlsRef(controls)
        } else {
          ;(controlsRef as any).current = controls
        }
      }
    },
    [controlsRef],
  )

  const {
    ref: overflowDetectorRef,
    scrollBy,
    vertical,
    horizontal,
  } = useOverflowDetectorAndScrollControls()

  useImperativeHandle(
    contentOverflowRef,
    () => ({
      scrollBy,
      horizontal,
      vertical,
    }),
    [scrollBy, horizontal, vertical],
  )

  return (
    <BaseModal
      controlsRef={controlsRefCallback}
      needExplicitHeight={needExplicitHeight}
      wide={wide}
      persistent={persistent}
      clickOutsideToClose={clickOutsideToClose}
    >
      <Layout>
        <HeaderControls>
          {showBack ? (
            <Button
              icon='arrow-back'
              size={'medium'}
              style={'flat'}
              onClick={onBack}
              cssStyle={{ position: 'absolute', left: '24px', top: '24px', zIndex: '8' }}
            />
          ) : (
            <span style={{ position: 'absolute', top: '0', left: '0' }}></span>
          )}
          <Button
            cssStyle={{
              position: 'absolute',
              right: '24px',
              top: '24px',
              zIndex: '9',
            }}
            icon='close'
            size={'large'}
            style={'flat'}
            onClick={() => {
              internalControlsRef.current?.close()
            }}
          />
        </HeaderControls>

        {header && <div>{header}</div>}

        <ContentContainer ref={overflowDetectorRef}>{children}</ContentContainer>

        {footer && <div>{footer}</div>}
      </Layout>
    </BaseModal>
  )
}

const Layout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
`

const HeaderControls = styled.div`
  padding: 48px 40px 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

const ContentContainer = styled.div`
  flex-grow: 1;
  height: 0;
  min-height: 100px;
  overflow: auto;
`
