import { Button } from '@valuecase/ui-components'
import ReactDOM from 'react-dom'
import ValuecaseDefaultTheme from '../global-styles/ValuecaseDefaultTheme'
import ValuecaseThemeProvider from '../global-styles/ValuecaseThemeProvider'
import { ReactNode, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import styled from 'styled-components'

const SimpleModal2 = ({
  isShown,
  onClose,
  children,
  onExit,
  preventClosing,
}: {
  isShown: boolean
  onClose: () => void
  children: ReactNode
  onExit?: () => void
  preventClosing?: boolean
}) => {
  const nodeRef = useRef(null)

  return ReactDOM.createPortal(
    // Behind-modal background
    <ValuecaseThemeProvider theme={ValuecaseDefaultTheme}>
      <AnimatedBackdropWrapper>
        <CSSTransition
          nodeRef={nodeRef}
          in={isShown}
          timeout={200}
          classNames='modal-backdrop'
          unmountOnExit={true}
          onExited={() => onExit?.()}
        >
          <div
            ref={nodeRef}
            className={
              'fixed inset-0 flex top-0 left-0 w-full h-full overflow-hidden justify-center items-center bg-[rgba(48,50,58,0.5)]'
            }
            onClick={(event) => {
              event.stopPropagation()
              if (preventClosing || !isShown) {
                return
              }
              onClose()
            }}
          >
            {/* Modal */}
            <div
              onClick={(event) => event.stopPropagation()}
              className='w-[calc(100%-16px)] max-w-[554px] rounded-lg relative bg-white text-center'
            >
              {children}
              {!preventClosing && (
                <Button
                  cssStyle={{
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                  }}
                  icon='close'
                  size={'large'}
                  style={'flat'}
                  onClick={() => {
                    if (!isShown) {
                      return
                    }
                    onClose()
                  }}
                />
              )}
            </div>
          </div>
        </CSSTransition>
      </AnimatedBackdropWrapper>
    </ValuecaseThemeProvider>,
    document.body,
  )
}

export default SimpleModal2

const AnimatedBackdropWrapper = styled.div`
  .modal-backdrop-enter {
    opacity: 0;
  }
  .modal-backdrop-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
  .modal-backdrop-exit {
    opacity: 1;
  }
  .modal-backdrop-exit-active {
    opacity: 0;
    transition: opacity 200ms;
  }
`
