import { useQuery } from '@tanstack/react-query'
import SellerApi from '../../../api/SellerApi'
import { HubSpotCurrentUser } from './types/HubSpotCurrentUser'

async function readHubSpotCurrentUser() {
  const res = await SellerApi.get<HubSpotCurrentUser, undefined>('integrations/hubspot/me')
  return res.payload || null
}

/**
 * Queries the current HubSpot user id and portal id for the HubSpot user linked to the
 * current Valuecase user via OAuth2.
 */
export function useReadHubSpotCurrentUser(opts: { enabled?: boolean }) {
  const { enabled } = opts

  const query = useQuery({
    queryKey: ['readHubSpotCurrentUser'],
    queryFn: readHubSpotCurrentUser,
    enabled,
  })

  return {
    isLoading: query.isLoading,
    isReloading: query.isRefetching && query.isStale,
    isError: query.isError,
    error: query.error,
    hubSpotCurrentUser: query.data,
    isFetched: query.isFetched,
    refetch: query.refetch,
  }
}
