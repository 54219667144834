export function getInitialsLogoUrl(initials: string) {
  const _lowercaseInitials = initials.toLowerCase()
  // Auth0 Gravatars only work with 1-2 English-alphabet lowercase initials
  const initialsLogo = /^[a-z]{1,2}$/.test(_lowercaseInitials)
    ? `https://s.gravatar.com/avatar/21ac5ea4cf2a19ad776ac70b8671f274?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2F${encodeURIComponent(
        _lowercaseInitials,
      )}.png`
    : // If we don't have 1-2 English-alphabet initials, default to blank image
      'https://s.gravatar.com/avatar/00000000000000000000000000000000?d=blank&f=y'
  return initialsLogo
}
