import { ReadSpacesListDTO } from '@/modules/spaces/hooks/types'
import { useUpdateSpaceTeams } from '@/modules/spaces/hooks/useUpdateSpaceTeams'
import TeamSelection from '@/modules/teams/dialogs/TeamSelection'
import useReadTeams from '@/modules/teams/hooks/useReadTeams'
import useSelectedTeams from '@/modules/teams/hooks/useSelectedTeams'
import { Dialog, DialogContent, DialogFooter, DialogTitle } from '@valuecase/ui-components'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'
import { useState } from 'react'

const EditSpaceTeamsDialog = ({
  onOpenChange,
  space,
}: {
  onOpenChange: (open: boolean) => void
  space: ReadSpacesListDTO
}) => {
  const { data: teams } = useReadTeams()
  const { selectedTeams, toggleTeam } = useSelectedTeams(space.teams)
  const [isOpen, setIsOpen] = useState(false)
  const { updateTeams } = useUpdateSpaceTeams()

  const handleUpdateSpaceTeams = async () => {
    await updateTeams({
      spaceId: space.id,
      teamIds: [...selectedTeams].map((team) => team.id),
    })
    onOpenChange(false)
  }

  return (
    <Dialog open={!!space} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogTitle className='w-full text-center'>Edit Teams for {space.companyName}</DialogTitle>

        <TeamSelection
          teams={teams ?? []}
          selectedTeams={selectedTeams}
          toggleTeam={toggleTeam}
          isOpen={isOpen}
          toggle={(isOpen) => setIsOpen(isOpen)}
        />
        <DialogFooter>
          <div className='flex flex-row gap-3 items-center w-full px-5'>
            <Button2
              leadingIcon='close-circle-outline'
              variant={'outlined'}
              onClick={() => onOpenChange(false)}
              className='w-full'
            >
              Cancel
            </Button2>
            <Button2 className='w-full ' onClick={handleUpdateSpaceTeams}>
              Save changes
            </Button2>
          </div>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default EditSpaceTeamsDialog
