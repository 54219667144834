import { useQuery } from '@tanstack/react-query'
import UserManagementApi from '../../../api/resources/UserManagementApi'
import { User } from '@valuecase/common'

async function readUsersQueryFn() {
  const res = await UserManagementApi.listUserOfCurrentTenant()
  return res.payload as User[]
}

export function useReadUsers() {
  const query = useQuery({
    queryKey: ['readUsers'],
    queryFn: readUsersQueryFn,
  })

  return {
    isLoading: query.isLoading,
    isReloading: query.isRefetching && query.isStale,
    isError: query.isError,
    error: query.error,
    users: query.data,
    isFetched: query.isFetched,
    refetch: query.refetch,
  }
}
