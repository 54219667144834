import Imprint from './imprint/Imprint'
import Privacy from './privacy/Privacy'

const ImprintAnPrivacy = () => {
  return (
    <div style={{ display: 'flex', gap: '8px' }}>
      <Imprint />
      <br /> <h1>&</h1> <br />
      <Privacy />
    </div>
  )
}

export default ImprintAnPrivacy
