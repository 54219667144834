import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UpdateTemplateQueryTemplateInput } from '../types/CreateTemplateQueryTemplateInput'
import { UpdateTemplateReqDTO } from './types/UpdateTemplateReqDTO'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'

export async function updateTemplateQueryFn(templateInput: UpdateTemplateQueryTemplateInput) {
  const { ...template } = templateInput
  const templateDto: UpdateTemplateReqDTO = {
    data: {
      description: template.description,
      title: template.title,
      icon: {
        source: 'emoji-native',
        emojiNative: template.icon,
      },
    },
    teamIds: template.teamIds,
  }
  if (typeof templateInput.archived === 'boolean') {
    templateDto.unversionedData = {
      archived: template.archived,
    }
  }
  await spacesApi.patch(`/templates/${template.templateId}`, templateDto)
}

export function useUpdateTemplate(props?: { onSuccess: () => void; onError: () => void }) {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: updateTemplateQueryFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['readTemplates'] })
      if (props?.onSuccess) {
        props.onSuccess()
      }
    },
    onError: () => {
      if (props?.onError) {
        props.onError()
      }
    },
  })
  return {
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    updateTemplate: (templateInput: UpdateTemplateQueryTemplateInput) =>
      mutation.mutate(templateInput),
    updateTemplateAsync: async (templateInput: UpdateTemplateQueryTemplateInput) =>
      mutation.mutateAsync(templateInput),
  }
}
