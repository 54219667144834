import styled from 'styled-components'

export const MenuDropdownCard = styled.div`
  box-shadow: 0 6px 16px -4px rgba(0, 0, 0, 0.1), 0 2px 6px -2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 6px;
  width: fit-content;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid var(--theme-grey-s2);
  font-size: 12px;
`
