import { FC, useEffect, useMemo, useState } from 'react'
import { UpcomingTasksColumn } from './UpcomingTasksColumn'
import { NextUnscheduledTasksColumn } from './UnscheduledTasksColumn'
import { OverdueTasksColumn } from './OverdueTasksColumn'
import { NoTasksExistEmptyState } from './NoTasksExistEmptyState'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'
import { ProgressBar } from '@/modules/tasks/ProgressBar'
import { PageHeader } from '@/ui-components/PageHeader'
import {
  useUpcomingTasks,
  useOverdueTasks,
  useNextUnscheduledTasks,
  defaultTaskSearchTimeRange,
} from '@valuecase/ui-components'
import { DateTime } from 'luxon'
import { TaskListTimeFilter } from './TaskListTimeFilter'
import { useProgressBarQuery } from './useProgressBarQuery'
import { useTasksContext } from './TasksContext'

export const TaskDashboard: FC = () => {
  const { trackEvent } = useTrackEvent()
  const [visitTracked, setVisitTracked] = useState(false)
  const { searchTimeRange } = useTasksContext()

  // Only search default time range, since results here are only used to calculate whether to show
  // empty state or not at this level. Child components handle displaying data for the selected time
  // range. Empty state shown if no tasks exist for spaces created in the given time range.
  const baseDefaultQueryParams = useMemo(
    () => ({
      spaceCreatedAfter: searchTimeRange
        ? DateTime.now().minus(defaultTaskSearchTimeRange).startOf('day').toJSDate()
        : undefined,
    }),
    [searchTimeRange],
  )
  const progressBarQuery = useProgressBarQuery(baseDefaultQueryParams)
  const upcomingTasks = useUpcomingTasks(baseDefaultQueryParams)
  const overdueTasks = useOverdueTasks(baseDefaultQueryParams)
  const nextUnscheduledTasks = useNextUnscheduledTasks(baseDefaultQueryParams)
  const [itemsExist, setItemsExist] = useState<boolean | undefined>(undefined)
  const spaceCreatedAfter = useMemo(
    () => searchTimeRange && DateTime.now().minus(searchTimeRange).startOf('day').toJSDate(),
    [searchTimeRange],
  )
  // Show empty state only if we're definitely sure that there are no items
  const showEmptyState = useMemo(() => itemsExist === false, [itemsExist])
  // Show the columns if we're not yet sure if items exist (because columns have loading states)
  // or if we know that items definitely exist
  const showColumns = useMemo(() => {
    return itemsExist === undefined || itemsExist
  }, [itemsExist])

  useEffect(() => {
    if (!visitTracked) {
      trackEvent({
        event: 'dashboard-visit',
        eventProperties: {
          indexPageName: 'Task dashboard',
        },
      })
      setVisitTracked(true)
    }
  }, [trackEvent, visitTracked])

  // As soon as one of the task queries has loaded and there are items, we know that there are items
  // available to show in the columns (it just depends on the selected time range) so we do not need
  // to show the empty state in this case.
  useEffect(() => {
    // The value of itemsExist is only set once, so we can return early if it's already set.
    if (itemsExist !== undefined) {
      return
    }
    if (!progressBarQuery.isLoading && progressBarQuery.spaces?.length === 0) {
      // Just take a shortcut to working out if there are no items. Leave the positive case
      // up to the individual task queries.
      setItemsExist(false)
      return
    }
    if (
      !upcomingTasks.isLoading &&
      typeof upcomingTasks.data?.totalItems === 'number' &&
      upcomingTasks.data.totalItems > 0
    ) {
      setItemsExist(true)
      return
    }
    if (
      !overdueTasks.isLoading &&
      typeof overdueTasks.data?.totalItems === 'number' &&
      overdueTasks.data.totalItems > 0
    ) {
      setItemsExist(true)
      return
    }
    if (
      !nextUnscheduledTasks.isLoading &&
      typeof nextUnscheduledTasks.data?.totalItems === 'number' &&
      nextUnscheduledTasks.data.totalItems > 0
    ) {
      setItemsExist(true)
      return
    }
    if (!upcomingTasks.isLoading && !overdueTasks.isLoading && !nextUnscheduledTasks.isLoading) {
      setItemsExist(false)
    }
  }, [
    itemsExist,
    setItemsExist,
    upcomingTasks,
    overdueTasks,
    nextUnscheduledTasks,
    progressBarQuery.isLoading,
    progressBarQuery.spaces?.length,
  ])

  return (
    <div className='w-full pt-11 flex flex-col'>
      <PageHeader
        title='Task Dashboard'
        description='Stay on top of your due dates and manage tasks.'
      />
      {showEmptyState && <NoTasksExistEmptyState />}
      {showColumns && ( // Nested components have loading states inside, so we display them when loading already
        <>
          <div className='mt-10'>
            {/* TaskListTimeFilter Uses TasksContext for state management */}
            <TaskListTimeFilter />
          </div>
          <div className='mt-3'>
            <ProgressBar spaceCreatedAfter={spaceCreatedAfter} />
          </div>
          <div className='pt-10 pb-11 flex grow'>
            <div className='flex items-start gap-3 relative w-full grow'>
              <OverdueTasksColumn spaceCreatedAfter={spaceCreatedAfter} />
              <UpcomingTasksColumn spaceCreatedAfter={spaceCreatedAfter} />
              <NextUnscheduledTasksColumn spaceCreatedAfter={spaceCreatedAfter} />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
