import { TeamUnion } from '@valuecase/common'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuContentScrollable,
  DropdownMenuTrigger,
  InitialsAvatar,
  InitialsAvatarSize,
  IonIcon,
} from '@valuecase/ui-components'
import { Chip, ChipClose, ChipLabel } from '@valuecase/ui-components/src/components/ui/chip'
import { cn } from '@valuecase/ui-components/src/lib/utils'

const TeamSelection = ({
  teams,
  selectedTeams,
  toggleTeam,
  isOpen,
  toggle,
}: {
  teams: TeamUnion[]
  selectedTeams: Set<TeamUnion>
  toggleTeam: (team: TeamUnion) => void
  toggle: (isOpen: boolean) => void
  isOpen: boolean
}) => {
  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-col gap-2'>
        <div className='text-xs font-semibold text-grey-s4'>Teams</div>

        {teams && teams?.length > 0 && selectedTeams.size === 0 && (
          <>
            <div className='text-xs font-regular text-grey-s3'>No teams selected.</div>
          </>
        )}
        {teams?.length === 0 && (
          <div className='flex flex-col gap-2'>
            <div className='text-xs font-regular text-grey-s3'>You have no teams yet.</div>
          </div>
        )}
        <div className={'flex flex-wrap gap-2'}>
          {[...selectedTeams].map((team) => {
            return (
              <Chip key={team.id}>
                <InitialsAvatar
                  size={InitialsAvatarSize.EXTRA_SMALL}
                  text={team.name}
                  isSeller={true}
                />
                <ChipLabel>{team.name}</ChipLabel>
                <ChipClose onClick={() => toggleTeam(team)} />
              </Chip>
            )
          })}
        </div>
      </div>

      {teams && !!teams.length && (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div
              className='w-full border border-grey-s2 bg-white py-3 pr-3 pl-5 rounded-lg flex items-center justify-between
           hover:shadow-text-input transition-all'
            >
              <p
                className={cn('text-sm', {
                  'text-grey-s6': selectedTeams.size > 0,
                  'text-grey-s4': selectedTeams.size === 0,
                })}
              >
                {selectedTeams.size > 0 ? `Teams (${selectedTeams.size})` : 'Select teams'}
              </p>
              <IonIcon
                className={'text-grey-s4'}
                name={isOpen ? 'chevron-up-outline' : 'chevron-down-outline'}
              />
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent align={'start'} sideOffset={5} className='w-[366px]'>
            <DropdownMenuContentScrollable>
              {teams.map((team) => (
                <div key={team.id} className='text-left w-full'>
                  <DropdownMenuCheckboxItem
                    checked={[...selectedTeams].includes(team)}
                    onCheckedChange={() => toggleTeam(team)}
                    className='w-full bg-transparent hover:bg-grey-s1 px-4 py-3 rounded-lg'
                  >
                    <p className='font-medium text-left text-sm text-grey-s6'>{team.name}</p>
                  </DropdownMenuCheckboxItem>
                </div>
              ))}
            </DropdownMenuContentScrollable>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  )
}

export default TeamSelection
