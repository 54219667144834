import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from 'react'

export interface FormContextProps {
  showErrors: boolean
  submitCounter: number
  onValidation: () => void
  __default?: boolean
}

export const FormContext = React.createContext<FormContextProps>({
  showErrors: true,
  submitCounter: 0,
  onValidation: () => undefined,
  __default: true,
})

export type ValidationMode = 'afterFirstBlur' | 'always'

export type ValidatorFunction<ValueType> = (arg: ValueType) => string | boolean

export type Validator<ValueType> = ValidatorFunction<ValueType>[]

export interface Validatable<ValueType> {
  validationMode?: ValidationMode
  validators?: Validator<ValueType>
  onValidation?: (validationErrors: string[]) => void
  enableValidation?: boolean
}

export interface UseValidationProps<ValueType, ElementType extends HTMLElement>
  extends Validatable<ValueType> {
  ref: React.RefObject<ElementType>
  value: ValueType
}

export function useValidation<ValueType, ElementType extends HTMLElement>(
  props: UseValidationProps<ValueType, ElementType>,
): {
  validationErrors: string[]
} {
  const formContext = useContext(FormContext)

  const [errors, setErrors] = useState<string[]>([])
  const [showErrors, setShowErrors] = useState(props.validationMode === 'always')
  const [internalErrors, setInternalErrors] = useState<string[]>([])
  const blurTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (!formContext.__default) {
      setShowErrors(formContext.showErrors ?? true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext.showErrors]) /* eslint-disable-line */ /* TODO @ fix exhaustive-deps */

  useEffect(() => {
    if (showErrors) {
      setErrors(internalErrors)
    } else {
      setErrors([])
    }
  }, [showErrors]) /* eslint-disable-line */ /* TODO @ fix exhaustive-deps */

  const validators = props.validators ?? []
  const mode = props.validationMode ?? 'afterFirstBlur'

  const validate = () => {
    const validatorErrors: string[] = []

    if (props.enableValidation ?? true) {
      validators.forEach((validator) => {
        const validatorResult = validator(props.value)

        if (validatorResult !== true) {
          validatorErrors.push(validatorResult.toString())
        }
      })
    }

    setInternalErrors(validatorErrors)
    props.onValidation?.(validatorErrors)

    if (props.ref.current) {
      if (validatorErrors.length > 0) {
        props.ref.current.dataset.validationError = 'true'
      } else {
        delete props.ref.current.dataset.validationError
      }
    }

    if (showErrors) {
      setErrors(validatorErrors)
    } else {
      setErrors([])
    }
  }

  useEffect(() => {
    validate()
  }, [props.value]) /* eslint-disable-line */ /* TODO @ fix exhaustive-deps */

  useEffect(() => {
    validate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.enableValidation]) /* eslint-disable-line */ /* TODO @ fix exhaustive-deps */

  useEffect(() => {
    if (props.ref.current) {
      if (errors.length > 0) {
        props.ref.current.dataset.validationErrorMessages = JSON.stringify(errors)
      } else {
        delete props.ref.current.dataset.validationErrorMessages
      }

      formContext.onValidation()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showErrors, errors]) /* eslint-disable-line */ /* TODO @ fix exhaustive-deps */

  // Reset if submitCounter of formContext changes
  useEffect(() => {
    setErrors([])
    setShowErrors(props.validationMode === 'always')
    setInternalErrors([])

    if (blurTimeoutRef.current) {
      clearTimeout(blurTimeoutRef.current)
    }

    validate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formContext.submitCounter]) /* eslint-disable-line */ /* TODO @ fix exhaustive-deps */

  useLayoutEffect(() => {
    if (mode === 'afterFirstBlur' && props?.ref.current) {
      const blurHandler = () => {
        // give click on "submit" priority
        blurTimeoutRef.current = setTimeout(() => {
          setShowErrors(true)
        }, 100)
      }

      props.ref.current.addEventListener('blur', blurHandler, false)

      return () => {
        props.ref.current?.removeEventListener('blur', blurHandler, false)
      }
    }
  }, []) /* eslint-disable-line */ /* TODO @ fix exhaustive-deps */

  return {
    validationErrors: errors,
  }
}
