import vloader170 from '/src/assets/vloader.gif'

export type VLoaderAnimationProps = {
  width?: string
  height?: string
  opacity?: number
}

export function VLoaderAnimation(props: VLoaderAnimationProps) {
  return (
    <img
      style={{
        width: '36px',
        height: '36px',
        opacity: 0.5,
        filter: 'brightness(0.9) invert(.7) sepia(.5) hue-rotate(188deg) saturate(160%)',
        ...props,
      }}
      src={vloader170}
      alt={'Valuecase Loading Spinner'}
    />
  )
}

export type VLoaderAnimationFullSizeCenteredProps = {
  animation?: VLoaderAnimationProps
}

export function VLoaderAnimationFullSizeCentered(props: VLoaderAnimationFullSizeCenteredProps) {
  return (
    <div
      style={{
        padding: '24px',
        display: 'grid',
        placeItems: 'center',
        width: '100%',
        height: '100%',
      }}
    >
      <VLoaderAnimation {...props.animation} />
    </div>
  )
}
