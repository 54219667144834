import { FC, useCallback } from 'react'
import { Button } from '@valuecase/ui-components'
import {
  TemplateEditorHeaderSubtitle,
  TemplateEditorHeaderTitle,
  TemplateEditorHeaderWrapper,
} from './TemplateDeleteParts.styled'
import { useUpdateTemplate } from '../hooks/useUpdateTemplate'
import { useNotifications } from '../../../utils/Notifications/Notifications'
import { TemplateCreatorTemplateDetails } from '../types/TemplateCreatorTemplateDetails'
import { VLoaderAnimationFullSizeCentered } from '../../../ui-components/VLoader/VLoader'

export interface TemplateEditorViewProps {
  error?: any
  editTemplate: () => void
  onCancel: () => void
  template: TemplateCreatorTemplateDetails
}

const TemplateEditorView: FC<TemplateEditorViewProps> = ({ editTemplate, onCancel, template }) => {
  const { asyncNotification } = useNotifications()
  const { updateTemplateAsync: updateTemplate, isLoading } = useUpdateTemplate()

  const onArchiveClick = useCallback(async () => {
    await asyncNotification(
      updateTemplate({
        templateId: template.id,
        title: template.title,
        description: template.description,
        icon: template.emojiIcon,
        archived: true,
      }),
      'Template successfully archived',
      'There was an error archiving the template. Please try again later.',
    )
    onCancel()
  }, [
    asyncNotification,
    onCancel,
    template.description,
    template.id,
    template.title,
    template.emojiIcon,
    updateTemplate,
  ])

  if (isLoading) {
    return <VLoaderAnimationFullSizeCentered />
  }

  return (
    <>
      <TemplateEditorHeaderWrapper>
        <TemplateEditorHeaderTitle>Delete Template</TemplateEditorHeaderTitle>
        <TemplateEditorHeaderSubtitle>
          You&apos;re about to delete the template <span className='bold'>{template.title}</span>.
          This action is irreversible. Are you sure you want to proceed?
        </TemplateEditorHeaderSubtitle>
      </TemplateEditorHeaderWrapper>

      <div className='button-container mt-13'>
        <Button
          icon={'close-circle-outline'}
          style='outlined'
          iconPosition='left'
          label='Cancel'
          onClick={onCancel}
          cssStyle={{ padding: '10px 34px' }}
        />
        <Button
          icon={'trash-outline'}
          style='warning'
          iconPosition='left'
          label='Yes, delete'
          onClick={editTemplate}
          cssStyle={{ padding: '10px 34px' }}
        />
      </div>
      {!template.archived && (
        <p className={'text-sm text-grey-s5 text-center mt-3'}>
          Please rather{' '}
          <button
            onClick={onArchiveClick}
            className={
              'text-primary-s5 no-underline font-extrabold hover:text-primary-s6 border-none m-0 p-0 min-w-0'
            }
          >
            archive
          </button>{' '}
          my template.
        </p>
      )}
    </>
  )
}

export default TemplateEditorView
