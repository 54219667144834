import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { NoTasksExistEmptyState } from './NoTasksExistEmptyState'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'
import {
  ascendToParentWithType,
  AvatarThumbnail,
  CheckMark,
  cn,
  Counter,
  DataTable,
  defaultTaskSearchTimeRange,
  Highlight,
  Input2,
  OneLineTruncatedText,
  UpdateTaskParams,
  useConfetti,
  useDayDifferenceCalculator,
  useTasks,
  useUpdateTaskMutation,
} from '@valuecase/ui-components'
import {
  getCoreRowModel,
  getFacetedRowModel,
  useReactTable,
  getFacetedUniqueValues,
  ColumnDef,
  TableState,
  SortingState,
  Updater,
} from '@tanstack/react-table'
import {
  actionPlanTasksQuerySchema,
  getCompanyInitials,
  TActionPlanTaskExpanded,
} from '@valuecase/common'
import { useCurrentTenantQuery } from '../tenant/hooks/useReadTenant'
import { DateTime } from 'luxon'
import { PageHeader } from '@/ui-components/PageHeader'
import { useDebounceValue } from 'usehooks-ts'
import { useNotifications } from '@/utils/Notifications/Notifications'
import { useNavigate, useSearch } from '@tanstack/react-router'
import { taskListRoute } from '../navigation/Router'
import { TaskListTimeFilter } from './TaskListTimeFilter'
import {
  OverdueTasksEmptyState,
  NextUnscheduledTasksEmptyState,
  UpcomingTasksEmptyState,
  AllTasksEmtpyState,
} from './empty-states'
import { useTasksContext } from './TasksContext'

const taskListPageSize = 7
const defaultSortBy = 'dueDate'
const defaultSortOrder = 'asc'
const defaultTaskStatus = 'overdue'

type DebouncedTaskCounts = {
  scheduledTasksCount: number
  overdueTasksCount: number
  nextUnscheduledTasksCount: number
  allTasksCount: number
}

export const TaskList: FC = () => {
  // Uses same query as UpcomingTasksColumn to leverage response caching, but task dashboard only
  // interested in the itemsExist property to see if any tasks exist in the tenant.
  const { trackEvent } = useTrackEvent()
  const [visitTracked, setVisitTracked] = useState(false)
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebounceValue(search, 300)
  const tenantQuery = useCurrentTenantQuery()
  const tenant = useMemo(() => tenantQuery.tenant?.payload, [tenantQuery.tenant?.payload])
  const navigate = useNavigate()
  const searchParams = useSearch<typeof taskListRoute>({ strict: false })
  const { showOnceOver } = useConfetti()
  const { searchTimeRange } = useTasksContext()

  const [countsToDisplay, setCountsToDisplay] = useState<DebouncedTaskCounts>({
    scheduledTasksCount: 0,
    overdueTasksCount: 0,
    nextUnscheduledTasksCount: 0,
    allTasksCount: 0,
  })

  // If debouncedSearch changes, reset the pagination to the first page
  useEffect(() => {
    setTaskTableState((prev) => ({
      ...prev,
      pagination: { pageIndex: 0, pageSize: taskListPageSize },
    }))
  }, [debouncedSearch])

  const initialState = useMemo(() => {
    // Default to search for upcoming tasks
    if (!searchParams.taskStatus) {
      return {
        sorting: [{ id: defaultSortBy, desc: defaultSortOrder !== 'asc' }],
        pagination: { pageIndex: 0, pageSize: taskListPageSize },
        columnFilters: [{ id: 'taskStatus', value: defaultTaskStatus }],
      }
    }
    // If taskStatus is unscheduled, sort by spaceLastVisit as default
    if (searchParams.taskStatus === 'nextUnscheduled') {
      return {
        sorting: [{ id: 'spaceLastVisit', desc: false }],
        pagination: { pageIndex: 0, pageSize: taskListPageSize },
        columnFilters: [{ id: 'taskStatus', value: 'nextUnscheduled' }],
      }
    }
    if (searchParams.taskStatus === 'all') {
      return {
        sorting: [{ id: 'dueDate', desc: false }],
        pagination: { pageIndex: 0, pageSize: taskListPageSize },
        columnFilters: [{ id: 'taskStatus', value: undefined }],
      }
    }
    // Otherwise, sort by dueDate as default
    return {
      sorting: [{ id: 'dueDate', desc: false }],
      pagination: { pageIndex: 0, pageSize: taskListPageSize },
      columnFilters: [{ id: 'taskStatus', value: searchParams.taskStatus }],
    }
  }, [searchParams.taskStatus])

  const { error } = useNotifications()
  const updateTaskMutation = useUpdateTaskMutation()

  const [taskTableState, setTaskTableState] =
    useState<Pick<TableState, 'sorting' | 'pagination' | 'columnFilters'>>(initialState)

  const dayDifferenceCalculator = useDayDifferenceCalculator({
    // Force english language for seller dashboard - other languages only supported for spaces frontend
    language: 'en-GB',
  })

  const formatDate = useCallback(
    (date?: Date | null) => {
      if (!date) {
        return null
      }
      const result = dayDifferenceCalculator.daysDiffFromNowOrFormatted({
        to: DateTime.fromJSDate(date, { zone: 'utc' }),
        // Force english locale for seller dashboard - other locales only supported for spaces frontend
        locale: tenant?.locale && tenant?.locale.startsWith('en') ? tenant?.locale : 'en-GB',
      })
      return result.message
    },
    [dayDifferenceCalculator, tenant?.locale],
  )

  const [hasFetchedAtLeastOnce, setHasFetchedAtLeastOnce] = useState(false)

  const sortBy = useMemo(() => {
    const parseResult = actionPlanTasksQuerySchema.shape.sortBy.safeParse(
      taskTableState.sorting[0]?.id,
    )
    return parseResult.success ? parseResult.data : undefined
  }, [taskTableState.sorting])

  const sortOrder = useMemo(() => {
    const parseResult = actionPlanTasksQuerySchema.shape.sortOrder.safeParse(
      taskTableState.sorting[0]?.desc ? 'desc' : 'asc',
    )
    return parseResult.success ? parseResult.data : undefined
  }, [taskTableState.sorting])

  const taskStatus = useMemo(() => {
    const parseResult = actionPlanTasksQuerySchema.shape.taskStatus.safeParse(
      taskTableState.columnFilters[0]?.value,
    )
    return parseResult.success ? parseResult.data : undefined
  }, [taskTableState.columnFilters])

  const displayedTasksQuery = useTasks({
    staleTime: 0,
    page: taskTableState.pagination.pageIndex + 1,
    itemsPerPage: taskTableState.pagination.pageSize,
    search: debouncedSearch,
    sortBy,
    sortOrder,
    taskStatus,
    includeTaskStatusMetadata: true,
    spaceCreatedAfter:
      searchTimeRange && DateTime.now().minus(searchTimeRange).startOf('day').toJSDate(),
  })

  const queryForTasksInDefaultFilterTimeRange = useTasks({
    page: 1,
    itemsPerPage: taskListPageSize, // Use same page size to take advantage of response caching
    search: debouncedSearch,
    sortBy: defaultSortBy,
    sortOrder: defaultSortOrder,
    taskStatus: defaultTaskStatus,
    includeTaskStatusMetadata: true,
    spaceCreatedAfter:
      searchTimeRange && DateTime.now().minus(defaultTaskSearchTimeRange).startOf('day').toJSDate(),
  })

  // the counts of tasks in each status which comeback from each query are valid across all queries
  // so we can keep displaying the counts from the last fully loaded query and only replace it
  // when a new query has fully loaded (i.e. don't use counts from the react query response cache)
  useEffect(() => {
    if (displayedTasksQuery.isSuccess && !displayedTasksQuery.isFetching) {
      setCountsToDisplay({
        scheduledTasksCount: displayedTasksQuery.data?.meta?.scheduledTasksCount || 0,
        overdueTasksCount: displayedTasksQuery.data?.meta?.overdueTasksCount || 0,
        nextUnscheduledTasksCount: displayedTasksQuery.data?.meta?.nextUnscheduledTasksCount || 0,
        allTasksCount: displayedTasksQuery.data?.meta?.allTasksCount || 0,
      })
    }
  }, [
    displayedTasksQuery.data?.meta,
    displayedTasksQuery.isFetching,
    displayedTasksQuery.isSuccess,
  ])

  useEffect(() => {
    if (displayedTasksQuery.isFetched) {
      setHasFetchedAtLeastOnce(true)
    }
  }, [displayedTasksQuery.isFetched])

  const showEmptyState = useMemo(() => {
    const itemsExist =
      queryForTasksInDefaultFilterTimeRange.isFetched &&
      typeof queryForTasksInDefaultFilterTimeRange.data?.meta?.allTasksCount === 'number' &&
      queryForTasksInDefaultFilterTimeRange.data?.meta?.allTasksCount > 0
    return queryForTasksInDefaultFilterTimeRange.isFetched && !itemsExist
  }, [
    queryForTasksInDefaultFilterTimeRange.isFetched,
    queryForTasksInDefaultFilterTimeRange.data?.meta?.allTasksCount,
  ])

  useEffect(() => {
    if (!visitTracked) {
      trackEvent({
        event: 'dashboard-visit',
        eventProperties: {
          indexPageName: 'Task List',
        },
      })
      setVisitTracked(true)
    }
  }, [trackEvent, visitTracked])

  const handleUpdateTask = useCallback(
    async (params: UpdateTaskParams) => {
      try {
        await updateTaskMutation.mutateAsync(params)
        trackEvent({
          event: 'task-completed-task-list',
          eventProperties: {
            completed: !!params.data.checked,
            taskStatus: taskStatus || 'all',
          },
        })
      } catch (err) {
        console.error(err)
        error('Error updating task')
      }
    },
    [error, taskStatus, trackEvent, updateTaskMutation],
  )

  const columns = useMemo<ColumnDef<TActionPlanTaskExpanded>[]>(
    () => [
      {
        id: 'check',
        size: 32,
        header: '',
        cell: (ctx) => (
          <CheckMark
            className={cn('mr-3 -mt-0.5', { 'text-success-s5': ctx.row.original.checked })}
            checked={ctx.row.original.checked}
            onClick={(e) => {
              e.stopPropagation()
              const newCheckedValue = !ctx.row.original.checked
              const element =
                e.target instanceof Node && ascendToParentWithType(e.target, HTMLElement)
              // Show confetti when task is checked
              if (newCheckedValue && element) {
                showOnceOver(element, { width: 200, height: 200 })
              }
              handleUpdateTask({
                id: ctx.row.original.id,
                data: { checked: newCheckedValue },
              })
            }}
          />
        ),
      },
      {
        id: 'name',
        header: () => <span>Task</span>,
        cell: (ctx) => (
          <OneLineTruncatedText
            className={cn('text-sm font-semibold', {
              'line-through': ctx.row.original.checked,
            })}
            textOnOverflow={ctx.row.original.title}
          >
            <Highlight searchTerm={debouncedSearch}>{ctx.row.original.title}</Highlight>
          </OneLineTruncatedText>
        ),
      },
      {
        id: 'spaceName',
        header: () => <span>Space</span>,
        cell: (ctx) => (
          <span className='inline-flex items-center gap-3'>
            <span className='min-w-8 max-w-8'>
              {!!ctx.row.original.spaceLogoUrl && (
                <AvatarThumbnail
                  size='MEDIUM'
                  type='image'
                  imageUrl={ctx.row.original.spaceLogoUrl}
                />
              )}
              {!ctx.row.original.spaceLogoUrl && (
                <AvatarThumbnail
                  size='MEDIUM'
                  type='gravatarInitials'
                  initials={getCompanyInitials(ctx.row.original.spaceCompanyName)}
                />
              )}
            </span>
            <OneLineTruncatedText textOnOverflow={ctx.row.original.spaceCompanyName}>
              <Highlight searchTerm={debouncedSearch}>
                {ctx.row.original.spaceCompanyName}
              </Highlight>
            </OneLineTruncatedText>
          </span>
        ),
      },
      {
        id: 'milestone',
        header: () => <span>Milestone</span>,
        cell: (ctx) => (
          <OneLineTruncatedText>{ctx.row.original.milestoneTitle}</OneLineTruncatedText>
        ),
      },
      {
        id: 'spaceLastVisit',
        enableSorting: true,
        accessorKey: 'spaceLastVisit',
        size: 80,
        header: () => <span>Last Visit</span>,
        cell: (ctx) => (
          <OneLineTruncatedText>
            {ctx.row.original.spaceLastVisit
              ? formatDate(ctx.row.original.spaceLastVisit) || '-'
              : '-'}
          </OneLineTruncatedText>
        ),
      },
      {
        id: 'dueDate',
        // Unscheduled tasks have no dueDate, sorting by dueDate does not make sense for these tasks
        enableSorting: taskStatus !== 'nextUnscheduled',
        accessorKey: 'dueDate',
        size: 80,
        header: () => <span>Due Date</span>,
        cell: (ctx) => (
          <OneLineTruncatedText>
            {ctx.row.original.dueDate ? formatDate(ctx.row.original.dueDate) || '-' : '-'}
          </OneLineTruncatedText>
        ),
      },
    ],
    [debouncedSearch, formatDate, handleUpdateTask, showOnceOver, taskStatus],
  )

  const onSortingChange = useCallback(
    (sortingChange: Updater<SortingState>) => {
      const resolvedSorting =
        typeof sortingChange === 'function' ? sortingChange(taskTableState.sorting) : sortingChange
      setTaskTableState((prev) => {
        const sorting = resolvedSorting?.length
          ? // If new sorting defined, then use it
            resolvedSorting
          : // If no new sorting defined, then toggle the direciton of current sorting
            prev.sorting?.length
            ? [{ id: prev.sorting[0].id, desc: !prev.sorting[0].desc }]
            : // If no previous sorting, then nothing to set.
              []
        return { ...prev, sorting }
      })
    },
    [taskTableState],
  )

  const onPaginationChange = useCallback(
    (paginationChange: Updater<{ pageIndex: number; pageSize: number }>) => {
      if (typeof paginationChange === 'function') {
        const pagination = paginationChange(taskTableState.pagination)
        if (pagination) {
          setTaskTableState((prev) => ({ ...prev, pagination }))
        }
      } else if (paginationChange) {
        setTaskTableState((prev) => ({ ...prev, pagination: paginationChange }))
      }
    },
    [taskTableState],
  )

  const tasksTable = useReactTable<TActionPlanTaskExpanded>({
    columns,
    data: displayedTasksQuery.data?.items || [],
    getCoreRowModel: getCoreRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onSortingChange,
    state: taskTableState,
    onPaginationChange,
    manualSorting: true,
    manualPagination: true,
    pageCount: displayedTasksQuery.data?.totalPages || 0,
  })

  const handleTaskRowClick = useCallback(
    (task: TActionPlanTaskExpanded) => {
      // Track the click
      trackEvent({
        event: 'task-clicked-task-list',
        eventProperties: {
          taskStatus: taskStatus || 'all',
        },
      })

      const url = new URL(location.origin)
      url.pathname = `/spaces/${task.spaceId}`
      url.searchParams.set('token', task.spaceToken)
      url.searchParams.set('block', task.blockId)
      window.open(url.toString(), '_blank')
    },
    [taskStatus, trackEvent],
  )

  const tableEmptyState = useMemo(() => {
    if (displayedTasksQuery.data?.items?.length) {
      return null
    }
    switch (taskStatus) {
      case 'overdue':
        return <OverdueTasksEmptyState />
      case 'nextUnscheduled':
        return <NextUnscheduledTasksEmptyState />
      case 'upcoming':
        return <UpcomingTasksEmptyState />
      default:
        return <AllTasksEmtpyState />
    }
  }, [displayedTasksQuery.data?.items?.length, taskStatus])

  return (
    <div className='w-full pt-11 flex flex-col'>
      <PageHeader title='Task List' description='View all open tasks in your spaces.' />
      <div className='pt-10 pb-11 flex grow'>
        <div className='flex items-start gap-3 relative w-full grow'>
          {showEmptyState && <NoTasksExistEmptyState />}
          {!showEmptyState && (
            <div className='flex flex-col w-full grow'>
              <div className='flex gap-2 mb-3'>
                <div className='w-80'>
                  <Input2
                    id='dashboard-tasks-search-input'
                    placeholder='Search tasks or spaces...'
                    leadingIcon='search'
                    value={search}
                    onInput={(e) => setSearch(e.currentTarget.value)}
                  />
                </div>
                {/* TaskListTimeFilter Uses TasksContext for state management */}
                <TaskListTimeFilter />
              </div>
              <div className='grid grid-cols-4 gap-3 mb-4'>
                <Counter
                  id='dashboard-tasks-overdue-filter'
                  active={taskStatus === 'overdue'}
                  iconName='calendar'
                  iconClassName='text-red-s4'
                  label='Overdue'
                  isLoading={!hasFetchedAtLeastOnce}
                  counter={countsToDisplay.overdueTasksCount}
                  onClick={() => {
                    navigate({ to: '/tasks/list', search: { taskStatus: 'overdue' } })
                    setTaskTableState((prev) => ({
                      ...prev,
                      sorting: [{ id: 'dueDate', desc: false }],
                      columnFilters: [{ id: 'taskStatus', value: 'overdue' }],
                      pagination: { pageIndex: 0, pageSize: taskListPageSize },
                    }))
                  }}
                />
                <Counter
                  id={'dashboard-tasks-upcoming-filter'}
                  active={taskStatus === 'upcoming'}
                  iconName='calendar'
                  iconClassName='text-blue-s4'
                  label='Upcoming Due Dates'
                  isLoading={!hasFetchedAtLeastOnce}
                  counter={countsToDisplay.scheduledTasksCount}
                  onClick={() => {
                    navigate({ to: '/tasks/list', search: { taskStatus: 'upcoming' } })
                    setTaskTableState((prev) => ({
                      ...prev,
                      sorting: [{ id: 'dueDate', desc: false }],
                      columnFilters: [{ id: 'taskStatus', value: 'upcoming' }],
                      pagination: { pageIndex: 0, pageSize: taskListPageSize },
                    }))
                  }}
                />
                <Counter
                  id={'dashboard-tasks-without-due-date-filter'}
                  active={taskStatus === 'nextUnscheduled'}
                  iconName='arrow-forward-circle'
                  iconClassName='text-orange-s4'
                  label='Next Task Without Due Date'
                  isLoading={!hasFetchedAtLeastOnce}
                  counter={countsToDisplay.nextUnscheduledTasksCount}
                  onClick={() => {
                    navigate({ to: '/tasks/list', search: { taskStatus: 'nextUnscheduled' } })
                    setTaskTableState((prev) => ({
                      ...prev,
                      sorting: [{ id: 'spaceLastVisit', desc: false }],
                      columnFilters: [{ id: 'taskStatus', value: 'nextUnscheduled' }],
                      pagination: { pageIndex: 0, pageSize: taskListPageSize },
                    }))
                  }}
                />
                <Counter
                  id={'dashboard-tasks-all-tasks-filter'}
                  active={taskStatus === undefined}
                  iconName='list'
                  iconClassName='text-grey-s4'
                  label='All Tasks'
                  isLoading={!hasFetchedAtLeastOnce}
                  counter={countsToDisplay.allTasksCount}
                  onClick={() => {
                    navigate({ to: '/tasks/list', search: { taskStatus: 'all' } })
                    setTaskTableState((prev) => ({
                      ...prev,
                      sorting: [{ id: 'dueDate', desc: false }],
                      columnFilters: [{ id: 'taskStatus', value: undefined }],
                      pagination: { pageIndex: 0, pageSize: taskListPageSize },
                    }))
                  }}
                />
              </div>
              <DataTable
                id='dashboard-tasks-table'
                className='w-full'
                rowClickHandler={(row) => {
                  handleTaskRowClick(row.original)
                }}
                isLoading={displayedTasksQuery.isLoading}
                table={tasksTable}
                emptyState={tableEmptyState}
                tableWrapperClassName={cn('rounded-lg border border-grey-s2')}
                resetFiltersClickHandler={() => {}}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
