import { Button } from '../Button'
import styled from 'styled-components'
import { DotsPageIndicator } from './DotsPageIndicator'

interface CarouselNavigationProps {
  numberOfItems: number
  activeIndex: number
  onShowItem: (itemIndex: number) => void
}

export function CarouselNavigation({
  numberOfItems,
  activeIndex,
  onShowItem,
}: CarouselNavigationProps) {
  return (
    <StyledCarouselNavigation data-test={'carousel-navigation'}>
      <Button
        testIdentifier={'carousel-navigation-back-button'}
        icon={'chevron-back-outline'}
        onClick={() => {
          if (activeIndex <= 0) {
            onShowItem(numberOfItems - 1)
            return
          }
          onShowItem(activeIndex - 1)
        }}
        style={'primary-flat'}
      />
      <DotsPageIndicator
        numberOfItems={numberOfItems}
        activeIndex={activeIndex}
        onIndicatorClicked={onShowItem}
      />
      <Button
        testIdentifier={'carousel-navigation-next-button'}
        icon={'chevron-forward-outline'}
        onClick={() => {
          if (activeIndex >= numberOfItems - 1) {
            onShowItem(0)
            return
          }
          onShowItem(activeIndex + 1)
        }}
        style={'primary-flat'}
      />
    </StyledCarouselNavigation>
  )
}

const StyledCarouselNavigation = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px;
`
