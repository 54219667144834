import { spacesApi } from '@/api/spacesApi/spacesAPIclient'
import axios from 'axios'
import { useState } from 'react'
import { z } from 'zod'

const presignedPostResponseSchema = z.object({
  presignedPost: z.object({
    url: z.string(),
    fields: z.object({
      key: z.string(),
      'Content-Type': z.string(),
      bucket: z.string(),
      'X-Amz-Algorithm': z.string(),
      'X-Amz-Credential': z.string(),
      'X-Amz-Date': z.string(),
      Policy: z.string(),
      'X-Amz-Signature': z.string(),
    }),
  }),
})

export const uploadFileToS3 = async (file: File): Promise<{ s3ObjectKey: string }> => {
  const presignedUrlResponse = await spacesApi.get(
    `/files/presignedPost?type=${encodeURIComponent(file.type)}`,
  )
  const presignedData = presignedPostResponseSchema.parse(presignedUrlResponse.data)

  const fileUploadData = new FormData()
  for (const [key, value] of Object.entries(presignedData.presignedPost.fields)) {
    fileUploadData.append(key, value)
  }
  fileUploadData.append('file', file)
  await axios.post(presignedData.presignedPost.url, fileUploadData)

  return {
    s3ObjectKey: presignedData.presignedPost.fields.key,
  }
}

export function useS3Upload() {
  // can be replaced with useActionState in React 19
  const [uploading, setUploading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  async function upload(file: File) {
    try {
      setError(null)
      setUploading(true)

      // We use await. Otherwise, the "finally" block would immediately set the uploading flag back to false
      return await uploadFileToS3(file)
    } catch (e: any) {
      setError(e.toString?.() ?? JSON.stringify(e))
      throw e
    } finally {
      setUploading(false)
    }
  }

  return {
    upload,
    uploading,
    error,
  }
}
