import styled, { keyframes } from 'styled-components'

const SpinnerRotation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const FullArea = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  background: white;
`

const Padder = styled.span`
  display: inline-block;
  padding: 0.75rem;
`

const Spinner = styled.span`
  width: 2rem;
  height: 2rem;
  border-radius: 100%;
  border: 2px solid #475e75;
  animation: ${SpinnerRotation} 0.75s cubic-bezier(0.25, 0.1, 0.52, 1) infinite;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 48% 100%, 48% 50%, 0% 50%);
  display: inline-block;
`

export interface BlurProps {
  $blur: boolean
}

export const BlurModeContainer = styled.div<BlurProps>`
  position: relative;
  min-height: 2rem; // for the spinner if there's no content

  ${(props) => {
    if (props.$blur) {
      return `
        pointer-events: none;
      `
    }
    return ''
  }}
`

export const BlurLayer = styled.div<BlurProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;

  display: flex;
  opacity: 0;

  justify-content: center;
  align-items: center;

  //transition: opacity 0.2s ease;
  z-index: 2;

  ${(props) => {
    if (props.$blur) {
      return `
        opacity: 1;
      `
    }
    return ''
  }}
`

const BlurContentContainer = styled.div<BlurProps>`
  //transition: opacity 0.2s ease, filter 0.2s ease;

  ${(props) => {
    if (props.$blur) {
      return `
        filter: blur(0.1rem);
        opacity: 0.5;
      `
    }
    return ''
  }}
`

export const S = {
  FullArea,
  Padder,
  Spinner,
  BlurModeContainer,
  BlurLayer,
  BlurContentContainer,
}
