import { spacesApi } from '@/api/spacesApi/spacesAPIclient'
import { buildLdQueryKey } from '@/launchdarkly/Launchdarkly'
import { useMutation, useQueryClient } from '@tanstack/react-query'

const useToggleEngagementScoreForTenant = () => {
  const queryClient = useQueryClient()

  const toggleFlagForTenant = useMutation(
    async ({ enable }: { enable: boolean }) => {
      const action = enable ? 'enableEngagementScore' : 'disableEngagementScore'
      return (await spacesApi.patch(`launchdarkly/${action}`)).data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(buildLdQueryKey())
      },
    },
  )

  return {
    toggleEngagementScoreForTenant: toggleFlagForTenant.mutate,
    isLoading: toggleFlagForTenant.isLoading,
    isError: toggleFlagForTenant.isError,
    error: toggleFlagForTenant.error,
  }
}

export default useToggleEngagementScoreForTenant
