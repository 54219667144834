import {
  EmptyState,
  EmptyStateAction,
  EmptyStateDescription,
  EmptyStateHeader,
  EmptyStateTitle,
} from '@valuecase/ui-components/src/components/ui/empty'
import DudeWithASignThinking from './assets/dude-with-a-sign-thinking.svg?react'

const TeamManagementEmptyState = () => {
  const onClickAboutTeams = () => {
    window.open('https://help.valuecase.de/en/articles/160095-manage-teams-in-valuecase')
  }
  return (
    <div className='w-full h-fit py-4 flex flex-row items-center justify-center bg-white rounded-lg'>
      <EmptyState>
        <EmptyStateHeader>
          <DudeWithASignThinking className='w-32 h-32' />
          <EmptyStateTitle>Start creating a new team</EmptyStateTitle>
          <EmptyStateDescription>
            And help your coworkers to focus only on what is relevant for them
          </EmptyStateDescription>
        </EmptyStateHeader>
        <EmptyStateAction onClick={onClickAboutTeams}>Learn more about teams</EmptyStateAction>
      </EmptyState>
    </div>
  )
}

export default TeamManagementEmptyState
