import React, {
  CSSProperties,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { DefaultTheme } from 'styled-components'
import styled from 'styled-components'

import { useValidation, Validatable } from '../Form/Validations'
import FormElementContainer from '../Form/FormElementContainer'
import { darken } from 'polished'
import classNames from 'classnames'
import { BasePortal } from '../Portal/BasePortal'
import ZIndeces from '../../../global-styles/ZIndices'
import {
  autoUpdate,
  shift,
  useFloating,
  size as sizeMiddleware,
} from '@floating-ui/react-dom-interactions'
import { flip, offset } from '@floating-ui/react-dom'
import { IonIcon } from '../../../Icons/IonIcon'
import { InputIconPosition, InputPopupRenderFunction, InputSize } from '../Form/types.form'
import { Spinner } from '../Spinner/Spinner'
import { LegacyTooltip } from '../Tooltip/Tooltip'

export interface InputProps extends Validatable<string> {
  value?: string
  onInput?: (newValue: string) => void
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
  readonly?: boolean
  fullWidth?: boolean
  placeholder?: string
  name?: string
  type?: string
  disabled?: boolean
  size?: InputSize
  icon?: string
  iconPosition?: InputIconPosition
  onIconClick?: () => void
  className?: string
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  autoFocus?: boolean
  refFromParent?: MutableRefObject<HTMLInputElement | null> // used to trigger focus from parent
  popup?: InputPopupRenderFunction
  testIdentifier?: string
  blurOnEscape?: boolean
  blurOnEnter?: boolean
  shouldStopPropagation?: boolean
  prepend?: React.ReactNode
  append?: React.ReactNode
  inputStyles?: CSSProperties
  onEscapeKey?: () => void
  iconRotationDegrees?: number
  intercomTarget?: string
  statusIndicator?: 'loading' | 'error' | 'idle'
  statusIndicatorErrorMessage?: string
}

/**
 * @deprecated use Input2
 */
export function Input({
  value = '',
  onInput = () => undefined,
  onKeyDown = () => undefined,
  readonly = false,
  fullWidth = true,
  placeholder = '',
  name,
  type = 'text',
  disabled = false,
  validators,
  validationMode,
  onValidation,
  size = InputSize.MEDIUM,
  icon,
  iconPosition = 'start',
  onIconClick,
  className,
  enableValidation,
  onFocus,
  onBlur,
  autoFocus,
  refFromParent,
  popup,
  testIdentifier,
  blurOnEscape,
  blurOnEnter,
  prepend,
  append,
  inputStyles,
  onEscapeKey,
  shouldStopPropagation,
  iconRotationDegrees,
  intercomTarget,
  statusIndicator = 'idle',
  statusIndicatorErrorMessage,
}: InputProps) {
  const [wrapper, setWrapper] = useState<HTMLDivElement | null>(null)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [width, setWidth] = useState(300)

  const inputRefCallback = useCallback(
    (element: HTMLInputElement) => {
      inputRef.current = element
      if (refFromParent) {
        refFromParent.current = element
      }
    },
    [inputRef, refFromParent],
  )

  useEffect(() => {
    if (wrapper) {
      const observer = new ResizeObserver(() => {
        setWidth(wrapper.getBoundingClientRect().width)
      })
      observer.observe(wrapper)

      return () => {
        observer.disconnect()
      }
    }
  }, [wrapper])

  const { validationErrors } = useValidation<string, HTMLInputElement>({
    ref: inputRef,
    value,
    validators,
    validationMode,
    onValidation,
    enableValidation,
  })
  const [focussed, setFocussed] = useState(false)

  const onInputHandler = (e: any) => {
    e.preventDefault()
    onInput(e.target.value)
  }

  const onKeyDownHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    onKeyDown(e)
  }

  const popupContent = popup?.({ inputWidth: width, value, focussed })
  const isPopupShown = !!popupContent

  const {
    x: popupX,
    y: popupY,
    reference: floatingRef,
    floating: popupRef,
    strategy: popupStrategy,
  } = useFloating({
    open: isPopupShown,
    whileElementsMounted: autoUpdate,
    strategy: 'absolute',
    placement: 'bottom',
    middleware: [
      offset(8),
      flip(),
      shift(),
      sizeMiddleware({
        apply({ availableWidth, availableHeight, elements }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${availableWidth}px`,
            maxHeight: `${availableHeight}px`,
            overflow: 'auto',
          })
        },
      }),
    ],
  })

  const wrapperRefCallback = useCallback<(element: HTMLDivElement | null) => void>(
    (element) => {
      setWrapper(element)
      floatingRef(element)
    },
    [floatingRef],
  )

  const renderedIcon: React.ReactNode = icon ? (
    <StyledInputOtherContainer>
      <IconContainer
        $sizeSetting={size}
        $isDisabled={disabled}
        $hasError={validationErrors.length > 0}
        $iconPosition={iconPosition}
        $allowPointerEvents={!!onIconClick}
        onClick={(e) => {
          if (onIconClick) {
            e.stopPropagation()
            onIconClick?.()
          }
        }}
      >
        {(statusIndicator === 'idle' || statusIndicator === 'error') && (
          <IonIcon
            name={statusIndicator === 'error' ? 'warning-outline' : icon}
            style={
              iconRotationDegrees
                ? {
                    transform: `rotate(${iconRotationDegrees}deg)`,
                  }
                : statusIndicator === 'error'
                ? {
                    color: 'var(--theme-warning-s5)',
                  }
                : undefined
            }
          />
        )}
        {statusIndicator === 'loading' && (
          <SpinnerWrapper>
            <Spinner $size='20px' />
          </SpinnerWrapper>
        )}
      </IconContainer>
    </StyledInputOtherContainer>
  ) : undefined

  const renderedWrappedIcon =
    statusIndicator === 'error' && !!statusIndicatorErrorMessage ? (
      <LegacyTooltip
        message={statusIndicatorErrorMessage}
        trigger={renderedIcon}
        placement='top'
        offsetHeight={8}
      />
    ) : (
      renderedIcon
    )

  return (
    <FormElementContainer $fullWidth={fullWidth}>
      <FormElementContainer $fullWidth={fullWidth} ref={wrapperRefCallback}>
        <StyledInputWrapper
          $iconPresent={!!icon}
          $iconPosition={iconPosition}
          $fullWidth={fullWidth}
          $sizeSetting={size}
          className={classNames({
            readOnly: readonly,
            focus: focussed,
            disabled: disabled,
            error: validationErrors.length > 0,
          })}
          onClick={() => {
            inputRef.current?.focus()
          }}
        >
          {prepend && <StyledInputOtherContainer>{prepend}</StyledInputOtherContainer>}

          {icon && iconPosition === 'start' && <>{renderedWrappedIcon}</>}

          <input
            style={inputStyles}
            disabled={disabled}
            readOnly={readonly}
            className={classNames(className, {
              error: validationErrors.length > 0,
            })}
            autoFocus={autoFocus}
            ref={inputRefCallback}
            value={value}
            onInput={onInputHandler}
            onKeyDown={onKeyDownHandler}
            placeholder={placeholder}
            type={type}
            name={name}
            onClick={(e) => {
              if (shouldStopPropagation) {
                e.stopPropagation()
              }
            }}
            onBlur={(e) => {
              onBlur?.(e)
              setFocussed(false)
            }}
            onFocus={(e) => {
              onFocus?.(e)
              setFocussed(true)
            }}
            onKeyUp={
              blurOnEscape || blurOnEnter || onEscapeKey
                ? (e) => {
                    if (e.key === 'Escape' || e.keyCode === 27) {
                      if (blurOnEscape) {
                        inputRef.current?.blur()
                      }
                      if (onEscapeKey) {
                        onEscapeKey()
                      }
                    }

                    if (blurOnEnter && e.key === 'Enter') {
                      inputRef.current?.blur()
                    }
                  }
                : undefined
            }
            data-test={testIdentifier}
            data-intercom-target={intercomTarget ? intercomTarget : undefined}
          />

          {icon && iconPosition === 'end' && <>{renderedIcon}</>}

          {append && <StyledInputOtherContainer>{append}</StyledInputOtherContainer>}
        </StyledInputWrapper>

        {isPopupShown && (
          <BasePortal portalId={'input-popup'} zIndex={ZIndeces.inputPopup}>
            <PopupCard
              onMouseDown={(e) => {
                e.preventDefault()
              }}
              style={{
                position: popupStrategy,
                left: `${popupX}px`,
                top: `${popupY}px`,
              }}
              ref={popupRef}
            >
              {popupContent}
            </PopupCard>
          </BasePortal>
        )}
      </FormElementContainer>

      {validationErrors?.length > 0 && (
        <ErrorContainer $sizeSetting={size}>
          {validationErrors?.length > 0 && <StyledError>{validationErrors.join(', ')}</StyledError>}
        </ErrorContainer>
      )}
    </FormElementContainer>
  )
}

const PopupCard = styled.div`
  pointer-events: auto;
  border: 1px solid var(--theme-grey-s2);
  box-shadow: 0px 6px 16px -4px rgba(0, 0, 0, 0.1), 0px 2px 6px -2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  overflow: hidden;
`

interface InputLook {
  fontSize: number
  height: number
  paddingX: number
  iconSize: number
  iconPaddingLeft: number
}

const SizeLooks: { [key in InputSize]: InputLook } = {
  [InputSize.LARGE]: {
    fontSize: 14,
    height: 44,
    paddingX: 24,
    iconSize: 24,
    iconPaddingLeft: 12,
  },
  [InputSize.MEDIUM]: {
    fontSize: 12,
    height: 36,
    paddingX: 20,
    iconSize: 20,
    iconPaddingLeft: 12,
  },
  [InputSize.SMALL]: {
    fontSize: 10,
    height: 30,
    paddingX: 16,
    iconSize: 16,
    iconPaddingLeft: 10,
  },
}

interface StyledInputWrapperProps {
  $sizeSetting: InputSize
  $fullWidth: boolean
  $iconPresent: boolean
  $iconPosition: InputIconPosition
}

const StyledInputWrapper = styled.div<StyledInputWrapperProps>`
  ${({ $sizeSetting: sizeSetting, $fullWidth: fullWidth }) => {
    const look = SizeLooks[sizeSetting]

    return `
      height: ${look.height}px;
      ${
        fullWidth
          ? 'display: block; width: 100%; > input { flex-grow: 1; width: 0; }'
          : 'width: fit-content;'
      }

      > input {
        font-size: ${look.fontSize}px;
        padding: 0 ${look.paddingX}px;
      }
    `
  }}

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 8px;
  line-height: 150%;
  margin: 0 0;

  border: 1px solid var(--theme-grey-s2);
  background: var(--theme-light);

  transition: 0.2s ease box-shadow, 0.2s ease border;

  cursor: text;

  &:hover:not(.readOnly) {
    outline: none;
    box-shadow: 0 0 0 4px var(--theme-primary-s2);
  }

  &.focus:not(.readOnly) {
    outline: none;
    box-shadow: 0 0 0 4px var(--theme-primary-s2);
    border-color: var(--theme-primary-s4);
  }

  &:hover.readonly {
    border-color: var(--theme-primary-s3);
  }

  &.disabled {
    cursor: not-allowed;
    box-shadow: none;
    background: var(--theme-grey-s1);
    border-color: var(--theme-grey-s1);

    > input {
      color: var(--theme-grey-s4);
      cursor: not-allowed;

      &::placeholder {
        color: var(--theme-grey-s3);
      }
    }
  }

  &:hover.disabled {
    cursor: not-allowed;
    background: var(--theme-grey-s1);
    border-color: var(--theme-grey-s1);
    box-shadow: none;
  }

  &.error {
    border-color: var(--theme-warning-s5);
  }

  &:hover.error {
    box-shadow: 0 0 0 4px var(--theme-warning-s2);
    border-color: var(--theme-warning-s5);
  }

  &.focus.error {
    box-shadow: 0 0 0 4px var(--theme-warning-s2);
    border-color: var(--theme-warning-s5);
  }

  > input {
    color: var(--theme-grey-s6);

    height: 100%;
    border-radius: 8px;
    line-height: 150%;
    margin: 0 0;

    outline: none;
    border: none;
    background: transparent;

    &::placeholder {
      color: var(--theme-grey-s4);
    }

    ${({ $iconPresent: iconPresent, $iconPosition: iconPosition }: StyledInputWrapperProps) => {
      if (iconPresent) {
        return `${iconPosition === 'end' ? 'padding-right' : 'padding-left'}: 0px;`
      }

      return ''
    }}
  }
`

const StyledInputOtherContainer = styled.div`
  display: inline-flex;
  align-items: center;
  width: fit-content;
  height: 100%;
`

const ErrorContainer = styled.span<{ $sizeSetting: InputSize }>`
  display: inline-block;
  width: 100%;

  padding-top: 8px;
  font-size: 12px;

  font-weight: 500;
  line-height: 150%;
  color: var(--theme-warning-s5);

  ${({ $sizeSetting: sizeSetting }) => {
    const look = SizeLooks[sizeSetting]
    return `
      font-size: ${look.fontSize}px;
    `
  }}
`

const StyledError = styled.span`
  margin-right: 16px;
`

interface IconContainerProps {
  $sizeSetting: InputSize
  $hasError: boolean
  $isDisabled: boolean
  $iconPosition: InputIconPosition
  $allowPointerEvents: boolean
}

const SpinnerWrapper = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: 2px;
  padding-right: 2px;
`

const IconContainer = styled.span<IconContainerProps>`
  display: inline-flex;
  align-items: center;

  height: 100%;
  pointer-events: ${({ $allowPointerEvents: allowPointerEvents }) =>
    allowPointerEvents ? 'auto' : 'none'};

  ${({ $allowPointerEvents: allowPointerEvents }) => (allowPointerEvents ? 'cursor: pointer;' : '')}

  .ionicon {
    transition: color 0.2s ease;
    color: var(--theme-primary-s3);
  }

  ${({ $sizeSetting: sizeSetting, $isDisabled: isDisabled, $hasError: hasError, $iconPosition: iconPosition, theme }) => {
    const look = SizeLooks[sizeSetting]
    return `
      font-size: ${look.iconSize}px;

      padding-left: ${iconPosition === 'start' ? 12 : 8}px;
      padding-right: ${iconPosition === 'start' ? 8 : 12}px;

      .ionicon {
        width: ${look.iconSize}px;
        height: ${look.iconSize}px;
        ${isDisabled ? 'color: var(--theme-grey-s2)' : ''}
        ${hasError ? `color: ${darken(0.05, theme.warning.s2)}` : ''}
      }
    `
  }}
`
