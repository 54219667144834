import { useMutation, useQueryClient } from '@tanstack/react-query'
import SellerApi from '../../../api/SellerApi'
import { useCurrentTenantQuery } from '@/modules/tenant/hooks/useReadTenant'

type CreateApiKeyDto = {
  name: string
}
const createPublicApiKey = async (
  tenantId: string | undefined,
  tenantName: string | undefined,
): Promise<string | null> => {
  if (!tenantId || !tenantName) {
    return null
  }

  const payload = {
    name: `Valuecase API - ${tenantName}`,
  }

  const res = await SellerApi.post<string, CreateApiKeyDto, unknown>(
    `/tenants/${tenantId}/create-api-credentials`,
    payload,
    {},
  )
  return res.payload
}

// Hook to trigger the API key creation using a mutation
const useCreatePublicApiKey = (): {
  mutate: () => void
  data: string | null
  isLoading: boolean
  error: unknown
  isSuccess: boolean
} => {
  const { tenant } = useCurrentTenantQuery()
  const queryClient = useQueryClient()

  const { mutate, data, isLoading, error, isSuccess } = useMutation(
    () => createPublicApiKey(tenant?.payload.id, tenant?.payload.tenantName),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['publicApiKey'])
      },
    },
  )

  return { mutate, data: data ?? null, isLoading, error, isSuccess }
}

export default useCreatePublicApiKey
