import styled from 'styled-components'

export enum NumberSize {
  Medium = 'Medium',
  Large = 'Large',
}

type SizeMapping = { [key in NumberSize]: string }

const mapping: SizeMapping = {
  [NumberSize.Medium]: '24px',
  [NumberSize.Large]: '28px',
}

export const Number = styled.div<{ $size?: NumberSize }>`
  border-radius: 50%;
  background: var(--theme-primary-s5);
  width: ${({ $size: size }) => mapping[size ?? NumberSize.Medium]};
  height: ${({ $size: size }) => mapping[size ?? NumberSize.Medium]};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 155%;
  flex-shrink: 0;
  flex-grow: 0;
`
