import { useMutation } from '@tanstack/react-query'
import { actionPlanTaskExpandedSchema, TActionPlanTaskUpdate } from '@valuecase/common'
import { useSpacesApi } from '../../../spaces-api'
import { useActionPlanTaskQueryHelpers } from './useActionPlanTaskQueryHelpers'

export type UpdateTaskParams = {
  id: string
  data: TActionPlanTaskUpdate
}

/**
 * Mutation to update an action plan task
 * Updates the task in the cache of task queries after a successful mutation
 */
export const useUpdateTaskMutation = () => {
  const { spacesApi } = useSpacesApi()
  const { updateTaskInCaches } = useActionPlanTaskQueryHelpers()

  return useMutation({
    mutationFn: async ({ id, data }: UpdateTaskParams) => {
      const response = await spacesApi.patch(`action-plan-task/${id}`, data)
      const result = actionPlanTaskExpandedSchema.parse(response.data)
      return result
    },
    onSuccess: (data) => updateTaskInCaches(data),
  })
}
