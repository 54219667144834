import { useMemo } from 'react'
import ZIndices from '../../../global-styles/ZIndices'

const LottieWrapperId = 'lottie-animation-container'

export function useLottieWrapper(): HTMLDivElement {
  return useMemo<HTMLDivElement>(() => {
    let lottieWrapper = document.getElementById(LottieWrapperId) as HTMLDivElement | null

    if (!lottieWrapper) {
      lottieWrapper = document.createElement('div')
      lottieWrapper.id = LottieWrapperId
      lottieWrapper.style.zIndex = `${ZIndices.lottieContainer}`
      lottieWrapper.style.width = '100%'
      lottieWrapper.style.height = '100%'
      lottieWrapper.style.position = 'absolute'
      lottieWrapper.style.left = '0'
      lottieWrapper.style.top = '0'
      lottieWrapper.style.display = 'block'
      lottieWrapper.style.pointerEvents = 'none'

      if (document.body.firstChild) {
        document.body.insertBefore(lottieWrapper, document.body.firstChild)
      } else {
        document.body.appendChild(lottieWrapper)
      }
    }

    return lottieWrapper
  }, [])
}
