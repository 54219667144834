import {
  Button2,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  Input2,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Spinner,
  Textarea,
} from '@valuecase/ui-components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useGenerateTemplate } from './hooks/useGenerateTemplate'

const TemplateGeneratorDialog = ({
  open,
  onClose,
}: {
  open: boolean
  onClose: (isOpen: boolean) => void
}) => {
  const [formData, setFormData] = useState<{
    useCase: 'onboarding' | 'sales'
    companyWebsite: string
    context?: string
  }>({
    useCase: 'onboarding',
    companyWebsite: '',
    context: '',
  })

  const [currentMessageIndex, setCurrentMessageIndex] = useState(0)
  const [dots, setDots] = useState('')
  const [templateData, setTemplateData] = useState<any>(null)

  const handleClose = useCallback(() => {
    setFormData({ useCase: 'onboarding', companyWebsite: '', context: '' })
    setTemplateData(null)
    onClose(false)
  }, [onClose])

  const { generateTemplate, isGenerating, error } = useGenerateTemplate()

  const handleGenerateTemplate = useCallback(async () => {
    try {
      const data = await generateTemplate(formData)
      setTemplateData(data)
    } catch (error) {
      console.error('Failed to generate template:', error)
    }
  }, [generateTemplate, formData])

  const loadingMessages = useMemo(
    () => [
      'Just a moment...',
      'Almost there...',
      'Hang tight...',
      'Working on it...',
      'Good things take time...',
      'Your template is on the way...',
      'Please wait a bit longer...',
      'We are crafting your template...',
      'Thanks for your patience...',
      'Almost done...',
    ],
    [],
  )

  useEffect(() => {
    let messageInterval: NodeJS.Timeout
    let dotsInterval: NodeJS.Timeout

    if (isGenerating) {
      messageInterval = setInterval(() => {
        setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % loadingMessages.length)
      }, 5000)

      dotsInterval = setInterval(() => {
        setDots((prevDots) => (prevDots.length >= 3 ? '' : prevDots + '.'))
      }, 500)
    }

    return () => {
      clearInterval(messageInterval)
      clearInterval(dotsInterval)
    }
  }, [isGenerating, loadingMessages.length])

  const getErrorMessage = useMemo(() => {
    if (!error) return ''
    if (typeof error === 'string') return error
    if (typeof error === 'object' && error !== null) {
      if (
        'response' in error &&
        error.response &&
        typeof error.response === 'object' &&
        'data' in error.response
      ) {
        return JSON.stringify(error.response.data, null, 2)
      }
      return JSON.stringify(error, null, 2)
    }
    return 'An unknown error occurred'
  }, [error])

  const handleViewTemplateData = useCallback(() => {
    if (templateData) {
      const blob = new Blob([JSON.stringify(templateData, null, 2)], {
        type: 'application/json',
      })
      const url = URL.createObjectURL(blob)
      window.open(url, '_blank')
      URL.revokeObjectURL(url)
    }
  }, [templateData])

  return (
    <Dialog open={open} onOpenChange={handleClose}>
      <DialogContent>
        {isGenerating ? (
          <div className='h-48 w-full flex flex-col items-center justify-center gap-4'>
            <Spinner />
            <div className='flex items-center'>
              <span className='text-xs font-semibold text-grey-s4'>
                {loadingMessages[currentMessageIndex]}
              </span>
              <span className='text-xs font-semibold text-grey-s4 w-6 text-left'>{dots}</span>
            </div>
          </div>
        ) : (
          <>
            <DialogHeader>
              <span className='text-lg font-semibold'>Generate Template</span>
            </DialogHeader>

            <div className='flex flex-col gap-4'>
              <Select
                value={formData.useCase}
                onValueChange={(value) =>
                  setFormData({ ...formData, useCase: value as 'onboarding' | 'sales' })
                }
              >
                <SelectTrigger>
                  <SelectValue placeholder='Select use case' />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value='onboarding'>Onboarding</SelectItem>
                  <SelectItem value='sales'>Sales</SelectItem>
                </SelectContent>
              </Select>
              <Textarea
                rows={5}
                placeholder='Context'
                value={formData.context}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                  setFormData((prevData) => ({ ...prevData, context: e.target.value }))
                }
              />
              <div className='bg-grey-s1 border-l-4 border-blue-s4 text-blue-s6 p-4 mb-4'>
                <span className='text-xs font-bold'>Example input:</span> <br />
                <span className='text-xs'>
                  Complex onboarding process of 30-60 days, every customer has different products
                  which impacts the onboarding process.
                </span>
              </div>
              <Input2
                placeholder='Company Website'
                value={formData.companyWebsite}
                onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFormData({ ...formData, companyWebsite: e.target.value })
                }
              />
            </div>
            <DialogFooter>
              <div className='flex flex-col gap-2 w-full'>
                <div className='w-full flex justify-end gap-2'>
                  <Button2
                    onClick={handleGenerateTemplate}
                    disabled={
                      !formData.companyWebsite ||
                      !/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/.test(
                        formData.companyWebsite,
                      )
                    }
                  >
                    Generate
                  </Button2>
                  {templateData && (
                    <Button2 onClick={handleViewTemplateData} variant='outlined'>
                      View Template Data
                    </Button2>
                  )}
                </div>

                {(error as any) && (
                  <>
                    <p className='text-red-500 mb-2'>An error occurred:</p>
                    <Textarea
                      value={getErrorMessage}
                      className='w-full bg-red-50 border-red-300'
                      readOnly
                    />
                  </>
                )}
              </div>
            </DialogFooter>
          </>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default TemplateGeneratorDialog
