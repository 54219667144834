import { FC, useState } from 'react'
import { HeaderFooterScrollableModal } from '@valuecase/ui-components'
import { TemplateCreatorModalProps } from '../types/TemplateCreatorModalProps'
import TemplateCreator from './TemplateCreator'

const TemplateCreatorModal: FC<TemplateCreatorModalProps> = ({
  controlsRef,
  initialSelectedTemplate,
}) => {
  const [modalIsPersistent, setModalIsPersistent] = useState<boolean>(false)

  return (
    <HeaderFooterScrollableModal
      persistent={modalIsPersistent}
      controlsRef={controlsRef}
      wide={true}
      needExplicitHeight={{ min: '300px', preferred: '656px' }}
    >
      <TemplateCreator
        setIsModalPersistent={setModalIsPersistent}
        initialSelectedTemplate={initialSelectedTemplate}
      />
    </HeaderFooterScrollableModal>
  )
}

export default TemplateCreatorModal
