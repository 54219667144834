import { FC, useCallback, useState } from 'react'
import TemplateFromSpaceCreatorView from './TemplateFromSpaceCreatorView'
import { TemplateCreatorSteps } from '../types/TemplateCreatorSteps'
import { useModalControls } from '@valuecase/ui-components'
import { TemplateCreatorTemplateDetails } from '../types/TemplateCreatorTemplateDetails'
import { TemplateCreatorForm } from './TemplateFromSpaceCreatorParts.styled'
import { TemplateIconSource } from '../types/TemplateIconSource'
import { useTrackEvent } from '../../../mixpanel/useTrackEvent'
import { TemplateFromSpaceCreatorProps } from './TemplateFromSpaceCreatorProps'
import { useCreateTemplateFromSpace } from '../hooks/useCreateTemplateFromSpace'

const emptyTemplate = {
  id: '',
  title: 'Empty',
  description: 'Use this empty template to start building a template from scratch',
  createdAt: '2023-02-20 13:20:28.332',
  updatedAt: '2023-02-20 13:20:28.332',
  createdBy: 'None',
} as TemplateCreatorTemplateDetails

const TemplateCreator: FC<TemplateFromSpaceCreatorProps> = ({ setIsModalPersistent, spaceId }) => {
  const { trackEvent } = useTrackEvent()
  const { close } = useModalControls()
  const [newTemplateName, setNewTemplateName] = useState<string>('')
  const [newTemplateUsage, setNewTemplateUsage] = useState<string>('')

  const [newTemplateIcon, setNewTemplateIcon] = useState<TemplateIconSource>({
    source: 'emoji-native',
    emojiNative: '🗒️',
  })
  const [currentStep, goToStep] = useState<TemplateCreatorSteps>('enterInfo')

  const { createTemplate, error } = useCreateTemplateFromSpace({
    onSuccess: (createdTemplate) => {
      setIsModalPersistent(false)
      close()
      trackEvent({
        event: 'templates-newtemplate-created-from-space',
        eventProperties: {
          indexPageName: 'templates',
          isNewTemplate: true,
          spaceId: spaceId,
          templateID: createdTemplate.id,
        },
      })
      if (!createdTemplate) return
      location.pathname = `/spaces/${createdTemplate.id}`
    },
    onError: () => {
      setIsModalPersistent(false)
      goToStep('enterInfo')
    },
  })

  const createNewTemplate = useCallback(() => {
    setIsModalPersistent(true)
    goToStep('loading')
    const data = {
      description: newTemplateUsage,
      spaceId: spaceId,
      title: newTemplateName,
      icon: newTemplateIcon,
    }
    createTemplate(data)
  }, [
    setIsModalPersistent,
    spaceId,
    createTemplate,
    newTemplateUsage,
    newTemplateName,
    newTemplateIcon,
  ])

  return (
    <TemplateCreatorForm
      onSubmit={(event) => {
        // Form element used for browser semantics, but submission
        // actually handled via createNewTemplate callback
        event.preventDefault()
      }}
    >
      <TemplateFromSpaceCreatorView
        error={error}
        spaceId={spaceId}
        newTemplateName={newTemplateName}
        newTemplateUsage={newTemplateUsage}
        newTemplateIcon={newTemplateIcon}
        setNewTemplateName={setNewTemplateName}
        setNewTemplateUsage={setNewTemplateUsage}
        setNewTemplateIcon={setNewTemplateIcon}
        createNewTemplate={createNewTemplate}
        currentStep={currentStep}
        goToStep={goToStep}
      />
    </TemplateCreatorForm>
  )
}

export default TemplateCreator
