import styled from 'styled-components'

const height = '72px'

const Topbar = styled.div`
  min-height: ${height};
  max-height: ${height};
  height: ${height};
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.white};
  border-bottom: 1px solid ${({ theme }) => theme.grey.s2};
  align-items: center;
`
const Left = styled.div`
  padding-left: 40px;
`

const CompanyName = styled.div`
  position: absolute;
  height: ${height};
  display: flex;
  left: 0;
  right: 0;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 140%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: ${({ theme }) => theme.grey.s3};
`
const Right = styled.div``

export const S = { Topbar, Left, CompanyName, Right }
