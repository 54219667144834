const storageKey = 'templates-list-settings'

interface TemplatesListSessionSettings {
  showTeamTemplates: boolean
  showArchivedTemplates: boolean
}
export const setTemplatesListSessionSettings = (newSettings: TemplatesListSessionSettings) => {
  window.sessionStorage.setItem(storageKey, JSON.stringify(newSettings))
}

export const getShowTeamTemplatesFilterSessionStorageValue = (): boolean | undefined => {
  const settings = getTemplatesListSessionSettings()
  if (settings === undefined) return undefined
  return settings.showTeamTemplates
}
export const getShowArchivedTemplatesFilterSessionStorageValue = (): boolean | undefined => {
  const settings = getTemplatesListSessionSettings()
  if (settings === undefined) return undefined
  return settings.showArchivedTemplates
}

const getTemplatesListSessionSettings = (): TemplatesListSessionSettings | undefined => {
  const settings = window.sessionStorage.getItem(storageKey)
  if (settings === null) return undefined
  return JSON.parse(settings) as TemplatesListSessionSettings
}
