import styled from 'styled-components'

interface Props {
  $borderRadius?: string
  $background?: string
  $blurAmount?: string
  $color?: string
}

export const Blur = styled.div<Props>`
  backdrop-filter: blur(${({ $blurAmount: blurAmount = '4px' }) => blurAmount});
  background: ${({ $background: background = 'rgba(49, 50, 58, 0.4)' }) => background};
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: ${({ $borderRadius: borderRadius = 0 }) => borderRadius};

  color: ${({ $color: color = 'white' }) => color};

  display: flex;
  justify-content: center;
  align-items: center;
`
