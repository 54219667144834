import classNames from 'classnames'
import styled from 'styled-components'

export type DottedPaginationProps = {
  page: number
  pageCount: number
  color?: 'primary' | 'grey'
  className?: string
  onPageChange?: (page: number) => void
}

const Container = styled.div`
  display: flex;
  gap: 12px;
`

const Dot = styled.div<{ $active: boolean; $color: 'primary' | 'grey' }>`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  transition: background 0.2s ease;
  background: ${({ theme, $color: color, $active: active }) => {
    if (color === 'primary') {
      return active ? theme.primary.s5 : theme.primary.s3
    } else {
      return active ? theme.grey.s5 : theme.grey.s3
    }
  }};

  &.clickable {
    cursor: pointer;
  }
`

export function DottedPagination({
  page,
  pageCount,
  onPageChange,
  color = 'primary',
  className = '',
}: DottedPaginationProps) {
  function handleClick(pageNumber: number) {
    onPageChange?.(pageNumber)
  }

  return (
    <Container className={`dotted-pagination-container ${className}`}>
      {new Array(pageCount).fill(0).map((_, index) => (
        <Dot
          className={classNames({
            dot: true,
            clickable: !!onPageChange,
          })}
          key={index}
          $color={color}
          $active={page === index}
          onClick={() => handleClick(index)}
        ></Dot>
      ))}
    </Container>
  )
}
