import ModalHeader from './ModalHeader'
import { Button } from '@valuecase/ui-components'

export type ConfirmDeleteUserBodyProps = {
  onCancel: () => void
  onDelete: () => void
  previousOwner: {
    name: string
  }
  newSpaceOwner: {
    name: string
  }
}

export default function ConfirmDeleteUserBody({
  onCancel,
  onDelete,
  previousOwner,
  newSpaceOwner,
}: ConfirmDeleteUserBodyProps) {
  return (
    <div className={'flex flex-col items-center gap-12'}>
      <ModalHeader
        title={'Delete user'}
        text={
          <>
            <div className={'text-center'}>
              All spaces of <span className={'font-bold'}>{previousOwner.name}</span> will now be
              owned by
            </div>

            <div className={'mt-12 text-center font-bold'}>{newSpaceOwner.name}</div>
          </>
        }
      />

      <div className={'flex flex-row justify-evenly items-stretch gap-2'}>
        <Button
          label={'Cancel'}
          icon={'close-circle-outline'}
          style={'gray-outlined'}
          cssStyle={{ minWidth: '155px' }}
          onClick={onCancel}
        />

        <Button
          disabled={!newSpaceOwner}
          style={'warning'}
          label={'Delete user'}
          icon={'trash-outline'}
          cssStyle={{ minWidth: '155px' }}
          onClick={onDelete}
        />
      </div>
    </div>
  )
}
