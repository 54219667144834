import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { ReadSpacesListDTO } from '@/modules/spaces/hooks/types'
import { cn } from '@/utils/Cn'
import {
  IonIcon,
  AvatarThumbnail,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  Skeleton,
} from '@valuecase/ui-components'
import { getCompanyInitials } from '@valuecase/common'
import { useProgressBarQuery } from './useProgressBarQuery'

type TBuckets = {
  '0-20': ReadSpacesListDTO[]
  '20-40': ReadSpacesListDTO[]
  '40-60': ReadSpacesListDTO[]
  '60-80': ReadSpacesListDTO[]
  '80-100': ReadSpacesListDTO[]
}

const scrollBackgroundClasses =
  'absolute top-0 rounded-lg h-16 z-10 flex justify-center items-center from-white to-transparent w-16'

const scrollButtonClasses = 'bg-primary-s5 rounded-full w-6 h-6 flex justify-center items-center'

export const ProgressBar: FC<{ spaceCreatedAfter?: Date }> = ({ spaceCreatedAfter }) => {
  const { spaces, isLoading } = useProgressBarQuery({ spaceCreatedAfter })

  const progressBuckets = useMemo(() => {
    const buckets: TBuckets = {
      '0-20': [],
      '20-40': [],
      '40-60': [],
      '60-80': [],
      '80-100': [],
    }

    for (const space of spaces ?? []) {
      const percentage = space.actionPlanSummary.progress * 100

      if (percentage < 20) {
        buckets['0-20'].push(space)
      } else if (percentage >= 20 && percentage < 40) {
        buckets['20-40'].push(space)
      } else if (percentage >= 40 && percentage < 60) {
        buckets['40-60'].push(space)
      } else if (percentage >= 60 && percentage < 80) {
        buckets['60-80'].push(space)
      } else if (percentage >= 80) {
        buckets['80-100'].push(space)
      }
    }

    return buckets
  }, [spaces])

  const spacesWithFinishedActionPlans = useMemo(() => {
    return spaces?.filter((space) => space.actionPlanSummary.progress === 1) ?? []
  }, [spaces])

  const containerRef = useRef<HTMLDivElement>(null)
  const [showScrollStart, setShowScrollStart] = useState(false)
  const [showScrollEnd, setShowScrollEnd] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = containerRef.current
        const isOverflowing = scrollWidth > clientWidth

        setShowScrollStart(isOverflowing && scrollLeft > 0)
        setShowScrollEnd(isOverflowing && scrollLeft < scrollWidth - clientWidth)
      }
    }

    const container = containerRef.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
      handleScroll() // Initial check
    }

    window.addEventListener('resize', handleScroll)

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll)
      }
      window.removeEventListener('resize', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current])

  const scrollToStart = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ left: 0, behavior: 'smooth' })
    }
  }

  const scrollToEnd = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ left: containerRef.current.scrollWidth, behavior: 'smooth' })
    }
  }

  return (
    <>
      {isLoading && <Skeleton className={'min-h-[108px] max-h-[108px] rounded-lg'} />}
      {!isLoading && (
        <div
          id='dashboard-tasks-action-plan-progress-bar'
          className={'bg-white rounded-lg flex flex-col flex-shrink-0 relative'}
        >
          {showScrollStart && (
            <div className={cn(scrollBackgroundClasses, 'left-0 bg-gradient-to-r')}>
              <button onClick={scrollToStart} className={scrollButtonClasses}>
                <IonIcon name={'chevron-back'} className={'text-white'} />
              </button>
            </div>
          )}
          {showScrollEnd && (
            <div className={cn(scrollBackgroundClasses, 'right-0 bg-gradient-to-l')}>
              <button onClick={scrollToEnd} className={scrollButtonClasses}>
                <IonIcon name={'chevron-forward'} className={'text-white'} />
              </button>
            </div>
          )}
          <div className={'flex flex-col gap-2'}>
            <div ref={containerRef} className={'flex gap-1 overflow-x-hidden p-3'}>
              <ProgressBarBucket
                label={{ text: '0%', position: 'START' }}
                startColor={'#F3F5F8'}
                endColor={'#E8F8F5'}
              >
                <div className={'flex justify-between'}>
                  <StackedThumbnails
                    spaces={progressBuckets['0-20'].filter(
                      (s) => s.actionPlanSummary.progress === 0,
                    )}
                  />
                  <StackedThumbnails
                    spaces={progressBuckets['0-20'].filter((s) => s.actionPlanSummary.progress > 0)}
                    className={'w-full'}
                  />
                </div>
              </ProgressBarBucket>
              <ProgressBarBucket startColor={'#E8F8F5'} endColor={'#D0F5EA'}>
                <div className={'flex justify-center'}>
                  <StackedThumbnails spaces={progressBuckets['20-40']} />
                </div>
              </ProgressBarBucket>
              <ProgressBarBucket
                startColor={'#D0F5EA'}
                endColor={'#9FE5D0'}
                label={{
                  text: '50%',
                  position: 'MIDDLE',
                }}
              >
                <StackedThumbnails spaces={progressBuckets['40-60']} />
              </ProgressBarBucket>
              <ProgressBarBucket startColor={'#9FE5D0'} endColor={'#55D1AC'}>
                <StackedThumbnails spaces={progressBuckets['60-80']} />
              </ProgressBarBucket>
              <ProgressBarBucket
                startColor={'#55D1AC'}
                endColor={'#34C79B'}
                label={{
                  text: '100% completed',
                  position: 'END',
                }}
              >
                <div className={'flex justify-between items-center'}>
                  <StackedThumbnails
                    spaces={progressBuckets['80-100'].filter(
                      (s) => s.actionPlanSummary.progress < 1,
                    )}
                    className={'w-full'}
                  />
                  {spacesWithFinishedActionPlans.length > 0 && (
                    <SpaceProgressTooltip spaces={spacesWithFinishedActionPlans} align={'end'}>
                      {spacesWithFinishedActionPlans.length}{' '}
                      {spacesWithFinishedActionPlans.length === 1 ? 'space' : 'spaces'}
                    </SpaceProgressTooltip>
                  )}
                </div>
              </ProgressBarBucket>
            </div>
            <span className={'text-xxs text-grey-s5 leading-5 pb-3 pl-3 pr-3 -mt-3'}>
              Action Plan Progress
            </span>
          </div>
        </div>
      )}
    </>
  )
}

function StackedThumbnails({
  spaces,
  className,
}: {
  spaces: ReadSpacesListDTO[]
  className?: string
}) {
  return (
    <div className={cn('flex justify-center ml-1.5 h-6', className)}>
      {spaces.slice(0, 4).map((space) => (
        <div key={space.id} className={'flex hover:z-10'}>
          <SpaceProgressTooltip
            spaces={[space]}
            align={'center'}
            className={'-ml-2 hover:scale-[1.2] transition-transform relative'}
          >
            {!!space.companyLogo && (
              <AvatarThumbnail size='SMALL' type='image' imageUrl={space.companyLogo} />
            )}
            {!space.companyLogo && (
              <AvatarThumbnail
                size='SMALL'
                type='gravatarInitials'
                initials={getCompanyInitials(space.companyName)}
              />
            )}
          </SpaceProgressTooltip>
        </div>
      ))}
      {spaces.length > 4 && (
        <SpaceProgressTooltip
          spaces={spaces.slice(4, spaces.length)}
          className={'hover:bg-grey-s1'}
        >
          +{spaces.length - 4}
        </SpaceProgressTooltip>
      )}
    </div>
  )
}

function ProgressBarBucket({
  children,
  label,
  startColor,
  endColor,
}: {
  startColor: string
  endColor: string
  children?: React.ReactNode
  label?: { text: string; position: 'START' | 'MIDDLE' | 'END' }
}) {
  return (
    <div className={'flex flex-col gap-1 basis-[200px] shrink-0 flex-grow'}>
      {children}
      <div
        className={'h-1 w-full'}
        style={{
          background: `linear-gradient(to right, ${startColor}, ${endColor})`,
        }}
      ></div>
      {label && (
        <div
          className={cn('flex w-full text-grey-s5', {
            'justify-start': label.position === 'START',
            'justify-center': label.position === 'MIDDLE',
            'justify-end': label.position === 'END',
          })}
        >
          <span className={'text-xxs leading-5'}>{label.text}</span>
        </div>
      )}
    </div>
  )
}

function SpaceProgressTooltip({
  spaces,
  children,
  align = 'center',
  className,
}: {
  spaces: ReadSpacesListDTO[]
  align?: 'start' | 'center' | 'end'
  children: ReactNode
  className?: string
}) {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <span
          className={
            cn(
              'text-grey-s5 flex-shrink-0 flex justify-center items-center align-middle leading-5 cursor-pointer',
              'rounded px-0.5 transition-colors',
              className,
            ) + ' text-xxs' // the cn helper doesn't support `text-xxs` so we have to add it manually
          }
        >
          {children}
        </span>
      </TooltipTrigger>
      <TooltipContent
        align={align}
        className={'flex flex-col gap-1 p-2 max-h-[50vh] overflow-auto'}
      >
        {spaces
          .sort((a, b) => a.actionPlanSummary.progress - b.actionPlanSummary.progress)
          .map((space) => (
            <div key={space.id} className={'flex gap-6 justify-between'}>
              <h3>
                <span>{space.companyName}</span>
                <span className={'text-white/65 ml-1'}>
                  {Math.round(space.actionPlanSummary.progress * 100)}%
                </span>
              </h3>
              <button
                onClick={() => {
                  const url = new URL(location.origin)
                  url.pathname = `/spaces/${space.rootNodeId}`
                  url.searchParams.set('token', space.authToken ?? '')
                  window.open(url.toString(), '_blank')
                }}
              >
                Open Space
              </button>
            </div>
          ))}
      </TooltipContent>
    </Tooltip>
  )
}
