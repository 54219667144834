import { useCallback, useState } from 'react'
import { Button, TextareaDeprecated } from '@valuecase/ui-components'
import { Spinner } from '@valuecase/ui-components/src/components/legacy/Spinner/Spinner'
import { spacesApi } from '@/api/spacesApi/spacesAPIclient'

export interface SpacePropertyGptPromptEntryProps {
  value: string
  onDiscard: () => void
  onAccept: (prompt: string) => void
}

export function SpacePropertyGptPromptEntry(props: SpacePropertyGptPromptEntryProps) {
  const { value, onDiscard, onAccept } = props
  const [prompt, setPrompt] = useState(value || '')
  const [exampleState, setExampleState] = useState<{
    status: 'idle' | 'loading' | 'success' | 'error'
    result: string
  }>({
    status: 'idle',
    result: '',
  })

  const generateExample = useCallback(async () => {
    try {
      setExampleState({ status: 'loading', result: '' })
      const processedPrompt = prompt
        .trim()
        .replaceAll('{{contactFirstName}}', 'John')
        .replaceAll('{{contactLastName}}', 'Doe')
        .replaceAll('{{companyName}}', 'Apple')
      const result = await spacesApi.get(`gpt?prompt=${encodeURIComponent(processedPrompt)}`)
      setExampleState({ status: 'success', result: result.data.message })
    } catch (e) {
      setExampleState({
        status: 'error',
        result: (e && (e as any).message) || 'Error generating example',
      })
    }
  }, [prompt])

  return (
    <>
      <TextareaDeprecated
        value={prompt}
        onInput={(s) => {
          // If user types new prompt after error, reset error state
          if (exampleState.status === 'error') {
            setExampleState({ status: 'idle', result: '' })
          }
          setPrompt(s)
        }}
        placeholder='Enter prompt...'
        className='w-full'
        fullWidth={true}
        maxHeight={100}
        autoFocus={true}
      />
      {!!prompt && (exampleState.status === 'loading' || exampleState.status === 'success') && (
        <div className='mt-2'>
          <div className='text-sm font-bold'>Example result:</div>
          <div className='border py-2 px-2 min-h-[2em] mt-2 rounded-lg bg-white text-sm'>
            {!!exampleState.result && exampleState.result}
            {!exampleState.result && exampleState.status === 'loading' && (
              <div className='flex'>
                <Spinner $size='20px' />
              </div>
            )}
          </div>
        </div>
      )}
      {!!prompt && exampleState.status === 'error' && (
        <div className='mt-2 text-red-500'>{exampleState.result}</div>
      )}
      <div className='flex mt-2'>
        <Button label='Discard' className='mr-2' style='gray-outlined' onClick={onDiscard} />
        <Button
          label='Test prompt*'
          className='ml-auto'
          style='outlined'
          onClick={generateExample}
        />
        <Button
          label='Accept'
          className='ml-2'
          onClick={() => {
            onAccept(prompt.trim())
          }}
        />
      </div>
    </>
  )
}
