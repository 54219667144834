import { CSSProperties, forwardRef, ReactNode } from 'react'
import { IonIcon } from '../../../Icons/IonIcon'
import styled from 'styled-components'
export type RequireAtLeastOne<T, Keys extends keyof T = keyof T> = Pick<T, Exclude<keyof T, Keys>> &
  {
    [K in Keys]-?: Required<Pick<T, K>> & Partial<Pick<T, Exclude<Keys, K>>>
  }[Keys]

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'outlined'
  | 'flat'
  | 'success'
  | 'warning'
  | 'warning-outlined'
  | 'primary-flat'
  | 'flat-sidebar'
  | 'flat-warning'
  | 'gray-outlined'
export type ButtonSize = 'x-small' | 'small' | 'medium' | 'large'

export type ButtonProps = RequireAtLeastOne<
  {
    id?: string
    label?: string | ReactNode
    icon?: string // https://ionic.io/ionicons
    onClick?: (e?: React.MouseEvent<HTMLButtonElement>) => void
    iconPosition?: 'left' | 'right'
    style?: ButtonType
    size?: ButtonSize
    disabled?: boolean
    loading?: boolean
    circle?: boolean
    stopClickPropagation?: boolean
    fullWidth?: boolean
    cssStyle?: CSSProperties
    iconType?: 'ion' | 'custom' // `ion` - default - pass the icon name; `custom` pass the path to the svg
    submitButton?: boolean
    testIdentifier?: string
    linksToBlock?: string
    intercomTarget?: string
    className?: string
  },
  'label' | 'icon'
>

type StyledButtonProps = {
  $circle: boolean
  $size: ButtonSize
  $buttonType: ButtonType
  $iconPosition: 'left' | 'right'
  $iconOnly: boolean
}

const StyledButton = styled.button<StyledButtonProps>`
  font-weight: 500;
  border-radius: ${({ $circle: circle, $size: size }) =>
    circle ? '9999px' : size === 'medium' ? '8px' : '6px'};

  transition: all 0.2s ease;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  border: none;
  display: inline-flex;
  gap: 6px;
  align-items: center;
  justify-content: center;
  flex-direction: ${({ $iconPosition: iconPosition }) =>
    iconPosition === 'right' ? 'row-reverse' : 'row'};
  white-space: nowrap;

  ${({ $size: size, $iconOnly: iconOnly }) => {
    if (size === 'large') {
      return `
          padding: ${iconOnly ? '10px 10px' : '10px 10px'};
        `
    }
    if (size === 'medium') {
      return `
          padding: ${iconOnly ? '10px 10px' : '10px 10px'};
        `
    }
    if (size === 'small') {
      return `
          padding: ${iconOnly ? '7px 7px' : '8px 8px'};
        `
    }
    if (size === 'x-small') {
      return `
          padding: ${iconOnly ? '7px 7px' : '6px 8px'};
        `
    }
  }}

  ${({ $size: size }) => {
    if (size === 'large') {
      return `
        font-size: 16px;
        line-height: 24px;

        .ionicon {
          font-size: 20px;
        }
      `
    }
    if (size === 'medium') {
      return `
        font-size: 12px;
        line-height: 16px;

        .ionicon {
          font-size: 16px;
        }
      `
    }
    if (size === 'small') {
      return `
        font-size: 10px;
        line-height: 14px;
        .ionicon {
          font-size: 12px;
        }
      `
    }
    if (size === 'x-small') {
      return `
        font-size: 10px;
        line-height: 14px;
        .ionicon {
          font-size: 12px;
        }
      `
    }
  }};

  ${({ $buttonType: buttonType, theme, disabled }) => {
    switch (buttonType) {
      case 'primary':
        return `
          color: #fff;
          background: ${!disabled ? theme.primary.s5 : theme.grey.s3};

          &:hover {
            background: ${!disabled ? theme.primary.s6 : theme.grey.s3};
          }
        `
      case 'secondary':
        return `
          color: ${!disabled ? theme.primary.s5 : theme.grey.s4};
          background: ${!disabled ? theme.primary.s2 : theme.grey.s1};

          &:hover {
            background: ${!disabled ? theme.primary.s3 : theme.grey.s1};
          }
        `
      case 'outlined':
        return `
          color: ${!disabled ? theme.primary.s5 : theme.grey.s4};
          background: ${!disabled ? theme.primary.s2 : theme.grey.s1};
          border: 1px solid ${!disabled ? theme.primary.s5 : theme.grey.s4};

          &:hover {
            background: ${!disabled ? theme.primary.s3 : theme.grey.s1};
          }
        `
      case 'flat':
        return `
          color: ${!disabled ? theme.grey.s6 : theme.grey.s3};
          background: transparent;

          &:hover {
            background: ${!disabled ? theme.grey.s1 : 'transparent'};
          }

          .ion-icon {
            color: ${!disabled ? theme.grey.s4 : theme.grey.s3};
          }
        `
      case 'primary-flat':
        return `
          color: ${!disabled ? theme.primary.s5 : theme.grey.s3};
          background: transparent;

          &:hover {
            background: ${!disabled ? theme.primary.s1 : 'transparent'};
          }

          .ion-icon {
            color: ${!disabled ? theme.primary.s5 : theme.grey.s3};
          }

        `
      case 'success':
        return `
          color: #fff;
          background: ${!disabled ? theme.success.s5 : theme.grey.s3};

          &:hover {
            background: ${!disabled ? theme.success.s6 : theme.grey.s3};
          }
        `
      case 'warning':
        return `
          color: #fff;
          background: ${!disabled ? theme.warning.s5 : theme.grey.s3};

          &:hover {
            background: ${!disabled ? theme.warning.s6 : theme.grey.s3};
          }
        `
      case 'warning-outlined':
        return `
          color: ${!disabled ? theme.warning.s5 : theme.grey.s4};
          background: ${!disabled ? theme.warning.s2 : theme.grey.s1};
          border: 1px solid ${!disabled ? theme.warning.s5 : theme.grey.s4};

          &:hover {
            background: ${!disabled ? theme.warning.s3 : theme.grey.s1};
          }
        `
      case 'flat-warning':
        return `
          color: ${!disabled ? theme.warning.s5 : theme.grey.s3};
          background: transparent;

          &:hover {
            background: ${!disabled ? theme.warning.s1 : 'transparent'};
          }

          .ion-icon {
            color: ${!disabled ? theme.warning.s5 : theme.grey.s3};
          }
        `
      case 'flat-sidebar':
        return `
          color: ${!disabled ? theme.grey.s6 : theme.grey.s3};
          background: transparent;

          &:hover {
            background: ${!disabled ? theme.grey.s2 : 'transparent'};
          }

          .ion-icon {
            color: ${!disabled ? theme.grey.s4 : theme.grey.s3};
          }
        `
      case 'gray-outlined':
        return `
          color: ${!disabled ? theme.grey.s6 : theme.grey.s3};
          background: ${!disabled ? theme.grey.s1 : theme.grey.s1};
          border: 1px solid ${!disabled ? theme.grey.s6 : theme.grey.s3};

          &:hover {
            background: ${!disabled ? theme.grey.s2 : theme.grey.s1};
          }
        `

      default:
        return `
        background: #2F80ED;
        `
    }
  }}
`

/**
 * @deprecated use Button2
 */
export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    id,
    label,
    icon,
    onClick,
    iconPosition = 'left',
    style = 'primary',
    size = 'medium',
    disabled = false,
    loading = false,
    stopClickPropagation,
    circle = false,
    cssStyle = {},
    iconType = 'ion',
    fullWidth = false,
    submitButton = false,
    testIdentifier,
    linksToBlock,
    intercomTarget,
    className,
  } = props

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (disabled || loading || submitButton) return
    onClick?.(e)
  }

  return (
    <StyledButton
      ref={ref}
      id={id}
      className={className}
      $circle={circle}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        handleClick(e)
        if (stopClickPropagation) e.stopPropagation()
      }}
      $size={size}
      data-test={testIdentifier}
      $buttonType={style}
      $iconPosition={iconPosition}
      disabled={disabled}
      $iconOnly={!label && !!icon}
      style={{ ...(fullWidth ? { width: '100%' } : { width: 'fit-content' }), ...cssStyle }}
      type={submitButton ? 'submit' : 'button'}
      data-block-link={linksToBlock && linksToBlock.length > 0 ? linksToBlock : undefined}
      data-intercom-target={intercomTarget ? intercomTarget : undefined}
    >
      {icon && (iconType === 'ion' ? <IonIcon name={icon} /> : <IonIcon src={icon} />)}
      {label}
    </StyledButton>
  )
})

Button.displayName = 'Button'
