import { FC, useMemo } from 'react'
import { TaskColumnView } from './TaskColumnView'
import { useNextUnscheduledTasks } from '@valuecase/ui-components'
import { EmptyStateColumnWrapper } from './EmptyStateColumnWrapper'
import { useNavigate } from '@tanstack/react-router'
import { NextUnscheduledTasksEmptyState } from './empty-states'

export const NextUnscheduledTasksColumn: FC<{ spaceCreatedAfter?: Date }> = ({
  spaceCreatedAfter,
}) => {
  const navigate = useNavigate()
  const tasksQuery = useNextUnscheduledTasks({ spaceCreatedAfter })

  const emptyState = useMemo(() => {
    return (
      <EmptyStateColumnWrapper>
        <NextUnscheduledTasksEmptyState />
      </EmptyStateColumnWrapper>
    )
  }, [])

  return (
    <TaskColumnView
      id='dashboard-tasks-without-due-date-column'
      iconName='arrow-forward-circle'
      iconClassName='text-orange-s4'
      title='Next Task Without Due Date'
      tooltip='This column only shows one task per action plan.'
      taskStatus='nextUnscheduled'
      tasksCount={tasksQuery.data?.totalItems || 0}
      tasks={tasksQuery.data?.items || []}
      emptyState={emptyState}
      isLoading={tasksQuery.isLoading}
      onViewAllClick={() =>
        navigate({ to: '/tasks/list', search: { taskStatus: 'nextUnscheduled' } })
      }
    />
  )
}
