import { useS3Upload } from '@/api/s3'
import { createLogger } from '@/logging/Logger'
import { useGetTemplateUsedCustomProperties } from '@/modules/spaceProperties/useGetTemplateUsedCustomProperties'
import { useGetTenantCustomProperties } from '@/modules/spaceProperties/useGetTenantCustomProperties'
import useReadMyTeams from '@/modules/teams/hooks/useReadMyTeams'
import useReadTeams from '@/modules/teams/hooks/useReadTeams'
import { useCurrentTenantQuery } from '@/modules/tenant/hooks/useReadTenant'
import {
  SalesforceAccount,
  SalesforceCase,
  SalesforceContact,
  SalesforceObject,
  SalesforceObjectType,
  SalesforceOpportunity,
  SupportedIntegrationThirdParty,
} from '@valuecase/common'
import { useModalControls } from '@valuecase/ui-components'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { z } from 'zod'
import SellerApi from '../../../../api/SellerApi'
import { useTrackEvent } from '../../../../mixpanel/useTrackEvent'
import { VLoaderAnimation } from '../../../../ui-components/VLoader/VLoader'
import { useNotifications } from '../../../../utils/Notifications/Notifications'
import { HubSpotObjectSelector } from '../../../integrations/hubspot/HubSpotObjectSelector'
import { HubSpotCompany, HubSpotContact, HubSpotDeal } from '../../../integrations/hubspot/types'
import { SalesforceObjectSelector } from '../../../integrations/salesforce/SalesforceObjectSelector'
import { useReadTemplates } from '../../../templates/hooks/useReadTemplates'
import { TemplateCreatorLibraryItemData } from '../../../templates/types/TemplateCreatorLibraryItemData'
import { CreateSpaceIntegrationIdMappingInput } from '../../hooks/types'
import { useCreateSpace } from '../../hooks/useCreateSpace'
import { HubSpotBadge } from './HubSpotBadge'
import { SalesforceBadge } from './SalesforceBadge'
import {
  SpaceCreationCustomPropertiesStep,
  SpaceProperty,
} from './SpaceCreationCustomPropertiesStep'
import { Navigation } from './SpaceCreationNavigation'
import { Step1 } from './SpaceCreationStep1'
import { Step2 } from './SpaceCreationStep2'
import { SpaceCreationSteps } from './types/SpaceCreationSteps'
import { HubSpotIntegration, SpaceIntegration } from './types/SpaceIntegration'

const Step1Schema = z.object({
  companyName: z.string().min(2),
  contactFirstName: z.string().min(2),
  contactLastName: z.string().min(2),
  logo: z.string().optional(),
  website: z.string().optional(),
  language: z.string().optional(),
  logoBlob: z.instanceof(File).optional(),
  customProperties: z
    .object({
      key: z.string(),
      value: z.string(),
    })
    .optional(),
})

const SpaceDataSchema = Step1Schema.extend({
  parentTemplateId: z.string(),
})

export type Step1DataType = Partial<z.infer<typeof Step1Schema>>

/**
 * If the id is in the format PortalId_ObjectId, return just ObjectId.
 * Else return the full id.
 */
function trimPortalIdFromHubSpotIdParam(hubSpotId: string) {
  const idParts = hubSpotId.split('_')
  return idParts[1] || idParts[0]
}

const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-bottom: 40px;
  gap: 8px;

  h1 {
    font-size: 24px;
    font-weight: 900;
    color: ${({ theme }) => theme.grey.s6};
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.grey.s5};
    margin: 0;
    padding: 0;
  }
`

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 200px;
`

type State = {
  step: SpaceCreationSteps
  isLoading: boolean
}

/**
 * This steps are skippable in the sense that the user can choose, for example, to not link a
 * Salesforce opportunity to the space and instead just enter the buyer details and select a
 * template. Same goes for HubSpot deals.
 */
const skippableSteps: Set<SpaceCreationSteps> = new Set([
  'select-salesforce-opportunity',
  'select-hubspot-deal',
])

export function SpaceCreationFlow({
  flags,
  integrations,
  embedMode,
}: {
  integrations: SpaceIntegration[]
  flags: Record<string, boolean | undefined>
  embedMode: boolean
}) {
  // Seems like overkill for now, but the structure of having a list of integrations with a name
  // is a building block for future integrations: working towards a state where, if we add more
  // integrations, we can just add them to the list and the rest of the code will work as is.
  const { hasSalesforceIntegration, hasHubSpotIntegration, hubSpotIntegration } = useMemo(() => {
    const hubSpotIntegration = integrations.find((i) => i.name === 'hubSpot') as
      | HubSpotIntegration
      | undefined
    return {
      hasSalesforceIntegration:
        integrations.find((i) => i.name === 'salesforce')?.status === 'enabled',
      hasHubSpotIntegration: hubSpotIntegration?.status === 'enabled',
      hubSpotIntegration,
    }
  }, [integrations])
  const [selectedTeamIds, setSelectedTeamIds] = useState<Set<string> | undefined>()
  const { myTeams } = useReadMyTeams()
  const { data: teams } = useReadTeams()
  const [didAutoTeamSet, setDidAutoTeamSet] = useState<boolean>(false)
  useEffect(() => {
    // If the current users's teams just loaded and a team selection has not yet been made, then
    // make a selection based on the current user's teams
    if (!didAutoTeamSet && Array.isArray(myTeams) && !selectedTeamIds) {
      setDidAutoTeamSet(true)
      if (myTeams.length === 0) {
        setSelectedTeamIds(new Set()) // Empty Set = select whole tenant
      } else if (myTeams.length === 1) {
        setSelectedTeamIds(new Set([myTeams[0].id]))
      }
      // If the user is a member of multiple teams, no selection is made for the user
    }
  }, [myTeams, didAutoTeamSet, selectedTeamIds])
  const { tenant } = useCurrentTenantQuery()
  const { upload } = useS3Upload()
  const { trackEvent } = useTrackEvent()
  const [customProperties, setCustomProperties] = useState<SpaceProperty[]>([])

  const [selectedSalesforceObject, setSelectedSalesforceObject] = useState<
    SalesforceObject | undefined
  >(undefined)
  const [selectedHubSpotObject, setSelectedHubSpotObject] = useState<
    HubSpotDeal | HubSpotCompany | HubSpotContact | undefined
  >(undefined)
  const [logoUrl, setLogoUrl] = useState<string>('')
  const [step1Data, setStep1Data] = useState<Step1DataType>({})
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | undefined>(undefined)
  const { close } = useModalControls()
  const { unarchivedTemplates: templates } = useReadTemplates()
  const selectedTemplate: TemplateCreatorLibraryItemData | undefined = useMemo(() => {
    if (!selectedTemplateId) {
      return undefined
    }
    const found = templates?.find((template) => template.id === selectedTemplateId)

    return found
  }, [selectedTemplateId, templates])
  const { templateUsedCustomProperties, isLoading: isLoadingTemplateUsedCustomProperties } =
    useGetTemplateUsedCustomProperties(selectedTemplate?.id)
  const { customProperties: tenantCustomProperties } = useGetTenantCustomProperties()
  const didHubSpotDataFetchOnLoad = useRef<boolean>(false)
  const { error } = useNotifications()
  const [integrationIdMappings, setIntegrationIdMappings] = useState<
    CreateSpaceIntegrationIdMappingInput[]
  >([])
  // Keep track of where auto-filled data comes from: if step is skipped after selection, then
  // we know we can clear it if the user ends up skipping the corresponding step.
  const [autoFillDataSource, setAutoFillDataSource] = useState<
    SupportedIntegrationThirdParty | undefined
  >(undefined)

  const requiredCustomProperties = useMemo(() => {
    return tenantCustomProperties?.filter((prop) => !prop.isOptional) || []
  }, [tenantCustomProperties])

  // Based on whether the integrations are enabled, determine the steps that are applicable.
  const applicableOrderedSteps = useMemo(() => {
    let steps: SpaceCreationSteps[] = []
    if (hasSalesforceIntegration && !embedMode) {
      steps = [...steps, 'select-salesforce-opportunity']
    }
    if (
      hasHubSpotIntegration &&
      !embedMode &&
      flags &&
      flags['select-hub-spot-deal-during-space-creation']
    ) {
      steps = [...steps, 'select-hubspot-deal']
    }
    steps = [...steps, 'enter-buyer-details', 'select-template']

    if (flags && flags['space-custom-properties']) {
      if (
        templateUsedCustomProperties &&
        Array.isArray(templateUsedCustomProperties) &&
        templateUsedCustomProperties.length > 0
      ) {
        steps = [...steps, 'space-custom-properties']
      }
    }
    return steps
  }, [
    flags,
    embedMode,
    hasHubSpotIntegration,
    hasSalesforceIntegration,
    templateUsedCustomProperties,
  ])
  const [state, setState] = useState<State>({
    // Initial step is the first applicable step
    step: applicableOrderedSteps[0],
    isLoading: false,
  })

  /**
   * Adds or overwrites the integration id mappings in the state with the given integration id
   * mappings. Ensures existing mappings are retained and only one mapping per third party entity
   * type and third party is present.
   */
  const addOrOverwriteIntegrationIdMappings = useCallback(
    (newIntegrationIdMappings: CreateSpaceIntegrationIdMappingInput[]) => {
      const updatedIntegrationIdMappings = [...integrationIdMappings]
      for (const newIntegrationIdMapping of newIntegrationIdMappings) {
        const existingIndex = updatedIntegrationIdMappings?.findIndex(
          (attr) => attr.thirdParty === newIntegrationIdMapping.thirdParty,
        )
        if (existingIndex !== undefined && existingIndex !== -1) {
          updatedIntegrationIdMappings[existingIndex] = newIntegrationIdMapping
        } else {
          updatedIntegrationIdMappings.push(newIntegrationIdMapping)
        }
      }
      setIntegrationIdMappings(updatedIntegrationIdMappings)
    },
    [integrationIdMappings],
  )

  /**
   * Removes the integration id mapping with the given third party entity type and third party
   * from the state.
   */
  const removeIntegrationIdMapping = useCallback(
    (thirdPartyEntityType: string, thirdParty: SupportedIntegrationThirdParty) => {
      const updatedIntegrationIdMappings = integrationIdMappings.filter(
        (attr) =>
          !(attr.thirdPartyEntityType === thirdPartyEntityType && attr.thirdParty === thirdParty),
      )
      setIntegrationIdMappings(updatedIntegrationIdMappings)
    },
    [integrationIdMappings],
  )

  const handleSalesforceObjectSelected = useCallback(
    (salesforceObject: SalesforceObject, salesforceObjectType: SalesforceObjectType) => {
      const linkedAccountName =
        ['Opportunity', 'Case', 'Contact'].includes(salesforceObjectType) &&
        (salesforceObject as SalesforceOpportunity | SalesforceCase | SalesforceContact).Account
          ?.Name
      const accountName =
        salesforceObjectType === 'Account' && (salesforceObject as SalesforceAccount).Name
      const companyName = linkedAccountName || accountName || null
      if (companyName) {
        updateForm('companyName', companyName)
        setAutoFillDataSource('Salesforce')
      }

      addOrOverwriteIntegrationIdMappings([
        {
          thirdParty: 'Salesforce',
          thirdPartyEntityType: salesforceObjectType,
          thirdPartyId: salesforceObject.Id,
        },
      ])
      setSelectedSalesforceObject(salesforceObject)
    },
    [addOrOverwriteIntegrationIdMappings],
  )

  const handleUploadFile = useCallback(
    async (file: File) => {
      updateForm('logoBlob', file)
      const uploadedFile = await upload(file)
      updateForm('logo', uploadedFile.s3ObjectKey)
    },
    [upload],
  )

  const getLogoFromUrl = useCallback(
    async (urlInput: string) => {
      let url = urlInput
      if (!url) {
        return
      }
      if (url.includes('http://') || url.includes('https://')) {
        url = url.replaceAll('http://', '').replaceAll('https://', '')
      }

      try {
        const response = await fetch(`https://cdn.brandfetch.io/${url}`)

        if (!response.ok) {
          error('No logo found for the given URL.')
          return
        }

        const file = new File([await response.blob()], `${crypto.randomUUID()}.png`, {
          type: 'image/png',
        })
        updateForm('website', url)
        await handleUploadFile(file)
        trackEvent({
          event: 'spaces-newspace-loadlogo',
          eventProperties: {
            indexPageName: 'Create Space Modal',
          },
        })
      } catch (err) {
        if (err instanceof TypeError && err.message.includes('Failed to fetch')) {
          error('No logo found for the given URL.')
          return
        }
        throw err
      }
    },
    [handleUploadFile, trackEvent, error],
  )

  const getLogoFromCurrentUrl = useCallback(async () => {
    if (logoUrl) {
      getLogoFromUrl(logoUrl)
    }
  }, [getLogoFromUrl, logoUrl])

  const fetchHubSpotCompany = useCallback(async (hubSpotCompanyId: string) => {
    const hubSpotCompanyRes = await SellerApi.get<HubSpotCompany, {}>(
      `integrations/hubspot/company/${encodeURIComponent(
        trimPortalIdFromHubSpotIdParam(hubSpotCompanyId),
      )}`,
    )
    return hubSpotCompanyRes.payload
  }, [])

  const fetchHubSpotContact = useCallback(async (hubSpotContactId: string) => {
    const hubSpotContactRes = await SellerApi.get<HubSpotContact, {}>(
      `integrations/hubspot/contact/${encodeURIComponent(
        trimPortalIdFromHubSpotIdParam(hubSpotContactId),
      )}`,
    )
    return hubSpotContactRes.payload
  }, [])

  /**
   * Fetches the HubSpot via the HubSpot API and updates the create space form fields
   * with the company name and website if they could be found.
   */
  const fetchHubSpotCompanyAndUpdateFields = useCallback(
    async (hubSpotCompanyId: string) => {
      try {
        const hubSpotCompany = await fetchHubSpotCompany(hubSpotCompanyId)
        const firstContactId = hubSpotCompany.associations?.contacts?.results?.[0].id
        const hubSpotContact = firstContactId
          ? await fetchHubSpotContact(firstContactId)
          : undefined
        setStep1Data({
          companyName: hubSpotCompany.properties.name,
          website: hubSpotCompany.properties.domain || undefined,
          contactFirstName: hubSpotContact?.properties.firstname,
          contactLastName: hubSpotContact?.properties.lastname,
        })
        if (hubSpotCompany.properties.domain) {
          setLogoUrl(hubSpotCompany.properties.domain || '')
          getLogoFromUrl(hubSpotCompany.properties.domain || '')
        }
        setAutoFillDataSource('HubSpot')
      } catch (err) {
        // Just means company name is not populated from HubSpot data.
        console.error(err)
      }
    },
    [fetchHubSpotCompany, fetchHubSpotContact, getLogoFromUrl],
  )

  /**
   * Fetches the HubSpot contact via the HubSpot API and updates the create space form fields
   * with the contact first and last name if they could be found. Additionally, if the contact
   * is associated with a company, the company name and website are also set if they could be found.
   */
  const fetchHubSpotContactAndUpdateFields = useCallback(
    async (hubSpotContactId: string) => {
      try {
        const hubSpotContact = await fetchHubSpotContact(hubSpotContactId)
        const firstCompanyId = hubSpotContact.associations?.companies?.results?.[0].id
        const hubSpotCompany = firstCompanyId
          ? await fetchHubSpotCompany(firstCompanyId)
          : undefined
        setStep1Data({
          contactFirstName: hubSpotContact.properties.firstname,
          contactLastName: hubSpotContact.properties.lastname,
          companyName: hubSpotCompany?.properties.name,
          website: hubSpotCompany?.properties.domain || undefined,
        })
        setLogoUrl(hubSpotCompany?.properties.domain || '')
        getLogoFromUrl(hubSpotCompany?.properties.domain || '')
        setAutoFillDataSource('HubSpot')
      } catch (err) {
        // Just means company contact first and last name fields are not populated
        // from HubSpot values.
        console.error(err)
      }
    },
    [fetchHubSpotCompany, fetchHubSpotContact, getLogoFromUrl],
  )

  /**
   * Fetches the HubSpot deal via the HubSpot API and, the deal is associated with a company, the
   * company name and website in the create space form are set if they could be found.
   */
  const fetchHubSpotDealAndUpdateFields = useCallback(
    async (hubSpotDealId: string) => {
      try {
        const hubSpotDealRes = await SellerApi.get<HubSpotDeal, {}>(
          `integrations/hubspot/deal/${encodeURIComponent(
            trimPortalIdFromHubSpotIdParam(hubSpotDealId),
          )}`,
        )
        const hubSpotDeal = hubSpotDealRes.payload
        const firstCompanyId = hubSpotDeal.associations?.companies?.results?.[0].id
        const firstContactId = hubSpotDeal.associations?.contacts?.results?.[0].id
        const [hubSpotCompany, hubSpotContact] = await Promise.all([
          firstCompanyId ? fetchHubSpotCompany(firstCompanyId) : undefined,
          firstContactId ? fetchHubSpotContact(firstContactId) : undefined,
        ])
        if (hubSpotCompany || hubSpotContact) {
          setStep1Data({
            companyName: hubSpotCompany?.properties.name,
            website: hubSpotCompany?.properties.domain || undefined,
            contactFirstName: hubSpotContact?.properties.firstname,
            contactLastName: hubSpotContact?.properties.lastname,
          })
          if (hubSpotCompany?.properties.domain) {
            setLogoUrl(hubSpotCompany.properties.domain || '')
            getLogoFromUrl(hubSpotCompany.properties.domain || '')
          }
          setAutoFillDataSource('HubSpot')
        }
      } catch (err) {
        // Just means company contact first and last name fields are not populated
        // from HubSpot values.
        console.error(err)
      }
    },
    [fetchHubSpotCompany, fetchHubSpotContact, getLogoFromUrl],
  )

  // TODO similar code to equivalent salesforce function above; in my view, not worth refactoring
  // for just 2 integrations - but may want to if we add more integrations here.
  const handleHubSpotObjectSelected = useCallback(
    (
      hubSpotObject: HubSpotDeal | HubSpotCompany | HubSpotContact,
      hubSpotObjectType: 'Deal' | 'Company' | 'Contact',
    ) => {
      // Used as type guard - enabled ensures portalId is available.
      if (hubSpotIntegration?.status !== 'enabled') {
        return
      }
      const currentlySelectedHubSpotObjectType = integrationIdMappings?.find(
        (attr) => attr.thirdParty === 'HubSpot',
      )?.thirdPartyEntityType

      if (
        currentlySelectedHubSpotObjectType &&
        currentlySelectedHubSpotObjectType !== hubSpotObjectType
      ) {
        const res = confirm(
          'You can only select one record at a time, this will unlink your previously selected record. Continue?',
        )
        if (!res) {
          return
        }
      }

      // Note: triggers side effect to fetch company data and populate fields
      // (done this way to support firing based on params in url, so not actually directly
      // triggerable in some cases).
      addOrOverwriteIntegrationIdMappings([
        {
          thirdParty: 'HubSpot',
          thirdPartyEntityType: hubSpotObjectType,
          // Need to scope ids with portal ids for HubSpot, otherwise they are not unique.
          thirdPartyId: `${hubSpotIntegration.portalId}_${hubSpotObject.id}`,
        },
      ])
      setSelectedHubSpotObject(hubSpotObject)
      if (hubSpotObjectType === 'Deal' && (hubSpotObject as HubSpotDeal).properties?.dealname) {
        fetchHubSpotDealAndUpdateFields(hubSpotObject.id + '')
      } else if (hubSpotObjectType === 'Contact' && hubSpotObject) {
        fetchHubSpotContactAndUpdateFields(hubSpotObject.id)
      } else if (hubSpotObjectType === 'Company' && hubSpotObject) {
        fetchHubSpotCompanyAndUpdateFields(hubSpotObject.id)
      }
    },
    [
      hubSpotIntegration?.status,
      hubSpotIntegration?.portalId,
      integrationIdMappings,
      addOrOverwriteIntegrationIdMappings,
      fetchHubSpotDealAndUpdateFields,
      fetchHubSpotContactAndUpdateFields,
      fetchHubSpotCompanyAndUpdateFields,
    ],
  )

  useEffect(() => {
    const newIntegrationAttributes: CreateSpaceIntegrationIdMappingInput[] = []
    // Should only occur once
    if (integrationIdMappings?.length) {
      return
    }
    const urlParams = new URLSearchParams(window.location.search)
    const hsUserId = urlParams.get('hs_user_id')
    if (hsUserId) {
      newIntegrationAttributes.push({
        thirdPartyEntityType: 'User',
        thirdPartyId: hsUserId,
        thirdParty: 'HubSpot',
      })
    }
    const hsDealId = urlParams.get('hs_deal_id')
    if (hsDealId) {
      newIntegrationAttributes.push({
        thirdPartyEntityType: 'Deal',
        thirdPartyId: hsDealId,
        thirdParty: 'HubSpot',
      })
    }
    const hsCompanyId = urlParams.get('hs_company_id')
    if (hsCompanyId) {
      newIntegrationAttributes.push({
        thirdPartyEntityType: 'Company',
        thirdPartyId: hsCompanyId,
        thirdParty: 'HubSpot',
      })
    }
    const hsContactId = urlParams.get('hs_contact_id')
    if (hsContactId) {
      newIntegrationAttributes.push({
        thirdPartyEntityType: 'Contact',
        thirdPartyId: hsContactId,
        thirdParty: 'HubSpot',
      })
    }
    if (newIntegrationAttributes.length) {
      addOrOverwriteIntegrationIdMappings(newIntegrationAttributes)
    } else {
      // This is saying if there is no HubSpot data in the URL which needs to be fetched, then
      // technically the load is complete. This is required to prevent the load-HubSpot-data-
      // as-side-effect-of-setting-integrationIdMappings from firing. It should only work
      // on load when HubSpot params are present - otherwise, fetching of HubSpot data should be
      // triggered by the user selecting a HubSpot deal.
      didHubSpotDataFetchOnLoad.current = true
    }
  }, [
    addOrOverwriteIntegrationIdMappings,
    fetchHubSpotContactAndUpdateFields,
    integrationIdMappings.length,
  ])

  useEffect(() => {
    // Load after attributes are present but only once
    if (integrationIdMappings?.length && !didHubSpotDataFetchOnLoad.current) {
      didHubSpotDataFetchOnLoad.current = true
      const companyIdAttribute = integrationIdMappings.find(
        (attr) => attr.thirdPartyEntityType === 'Company' && attr.thirdParty === 'HubSpot',
      )
      if (companyIdAttribute) {
        // Deliberately not using await here, don't need to wait for this
        // to finish before continuing
        fetchHubSpotCompanyAndUpdateFields(companyIdAttribute.thirdPartyId)
      }
      const contactIdAttribute = integrationIdMappings.find(
        (attr) => attr.thirdPartyEntityType === 'Contact' && attr.thirdParty === 'HubSpot',
      )
      if (contactIdAttribute) {
        // Deliberately not using await here, don't need to wait for this
        // to finish before before continuing
        fetchHubSpotContactAndUpdateFields(contactIdAttribute.thirdPartyId)
      }
      const dealIdAttribute = integrationIdMappings.find(
        (attr) => attr.thirdPartyEntityType === 'Deal' && attr.thirdParty === 'HubSpot',
      )
      if (dealIdAttribute) {
        // Deliberately not using await here, don't need to wait for this
        // to finish before before continuing
        fetchHubSpotDealAndUpdateFields(dealIdAttribute.thirdPartyId)
      }
    }
  }, [
    fetchHubSpotCompanyAndUpdateFields,
    fetchHubSpotDealAndUpdateFields,
    fetchHubSpotContactAndUpdateFields,
    integrationIdMappings,
    integrationIdMappings?.length,
  ])

  const { createSpace, isLoading } = useCreateSpace({
    onSuccess: (space) => {
      const spaceUrl = `${window.location.origin}/spaces/${space.id}?token=${space.unversionedData.auth.token}`
      close()
      location.pathname = `/spaces/${space.id}`
    },
  })

  useEffect(() => {
    if (templates && templates.length > 0) {
      const queryParameters = new URLSearchParams(window.location.search)
      // NOTE createSpaceFromTemplate uses rootNodeId
      const createSpaceFromTemplate = queryParameters.get('createSpaceFromTemplate')
      if (createSpaceFromTemplate) {
        setSelectedTemplateId(
          templates.find((template) => createSpaceFromTemplate === template.rootNodeId)?.id,
        )
      } else {
        setSelectedTemplateId(templates?.[0].id)
      }
    }
  }, [templates])

  const handleNext = useCallback(() => {
    const currentStepIndex = applicableOrderedSteps.findIndex((step) => step === state.step)
    const currentStep = applicableOrderedSteps[currentStepIndex]
    const nextStep = applicableOrderedSteps[currentStepIndex + 1]
    setState((prevState) => ({
      ...prevState,
      // Go to the next step or fall back to current step if there is no next step
      step: nextStep || currentStep,
    }))
    trackEvent({
      event: 'spaces-newspace-next_click',
      eventProperties: {
        indexPageName: 'Create Space Modal',
        currentStep,
        nextStep,
      },
    })
  }, [applicableOrderedSteps, state.step, trackEvent])

  const handleBack = useCallback(() => {
    const currentStepIndex = applicableOrderedSteps.findIndex((step) => step === state.step)
    const previousStep = applicableOrderedSteps[currentStepIndex - 1]
    setState((prevState) => ({
      ...prevState,
      // Go to the previous step or fall back to current step if there is no previous step
      step: previousStep || applicableOrderedSteps[currentStepIndex],
    }))
    trackEvent({
      event: 'spaces-newspace-back_click',
      eventProperties: {
        indexPageName: 'Create Space Modal',
        currentStep: applicableOrderedSteps[currentStepIndex],
        previousStep,
      },
    })
  }, [applicableOrderedSteps, state.step, trackEvent])

  const currentStepNumber = useMemo(() => {
    const currentStepIndex = applicableOrderedSteps.findIndex((step) => step === state.step)
    return currentStepIndex + 1
  }, [applicableOrderedSteps, state.step])

  const handleUpdateCustomProperties = useCallback((properties: SpaceProperty[]) => {
    setCustomProperties(properties)
  }, [])

  const isNextButtonDisabled = useMemo(() => {
    let disabled = false
    if (state.step === 'enter-buyer-details') {
      disabled = !Step1Schema.safeParse(step1Data).success || (!!teams?.length && !selectedTeamIds)
    } else if (state.step === 'select-template') {
      // Wait for loading the template used custom properties to finish before enabling the next
      // as well as basic template selection. This is required to work out if the space custom
      // properties step is required or not and proceed accordingly.
      disabled = !selectedTemplate || isLoadingTemplateUsedCustomProperties
    } else if (state.step === 'select-salesforce-opportunity') {
      disabled = !selectedSalesforceObject
    } else if (state.step === 'select-hubspot-deal') {
      disabled = !selectedHubSpotObject
    } else if (state.step === 'space-custom-properties') {
      const requiredCustomPropertiesForSpace = requiredCustomProperties?.filter((property) =>
        templateUsedCustomProperties?.find((prop: string) => prop === property.key),
      )

      // Check if all required properties have been filled out
      const allRequiredPropertiesFilled = requiredCustomPropertiesForSpace.every((requiredProp) =>
        customProperties.some(
          (customProp) => customProp.name === requiredProp.key && customProp.value.trim() !== '',
        ),
      )

      disabled = !allRequiredPropertiesFilled
    }
    return disabled
  }, [
    state.step,
    step1Data,
    teams?.length,
    selectedTeamIds,
    selectedTemplate,
    isLoadingTemplateUsedCustomProperties,
    selectedSalesforceObject,
    selectedHubSpotObject,
    requiredCustomProperties,
    customProperties,
    templateUsedCustomProperties,
  ])

  const handleCreateSpace = useCallback(() => {
    const formData = SpaceDataSchema.safeParse({
      ...step1Data,
      parentTemplateId: selectedTemplateId,
    })

    const data = formData.success ? formData.data : null

    if (data && selectedTemplate) {
      const payload = {
        companyName: data.companyName,
        contactFirstName: data.contactFirstName,
        contactLastName: data.contactLastName,
        parentTemplateId: selectedTemplate.rootNodeId,
        websiteUrl: data.website,
        language: data.language,
        logo: data.logo,
        integrationIdMappings,
        customProperties,
        teamIds: selectedTeamIds ? Array.from(selectedTeamIds) : [],
      }
      createSpace(payload)

      trackEvent({
        event: 'spaces-newspace-created',
        eventProperties: {
          indexPageName: 'Space-Creation-Modal',
          spaceId: data.companyName,
          templateID: data.parentTemplateId,
        },
      })
    } else {
      createLogger().error(
        'Space validation failed on creation: ' +
          (!formData.success
            ? formData.error.errors.map((err) => `${err.path.join('.')}: ${err.message}`).join(', ')
            : 'No selected template for template id ' + selectedTemplateId),
      )
    }
  }, [
    createSpace,
    customProperties,
    integrationIdMappings,
    selectedTeamIds,
    selectedTemplate,
    selectedTemplateId,
    step1Data,
    trackEvent,
  ])

  if (isLoading) {
    return (
      <SpinnerContainer>
        <VLoaderAnimation />
      </SpinnerContainer>
    )
  }

  function handleSkip() {
    // If we are skipping the Salesforce step, remove the integration id mapping and selected
    // opportunity.
    if (state.step === 'select-salesforce-opportunity') {
      setSelectedSalesforceObject(undefined)
      removeIntegrationIdMapping('Opportunity', 'Salesforce')
      if (autoFillDataSource === 'Salesforce') {
        setStep1Data({
          companyName: '',
        })
      }
      // Once we've worked out if we need to clear the fields, we know longer need to track
      // the source of the auto-filled data.
      setAutoFillDataSource(undefined)
    }
    // Same for HubSpot deals
    // TODO, refactor here when more integrations are added - probably not worth it for just 2
    // integrations at the moment.
    else if (state.step === 'select-hubspot-deal') {
      setSelectedHubSpotObject(undefined)
      removeIntegrationIdMapping('Deal', 'HubSpot')
      if (autoFillDataSource === 'HubSpot') {
        setLogoUrl('')
        setStep1Data({
          companyName: '',
          website: '',
          contactFirstName: '',
          contactLastName: '',
          logo: '',
          logoBlob: undefined,
        })
      }
      // Once we've worked out if we need to clear the fields, we know longer need to track
      // the source of the auto-filled data.
      setAutoFillDataSource(undefined)
    }
    handleNext()
  }

  function updateForm(field: keyof Step1DataType, value: string | File | null) {
    setStep1Data((data) => ({
      ...data,
      [field]: value,
    }))
  }

  const isOnLastStep = state.step === applicableOrderedSteps[applicableOrderedSteps.length - 1]

  return (
    <div>
      <Header>
        {state.step === 'select-salesforce-opportunity' && (
          <>
            <h1>Connect to Salesforce</h1>
            <p>
              Select a Salesforce object to link it to your space.
              <SalesforceBadge />
            </p>
          </>
        )}
        {state.step === 'select-hubspot-deal' && (
          <>
            <h1>Connect to HubSpot</h1>
            <p>
              Select a HubSpot object to link it to your space.
              <HubSpotBadge />
            </p>
          </>
        )}
        {state.step === 'enter-buyer-details' && (
          <>
            <h1>Create a Space</h1>
            <p>
              Add information about your stakeholder. Let&apos;s get started!
              {selectedSalesforceObject && <SalesforceBadge />}
              {selectedSalesforceObject && selectedHubSpotObject && ' '}
              {selectedHubSpotObject && <HubSpotBadge />}
            </p>
          </>
        )}
        {state.step === 'select-template' && (
          <>
            <h1>Select a Template</h1>
            <p>
              Select a template in order to create the space.
              {selectedSalesforceObject && <SalesforceBadge />}
              {selectedSalesforceObject && selectedHubSpotObject && ' '}
              {selectedHubSpotObject && <HubSpotBadge />}
            </p>
          </>
        )}
        {state.step === 'space-custom-properties' && (
          <>
            <h1>Customise the space</h1>
            <p>Add additional information to customise the space for your stakeholder.</p>
          </>
        )}
      </Header>
      {state.step === 'select-salesforce-opportunity' && (
        <SalesforceObjectSelector
          onObjectSelected={handleSalesforceObjectSelected}
          selectedObject={selectedSalesforceObject}
        />
      )}
      {state.step === 'select-hubspot-deal' && (
        <HubSpotObjectSelector
          onObjectSelected={handleHubSpotObjectSelected}
          selectedObject={selectedHubSpotObject}
        />
      )}
      {state.step === 'enter-buyer-details' && (
        <Step1
          logoUrl={logoUrl}
          setLogoUrl={setLogoUrl}
          values={step1Data}
          inputHandler={updateForm}
          getLogoFromUrl={getLogoFromCurrentUrl}
          handleUploadFile={handleUploadFile}
          selectedTeamIds={selectedTeamIds}
          setSelectedTeamIds={setSelectedTeamIds}
          teams={teams ?? []}
          tenantName={tenant?.payload.tenantName || ''}
        />
      )}
      {state.step === 'select-template' && (
        <Step2
          onSelectTemplate={(templateId) => {
            const filteredTemplates = templates?.filter(
              (template) => template.rootNodeId === templateId,
            )
            const selected = filteredTemplates && filteredTemplates[0]
            if (!selected) {
              return
            }
            setSelectedTemplateId(selected.id)
            trackEvent({
              event: 'spaces-newspace-template_selected',
              eventProperties: {
                indexPageName: 'Create Space Modal',
                templateID: templateId,
              },
            })
          }}
          selectedTemplate={selectedTemplate}
          templates={templates}
        />
      )}
      <SpaceCreationCustomPropertiesStep
        // Unlike other steps, this step component, SpaceCreationCustomPropertiesStep, cannot be
        // shown conditionally, or else it re-requests GPT completions because they are requested
        // on show and, if conditional, it means a new component instance each render, therefore
        // new requests. Instead, leave it up to component to decide whether to show itself or not.
        show={state.step === 'space-custom-properties'}
        flags={flags}
        onUpdateCustomProperties={handleUpdateCustomProperties}
        selectedTemplateId={selectedTemplateId}
        contactFirstName={step1Data.contactFirstName || ''}
        contactLastName={step1Data.contactLastName || ''}
        companyName={step1Data.companyName || ''}
      />
      <Navigation
        step={currentStepNumber}
        nextButtonLabel={isOnLastStep ? 'Create Space' : 'Next'}
        intercomTarget={
          isOnLastStep ? 'Space Creation Create Space Button' : 'Space Creation Next Button'
        }
        maxSteps={applicableOrderedSteps.length}
        isNextButtonDisabled={isNextButtonDisabled}
        onSkip={handleSkip}
        onNext={isOnLastStep ? handleCreateSpace : handleNext}
        showSkipButton={skippableSteps.has(state.step)}
        onBack={handleBack}
      />
    </div>
  )
}
