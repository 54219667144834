import { ReactNode } from 'react'

type PageHeaderProps = {
  description?: ReactNode
  title?: ReactNode
}

export const PageHeader = ({ description, title }: PageHeaderProps): JSX.Element => {
  return (
    <div className='flex flex-col items-start gap-11'>
      <div className='flex items-end justify-between self-stretch w-full'>
        <div className='flex flex-col items-start gap-2.5'>
          <div className='flex items-center gap-3 self-stretch w-full'>
            <div className='inline-flex max-w-[800px] items-center gap-2'>
              {title && <div className='text-grey-s6 text-2xl font-bold'>{title}</div>}
            </div>
          </div>
          {description && (
            <div className='max-w-140 font-normal text-grey-s5 text-sm'>{description}</div>
          )}
        </div>
      </div>
    </div>
  )
}
