import { cn } from '../../lib'
import { Accordion, AccordionContent, AccordionItem, AccordionClickableTrigger } from './accordion'
import { IonIcon } from '../../Icons/IonIcon'
import { useCallback, useEffect, useMemo, useState } from 'react'

export type TFlatTreeItem = {
  id: string
  name: string
  parentFolderId: string | null
}

type TTreeItem = {
  id: string
  name: string
  children?: TTreeItem[]
}

type BaseTreeProps = {
  currentItem?: string | null
  selectedItem?: string | null
  onSelectedChange?: (id: string) => void
  className?: string
  noChildrenTooltip?: string
}

const buildFolderTree = (items: TFlatTreeItem[]): TTreeItem[] => {
  // Create a map to hold each item by its id
  const itemMap: Record<string, TTreeItem> = {}

  // Initialize an empty array to hold the root items
  const rootItems: TTreeItem[] = []

  // First pass: create all items and store them in the map
  for (const item of items) {
    itemMap[item.id] = {
      id: item.id,
      name: item.name,
      children: [],
    }
  }

  // Second pass: build the tree structure
  for (const item of items) {
    const currentItem = itemMap[item.id]
    if (item.parentFolderId) {
      // If the item has a parent, add it to the parent's children array
      const parentItem = itemMap[item.parentFolderId]
      if (parentItem) {
        parentItem.children = parentItem.children || []
        parentItem.children.push(currentItem)
      }
    } else {
      // If the item has no parent, it is a root item
      rootItems.push(currentItem)
    }
  }

  return rootItems
}

export const Tree = ({
  items,
  selectedItem,
  ...props
}: BaseTreeProps & {
  items: { name: string; id: string; parentFolderId: string | null }[]
}) => {
  const [expandedItems, setExpandedItems] = useState<string[]>([])

  const setExpandedItemsRecursively = useCallback(
    (id: string) => {
      const item = items.find((item) => item.id === id)
      if (item) {
        setExpandedItems((prev) => {
          if (!prev.includes(item.id)) {
            return [...prev, item.id]
          }
          return prev
        })
        if (item.parentFolderId) {
          setExpandedItemsRecursively(item.parentFolderId)
        }
      }
    },
    [items],
  )

  useEffect(() => {
    if (selectedItem) {
      const item = items.find((item) => item.id === selectedItem)
      setExpandedItemsRecursively(item?.parentFolderId || '')
    }
  }, [items, selectedItem, setExpandedItemsRecursively])

  const nestedItems = useMemo(() => buildFolderTree(items), [items])

  return (
    <TreeView
      items={nestedItems}
      selectedItem={selectedItem}
      expandedItems={expandedItems}
      setExpandedItems={setExpandedItems}
      {...props}
    />
  )
}

const TreeView = ({
  items,
  currentItem,
  selectedItem,
  onSelectedChange,
  className,
  noChildrenTooltip,
  expandedItems,
  setExpandedItems,
}: BaseTreeProps & {
  items: TTreeItem[]
  expandedItems: string[]
  setExpandedItems: (items: string[]) => void
}) => {
  return (
    <Accordion
      type='multiple'
      className={cn('flex flex-col gap-1', className)}
      value={expandedItems}
      onValueChange={setExpandedItems}
    >
      {items.map((item) => (
        <AccordionItem key={item.id} value={item.id}>
          <AccordionClickableTrigger
            className={'rounded-md hover:bg-grey-s1 cursor-pointer'}
            disabled={!item.children || item.children.length === 0}
            disabledTooltip={noChildrenTooltip}
            onClick={() => {
              onSelectedChange?.(item.id)
            }}
          >
            <div className={'w-full flex items-center justify-between'}>
              <div className={'flex gap-1 items-center'}>
                <span>{item.name}</span>
                {item.id === currentItem && (
                  <span className={'text-grey-s4 font-normal'}>Current location</span>
                )}
              </div>
              {selectedItem === item.id && (
                <IonIcon name={'checkmark'} className='h-4 w-4 text-primary-s5 shrink-0' />
              )}
            </div>
          </AccordionClickableTrigger>
          {item.children && item.children.length > 0 && (
            <AccordionContent>
              <TreeView
                expandedItems={expandedItems}
                setExpandedItems={setExpandedItems}
                items={item.children}
                className={'ml-3 mt-1'}
                selectedItem={selectedItem}
                onSelectedChange={onSelectedChange}
                noChildrenTooltip={noChildrenTooltip}
                currentItem={currentItem}
              />
            </AccordionContent>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  )
}
