import * as DropdownMenuPrimitive from '@radix-ui/react-dropdown-menu'

import { Slot } from '@radix-ui/react-slot'
import { cva, VariantProps } from 'class-variance-authority'
import {
  ButtonHTMLAttributes,
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  HTMLAttributes,
} from 'react'
import { cn } from '../../lib/utils'
import { IonIcon } from '../../Icons/IonIcon'
import { CheckMark } from './checkmark/CheckMark'

const DropdownMenu = DropdownMenuPrimitive.Root
const DropdownMenuTrigger = DropdownMenuPrimitive.Trigger
const DropdownMenuRadioGroup = DropdownMenuPrimitive.RadioGroup

const DropdownMenuContent = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <DropdownMenuPrimitive.Portal>
    <DropdownMenuPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      // prevent focus on close, to prevent having to selected focus shadows next to each other
      // enable if needed in future for a11y
      onCloseAutoFocus={(e) => e.preventDefault()}
      className={cn(
        'flex gap-1 flex-col z-50 min-w-[8rem] overflow-hidden rounded-md border border-grey-s2 bg-white',
        'p-2 shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0',
        'data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95',
        'data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2',
        'data-[side=top]:slide-in-from-bottom-2',
        className,
      )}
      {...props}
    />
  </DropdownMenuPrimitive.Portal>
))
DropdownMenuContent.displayName = DropdownMenuPrimitive.Content.displayName

const DropdownMenuContentScrollable = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'max-h-[min(296px,calc(var(--radix-dropdown-menu-content-available-height)-80px))] overflow-y-auto scroll-smooth focus:scroll-auto',
          className,
        )}
      >
        {children}
      </div>
    )
  },
)

DropdownMenuContentScrollable.displayName = 'DropdownMenuContentScrollable'

const DropdownMenuItem = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Item>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Item> & {
    inset?: boolean
    icon?: string
    destructive?: boolean
  }
>(({ className, inset, children, icon, destructive, ...props }, ref) => (
  <DropdownMenuPrimitive.Item
    ref={ref}
    className={cn(
      'relative flex cursor-default select-none items-center rounded',
      'px-2 py-1.5 text-sm outline-none transition-colors focus:bg-grey-s1',
      'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      inset && 'pl-8',
      { 'text-warning-s5': destructive },
      className,
    )}
    {...props}
  >
    {icon && (
      <IonIcon
        name={icon}
        className={cn('h-5 w-4 mr-2 text-grey-s4', {
          'text-warning-s4': destructive,
        })}
      />
    )}
    {children}
  </DropdownMenuPrimitive.Item>
))
DropdownMenuItem.displayName = DropdownMenuPrimitive.Item.displayName

const dropdownMenuCheckboxItemVariants = cva('flex', {
  variants: {
    descriptionVariant: {
      inline: 'flex items-center gap-3 overflow-hidden',
      stacked: 'flex flex-col gap-1',
    },
  },
  defaultVariants: {
    descriptionVariant: 'inline',
  },
})

interface DropdownMenuCheckboxItemProps
  extends ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.CheckboxItem>,
    VariantProps<typeof dropdownMenuCheckboxItemVariants> {
  icon?: string
  description?: string
}

const DropdownMenuCheckboxItem = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.CheckboxItem>,
  DropdownMenuCheckboxItemProps
>(({ className, children, checked, icon, description, descriptionVariant, ...props }, ref) => (
  <DropdownMenuPrimitive.CheckboxItem
    ref={ref}
    className={cn(
      'relative flex cursor-default select-none p-2 pr-3 rounded outline-none transition-colors focus:bg-grey-s1 ',
      'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      {
        'items-center': (descriptionVariant ?? 'inline') === 'inline',
        'items-start': descriptionVariant === 'stacked',
      },
      className,
    )}
    // prevent closing dropdown on click
    onSelect={(e) => e.preventDefault()}
    checked={checked}
    {...props}
  >
    <CheckMark checked={checked === true} className={'mr-3'} />
    {icon && <IonIcon name={icon} className={'h-5 w-4 mr-3 text-grey-s4'} />}
    <div className={cn(dropdownMenuCheckboxItemVariants({ descriptionVariant }))}>
      <span className={'text-sm align-middle truncate text-grey-s6'}>{children}</span>
      {description && (
        <span
          className={cn('text-xs text-grey-s5', {
            'mt-0.5': (descriptionVariant ?? 'inline') === 'inline',
          })}
        >
          {description}
        </span>
      )}
    </div>
  </DropdownMenuPrimitive.CheckboxItem>
))
DropdownMenuCheckboxItem.displayName = DropdownMenuPrimitive.CheckboxItem.displayName

const DropdownMenuRadioItem = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.RadioItem>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.RadioItem>
>(({ className, children, ...props }, ref) => (
  <DropdownMenuPrimitive.RadioItem
    ref={ref}
    className={cn(
      'relative flex cursor-default select-none items-center rounded p-2 pr-9',
      'text-sm outline-none transition-colors focus:bg-grey-s1',
      'data-[disabled]:pointer-events-none data-[disabled]:opacity-50',
      className,
    )}
    {...props}
  >
    {children}
    <span className='absolute right-2 flex mt-1 items-center justify-center'>
      <DropdownMenuPrimitive.ItemIndicator>
        <IonIcon name={'checkmark-outline'} className='h-5 w-4 text-grey-s5' />
      </DropdownMenuPrimitive.ItemIndicator>
    </span>
  </DropdownMenuPrimitive.RadioItem>
))
DropdownMenuRadioItem.displayName = DropdownMenuPrimitive.RadioItem.displayName

const DropdownMenuSeparator = forwardRef<
  ElementRef<typeof DropdownMenuPrimitive.Separator>,
  ComponentPropsWithoutRef<typeof DropdownMenuPrimitive.Separator> & {
    text?: string
  }
>(({ className, text, ...props }, ref) => (
  <>
    {!text && (
      // If there is no text, just show a basic line separator
      <DropdownMenuPrimitive.Separator
        ref={ref}
        className={cn('-mx-2 my-1 h-px bg-grey-s2', className)}
        {...props}
      />
    )}
    {!!text && (
      // If there is text, wrap the separator with a containing div to display the text and
      // separator together - required so height of text factored into box model layout calculations
      <DropdownMenuPrimitive.Separator
        className='flex items-center justify-center text-xs text-grey-s5 py-1 px-2'
        {...props}
      >
        <div ref={ref} className={cn('my-1 h-px bg-grey-s2 flex-grow', className)} {...props} />
        <div className='mx-2'>{text}</div>
        <div ref={ref} className={cn('my-1 h-px bg-grey-s2 flex-grow', className)} {...props} />
      </DropdownMenuPrimitive.Separator>
    )}
  </>
))
DropdownMenuSeparator.displayName = DropdownMenuPrimitive.Separator.displayName

const dropdownMenuButtonVariants = cva(
  cn(
    'flex items-center justify-between bg-white border-solid border rounded-lg',
    'border-grey-s2 hover:shadow-text-input transition-colors focus:outline-none ',
    'focus-visible:outline-none focus-visible:shadow-text-input',
  ),
  {
    variants: {
      buttonSize: {
        default: 'h-9 px-3 py-2.5 text-xs',
        large: 'h-11 px-4 py-3 text-sm',
      },
    },
    defaultVariants: {
      buttonSize: 'default',
    },
  },
)

export interface DropdownMenuButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof dropdownMenuButtonVariants> {
  icon?: string
  asChild?: boolean
}

const DropdownMenuButton = forwardRef<HTMLButtonElement, DropdownMenuButtonProps>(
  ({ className, buttonSize, asChild = false, children, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(dropdownMenuButtonVariants({ buttonSize }), className)}
        ref={ref}
        {...props}
      >
        <div className={'flex items-center truncate'}>
          {props.icon && (
            <IonIcon name={props.icon} className={'h-4 w-4 mr-3 text-primary-s3 shrink-0'} />
          )}
          <span className={'truncate'}>{children}</span>
        </div>
        <IonIcon name={'chevron-down'} className={'h-4 w-4 ml-3 text-grey-s3 shrink-0'} />
      </Comp>
    )
  },
)
DropdownMenuButton.displayName = 'DropdownMenuButton'

export {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuContentScrollable,
  DropdownMenuItem,
  DropdownMenuCheckboxItem,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuRadioGroup,
  DropdownMenuButton,
}
