import { useCallback, useRef, useState } from 'react'

export interface DirectionOverflow {
  overflowing: boolean
  overflowStart: number
  overflowEnd: number
  visibleSize: number
  size: number
}

export interface OverflowDetectorHook {
  ref: (element: HTMLElement | null) => void
  horizontal: DirectionOverflow
  vertical: DirectionOverflow
}

const defaultOverflowInformation: DirectionOverflow = {
  visibleSize: 0,
  size: 0,
  overflowing: false,
  overflowStart: 0,
  overflowEnd: 0,
}

export function useOverflowDetector(): OverflowDetectorHook {
  const resizeObserverRef = useRef<ResizeObserver | null>(null)
  const mutationObserverRef = useRef<MutationObserver | null>(null)
  const scrollListenerRef = useRef<(() => void) | null>(null)

  const elementRef = useRef<HTMLElement | null>(null)

  const [overflowHorizontal, setOverflowHorizontal] = useState<DirectionOverflow>(
    defaultOverflowInformation,
  )
  const [overflowVertical, setOverflowVertical] = useState<DirectionOverflow>(
    defaultOverflowInformation,
  )

  function checkOverflow() {
    if (elementRef.current) {
      const rectangle = elementRef.current.getBoundingClientRect()

      const horizontalOverflow = elementRef.current.scrollWidth > elementRef.current.clientWidth
      const horizontalLeftOverflow = elementRef.current.scrollLeft
      const horizontalRightOverflow =
        elementRef.current.scrollWidth -
        elementRef.current.clientWidth -
        elementRef.current.scrollLeft

      setOverflowHorizontal({
        visibleSize: rectangle.width,
        size: elementRef.current.scrollWidth,
        overflowing: horizontalOverflow,
        overflowStart: horizontalLeftOverflow,
        overflowEnd: horizontalRightOverflow,
      })

      const verticalOverflow = elementRef.current.scrollHeight > elementRef.current.clientHeight
      const verticalTopOverflow = elementRef.current.scrollTop
      const verticalBottomOverflow =
        elementRef.current.scrollHeight -
        elementRef.current.clientHeight -
        elementRef.current.scrollTop

      setOverflowVertical({
        visibleSize: rectangle.height,
        size: elementRef.current.scrollHeight,
        overflowing: verticalOverflow,
        overflowStart: verticalTopOverflow,
        overflowEnd: verticalBottomOverflow,
      })
    }
  }

  function disconnectObservers() {
    resizeObserverRef.current?.disconnect()
    mutationObserverRef.current?.disconnect()

    if (scrollListenerRef.current) {
      elementRef.current?.removeEventListener('scroll', scrollListenerRef.current, true)
    }
  }

  const elementCallback = useCallback<(element: HTMLElement | null) => void>((element) => {
    // if (element && element !== elementRef.current) {
    //   disconnectObservers()

    //   elementRef.current = element

    //   resizeObserverRef.current = new ResizeObserver(() => {
    //     checkOverflow()
    //   })
    //   resizeObserverRef.current.observe(element)

    //   mutationObserverRef.current = new MutationObserver(() => {
    //     checkOverflow()
    //   })
    //   mutationObserverRef.current?.observe(element, {
    //     subtree: true,
    //     childList: true,
    //     attributes: true,
    //   })

    //   scrollListenerRef.current = () => {
    //     checkOverflow()
    //   }
    //   element.addEventListener('scroll', scrollListenerRef.current, true)
    // } else if (!element) {
    //   disconnectObservers()
    // }
  }, [])

  return {
    ref: elementCallback,
    horizontal: overflowHorizontal,
    vertical: overflowVertical,
  }
}
