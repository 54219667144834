import { TeamWithMembers } from '@valuecase/common'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'
import {
  PanelTabsList,
  PanelTabsTrigger,
  Tabs,
  TabsContent,
} from '@valuecase/ui-components/src/components/ui/tabs'
import { useEffect, useMemo, useState } from 'react'
import TeamManagementEmptyState from './TeamManagementEmptyState'
import TeamTable from './TeamTable'
import CreateTeamDialog from './dialogs/CreateTeamDialog'
import useReadTeams from './hooks/useReadTeams'

const TeamsManagement = () => {
  const { data: teams, isFetched } = useReadTeams()
  const [createTeamDialogIsOpen, setCreateTeamDialogIsOpen] = useState(false)
  const [initialTabSet, setInitialTabSet] = useState(false)

  const sortedTeams = useMemo(() => {
    if (teams) {
      return [...teams].sort((a, b) => a.name.localeCompare(b.name))
    }
    return []
  }, [teams])

  const [activeTab, setActiveTab] = useState<string | undefined>()

  useEffect(() => {
    // Set the initial active tab when teams are loaded and not empty
    if (isFetched && !initialTabSet && sortedTeams.length > 0) {
      setActiveTab(sortedTeams[0].id)
      setInitialTabSet(true)
    }
  }, [initialTabSet, isFetched, sortedTeams])

  const handleOpenCreateTeamDialog = () => setCreateTeamDialogIsOpen(true)

  useEffect(() => {
    // Adjust the active tab if it no longer exists in the sorted teams list
    if (activeTab && !sortedTeams.find((team) => team.id === activeTab)) {
      setActiveTab(sortedTeams.length > 0 ? sortedTeams[sortedTeams.length - 1].id : undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortedTeams])

  const showEmptyState = useMemo(() => sortedTeams.length === 0, [sortedTeams])

  const handleTabChange = (teamId: string) => {
    setActiveTab(teamId)
  }

  const handleTeamCreated = (team: TeamWithMembers) => {
    setCreateTeamDialogIsOpen(false)
    setActiveTab(team.id)
  }

  return (
    <div className='flex flex-col py-11 w-full gap-8'>
      <div className={'flex flex-row justify-between'}>
        <div className={'flex flex-col w-118'}>
          <h1 className={'text-2xl font-bold'}>Teams Management</h1>
          <p className={'text-sm text-grey-s5 mt-2 leading-6'}>
            Organize your users into teams to control visibility of templates and spaces.
          </p>
        </div>
        <div className='flex flex-col h-full justify-end'>
          <Button2 onClick={handleOpenCreateTeamDialog} leadingIcon='add'>
            Create Team
          </Button2>
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={handleTabChange}>
        <PanelTabsList>
          {sortedTeams?.map((team) => (
            <PanelTabsTrigger key={team.id} value={team.id}>
              {team.name} ({team.memberIds?.length})
            </PanelTabsTrigger>
          ))}
        </PanelTabsList>
        {sortedTeams?.map((team) => (
          <TabsContent key={team.id} value={team.id} className='py-4'>
            <TeamTable team={team} />
          </TabsContent>
        ))}
      </Tabs>

      <CreateTeamDialog
        open={createTeamDialogIsOpen}
        onOpenChange={(open) => setCreateTeamDialogIsOpen(open)}
        onTeamCreated={handleTeamCreated}
      />
      {showEmptyState ? <TeamManagementEmptyState /> : null}
    </div>
  )
}

export default TeamsManagement
