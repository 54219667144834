const THUMBNAIL_WIDTH = 500

import * as pdfjs from 'pdfjs-dist'

async function getFileFromCanvas(canvas: HTMLCanvasElement, original: File): Promise<File | null> {
  return new Promise((resolve, reject) => {
    const thumbnailFilename = `${original.name.split('.').slice(0, -1).join('.')}-thumbnail.jpg`
    canvas.toBlob((blob) => {
      if (!blob) {
        reject(new Error('Error getting blob from canvas element.'))
        return
      }
      const thumbnailFile = new File([blob], thumbnailFilename, { type: 'image/jpeg' })
      resolve(thumbnailFile)
      return
    }, 'image/jpeg')
  })
}

function getCanvasWithResizedThumbnail(
  drawable: HTMLImageElement | HTMLVideoElement,
): HTMLCanvasElement {
  const inputWidth =
    drawable instanceof HTMLVideoElement ? drawable.videoWidth : drawable.naturalWidth
  const inputHeight =
    drawable instanceof HTMLVideoElement ? drawable.videoHeight : drawable.naturalHeight

  const outputWidth = THUMBNAIL_WIDTH
  const scaleFactor = outputWidth / inputWidth
  const outputHeight = Math.floor(inputHeight * scaleFactor)

  const thumbnailCanvas = document.createElement('canvas')
  thumbnailCanvas.width = outputWidth
  thumbnailCanvas.height = outputHeight

  const thumbnailContext2d = thumbnailCanvas.getContext('2d') as CanvasRenderingContext2D
  thumbnailContext2d.drawImage(
    drawable,
    0,
    0,
    inputWidth,
    inputHeight,
    0,
    0,
    outputWidth,
    outputHeight,
  )

  return thumbnailCanvas
}

async function generateImageThumbnail(original: File): Promise<File | null> {
  const imageSrc = URL.createObjectURL(original)
  return new Promise((resolve, reject) => {
    const image = document.createElement('img')
    image.onload = () => {
      if (image.naturalWidth > THUMBNAIL_WIDTH) {
        const canvas = getCanvasWithResizedThumbnail(image)
        resolve(getFileFromCanvas(canvas, original))
        return
      }
      resolve(original)
    }
    image.src = imageSrc
  })
}

async function generatePdfThumbnail(original: File): Promise<File | null> {
  const pageToRender = 1
  const typedarray = await original.arrayBuffer()
  const pdf = await pdfjs.getDocument(typedarray).promise
  const page = await pdf.getPage(pageToRender)
  const canvas = document.createElement('canvas')
  const canvasContext = canvas.getContext('2d')
  const scale1Viewport = page.getViewport({ scale: 1 })
  const scaleForDesiredWidth = THUMBNAIL_WIDTH / scale1Viewport.width
  const viewport = page.getViewport({ scale: scaleForDesiredWidth })
  const renderContext = { canvasContext: canvasContext as CanvasRenderingContext2D, viewport }
  canvas.height = viewport.height
  canvas.width = viewport.width
  await page.render(renderContext).promise
  return getFileFromCanvas(canvas, original)
}

async function generateMovieThumbnail(original: File): Promise<File | null> {
  return new Promise((resolve, reject) => {
    const video = document.createElement('video')
    video.autoplay = true
    video.muted = true
    video.onloadeddata = () => {
      const canvas = getCanvasWithResizedThumbnail(video)
      video.pause()
      resolve(getFileFromCanvas(canvas, original))
    }

    function errorHandler() {
      resolve(null)
    }

    video.onerror = errorHandler
    video.onabort = errorHandler

    video.src = URL.createObjectURL(original)
  })
}

export async function generateThumbnail(original: File): Promise<File | null> {
  if (original.type.startsWith('image/')) {
    return generateImageThumbnail(original)
  }
  if (original.type === 'application/pdf') {
    return generatePdfThumbnail(original)
  }
  if (original.type.startsWith('video/')) {
    return generateMovieThumbnail(original)
  }
  return null
}
