import { useEffect, useState } from 'react'
import { GenericChartContainer } from './GenericChartContainer'
import { ChartProps, Series, Stat } from './types'
import { useGetChart } from './useGetChart'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  // Brush,
} from 'recharts'

export function CreatedSpaces({ range }: ChartProps) {
  const [data, setData] = useState<Series[] | null>(null)
  const { chartData, isLoading, isReloading } = useGetChart<Stat[]>('created-spaces', range)
  const [sum, setSum] = useState(0)

  useEffect(() => {
    if (chartData) {
      const d = chartData.map((d) => {
        return {
          name: String(d.date),
          value: d.value,
        }
      })
      setData(d)
      const s = chartData.reduce((acc, cur) => {
        return acc + cur.value
      }, 0)

      setSum(s)
    }
  }, [chartData, range])

  return (
    <GenericChartContainer
      title='Spaces created'
      total={sum}
      isLoading={isLoading || isReloading || !data}
    >
      {data ? (
        <>
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              data={data}
              margin={{
                left: -30,
                right: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray='6 1' stroke='#E0E2E7' />
              <XAxis dataKey='name' />
              <YAxis allowDecimals={false} />
              <Tooltip cursor={{ fill: '#E9EDFF' }} />
              <Bar dataKey='value' fill='#2A4CFA' />
            </BarChart>
          </ResponsiveContainer>
        </>
      ) : (
        <div />
      )}
    </GenericChartContainer>
  )
}
