import { QueryKey, useQueryClient } from '@tanstack/react-query'
import {
  TActionPlanTaskExpanded,
  TActionPlanTasksQuery,
  actionPlanTaskPaginatedListSchema,
  actionPlanTaskPaginatedListWithMetaSchema,
  actionPlanTasksQuerySchema,
} from '@valuecase/common'
import { useCallback } from 'react'

export const taskQueryParamsToKey = (params: TActionPlanTasksQuery) => {
  const {
    page,
    itemsPerPage,
    sortBy,
    sortOrder,
    taskStatus,
    search,
    spaceIds,
    includeTaskStatusMetadata,
    spaceCreatedAfter,
    spaceUpdatedAfter,
  } = params
  return [
    'tasks',
    taskStatus,
    spaceUpdatedAfter,
    page,
    itemsPerPage,
    sortBy,
    sortOrder,
    search,
    spaceIds,
    includeTaskStatusMetadata,
    spaceCreatedAfter,
  ]
}

export const taskQueryKeyToParams = (key: QueryKey) =>
  actionPlanTasksQuerySchema.parse({
    taskStatus: key[1],
    spaceUpdatedAfter: key[2],
    page: key[3],
    itemsPerPage: key[4],
    sortBy: key[5],
    sortOrder: key[6],
    search: key[7],
    spaceIds: key[8],
    includeTaskStatusMetadata: key[9],
    spaceCreatedAfter: key[10],
  })

export function parseTasksResponse({
  includeTaskStatusMetadata,
  responsePayload: response,
}: Pick<TActionPlanTasksQuery, 'includeTaskStatusMetadata'> & {
  responsePayload: unknown
}) {
  return includeTaskStatusMetadata
    ? actionPlanTaskPaginatedListWithMetaSchema.parse(response)
    : actionPlanTaskPaginatedListSchema.parse(response)
}

export function safeParseTasksResponse({
  includeTaskStatusMetadata,
  response,
}: Pick<TActionPlanTasksQuery, 'includeTaskStatusMetadata'> & {
  response: unknown
}) {
  return includeTaskStatusMetadata
    ? actionPlanTaskPaginatedListWithMetaSchema.safeParse(response)
    : actionPlanTaskPaginatedListSchema.safeParse(response)
}

export const useActionPlanTaskQueryHelpers = () => {
  const queryClient = useQueryClient()
  const updateTaskInCaches = useCallback(
    (task: TActionPlanTaskExpanded) => {
      const taskQueriesData = queryClient.getQueriesData(['tasks'])
      if (!taskQueriesData || !taskQueriesData.length) {
        return
      }
      for (const [queryKey, previousQueryData] of taskQueriesData) {
        const params = taskQueryKeyToParams(queryKey)
        const response = safeParseTasksResponse({
          includeTaskStatusMetadata: params.includeTaskStatusMetadata,
          response: previousQueryData,
        })
        if (!response.success) {
          console.error('Failed to parse task query data', response.error)
          continue
        }
        const newQueryData = {
          ...response.data,
          items: response.data.items.map((item) => (item.id === task.id ? task : item)),
        }
        queryClient.setQueryData(queryKey, newQueryData)
      }
    },
    [queryClient],
  )

  return { updateTaskInCaches }
}
