import React, { useCallback, useRef } from 'react'
import styled from 'styled-components'
import SimpleModalHeader from './SimpleModalHeader'
import BaseModal, { ModalControls } from '../base/BaseModal'

export type AdditionalStyleClasses = {
  layout?: string
  baseModal?: string
}

export interface SimpleModalProps {
  header?: React.ReactNode
  body?: React.ReactNode
  persistent?: boolean
  wide?: boolean
  maxWidth?: string
  showBack?: boolean
  onBack?: (controls: ModalControls) => void
  hideClose?: boolean
  controlsRef?: React.MutableRefObject<ModalControls | null> | ((controls: ModalControls) => void)
  additionalStyleClasses?: AdditionalStyleClasses
  onCloseCallback?: () => void
}

/**
 * @deprecated use Dialog
 */
export function SimpleModal({
  header,
  body,
  persistent,
  wide,
  maxWidth,
  showBack = false,
  onBack,
  hideClose = false,
  controlsRef,
  additionalStyleClasses,
  onCloseCallback,
}: SimpleModalProps) {
  const controls = useRef<ModalControls | null>(null)

  const controlsCallback = useCallback(
    (newControls: ModalControls) => {
      controls.current = newControls

      if (controlsRef) {
        if (typeof controlsRef === 'function') {
          controlsRef(newControls)
        } else {
          controlsRef.current = newControls
        }
      }
    },
    [controlsRef],
  )

  function handleClose() {
    controls.current?.close()
  }

  return (
    <BaseModal
      controlsRef={controlsCallback}
      persistent={persistent}
      wide={wide}
      maxWidth={maxWidth}
      onCloseCallback={onCloseCallback}
      className={additionalStyleClasses ? additionalStyleClasses.baseModal : undefined}
    >
      <SimpleModalLayout
        className={`${additionalStyleClasses ? additionalStyleClasses.layout : undefined}`}
      >
        <SimpleModalHeader
          showBack={showBack}
          hideClose={hideClose}
          onBack={() => {
            if (onBack && controls.current) {
              onBack(controls.current)
            } else {
              handleClose()
            }
          }}
          onClose={() => {
            handleClose()
          }}
        >
          {header}
        </SimpleModalHeader>

        <div>{body}</div>
      </SimpleModalLayout>
    </BaseModal>
  )
}

const SimpleModalLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;

  padding: 32px 32px 48px 32px;
  overflow-y: auto;
`
