import styled from 'styled-components'

const HomeBadge = () => {
  return (
    <Home>
      <div>HOME</div>
    </Home>
  )
}

export default HomeBadge
const Home = styled.div`
  margin-top: 1.5px;
  color: ${({ theme }) => theme.primary.s4};
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 140%;
  margin-left: 8px;
`
