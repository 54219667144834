/**
 * https://pqina.nl/blog/fade-out-overflow-using-css-mask-image/
 */
import React, { useEffect, useImperativeHandle } from 'react'
import { DefaultTheme, IStyledComponent } from 'styled-components'
import styled from 'styled-components'
import { CSSProperties } from 'styled-components'
import { DirectionOverflow } from '../DetectOverflow/OverflowDetector'
import {
  ScrollByFunction,
  useOverflowDetectorAndScrollControls,
} from '../DetectOverflow/OverflowDetectorAndScrollControls'

export type FadingOverflowMode = 'on-off' | 'dynamic'

export type FadingOverflowPreferredDirection = 'horizontal' | 'vertical'

export interface OverflowProps {
  $horizontal: DirectionOverflow
  $vertical: DirectionOverflow
  $fadeWidth: number
  $fadeMode: FadingOverflowMode
  $preferredDirection: FadingOverflowPreferredDirection
}

export function createFadingContainer(container: IStyledComponent<any, DefaultTheme>) {
  return styled(container)<OverflowProps>`
    mask-size: 100%;
    mask-position: center;
    mask-repeat: no-repeat;

    ${({
      $horizontal: horizontal,
      $vertical: vertical,
      $fadeWidth: fadeWidth,
      $fadeMode: fadeMode,
      $preferredDirection: preferredDirection,
    }) => {
      if (
        (horizontal.overflowing && !vertical.overflowing) ||
        (vertical.overflowing && horizontal.overflowing && preferredDirection === 'horizontal')
      ) {
        const hColorStart =
          fadeMode === 'on-off'
            ? 'transparent'
            : `rgba(255, 255, 255, ${
                1 - Math.min(horizontal.overflowStart, fadeWidth) / fadeWidth
              })`

        const hColorEnd =
          fadeMode === 'on-off'
            ? 'transparent'
            : `rgba(255, 255, 255, ${1 - Math.min(horizontal.overflowEnd, fadeWidth) / fadeWidth})`

        const fadeEffectStart =
          fadeMode === 'on-off' && horizontal.overflowStart >= 1
            ? fadeWidth
            : Math.min(horizontal.overflowStart, fadeWidth)

        const fadeEffectEnd =
          fadeMode === 'on-off' && horizontal.overflowEnd >= 1
            ? fadeWidth
            : Math.min(horizontal.overflowEnd, fadeWidth)

        /* eslint-disable */
        return `
          mask: linear-gradient(to right, ${hColorStart} 0%, #fff ${fadeEffectStart}px calc(100% - ${fadeEffectEnd}px), ${hColorEnd} 100%);
        `
        /* eslint-enable */
      }

      if (vertical.overflowing) {
        const vColorStart =
          fadeMode === 'on-off'
            ? 'transparent'
            : `rgba(255, 255, 255, ${1 - Math.min(vertical.overflowStart, fadeWidth) / fadeWidth})`

        const vColorEnd =
          fadeMode === 'on-off'
            ? 'transparent'
            : `rgba(255, 255, 255, ${1 - Math.min(vertical.overflowEnd, fadeWidth) / fadeWidth})`

        /* eslint-disable */
        return `
          mask: linear-gradient(to bottom, ${vColorStart} 0%, #fff ${Math.min(
          vertical.overflowStart,
          fadeWidth,
        )}px calc(100% - ${Math.min(vertical.overflowEnd, fadeWidth)}px), ${vColorEnd} 100%);
        `
        /* eslint-enable */
      }

      return ''
    }}
  `
}

const DefaultContainer = styled.div`
  overflow: auto;
`

const DefaultFadingContainer = createFadingContainer(DefaultContainer)

export interface FadingOverflowControls {
  scrollBy: ScrollByFunction
}

export interface FadingOverflowProps {
  Container: any
  children?: React.ReactNode
  fadeWidth?: number
  fadeMode?: FadingOverflowMode
  onOverflowChange?: (horizontal: DirectionOverflow, vertical: DirectionOverflow) => void
  preferredDirection?: FadingOverflowPreferredDirection
  controls?: React.Ref<FadingOverflowControls>
  style?: CSSProperties
}

export function FadingOverflow({
  children,
  Container,
  fadeWidth = 16,
  fadeMode = 'dynamic',
  onOverflowChange,
  preferredDirection = 'horizontal',
  controls,
  ...rest
}: FadingOverflowProps) {
  const {
    ref: detectorElementCallback,
    horizontal,
    vertical,
    scrollBy,
  } = useOverflowDetectorAndScrollControls()

  const FadingContainer = Container ?? DefaultFadingContainer

  useEffect(() => {
    if (onOverflowChange) {
      onOverflowChange(horizontal, vertical)
    }
  }, [horizontal, vertical, onOverflowChange])

  useImperativeHandle(
    controls,
    () => ({
      scrollBy: (percentage, options) => {
        scrollBy(percentage, { direction: preferredDirection, ...options })
      },
    }),
    [preferredDirection, scrollBy],
  )

  return (
    <FadingContainer
      ref={detectorElementCallback}
      $horizontal={horizontal}
      $vertical={vertical}
      $fadeWidth={fadeWidth}
      $fadeMode={fadeMode}
      $preferredDirection={preferredDirection}
      {...rest}
    >
      {children}
    </FadingContainer>
  )
}
