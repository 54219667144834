import { S } from './Sidebar.styled'
import SidebarItem from './components/SidebarItem/SidebarItem'
import { useCurrentLocation } from '../hooks/useCurrentLocation'
import { useSidebarItemStructure } from './hooks/useSidebarItemStructure'

import Footer from './components/Footer/Footer.sidebar'

const Sidebar = () => {
  const currentLocation = useCurrentLocation()
  const sidebarItems = useSidebarItemStructure()

  return (
    <S.Sidebar>
      <S.List>
        {sidebarItems.map((item) => (
          <SidebarItem
            key={item.id}
            id={item.id}
            iconName={item.iconName}
            onClick={item.redirectTo}
            label={item.label}
            subItems={item.childrenItems}
            isActive={
              currentLocation === item.location ||
              item.childrenItems?.find((child) => child.location === currentLocation) !== undefined
            }
            currentLocation={currentLocation}
            badge={item.badges}
          />
        ))}
      </S.List>
      <Footer />
    </S.Sidebar>
  )
}

export default Sidebar
