import { useEffect, useState } from 'react'
import { useNotifications } from '../../utils/Notifications/Notifications'
import { useCompanyVisualSettings } from './hooks/useCompanyVisualSettings'
import { SettingsInfo } from './SettingsInfo'
import {
  IonIcon,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@valuecase/ui-components'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'

export const ThemeSetting = () => {
  const { success } = useNotifications()
  const { trackEvent } = useTrackEvent()

  const [theme, setTheme] = useState<'Light' | 'Dark' | undefined>()

  const { companySettings, updateCompanySettings } = useCompanyVisualSettings({
    onUpdateSuccess: () => {
      trackEvent({
        event: 'settings-topbarstyle-update',
        eventProperties: {
          indexPageName: 'Company Settings',
        },
      })
      success('Top Bar style updated')
    },
  })

  useEffect(() => {
    if (companySettings?.theme) {
      setTheme(companySettings.theme)
    }
  }, [companySettings?.theme])

  return (
    <div className='flex justify-between w-full items-start'>
      <SettingsInfo
        title='Top Bar Style'
        description='Set the style for the space navigation here. Choose between dark mode with a light text color, or light mode with a darker text.'
      />

      <Select
        value={theme}
        onValueChange={(value) => {
          if (value !== 'Light' && value !== 'Dark') {
            throw new Error('invalid theme value')
          }

          setTheme(value)
          updateCompanySettings({ theme: value })
        }}
      >
        <SelectTrigger className={'w-44'}>
          <SelectValue>
            <div className={'flex items-center gap-2'}>
              {theme === 'Light' ? (
                <IonIcon name='sunny-outline' className={'w-4 h-4 text-primary-s3'} />
              ) : (
                <IonIcon name='moon-outline' className={'w-4 h-4 text-primary-s3'} />
              )}
              {theme}
            </div>
          </SelectValue>
        </SelectTrigger>
        <SelectContent>
          <SelectItem icon={'sunny-outline'} value={'Light'}>
            Light
          </SelectItem>
          <SelectItem icon={'moon-outline'} value={'Dark'}>
            Dark
          </SelectItem>
        </SelectContent>
      </Select>
    </div>
  )
}
