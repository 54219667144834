import React, { useContext, useMemo } from 'react'
import {
  LoadableGroupKey,
  useLoadable,
  useLoadableGroup,
  UseLoadableGroupHook,
  UseLoadableHook,
} from './Loadables'

const defaultGroupKey: LoadableGroupKey = '__default_group__'

function constructGroupKey(resourceKey: string, groupKey: LoadableGroupKey) {
  return `${resourceKey}:${groupKey}`
}

export type ContextualizedResourceGroupKeys = { [resourceKey: string]: LoadableGroupKey }

export type ContextualizedLoadablesProps = {
  children?: React.ReactNode
  resourceConfigs: ContextualizedResourceGroupKeys
}

type ContextModel = {
  resourceConfigs: ContextualizedResourceGroupKeys
}

const Context = React.createContext<ContextModel>({
  resourceConfigs: {},
})

export function ContextualizedLoadables({
  children,
  resourceConfigs,
}: ContextualizedLoadablesProps) {
  const json = JSON.stringify(resourceConfigs)

  const contextValue = useMemo<ContextModel>(() => {
    return {
      resourceConfigs,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [json])

  return <Context.Provider value={contextValue}>{children}</Context.Provider>
}

function useContextualizedResourceGroupKey(resourceKey: string) {
  const context = useContext(Context)

  return useMemo<LoadableGroupKey>(() => {
    return constructGroupKey(resourceKey, context.resourceConfigs[resourceKey] ?? defaultGroupKey)
  }, [resourceKey, context])
}

export function useContextualizedLoadable(
  resourceKey: string,
  loadingByDefault?: boolean,
): UseLoadableHook {
  return useLoadable(useContextualizedResourceGroupKey(resourceKey), loadingByDefault)
}

export function useContextualizedLoadableGroup(
  resourceKey: string,
  groupKey: LoadableGroupKey,
): UseLoadableGroupHook {
  return useLoadableGroup(constructGroupKey(resourceKey, groupKey))
}
