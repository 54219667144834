import {
  CheckMark,
  cn,
  IonIcon,
  AvatarThumbnail,
  UpdateTaskParams,
  useConfetti,
  ascendToParentWithType,
} from '@valuecase/ui-components'
import { FC, MouseEventHandler, ReactNode, useCallback, useMemo } from 'react'
import { getCompanyInitials, TActionPlanTaskExpanded } from '@valuecase/common'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'
import {
  TaskCardBodySection,
  TaskCardBottomSection,
  TaskCardContentBackground,
  TaskCardDateLabel,
  TaskCardDateWrapper,
  TaskCardTaskSubtitle,
  TaskCardTaskTitle,
  TaskCardTopLabel,
} from './tasks-ui-helpers'

export const TaskCardView: FC<{
  task: TActionPlanTaskExpanded
  timeToDueDate: ReactNode
  onClick: MouseEventHandler<HTMLDivElement>
  handleUpdateTask: ({ id, data }: UpdateTaskParams) => Promise<void>
}> = ({ task, timeToDueDate, onClick, handleUpdateTask }) => {
  const { showOnceOver } = useConfetti()

  const companyInitials = useMemo(
    () => getCompanyInitials(task.spaceCompanyName),
    [task.spaceCompanyName],
  )

  const handleTaskCheckMarkClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      const newCheckedValue = !task.checked
      const element = e.target instanceof Node && ascendToParentWithType(e.target, HTMLElement)
      // Show confetti when task is checked
      if (newCheckedValue && element) {
        showOnceOver(element, { width: 200, height: 200 })
      }
      handleUpdateTask({ id: task.id, data: { checked: newCheckedValue } })
    },
    [handleUpdateTask, showOnceOver, task.checked, task.id],
  )

  return (
    <Button2 asChild>
      {/* Keep in sync with element structure in loading skeleton in TaskColumnView.tsx */}
      <TaskCardContentBackground
        onClick={onClick}
        className='cursor-pointer bg-white/85 hover:bg-white/100 hover:shadow transition'
      >
        <CheckMark
          className={cn('mr-3 -mt-0.5', { 'text-success-s5': task.checked })}
          checked={task.checked}
          onClick={handleTaskCheckMarkClick}
        />
        {!!task.spaceLogoUrl && (
          <AvatarThumbnail size='MEDIUM' type='image' imageUrl={task.spaceLogoUrl} />
        )}
        {!task.spaceLogoUrl && (
          <AvatarThumbnail size='MEDIUM' type='gravatarInitials' initials={companyInitials} />
        )}
        <TaskCardBodySection className='ml-2'>
          <TaskCardTopLabel>{task.spaceCompanyName}</TaskCardTopLabel>
          <TaskCardTaskTitle
            className={cn({
              'text-grey-s6': !task.checked,
              'text-grey-s5': task.checked,
              'line-through': task.checked,
            })}
          >
            {task.title}
          </TaskCardTaskTitle>
          <TaskCardTaskSubtitle>{task.milestoneTitle}</TaskCardTaskSubtitle>
          <TaskCardBottomSection>
            <div className='flex-1 relative'>{/* Assignee info goes here */}</div>
            {!!task.dueDate && (
              <>
                <TaskCardDateWrapper>
                  <IonIcon name='calendar' className='text-xs text-grey-s5' />
                  <TaskCardDateLabel>{timeToDueDate}</TaskCardDateLabel>
                </TaskCardDateWrapper>
              </>
            )}
          </TaskCardBottomSection>
        </TaskCardBodySection>
      </TaskCardContentBackground>
    </Button2>
  )
}
