import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuButton,
  DropdownMenuContentScrollable,
  DropdownMenuButtonProps,
} from './dropdown-menu'
import { Highlight } from './text'
import { cn } from '../../lib'
import { Input2 } from './input'
import { useState, useMemo, useCallback, ReactNode } from 'react'
import { DropdownMenuContentProps, DropdownMenuProps } from '@radix-ui/react-dropdown-menu'
import { Button2 } from './button'
import { Badge } from './badge'

export type ToggleIdSelectionProps = {
  selectedIds: Set<string> | undefined
  setSelectedIds: (ids: Set<string> | undefined) => void
}

export const useToggleIdSelection = ({ selectedIds, setSelectedIds }: ToggleIdSelectionProps) => {
  const toggleSelection = useCallback(
    (id: string) => {
      if (!selectedIds) {
        setSelectedIds(new Set([id]))
        return
      }
      const newSelection = new Set(selectedIds)
      if (newSelection.has(id)) {
        newSelection.delete(id)
      } else {
        newSelection.add(id)
      }

      if (newSelection.size === 0) {
        setSelectedIds(undefined)
      } else {
        setSelectedIds(newSelection)
      }
    },
    [selectedIds, setSelectedIds],
  )

  return toggleSelection
}

export type FilterDropdownItem = {
  id: string
  title: string
}

export type FilterDropdownProps<T> = {
  items: T[]
  selectedIds: Set<string> | undefined
  toggleSelection: (id: string) => void
  setSelectedIds: (ids: Set<string> | undefined) => void
  labelForNoSelection: ReactNode
  labelForSelection: ReactNode
  filterItems?: (items: T[], searchTerm: string) => T[]
  renderListItem?: (item: T) => JSX.Element
  searchPlaceholder?: string
  open?: DropdownMenuProps['open']
  onOpenChange?: DropdownMenuProps['onOpenChange']
  contentAlign?: DropdownMenuContentProps['align']
  buttonClassName?: DropdownMenuButtonProps['className']
  contentClassName?: DropdownMenuContentProps['className']
  listClassName?: DropdownMenuContentProps['className']
}

export const FilterDropdown = <T extends FilterDropdownItem>({
  items,
  selectedIds,
  toggleSelection,
  labelForNoSelection,
  labelForSelection,
  filterItems,
  renderListItem,
  searchPlaceholder,
  onOpenChange,
  open,
  contentAlign = 'start',
  buttonClassName,
  contentClassName,
  listClassName,
  setSelectedIds,
}: FilterDropdownProps<T>): JSX.Element => {
  const hasSelection = useMemo(() => selectedIds && selectedIds.size >= 1, [selectedIds])

  const [searchTerm, setSearchTerm] = useState('')

  const filteredItems = useMemo(() => {
    if (!searchTerm) {
      return items
    }
    const filterFn =
      filterItems ||
      ((items, searchTerm) =>
        items.filter((item) => item.title.toLowerCase().includes(searchTerm.toLowerCase())))
    return filterFn(items, searchTerm)
  }, [items, searchTerm, filterItems])

  const label = useMemo(() => {
    const count = selectedIds?.size || 0
    if (count === 0) {
      return labelForNoSelection
    }
    return (
      <span>
        {labelForSelection}
        <Badge variant={'solid'} className='ml-2'>
          {count}
        </Badge>
      </span>
    )
  }, [selectedIds?.size, labelForNoSelection, labelForSelection])

  return (
    <DropdownMenu open={open} onOpenChange={onOpenChange}>
      <DropdownMenuTrigger asChild>
        <DropdownMenuButton
          className={cn(buttonClassName, {
            'border-primary-s5 text-primary-s5 font-semibold': hasSelection,
          })}
        >
          {label}
        </DropdownMenuButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent className={cn('w-[284px]', contentClassName)} align={contentAlign}>
        <div className='p-1'>
          <Input2
            type='text'
            placeholder={searchPlaceholder}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()}
            trailingIcon='search'
          />
        </div>
        <DropdownMenuSeparator />
        <div className='p-1'>
          <Button2 variant='plain' onClick={() => setSelectedIds(undefined)}>
            Reset and show all items
          </Button2>
        </div>
        <DropdownMenuSeparator />
        <DropdownMenuContentScrollable className={cn('w-full', listClassName)}>
          {filteredItems.map((item) =>
            renderListItem ? (
              renderListItem(item)
            ) : (
              <DropdownMenuCheckboxItem
                key={item.id}
                checked={selectedIds?.has(item.id) || false}
                onCheckedChange={() => toggleSelection(item.id)}
                onSelect={(event) => event.preventDefault()}
              >
                <Highlight searchTerm={searchTerm}>{item.title}</Highlight>
              </DropdownMenuCheckboxItem>
            ),
          )}
        </DropdownMenuContentScrollable>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
