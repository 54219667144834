import { BaseDropdown, IonIcon } from '@valuecase/ui-components'
import { shift, size } from '@floating-ui/dom'
import classNames from 'classnames'
import { useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { GetAvailableSpaceOwnersApiSingle } from '@valuecase/seller-backend'
import { useElementSize, useWindowSize } from 'usehooks-ts'
import { useReadAvailableSpaceOwners } from '../../modules/spaces/hooks/useReadAvailableSpaceOwners'

export type SelectNewSpaceOwnerProps = {
  currentSpaceOwnerId?: string
  onNewSpaceOwnerSelected: (newOwner?: { id: string; name: string } | undefined) => void
}

function getUserName(user: GetAvailableSpaceOwnersApiSingle): string {
  return user.firstName || user.lastName
    ? `${user.firstName ?? ''} ${user.lastName ?? ''}`
    : `[User with ID ${user.id}]`
}

export default function SelectNewSpaceOwner({
  currentSpaceOwnerId,
  onNewSpaceOwnerSelected,
}: SelectNewSpaceOwnerProps) {
  const [triggerRef, { width }] = useElementSize()
  const [opened, setOpened] = useState(false)
  const { availableSpaceOwners } = useReadAvailableSpaceOwners()
  const [selectedItem, setSelectedItem] = useState<GetAvailableSpaceOwnersApiSingle | undefined>(
    undefined,
  )

  const lastItemRef = useRef<HTMLLIElement | null>(null)

  const { height } = useWindowSize()
  const [listHeight, setListHeight] = useState('auto')

  const isListScrollable = useMemo(() => {
    if (!lastItemRef.current) return false
    return lastItemRef.current?.getBoundingClientRect().bottom > height
  }, [height, lastItemRef])

  const triggerWidth = useMemo(() => {
    if (!width) return '100%'
    return width + 'px'
  }, [width])

  function onSelectItem(item?: GetAvailableSpaceOwnersApiSingle) {
    setSelectedItem(item)
    onNewSpaceOwnerSelected(item ? { id: item.id, name: getUserName(item) } : undefined)
    setOpened(false)
  }

  const possibleSpaceOwners = useMemo(() => {
    if (!availableSpaceOwners) return []
    return availableSpaceOwners.filter((user) => user.id !== currentSpaceOwnerId)
  }, [availableSpaceOwners, currentSpaceOwnerId])

  return (
    <div className={'text-sm w-full'}>
      <BaseDropdown
        opened={opened}
        middleware={[
          shift({ padding: 40 }),
          size({
            apply({ availableHeight }) {
              const heightOfAllListItems = possibleSpaceOwners.length * 32.5
              if (heightOfAllListItems < availableHeight - 16) return
              setListHeight(availableHeight - 16 + 'px')
            },
          }),
        ]}
        trigger={
          <Trigger $isActive={opened} $isPlaceholder={selectedItem === undefined} ref={triggerRef}>
            <p>{selectedItem === undefined ? 'Select a user' : getUserName(selectedItem)}</p>
            {!opened && <IonIcon name='chevron-down' />}
          </Trigger>
        }
        onChange={(opened) => {
          setOpened(opened)
        }}
        onTriggerClick={(e) => {
          e.stopPropagation()
          setOpened(!opened)
        }}
      >
        <SelectList $width={triggerWidth} $height={listHeight} $scrollable={isListScrollable}>
          {possibleSpaceOwners.map((user, index) => (
            <li
              key={user.id}
              onClick={() => onSelectItem(user)}
              className={classNames({ selected: selectedItem?.id === user.id })}
              ref={index === possibleSpaceOwners.length - 1 ? lastItemRef : null}
            >
              {getUserName(user)}

              {selectedItem?.id === user.id && (
                <IonIcon name='checkmark-outline' style={{ width: '14px', height: '14px' }} />
              )}
            </li>
          ))}
        </SelectList>
      </BaseDropdown>
    </div>
  )
}

const SelectList = styled.ul<{ $width: string; $height: string; $scrollable: boolean }>`
  background: white;
  border: 1px solid var(--theme-grey-s2);
  box-shadow:
    0 6px 16px -4px rgba(0, 0, 0, 0.1),
    0px 2px 6px -2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  width: ${({ $width: width }) => width};
  height: ${({ $height: height }) => height};
  padding: 8px;
  color: var(--theme-grey-s6);
  overflow-y: scroll;
  li {
    list-style: none;
    &.selected {
      background: var(--theme-grey-s2);
    }
    padding: 8px 8px 8px 16px;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(.selected):hover {
      cursor: pointer;
      background: var(--theme-grey-s1);
    }
  }
`

const Trigger = styled.div<{ $isPlaceholder: boolean; $isActive: boolean }>`
  width: 100%;
  padding: 12px 22px 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--theme-grey-s2);
  border-radius: 8px;
  color: ${({ $isPlaceholder: isPlaceholder }) =>
    isPlaceholder ? 'var(--theme-grey-s4)' : 'var(--theme-grey-s6)'};
  p {
    margin: 0;
  }
  ${({ $isActive: isActive }) =>
    isActive &&
    'border: 1px solid var(--theme-primary-s4); box-shadow: 0px 0px 0px 4px var(--theme-primary-s2)'};
`
