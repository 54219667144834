import * as React from 'react'
import { cva, VariantProps } from 'class-variance-authority'
import { cn } from '../../lib/utils'
import { IonIcon } from '../../Icons/IonIcon'

const bannerVariants = cva('flex gap-2 p-3 text-xs rounded-lg text-grey-s5', {
  variants: {
    variant: {
      grey: 'bg-grey-s1',
      blue: 'bg-primary-s2',
    },
  },
  defaultVariants: {
    variant: 'grey',
  },
})

const bannerIconVariants = cva('w-4 h-4', {
  variants: {
    variant: {
      grey: 'text-grey-s4',
      blue: 'text-primary-s4',
    },
  },
  defaultVariants: {
    variant: 'grey',
  },
})

type BannerPropVariants = VariantProps<typeof bannerVariants>

export interface BannerProps extends React.HTMLAttributes<HTMLDivElement>, BannerPropVariants {
  icon?: string
}

function Banner({ className, variant, icon, children, ...props }: BannerProps) {
  return (
    <div className={cn(bannerVariants({ variant }), className)} {...props}>
      <IonIcon
        name={icon}
        className={cn('w-4 h-4 flex-shrink-0', bannerIconVariants({ variant }))}
      />
      <p className={'text-xs'}>{children}</p>
    </div>
  )
}

export { Banner }
