import { Input2, Skeleton, useNotifications } from '@valuecase/ui-components'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'
import { Label } from '@valuecase/ui-components/src/components/ui/label'
import { useEffect, useState } from 'react'
import { SettingsInfo } from './SettingsInfo'
import useCreatePublicApiKey from './hooks/useCreatePublicApiKey'
import usePublicApiKey from './hooks/usePublicApiKey'

const ApiKeySettings = () => {
  const { data: publicApiCredentials } = usePublicApiKey()
  const { mutate, isLoading, isSuccess } = useCreatePublicApiKey()
  const [isKeyCopied, setIsKeyCopied] = useState(false)
  const [isClientIdCopied, setIsClientIdCopied] = useState(false)
  const { success } = useNotifications()

  const handleCopyKey = () => {
    if (!publicApiCredentials) {
      return
    }
    navigator.clipboard.writeText(publicApiCredentials.key)
    setIsKeyCopied(true)
  }

  const handleCopyClientId = () => {
    if (!publicApiCredentials) {
      return
    }
    navigator.clipboard.writeText(publicApiCredentials.clientId)
    setIsClientIdCopied(true)
  }

  useEffect(() => {
    if (isSuccess && publicApiCredentials) {
      success('API credentials created.')
    }
  }, [isSuccess, publicApiCredentials, success])

  useEffect(() => {
    if (isKeyCopied) {
      setTimeout(() => {
        setIsKeyCopied(false)
      }, 2000)
    }
    if (isClientIdCopied) {
      setTimeout(() => {
        setIsClientIdCopied(false)
      }, 2000)
    }
  }, [isClientIdCopied, isKeyCopied])

  return (
    <div className='flex justify-between w-full items-start'>
      <SettingsInfo
        title='API Key'
        description={'Authentication credentials to access our API, Zapier or Make apps.'}
      />

      {publicApiCredentials ? (
        <div className='flex flex-col gap-2 items-end'>
          <div className='flex items-center gap-2 animate-pop-in-left'>
            <Label>Client ID</Label>
            <Input2
              disabled
              value={publicApiCredentials.clientId}
              className='w-fit mr-2'
              readOnly
            />

            <Button2
              onClick={handleCopyClientId}
              trailingIcon={isClientIdCopied ? 'checkmark' : 'copy-outline'}
              variant={isClientIdCopied ? 'success' : 'solid'}
              className='w-20'
            >
              {isClientIdCopied ? 'Copied' : 'Copy'}
            </Button2>
          </div>

          <div className='flex items-center gap-2 animate-pop-in-left'>
            <Label>API Key</Label>
            <Input2 disabled value={publicApiCredentials.key} className='w-fit mr-2' readOnly />

            <Button2
              onClick={handleCopyKey}
              trailingIcon={isKeyCopied ? 'checkmark' : 'copy-outline'}
              variant={isKeyCopied ? 'success' : 'solid'}
              className='w-20'
            >
              {isKeyCopied ? 'Copied' : 'Copy'}
            </Button2>
          </div>
        </div>
      ) : (
        <>
          {isLoading ? (
            <div className='w-36 flex  h-full flex-col gap-2'>
              <Skeleton className='w-full h-8' />
              <Skeleton className='w-full h-8' />
            </div>
          ) : (
            <Button2 onClick={mutate} trailingIcon='add'>
              Create credentials
            </Button2>
          )}
        </>
      )}
    </div>
  )
}

export default ApiKeySettings
