import { PaginationState } from '@tanstack/react-table'
import { useState, useCallback, useMemo } from 'react'

export const usePagination = (initialPageIndex = 0, initialPageSize = 8) => {
  const [pageIndex, setPageIndex] = useState(initialPageIndex)
  const [pageSize, setPageSize] = useState(initialPageSize)

  const handlePageChange = useCallback((newPageIndex: number) => {
    setPageIndex(newPageIndex)
  }, [])

  const handlePageSizeChange = useCallback((newPageSize: number) => {
    setPageSize(newPageSize)
  }, [])

  const setPagination = useCallback((pagination: PaginationState) => {
    setPageIndex(pagination.pageIndex)
    setPageSize(pagination.pageSize)
  }, [])

  const resetPagination = useCallback(() => {
    setPageIndex(initialPageIndex)
    setPageSize(initialPageSize)
  }, [initialPageIndex, initialPageSize])

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
      handlePageChange,
      handlePageSizeChange,
      setPagination,
      resetPagination,
    }),
    [handlePageChange, handlePageSizeChange, pageIndex, pageSize, resetPagination, setPagination],
  )

  return pagination
}
