import { useQuery } from '@tanstack/react-query'
import { useAuthState } from '../auth/auth'
import { spacesApi } from '../api/spacesApi/spacesAPIclient'

export function buildLdQueryKey(): string[] {
  return ['ld-flags']
}
export function useLDflags() {
  const authState = useAuthState()
  const query = useQuery<Map<string, string>>(
    buildLdQueryKey(),
    async () => {
      return (await spacesApi.get(`/launchdarkly`)).data
    },
    {
      enabled: authState.authorized,
      retryOnMount: false,
      retry: 1,
      retryDelay: 500,
      staleTime: 1000 * 60 * 5, // 5 minutes,
    },
  )

  return {
    isLoading: query.isLoading,
    isReloading: query.isRefetching && query.isStale,
    isFetched: query.isFetched,
    isError: query.isError,
    error: query.error,
    flags: query.data as undefined | Record<string, boolean | undefined>,
  }
}
