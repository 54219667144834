import { cn } from '@valuecase/ui-components'
import classNames from 'classnames'
import { FC, HTMLAttributes } from 'react'

export const TaskColumnContentBackground: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={cn(
        'flex w-full h-full bg-grey-s1 rounded-[10px] p-1 justify-center grow items-start',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export const TaskCardContentBackground: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={cn(
        'flex flex-row items-start relative w-full bg-white/85 rounded-lg p-2 shrink',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export const TaskCardTopLabel: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'flex relative self-stretch leading-[1.4em] items-center text-grey-s5 text-xxs',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export const TaskCardBodySection: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={cn('flex flex-col items-end relative self-stretch w-full', className)}
      {...props}
    >
      {children}
    </div>
  )
}

export const TaskCardTaskTitle: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'flex relative text-sm flex-1 font-medium items-center leading-[1.286em] w-full self-stretch',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export const TaskCardTaskSubtitle: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={classNames(
        'flex relative self-stretch text-xxs leading-[1.4em] text-grey-s5 items-center',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export const TaskCardBottomSection: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div
      className={cn('flex items-end gap-1 leading-[1.4em] relative self-stretch w-full', className)}
      {...props}
    >
      {children}
    </div>
  )
}

export const TaskCardDateWrapper: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div className={cn('inline-flex items-center gap-2', className)} {...props}>
      {children}
    </div>
  )
}

export const TaskCardDateLabel: FC<HTMLAttributes<HTMLDivElement>> = ({
  children,
  className,
  ...props
}) => {
  return (
    <div className={classNames('relative w-fit text-xs text-grey-s5', className)} {...props}>
      {children}
    </div>
  )
}
