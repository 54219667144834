import { Button } from '@valuecase/ui-components'
import { useRef } from 'react'
import { useNotifications } from '../../utils/Notifications/Notifications'
import { useCompanyVisualSettings } from './hooks/useCompanyVisualSettings'
import { useDeleteUploadedImage } from './hooks/useDeleteUploadedImage'
import { SettingsInfo } from './SettingsInfo'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import { useS3Upload } from '@/api/s3'

export const BackgroundImageSetting = () => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const { success } = useNotifications()
  const { upload } = useS3Upload()
  const { show } = useDeleteUploadedImage()
  const { trackEvent } = useTrackEvent()

  const { companySettings, updateCompanySettings, isQueryFetched } = useCompanyVisualSettings({
    onUpdateSuccess: () => {
      success('Background image updated')
    },
  })

  function handleUploadClick() {
    fileInputRef?.current?.click()
  }

  async function handleUploadBackgroundImage(file: File) {
    const uploadedFile = await upload(file)
    trackEvent({
      event: 'settings-background-upload_finished',
      eventProperties: {
        indexPageName: 'Company Settings',
      },
    })
    const logoImageData = {
      source: 'S3',
      s3Key: uploadedFile.s3ObjectKey,
    }

    updateCompanySettings({ backgroundImage: logoImageData })
  }

  function deleteBackgroundImage() {
    show(() => updateCompanySettings({ backgroundImage: null }), 'background')
  }

  return (
    <div className='flex justify-between w-full items-start'>
      <SettingsInfo
        title='Space Background'
        description='Upload a custom background image for your spaces.'
      />
      {isQueryFetched && !companySettings?.backgroundImage && (
        <div>
          <Button
            icon='cloud-upload-outline'
            iconPosition='right'
            label='Upload Image'
            onClick={() => {
              trackEvent({
                event: 'settings-background-upload_click',
                eventProperties: {
                  indexPageName: 'Company Settings',
                },
              })
              handleUploadClick()
            }}
          />

          <input
            ref={fileInputRef}
            type={'file'}
            style={{ display: 'none' }}
            multiple={false}
            accept={'image/*'}
            onInput={(e) => {
              e.preventDefault()
              const file = (e.target as HTMLInputElement)?.files?.[0]
              if (file) {
                handleUploadBackgroundImage(file)
              }
            }}
          />
        </div>
      )}

      {isQueryFetched && companySettings?.backgroundImage && (
        <div className='flex gap-3'>
          <Button
            icon='trash-outline'
            iconPosition='right'
            circle={true}
            style='flat-warning'
            onClick={() => {
              trackEvent({
                event: 'settings-background-remove',
                eventProperties: {
                  indexPageName: 'Company Settings',
                },
              })
              deleteBackgroundImage()
            }}
          />
          <Button
            icon='create-outline'
            iconPosition='right'
            label='Change'
            onClick={() => {
              trackEvent({
                event: 'settings-background-change_click',
                eventProperties: {
                  indexPageName: 'Company Settings',
                },
              })
              handleUploadClick()
            }}
          />
          <input
            ref={fileInputRef}
            type={'file'}
            style={{ display: 'none' }}
            multiple={false}
            accept={'image/*'}
            onInput={(e) => {
              e.preventDefault()
              const file = (e.target as HTMLInputElement)?.files?.[0]
              if (file) {
                handleUploadBackgroundImage(file)
              }
            }}
          />
        </div>
      )}
    </div>
  )
}
