import { Tooltip, TooltipContent, TooltipTrigger } from '@valuecase/ui-components'
import { useMemo } from 'react'

const TeamsColumn: React.FC<{
  teams?: {
    id: string
    name: string
  }[]
}> = ({ teams }) => {
  const sortedTeams = useMemo(
    () => teams && [...teams].sort((a, b) => a.name.localeCompare(b.name)),
    [teams],
  )

  const firstTeamName = useMemo(() => (sortedTeams && sortedTeams[0]?.name) || '', [sortedTeams])

  const additionalTeamsCount = useMemo(
    () => (sortedTeams && sortedTeams.length > 1 ? sortedTeams.length - 1 : 0),
    [sortedTeams],
  )

  const hasAdditionalTeams = useMemo(() => additionalTeamsCount > 0, [additionalTeamsCount])

  return (
    <>
      {hasAdditionalTeams && (
        <Tooltip>
          <TooltipTrigger>
            <span className={'flex text-xs text-grey-s6 text-left text-wrap'}>
              {firstTeamName} (+{additionalTeamsCount})
            </span>
          </TooltipTrigger>
          <TooltipContent>
            <div>{sortedTeams?.map((team, index) => <div key={index}>{team.name}</div>)}</div>
          </TooltipContent>
        </Tooltip>
      )}
      {!hasAdditionalTeams && <span className={'text-xs text-grey-s6'}>{firstTeamName}</span>}
    </>
  )
}

export default TeamsColumn
