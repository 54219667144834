import { FC } from 'react'
import { SelectListProps } from './SelectListProps'
import {
  SelectListItem,
  SelectListItemCheckmark,
  SelectListItemIcon,
  SelectListItemText,
  SelectListLabel,
  SelectListRadio,
  SelectListUnorderedList,
} from './SelectListParts'
import { IonIcon } from '@valuecase/ui-components'

export const SelectList: FC<SelectListProps> = ({ items, selectItem, selectedId }) => {
  return (
    <SelectListUnorderedList>
      {items.map((item) => {
        const inputId = `listItem-${item.id}`
        const isChecked = item.id === selectedId
        return (
          <SelectListItem key={item.id}>
            <SelectListRadio
              id={inputId}
              type='radio'
              name='selectTemplateFromLibrary'
              value={item.id}
              checked={isChecked}
              onChange={() => selectItem(item.id)}
            />
            <SelectListLabel htmlFor={inputId}>
              <SelectListItemIcon>{item.emojiIcon}</SelectListItemIcon>
              <SelectListItemText>{item.text}</SelectListItemText>
              {isChecked && (
                <SelectListItemCheckmark>
                  <IonIcon name='checkmark-outline'></IonIcon>
                </SelectListItemCheckmark>
              )}
            </SelectListLabel>
          </SelectListItem>
        )
      })}
    </SelectListUnorderedList>
  )
}
