import { useState, useCallback } from 'react'
import { User } from '@valuecase/common'

const useSelectedMembers = (initialMembers: User[] = []) => {
  const [selectedMembers, setSelectedMembers] = useState(new Set(initialMembers))

  const toggleMember = useCallback((user: User) => {
    setSelectedMembers((prevSet) => {
      const newSet = new Set(prevSet)
      if (newSet.has(user)) {
        newSet.delete(user)
      } else {
        newSet.add(user)
      }
      return newSet
    })
  }, [])

  const resetSelectedMembers = useCallback(() => {
    setSelectedMembers(new Set())
  }, [])

  return { selectedMembers, toggleMember, resetSelectedMembers }
}

export default useSelectedMembers
