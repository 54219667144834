import styled from 'styled-components'
const Layout = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  background: #f6f8fc;
`

const SecondRow = styled.div`
  display: flex;
  height: 100%;
  overflow-y: hidden;
`
const Main = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  overflow-y: auto;
`

const Margin = styled.div`
  display: flex;
  flex-grow: 1;
  width: 100%;
  max-width: 1400px;
  padding: 0 40px;

  @media (max-width: 800px) {
    padding: 0 10px;
  }
`

const ScreenNotAllowedTxt = styled.div`
  font-weight: 550;
  font-size: 15px;
  line-height: 44px;
  font-stretch: 100;
  color: ${({ theme }) => theme.grey.s6};
  margin: auto;
`

export const S = { Layout, SecondRow, Main, Margin, ScreenNotAllowedTxt }
