import { useMemo } from 'react'
import useReadTeams from './useReadTeams'
import { useAuthState } from '@/auth/auth'

const useReadMyTeams = () => {
  const { data: teams } = useReadTeams()
  const authState: { authorized: boolean; sub?: string } = useAuthState()
  const userId = useMemo(
    () => authState.authorized === true && authState.sub,
    [authState.sub, authState.authorized],
  )
  const myTeams = useMemo(() => {
    if (!userId) {
      return []
    }
    return teams?.filter((team) => team.memberIds.includes(userId))
  }, [teams, userId])
  return { myTeams }
}

export default useReadMyTeams
