import { useQuery } from '@tanstack/react-query'
import SellerApi from '../../../api/SellerApi'

async function readSalesforceIntegrationStatusFn() {
  const res = await SellerApi.get<{ status: 'enabled' | 'disabled' }, undefined>(
    'integrations/salesforce/status',
  )
  return res.payload.status
}

export function useReadSalesforceIntegrationStatus() {
  const query = useQuery({
    queryKey: ['readSalesforceIntegrationStatus'],
    queryFn: readSalesforceIntegrationStatusFn,
  })

  return {
    isLoading: query.isLoading,
    isReloading: query.isRefetching && query.isStale,
    isError: query.isError,
    error: query.error,
    salesforceIntegrationStatus: query.data,
    isFetched: query.isFetched,
    refetch: query.refetch,
  }
}
