import { HeaderContext } from '@tanstack/react-table'
import { IonIcon } from '@valuecase/ui-components'
import { FC, ReactNode } from 'react'
import styled, { useTheme } from 'styled-components'

interface HeaderColProps {
  headerContext: HeaderContext<any, any>
  className?: string
  label?: string
  bg?: string
  width?: string
  leftPadding?: string
  children?: ReactNode
  minHeight?: string
  onClick?: () => void
  id?: string
}
const HeaderCol: FC<HeaderColProps> = ({
  children,
  className,
  headerContext,
  label,
  bg,
  width,
  leftPadding,
  minHeight,
  onClick,
  id,
}) => {
  const theme = useTheme()

  return (
    <FlexContainer
      className={`${className} ${id} text-xs`}
      $isClickable={headerContext.column.getCanSort()}
      $minHeight={minHeight}
      $leftPadding={leftPadding}
      $bg={bg}
      onClick={onClick}
    >
      <ColumnHeaderText>{label}</ColumnHeaderText>
      {children}
      <SortIconWrapper>
        {{
          asc: (
            <IonIcon className={'text-xs'} name='arrow-up' style={{ color: theme.primary.s5 }} />
          ),
          desc: (
            <IonIcon className={'text-xs'} name='arrow-down' style={{ color: theme.primary.s5 }} />
          ),
        }[headerContext.column.getIsSorted() as string] ?? (
          <div className={'flex items-center'}>
            {headerContext.column.getCanSort() && (
              <IonIcon className={'text-xs'} name='arrow-down' style={{ color: theme.grey.s4 }} />
            )}
          </div>
        )}
      </SortIconWrapper>
    </FlexContainer>
  )
}

export default HeaderCol

const SortIconWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 5px;
`

const FlexContainer = styled.div<{
  $bg?: string
  $leftPadding?: string
  $isClickable?: boolean
  $minHeight?: string
}>`
  display: flex;
  gap: 4px;
  min-height: ${({ $minHeight: minHeight }) => (minHeight ? minHeight : '40px')};
  align-items: center;
  background: ${({ $bg: bg }) => (bg ? bg : 'transparent')};
  ${({ $leftPadding: leftPadding }) => {
    if (leftPadding) {
      return `padding-left: ${leftPadding};`
    }
  }}
  ${({ $isClickable: isClickable }) => (isClickable ? 'cursor: pointer;' : '')}
`

const ColumnHeaderText = styled.span`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.grey.s6};
`
