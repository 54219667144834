import { spacesApi } from '@/api/spacesApi/spacesAPIclient'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { GroupBy } from './TaskCompletionChart'

/**
 * Types for action plan analytics
 */
export type ActionPlanAnalyticsGroupingLevel = 'template' | 'action plan' | 'milestone' | 'task'

export type ActionPlanAnalyticsQueryBaseFilter = {
  selectedTemplateIds: Set<string> | undefined
  selectedActionPlanIds: Set<string> | undefined
  selectedMilestoneIds: Set<string> | undefined
  selectedOwnerIds: Set<string> | undefined
  includeArchivedTemplates: boolean
  includeArchivedSpaces: boolean
  spaceCreatedAfter?: Date | undefined
  spaceCreatedBefore?: Date | undefined
}

export type ActionPlanAnalyticsQueryFilter = ActionPlanAnalyticsQueryBaseFilter & {
  groupingLevel: ActionPlanAnalyticsGroupingLevel
  search: string
}

export type ActionPlanTasksOverTimeQueryFilter = ActionPlanAnalyticsQueryBaseFilter & {
  search: string
}

export type ActionPlanAnalyticsCellDetailsQueryFilter = ActionPlanAnalyticsQueryBaseFilter & {
  cellDetailsConfig: CellDetailsConfig | undefined
  groupingLevel: ActionPlanAnalyticsGroupingLevel
}

export function setActionPlanAnalyticsBaseFilterParams(
  filter: ActionPlanAnalyticsQueryBaseFilter,
  params: URLSearchParams,
) {
  if (filter.spaceCreatedAfter) {
    params.set('spaceCreatedAfter', filter.spaceCreatedAfter.toISOString())
  }
  if (filter.spaceCreatedBefore) {
    params.set('spaceCreatedBefore', filter.spaceCreatedBefore.toISOString())
  }
  if (filter.selectedTemplateIds && filter.selectedTemplateIds.size > 0) {
    params.set('selectedTemplateIds', Array.from(filter.selectedTemplateIds).join(','))
  }
  if (filter.selectedActionPlanIds && filter.selectedActionPlanIds.size > 0) {
    params.set('selectedActionPlanIds', Array.from(filter.selectedActionPlanIds).join(','))
  }
  if (filter.selectedMilestoneIds && filter.selectedMilestoneIds.size > 0) {
    params.set('selectedMilestoneIds', Array.from(filter.selectedMilestoneIds).join(','))
  }
  if (filter.selectedOwnerIds && filter.selectedOwnerIds.size > 0) {
    params.set('ownerIds', Array.from(filter.selectedOwnerIds).join(','))
  }
  if (filter.includeArchivedTemplates) {
    params.set('includeArchivedTemplates', filter.includeArchivedTemplates.toString())
  }
  if (filter.includeArchivedSpaces) {
    params.set('includeArchivedSpaces', filter.includeArchivedSpaces.toString())
  }
}

/**
 * Queries for action plan analytics
 */
export function useActionPlanAnalyticsQuery({
  filter,
}: {
  filter: ActionPlanAnalyticsQueryFilter
}) {
  return useQuery({
    staleTime: 0,
    queryKey: [
      'taskAnalytics',
      Array.from(filter.selectedTemplateIds || []),
      Array.from(filter.selectedActionPlanIds || []),
      Array.from(filter.selectedMilestoneIds || []),
      filter.search,
      filter.groupingLevel,
      Array.from(filter.selectedOwnerIds || []),
      filter.includeArchivedTemplates,
      filter.includeArchivedSpaces,
      filter.spaceCreatedAfter,
      filter.spaceCreatedBefore,
    ],
    queryFn: async () => {
      const params = new URLSearchParams()
      setActionPlanAnalyticsBaseFilterParams(filter, params)
      params.set('groupingLevel', filter.groupingLevel)
      if (filter.search) {
        params.set('search', filter.search)
      }
      const res = await spacesApi.get(
        `/action-plan-analytics/template-summary-table?${params.toString()}`,
      )
      return res
    },
  })
}

export function useActionPlanTasksOverTimeQuery({
  filter,
  groupBy,
  enabled,
}: {
  enabled: boolean
  groupBy: GroupBy
  filter: ActionPlanTasksOverTimeQueryFilter
}) {
  return useQuery({
    enabled,
    queryKey: [
      'tasksCompletedOverTime',
      Array.from(filter.selectedTemplateIds || []),
      Array.from(filter.selectedActionPlanIds || []),
      Array.from(filter.selectedMilestoneIds || []),
      filter.search,
      Array.from(filter.selectedOwnerIds || []),
      filter.includeArchivedTemplates,
      filter.includeArchivedSpaces,
      filter.spaceCreatedAfter,
      filter.spaceCreatedBefore,
      groupBy,
    ],
    queryFn: async () => {
      const params = new URLSearchParams()
      setActionPlanAnalyticsBaseFilterParams(filter, params)
      params.set('groupBy', groupBy)
      if (filter.search) {
        params.set('search', filter.search)
      }
      const res = await spacesApi.get(
        `/action-plan-analytics/count-of-tasks-completed-over-time?${params.toString()}`,
      )
      return res
    },
  })
}
export function useActionPlanAnalyticsCellDetailsQuery({
  filters,
}: {
  filters: ActionPlanAnalyticsCellDetailsQueryFilter
}) {
  const cellDetailsEnabled = useMemo(() => {
    return !!(filters.cellDetailsConfig?.originNodeId && filters.cellDetailsConfig?.cellType)
  }, [filters.cellDetailsConfig])
  const cellDetailsQuery = useQuery({
    staleTime: 0,
    enabled: cellDetailsEnabled,
    queryKey: [
      'cellDetails',
      Array.from(filters.selectedTemplateIds || []),
      Array.from(filters.selectedActionPlanIds || []),
      Array.from(filters.selectedMilestoneIds || []),
      Array.from(filters.selectedOwnerIds || []),
      filters.includeArchivedTemplates,
      filters.includeArchivedSpaces,
      filters.spaceCreatedAfter,
      filters.spaceCreatedBefore,
      filters.cellDetailsConfig?.cellType,
      filters.cellDetailsConfig?.originNodeId,
      filters.groupingLevel,
    ],
    queryFn: async () => {
      // For type safety; but query won't actually be enabled if it is true that neither
      // cellDetailsConfig.originNodeId nor cellDetailsConfig.cellType is defined
      if (!(filters.cellDetailsConfig?.originNodeId && filters.cellDetailsConfig?.cellType)) {
        return []
      }
      const params = new URLSearchParams()
      setActionPlanAnalyticsBaseFilterParams(filters, params)
      params.set('originNodeId', filters.cellDetailsConfig.originNodeId)
      params.set('groupingLevel', filters.groupingLevel)
      params.set('cellType', filters.cellDetailsConfig.cellType)
      const res = await spacesApi.get(
        `/action-plan-analytics/space-summary-table?${params.toString()}`,
      )
      return res.data
    },
  })
  return cellDetailsQuery
}
export type ActionPlanTaskAnalyticsRecord = {
  originNodeId: string
  title: string
  spaceCount: number
  completedCount: number
  avgCompletionDays: number | null
  lowerQuartileDays: number | null
  upperQuartileDays: number | null
}
type ActionPlanMilestoneAnalyticsRecord = {
  originNodeId: string
  title: string
  tasks: ActionPlanTaskAnalyticsRecord[]
} & Partial<Omit<ActionPlanTaskAnalyticsRecord, 'originNodeId' | 'title'>>
type ActionPlanAnalyticsRecord = {
  originNodeId: string
  title: string
  milestones: ActionPlanMilestoneAnalyticsRecord[]
} & Partial<Omit<ActionPlanTaskAnalyticsRecord, 'originNodeId' | 'title'>>
export type TemplateActionPlansAnalyticRecord = {
  templateId: string
  templateNodeId: string
  title: string
  actionPlans: ActionPlanAnalyticsRecord[]
} & Partial<Omit<ActionPlanTaskAnalyticsRecord, 'originNodeId' | 'title'>>

export type ActionPlanTasksAggregatedByTemplate = TemplateActionPlansAnalyticRecord[]

export type CellDetailsConfig = {
  title: string
  description: string
  cellType: 'space count' | 'completed' | 'days to completion' | 'quickest' | 'slowest'
  originNodeId: string
}
