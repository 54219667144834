import { useQuery, UseQueryOptions } from '@tanstack/react-query'
import { TActionPlanTasksQuery } from '@valuecase/common'
import { useSpacesApi } from '../../../spaces-api'
import { DateTime } from 'luxon'
import { useMemo } from 'react'
import { parseTasksResponse, taskQueryParamsToKey } from './useActionPlanTaskQueryHelpers'

type UseTasksProps = TActionPlanTasksQuery &
  Pick<UseQueryOptions, 'enabled' | 'staleTime' | 'keepPreviousData'>

export const defaultTaskSearchTimeRange = { months: 3 }

export const useTasks = ({
  page,
  itemsPerPage,
  sortBy,
  sortOrder,
  taskStatus,
  enabled,
  search,
  spaceIds,
  includeTaskStatusMetadata,
  keepPreviousData,
  spaceCreatedAfter,
  spaceUpdatedAfter,
  staleTime,
}: UseTasksProps) => {
  const { spacesApi } = useSpacesApi()
  const queryKey = useMemo(
    () =>
      taskQueryParamsToKey({
        page,
        itemsPerPage,
        sortBy,
        sortOrder,
        taskStatus,
        search,
        spaceIds,
        includeTaskStatusMetadata,
        spaceCreatedAfter,
        spaceUpdatedAfter,
      }),
    [
      page,
      itemsPerPage,
      sortBy,
      sortOrder,
      taskStatus,
      search,
      spaceIds,
      includeTaskStatusMetadata,
      spaceCreatedAfter,
      spaceUpdatedAfter,
    ],
  )

  return useQuery({
    queryKey,
    enabled,
    staleTime,
    keepPreviousData,
    queryFn: async () => {
      const urlParams = new URLSearchParams()
      if (taskStatus) {
        urlParams.append('status', taskStatus)
      }
      if (spaceCreatedAfter) {
        urlParams.append('spaceCreatedAfter', spaceCreatedAfter.toISOString())
      }
      if (spaceUpdatedAfter) {
        urlParams.append('spaceUpdatedAfter', spaceUpdatedAfter.toISOString())
      }
      if (page) {
        urlParams.append('page', page.toString())
      }
      if (itemsPerPage) {
        urlParams.append('itemsPerPage', itemsPerPage.toString())
      }
      if (sortBy) {
        urlParams.append('sortBy', sortBy)
      }
      if (sortOrder) {
        urlParams.append('sortOrder', sortOrder)
      }
      if (search) {
        urlParams.append('search', search)
      }
      if (spaceIds) {
        urlParams.append('spaceIds', spaceIds.join(','))
      }
      if (includeTaskStatusMetadata) {
        urlParams.append('includeTaskStatusMetadata', 'true')
      }
      const response = await spacesApi.get(`action-plan-task?${urlParams.toString()}`)
      return parseTasksResponse({ includeTaskStatusMetadata, responsePayload: response.data })
    },
  })
}

export const useUpcomingTasks = (params: Partial<UseTasksProps> = {}) => {
  const fullFilter = useMemo(
    (): UseTasksProps => ({
      page: 1,
      itemsPerPage: 10,
      sortBy: 'dueDate',
      sortOrder: 'asc',
      taskStatus: 'upcoming',
      includeTaskStatusMetadata: false,
      spaceCreatedAfter: DateTime.now().minus(defaultTaskSearchTimeRange).startOf('day').toJSDate(),
      ...params,
    }),
    [params],
  )

  return useTasks(fullFilter)
}

export const useOverdueTasks = (params: Partial<UseTasksProps> = {}) => {
  const fullFilter = useMemo(
    (): UseTasksProps => ({
      page: 1,
      itemsPerPage: 10,
      sortBy: 'dueDate',
      sortOrder: 'asc',
      taskStatus: 'overdue',
      includeTaskStatusMetadata: false,
      spaceCreatedAfter: DateTime.now().minus(defaultTaskSearchTimeRange).startOf('day').toJSDate(),
      ...params,
    }),
    [params],
  )

  return useTasks(fullFilter)
}

export const useNextUnscheduledTasks = (params: Partial<UseTasksProps> = {}) => {
  const fullFilter = useMemo(
    (): UseTasksProps => ({
      page: 1,
      itemsPerPage: 10,
      sortBy: 'actionPlanLastTaskCompletedAt',
      sortOrder: 'asc',
      taskStatus: 'nextUnscheduled',
      includeTaskStatusMetadata: false,
      spaceCreatedAfter: DateTime.now().minus(defaultTaskSearchTimeRange).startOf('day').toJSDate(),
      ...params,
    }),
    [params],
  )

  return useTasks(fullFilter)
}
