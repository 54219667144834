import { Placement } from '@floating-ui/react-dom-interactions'
import { FC, ReactNode } from 'react'
import { AutoDropdown } from '../AutoDropdown/AutoDropdown'
import { MenuDropdownCard } from './MenuDropdownCard'

interface Props {
  Trigger: ReactNode
  children?: ReactNode
  placement?: Placement
  onOpenStateChange?: (isOpen: boolean) => void
  disabled?: boolean
  onTriggerClick?: (isOpen: boolean) => void
}

/**
 * @deprecated use DropdownMenu or Select instead
 */
export const MenuDropdown: FC<Props> = ({
  Trigger,
  children,
  placement,
  onOpenStateChange,
  disabled,
  onTriggerClick,
}) => {
  return (
    <AutoDropdown
      trigger={Trigger}
      placements={placement ? [placement] : undefined}
      onChange={onOpenStateChange}
      disabled={!!disabled}
      onTriggerClick={onTriggerClick}
    >
      <MenuDropdownCard>{children}</MenuDropdownCard>
    </AutoDropdown>
  )
}
