import toast, { ToastOptions } from 'react-hot-toast'
import { createLogger } from '../../utils/Logger'
import { IonIcon } from '../../Icons/IonIcon'
import { ReactNode, useCallback } from 'react'
import { cn } from '../../lib'

const Notification = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <div
      className={cn(
        'flex gap-2 m-h-8 items-center justify-between shadow-lg rounded-md bg-white',
        'py-2 px-4 text-xs font-medium transition-opacity duration-200',
        className,
      )}
    >
      {children}
    </div>
  )
}

export function useNotifications() {
  const success = useCallback((message?: string, customOptions?: ToastOptions) => {
    toast.custom(
      (t) => (
        <Notification>
          <IonIcon name='checkmark-circle-outline' className={'text-success-s5'} />
          {message ?? 'Action succeeded.'}
        </Notification>
      ),
      {
        duration: 2500,
        position: 'bottom-right',
        ...customOptions,
      },
    )
  }, [])

  const error = useCallback(
    (message?: string, customOptions?: ToastOptions, hasBeenLogged = false) => {
      if (!hasBeenLogged && message) {
        const logger = createLogger()
        logger.warn(message)
      }
      toast.custom(
        (t) => (
          <Notification>
            <IonIcon name='alert-circle-outline' className={'text-warning-s5'} />
            {message ?? 'Action had error.'}
          </Notification>
        ),
        {
          duration: 6000,
          position: 'bottom-right',
          ...customOptions,
        },
      )
    },
    [],
  )

  return {
    success,
    error,
  }
}
