import styled from 'styled-components'
const Spaces = styled.div`
  flex-grow: 1;
  width: 100%;
  display: block;
`

const SpacesTableWrapper = styled.div`
  padding-bottom: 24px;
`

export const S = { Spaces, SpacesTableWrapper }
