import { useQuery } from '@tanstack/react-query'
import SellerApi from '../../../api/SellerApi'
import { GetAvailableSpaceOwnersApiResponse } from '@valuecase/seller-backend'

export type SpaceOwner = GetAvailableSpaceOwnersApiResponse[number]

const readAvailableSpaceOwners = async (): Promise<GetAvailableSpaceOwnersApiResponse> => {
  const wrappedInSuccessEnvelope = await SellerApi.get<
    GetAvailableSpaceOwnersApiResponse,
    undefined
  >('current-tenant/available-space-owners')
  return wrappedInSuccessEnvelope.payload
}

export function useReadAvailableSpaceOwners() {
  const query = useQuery({
    queryKey: ['available-space-owners'],
    queryFn: readAvailableSpaceOwners,
  })

  return {
    isLoading: query.isLoading,
    isReloading: query.isRefetching && query.isStale,
    isError: query.isError,
    error: query.error,
    availableSpaceOwners: query.data,
    isFetched: query.isFetched,
  }
}
