import { useQuery } from '@tanstack/react-query'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'
import { CreateSpaceIntegrationIdMappingInput } from './types'

type GetSpaceIntegrationIdMappingsQueryInput = {
  spaceId?: string
}

/**
 * Retrieves the space integration id mappings.
 */
export async function getSpaceIntegrationIdMappings({
  spaceId,
}: GetSpaceIntegrationIdMappingsQueryInput): Promise<CreateSpaceIntegrationIdMappingInput[]> {
  if (!spaceId) {
    return []
  }
  const response = await spacesApi.get(
    `/spaces/${encodeURIComponent(spaceId)}/integration-id-mappings`,
  )
  return response.data as CreateSpaceIntegrationIdMappingInput[]
}

export function useReadSpaceIntegrationIdMappings(spaceId?: string) {
  const query = useQuery<CreateSpaceIntegrationIdMappingInput[], Error>({
    queryKey: ['spaceIntegrationIdMappings', spaceId],
    queryFn: () => getSpaceIntegrationIdMappings({ spaceId }),
  })

  return {
    status: query.status,
    isLoading: query.isLoading,
    isSuccess: query.isSuccess,
    isError: query.isError,
    error: query.error,
    data: query.data,
  }
}
