import { TeamUnion } from '@valuecase/common'
import { useCallback, useEffect, useState } from 'react'

const useSelectedTeams = (initialTeams: TeamUnion[] = []) => {
  const [selectedTeams, setSelectedTeams] = useState<Set<TeamUnion>>(new Set(initialTeams))

  useEffect(() => {
    setSelectedTeams(new Set(initialTeams))
  }, [initialTeams])

  const toggleTeam = useCallback((team: TeamUnion) => {
    setSelectedTeams((prevSet) => {
      const newSet = new Set(prevSet)
      if (newSet.has(team)) {
        newSet.delete(team)
      } else {
        newSet.add(team)
      }
      return newSet
    })
  }, [])

  const resetSelectedTeams = useCallback(() => {
    setSelectedTeams(new Set([]))
  }, [])

  return { selectedTeams, toggleTeam, resetSelectedTeams }
}

export default useSelectedTeams
