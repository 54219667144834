import { Button } from '@valuecase/ui-components'
import { useCallback, useEffect, useState } from 'react'
import { useAuthState } from '../../auth/auth'
import { SettingsStack } from '../../ui-components/SettingsStack/SettingsStack'
import SlackIcon from './components/assets/slack-icon.svg'
import HubSpotIcon from './components/assets/hubspot-icon.svg'
import SalesforceIcon from './components/assets/salesforce-icon.svg'
import AssistantIcon from './components/assets/assistant-icon.svg'
import TeamsIcon from './components/assets/msteams-icon.svg'
import { SettingsStackRowProps } from '../../ui-components/SettingsStack/SettingsStackRow'
import styled from 'styled-components'
import SellerApi from '../../api/SellerApi'
import { useReadHubSpotIntegrationStatus } from './hubspot/useReadHubSpotIntegrationStatus'
import { useDeleteHubSpotIntegration } from './hubspot/useDeleteHubSpotIntegration'
import { useReadSalesforceIntegrationStatus } from './salesforce/useReadSalesforceIntegrationStatus'
import { useDeleteSalesforceIntegration } from './salesforce/useDeleteSalesforceIntegration'
import { useNotifications } from '../../utils/Notifications/Notifications'
import { useLDflags } from '../../launchdarkly/Launchdarkly'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import ConfirmDeleteModal from '../../ui-components/ConfirmDeleteModal'

const IntegrationsWrapper = styled.div`
  .integration-icon {
    min-width: 56px;
  }

  .integration-icon-hubspot {
    background-color: white;
    display: flex;
    justify-content: center;

    svg {
      padding-left: 4px;
      padding-right: 4px;
    }
  }
`

const Integrations = () => {
  const { trackEvent } = useTrackEvent()

  const auth = useAuthState()
  const { error, success } = useNotifications()
  const { flags } = useLDflags()
  const [mixpanel_pageVisitedTracked, set_mixpanel_pageVisitedTracked] = useState(false)

  // HubSpot integration hooks
  const { hubSpotIntegrationStatus } = useReadHubSpotIntegrationStatus()
  const { deleteHubSpotIntegrationAsync, isLoading: isDisconnectingHubSpot } =
    useDeleteHubSpotIntegration()

  // Salesforce integration hooks
  const { salesforceIntegrationStatus } = useReadSalesforceIntegrationStatus()
  const { deleteSalesforceIntegrationAsync, isLoading: isDisconnectingSalesforce } =
    useDeleteSalesforceIntegration()
  const [showDeleteSalesforceModal, setShowDeleteSalesforceModal] = useState(false)
  const [isDeletingSalesforce, setIsDeletingSalesforce] = useState(false)
  // TODO - possible to refactor to use common code with HubSpot, do this when future integrations
  // are added
  const [showDeleteHubSpotModal, setShowDeleteHubSpotModal] = useState(false)
  const [isDeletingHubSpot, setIsDeletingHubSpot] = useState(false)

  const installSlack = useCallback(() => {
    if (!auth.authorized) {
      return
    }
    const sub = auth.sub.startsWith('bubble|') ? auth.sub.substring(7) : auth.sub
    const tenantId = auth.tenant
    if (!import.meta.env.VITE_NOTIFICATION_SERVICE_URL) {
      throw Error('NOTIFICATION_SERVICE_URL is undefined')
    }
    window.open(
      `${
        import.meta.env.VITE_NOTIFICATION_SERVICE_URL
      }/slack/install?userId=${sub}&tenantId=${tenantId}`,
      '_blank',
    )
  }, [auth])

  const installHubSpot = useCallback(() => {
    async function triggerHubspotInstall() {
      try {
        const res = await SellerApi.post<{ connectUrl: string }, undefined, undefined>(
          'integrations/hubspot/auth-trigger',
          undefined,
        )
        window.location.href = res.payload.connectUrl
      } catch (err) {
        console.error(err)
        error('Could not install HubSpot')
      }
    }
    triggerHubspotInstall()
  }, [error])

  const installSalesforce = useCallback(() => {
    async function triggerSalesforceInstall() {
      try {
        const res = await SellerApi.post<{ connectUrl: string }, undefined, undefined>(
          'integrations/salesforce/auth-trigger',
          undefined,
        )
        window.location.href = res.payload.connectUrl
      } catch (err) {
        console.error(err)
        error('Could not install Salesforce')
      }
    }
    triggerSalesforceInstall()
  }, [error])

  const connectTeamsAccount = useCallback(
    (channelId: string, userId: string, serviceUrl: string) => {
      async function triggerTeamsInstall() {
        try {
          const res = await SellerApi.post<
            { connectUrl: string },
            { teamsChannelId: string; teamsUserId: string; teamsServiceUrl: string },
            undefined
          >('integrations/msteams/connect', {
            teamsChannelId: channelId,
            teamsUserId: userId,
            teamsServiceUrl: serviceUrl,
          })
        } catch (err) {
          console.error(err)
          error('Could not store Microsoft Teams credentials')
        }
      }
      triggerTeamsInstall()
    },
    [error],
  )

  const handleAddToChrome = useCallback(() => {
    window.open(
      'https://chromewebstore.google.com/detail/valuecase-assistant/iappacfejcfelnlnjfafgcinkfgnhkdm',
      '_blank',
    )
  }, [])

  const slackData: SettingsStackRowProps = {
    title: 'Slack',
    description:
      'Stay up to date – Connect your slack workspace to receive real-time notifications when your stakeholders interact with your spaces.',
    rightChild: (
      <Button
        label='Connect'
        iconPosition='right'
        icon='link-outline'
        onClick={() => {
          trackEvent({
            event: 'integrations-slack-click',
            eventProperties: {
              indexPageName: 'Integrations',
            },
          })
          installSlack()
        }}
      />
    ),
    image: SlackIcon,
  }

  const teamsData: SettingsStackRowProps = {
    title: 'Microsoft Teams',
    description:
      'Get real-time notifications on buyer activity in MS Teams. Note: MS Teams needs to be activated by an Admin for your organisation, before you can start using it. Please contact <a href="mailto:lennart@valuecase.de?subject=Please send me the details to activate Valuecase in MS Teams.">lennart@valuecase.de</a> to obtain the details required for activation.',
    rightChild: (
      <Button
        label='Connect'
        iconPosition='right'
        icon='link-outline'
        onClick={() => {
          trackEvent({
            event: 'integrations-msteams-click',
            eventProperties: {
              indexPageName: 'Integrations',
            },
          })
          window.open('https://teams.microsoft.com/_#/apps', '_blank')
        }}
      />
    ),
    image: TeamsIcon,
  }

  const hubspotData: SettingsStackRowProps = {
    title: 'HubSpot',
    description:
      'Simplify your workflow – create spaces directly from within Hubspot. Save time with automatic syncing of key deal properties such as Name, Company Name, and Company Website.',
    rightChild: (
      <>
        {hubSpotIntegrationStatus === 'disabled' && (
          <Button
            label='Connect'
            iconPosition='right'
            icon='link-outline'
            onClick={() => {
              trackEvent({
                event: 'integrations-hubspot-click',
                eventProperties: {
                  indexPageName: 'Integrations',
                },
              })
              installHubSpot()
            }}
          />
        )}
        {hubSpotIntegrationStatus === 'enabled' && (
          <Button
            label='Connected'
            style='success'
            onClick={() => setShowDeleteHubSpotModal(true)}
            disabled={isDisconnectingHubSpot}
          />
        )}
      </>
    ),
    image: HubSpotIcon,
  }

  const salesforceComponent: SettingsStackRowProps = {
    title: 'Salesforce',
    description: 'Create spaces for your Salesforce leads - directly from Valuecase.',
    rightChild: (
      <>
        {salesforceIntegrationStatus === 'disabled' && (
          <Button
            label='Connect'
            iconPosition='right'
            icon='link-outline'
            onClick={() => {
              trackEvent({
                event: 'integrations-salesforce-click',
                eventProperties: {
                  indexPageName: 'Integrations',
                },
              })
              installSalesforce()
            }}
          />
        )}
        {salesforceIntegrationStatus === 'enabled' && (
          <Button
            label='Connected'
            style='success'
            onClick={() => setShowDeleteSalesforceModal(true)}
            disabled={isDisconnectingSalesforce}
          />
        )}
      </>
    ),
    image: SalesforceIcon,
  }

  const chromeExtensionComponent: SettingsStackRowProps = {
    title: 'Valuecase Assistant - Chrome Extension',
    description:
      'The Valuecase Chrome Extension allows you to create and manage spaces from any website you visit in your Chrome browser via a sidepanel. ',
    rightChild: (
      <>
        <Button label='Add to Chrome' onClick={handleAddToChrome} />
      </>
    ),
    image: AssistantIcon,
  }

  const data = [hubspotData]
  if (flags && flags['salesforce-integration']) {
    data.push(salesforceComponent)
  }
  if (import.meta.env.VITE_NOTIFICATION_SERVICE_URL) {
    data.push(slackData)
  }
  data.push(teamsData)
  data.push(chromeExtensionComponent)

  // If we are installing MS Teams - store credentials + show success message
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)

    if (queryParams.has('connectionType') && queryParams.get('connectionType') === 'msteams') {
      const channelId = queryParams.get('channelId')
      const userId = queryParams.get('userId')
      const serviceUrl = queryParams.get('serviceUrl')

      if (!channelId || !userId || !serviceUrl) {
        error('Could not set up Microsoft Teams integration')
        return
      }

      queryParams.delete('connectionType')
      queryParams.delete('channelId')
      queryParams.delete('userId')
      queryParams.delete('serviceUrl')
      const updatedUrl = new URL(window.location.href)
      updatedUrl.search = queryParams.toString()
      window.history.replaceState(null, '', updatedUrl.href)

      connectTeamsAccount(channelId, userId, serviceUrl)

      success('Your Microsoft Teams integration is now set up')
    }
  }, [success, error, connectTeamsAccount])

  //mixpanel track  page visit
  useEffect(() => {
    if (!mixpanel_pageVisitedTracked) {
      trackEvent({
        event: 'dashboard-visit',
        eventProperties: {
          indexPageName: 'Integrations page',
        },
      }).finally(() => {
        set_mixpanel_pageVisitedTracked(true)
      })
    }
  }, [mixpanel_pageVisitedTracked, trackEvent, set_mixpanel_pageVisitedTracked])

  return (
    <IntegrationsWrapper className={'flex-grow'}>
      <SettingsStack
        data={data}
        title={'Integrations'}
        description={
          'Optimize your productivity by seamlessly connecting Valuecase with your favorite tools. Looking for another integration? Let us know!'
        }
      />
      <ConfirmDeleteModal
        isShown={showDeleteSalesforceModal}
        onClose={() => setShowDeleteSalesforceModal(false)}
        confirmationMessage={
          <p className={'text-sm text-grey-s5 text-center'}>
            Are you sure that you want to disconnect the <b>Salesforce</b> integration? You will not
            be able to create spaces connected to Salesforce or receive any data.
          </p>
        }
        title='Disconnect integration'
        onConfirm={async () => {
          setIsDeletingSalesforce(true)
          await deleteSalesforceIntegrationAsync()
          setShowDeleteSalesforceModal(false)
        }}
        confirmButtonText='Yes, disconnect'
        showLoader={isDeletingSalesforce}
        onExit={() => {
          // Bit of side-effect here, but it prevents flash of modal content when closing
          setIsDeletingSalesforce(false)
        }}
      />
      <ConfirmDeleteModal
        isShown={showDeleteHubSpotModal}
        onClose={() => setShowDeleteHubSpotModal(false)}
        confirmationMessage={
          <p className={'text-sm text-grey-s5 text-center'}>
            Are you sure that you want to disconnect the <b>HubSpot</b> integration? You will not be
            able to create spaces connected to HubSpot or receive any data.
          </p>
        }
        title='Disconnect integration'
        onConfirm={async () => {
          setIsDeletingHubSpot(true)
          await deleteHubSpotIntegrationAsync()
          setShowDeleteHubSpotModal(false)
        }}
        confirmButtonText='Yes, disconnect'
        showLoader={isDeletingHubSpot}
        onExit={() => {
          // Bit of side-effect here, but it prevents flash of modal content when closing
          setIsDeletingHubSpot(false)
        }}
      />
    </IntegrationsWrapper>
  )
}

export default Integrations
