import SellerApi from '@/api/SellerApi'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CreateTeamWithMembersPayload, teamWithMembersSchema } from '@valuecase/common'

const createTeam = async (team: CreateTeamWithMembersPayload) => {
  const res = await SellerApi.post(`teams`, team)
  return teamWithMembersSchema.parse(res.payload)
}

const useCreateTeam = () => {
  const queryClient = useQueryClient()

  return useMutation((team: CreateTeamWithMembersPayload) => createTeam(team), {
    // onMutate: async (newTeam) => {
    //   // Cancel any outgoing refetches (so they don't overwrite optimistic update)
    //   await queryClient.cancelQueries(['teams'])

    //   // Snapshot the previous value
    //   const previousTeams = queryClient.getQueryData<TeamWithMembers[]>(['teams'])

    //   // Optimistically update to the new value
    //   if (previousTeams) {
    //     queryClient.setQueryData<TeamWithMembers[]>(
    //       ['teams'],
    //       [...previousTeams, { ...newTeam, id: 'temp-id' }],
    //     )
    //   }

    //   return { previousTeams }
    // },
    // onError: (error, newTeam, context) => {
    //   queryClient.setQueryData(['teams'], context?.previousTeams)
    // },
    onSuccess: () => {
      queryClient.invalidateQueries(['teams'])
    },
  })
}

export default useCreateTeam
