import styled from 'styled-components'

const TopbarRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  margin-right: 32px;
`
const UserInfoContainer = styled.div`
  width: fit-content;
  display: flex;
  gap: 12px;
  align-items: center;
  height: 56px;
  min-height: 56px;
  padding-left: 8.83px;
  padding-right: 8.83px;
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.grey.s1};
    border-radius: 8px;
  }
`

const ProfileImage = styled.div<{ $imgUrl: string }>`
  display: inline-block;
  width: 39.17px;
  height: 39.17px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url(${({ $imgUrl: imgUrl }) => imgUrl});
  border: 1px solid var(--theme-grey-s2);
`

const NameAndRoleContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
`
const Name = styled.div`
  height: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  color: ${({ theme }) => theme.grey.s6};
`
const Role = styled.div`
  height: 19px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: ${({ theme }) => theme.grey.s5};
`

const DropdownTrigger = styled.div`
  cursor: pointer;
`
export const S = {
  TopbarRightSide,
  ProfileImage,
  NameAndRoleContainer,
  Name,
  Role,
  UserInfoContainer,
  DropdownTrigger,
}
