import { Button } from '../../Button'
import React from 'react'
import styled from 'styled-components'

export interface SimpleModalHeaderProps {
  showBack: boolean
  hideClose: boolean
  onBack: () => void
  onClose: () => void
  children?: React.ReactNode
}

export default function SimpleModalHeaderProps({
  showBack,
  hideClose,
  onBack,
  onClose,
  children,
}: SimpleModalHeaderProps) {
  return (
    <>
      {(showBack || !hideClose) && (
        <HeaderButtonsRow>
          {showBack ? (
            <Button icon='arrow-back' size={'medium'} style={'flat'} onClick={onBack} />
          ) : (
            <span />
          )}

          {!hideClose ? (
            <Button icon='close' size={'medium'} style={'flat'} onClick={onClose} />
          ) : (
            <span />
          )}
        </HeaderButtonsRow>
      )}

      {children}
    </>
  )
}

const HeaderButtonsRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: -8px -8px 0px -8px;
`
