import { useMemo, useState, useCallback } from 'react'
import { SpacesQueryFilters } from './useSpaceFiltering'

const useAdditionalFilters = (columnIds: string[], columnFilters: SpacesQueryFilters) => {
  const activeAdditionalFilters = useMemo(() => {
    return Object.keys(columnFilters).filter((filterKey) => {
      const filterValue = columnFilters[filterKey]
      return (
        columnIds.includes(filterKey) &&
        filterValue !== null &&
        !(Array.isArray(filterValue) && filterValue.length === 0)
      )
    })
  }, [columnFilters, columnIds])

  const [openedFilter, setOpenedFilter] = useState<string | null>(null)

  const handleFilterDropdownOpenChange = useCallback((columnId: string | null, opened: boolean) => {
    if (opened) {
      setOpenedFilter(columnId)
    } else {
      setOpenedFilter(null)
    }
  }, [])

  const filterDropdownOpen = useCallback(
    (columnId: string) => {
      return openedFilter === columnId
    },
    [openedFilter],
  )

  const filterActive = useCallback(
    (columnId: string) => {
      return activeAdditionalFilters.includes(columnId) || openedFilter === columnId
    },
    [activeAdditionalFilters, openedFilter],
  )

  const anyFilterActive = useMemo(() => {
    return activeAdditionalFilters.length > 0 || openedFilter !== null
  }, [activeAdditionalFilters, openedFilter])

  const visibleAdditionalFilters = useMemo(() => {
    if (openedFilter && !activeAdditionalFilters.includes(openedFilter)) {
      return [...activeAdditionalFilters, openedFilter]
    } else {
      return activeAdditionalFilters
    }
  }, [activeAdditionalFilters, openedFilter])

  const allFiltersVisible = useMemo(() => {
    return visibleAdditionalFilters.length === columnIds.length
  }, [columnIds, visibleAdditionalFilters])

  return {
    handleFilterDropdownOpenChange,
    filterDropdownOpen,
    filterActive,
    anyFilterActive,
    visibleAdditionalFilters,
    allFiltersVisible,
  }
}

export default useAdditionalFilters
