export interface ExtractInitialsOptions {
  max?: number
  fallback?: string
  transformCase?: 'upper' | 'lower' | 'preserve'
}

export default function extractInitials(
  text: string,
  { max = 2, fallback = '?', transformCase = 'upper' }: ExtractInitialsOptions,
): string {
  const trimmedText = (text ?? '').trim().replace(/[^\w\s]|\d/g, '')

  // computing the initials
  const chosenInitials = []

  if (trimmedText.includes(' ')) {
    // take first letters of each token
    const firstLetters = trimmedText
      .split(' ')
      .filter((p) => p.trim().length > 0)
      .map((p) => p.substring(0, 1))

    chosenInitials.push(firstLetters[0])

    // if there is a second token, take the first letter of it
    const desiredRestAmount = max - 1

    if (desiredRestAmount <= firstLetters.length - 1) {
      for (let i = firstLetters.length - desiredRestAmount; i < firstLetters.length; i++) {
        chosenInitials.push(firstLetters[i])
      }
    } else {
      for (let i = 1; i < firstLetters.length; i++) {
        chosenInitials.push(firstLetters[i])
      }
    }
  } else if (trimmedText.length > 0) {
    // take n letters of only token present
    for (let i = 0; i < Math.min(trimmedText.length, max); i++) {
      chosenInitials.push(trimmedText[i])
    }
  } else {
    // use fallback
    chosenInitials.push(fallback)
  }

  let initialsText = chosenInitials.join('')
  if (transformCase === 'upper') initialsText = initialsText.toUpperCase()
  else if (transformCase === 'lower') initialsText = initialsText.toLowerCase()

  return initialsText
}
