import React, { useContext, useEffect, useRef, useState } from 'react'
import { BasePortal } from '../Portal/BasePortal'
import { OverlayWrapper } from './Overlay.styled'
import classNames from 'classnames'

export interface OverlayProps {
  shown: boolean
  children?: React.ReactNode
}

export interface OverlayContext {
  show: (children: React.ReactNode, clearStack?: boolean) => void
  hide: () => void
}

const OverlayReactContext = React.createContext<OverlayContext>({
  show: () => undefined,
  hide: () => undefined,
})

/**
 * @deprecated use Dialog
 */
export function useOverlay() {
  return useContext(OverlayReactContext)
}

type OverlayState =
  | 'hidden'
  | 'before-entering'
  | 'entering'
  | 'shown'
  | 'before-leaving'
  | 'leaving'

const visibleStates: OverlayState[] = ['entering', 'shown']

const beforeDuration = 25
const animationDuration = 200

export function OverlayProvider({ children }: { children?: React.ReactNode }) {
  const [shown, setShown] = useState(false)
  const [state, setState] = useState<OverlayState>('hidden')
  const [nextContent, setNextContent] = useState<React.ReactNode | null>(null)
  const [portalContent, setPortalContent] = useState<React.ReactNode | null>(null)
  const [contentStack, setContentStack] = useState<React.ReactNode[]>([])

  // dirty == true means, there is new content to be displayed, but not yet done
  const [dirty, setDirty] = useState(false)

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const cancelScheduled = useRef<() => void>(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
      timeoutRef.current = null
    }
  })

  const scheduleState = useRef<(state: OverlayState, timeout: number) => void>(
    (state: OverlayState, timeout: number) => {
      cancelScheduled.current()

      timeoutRef.current = setTimeout(() => {
        setState(state)
      }, timeout)
    },
  )

  // Trigger next enter steps
  useEffect(() => {
    // Fade out part
    if (!shown || dirty) {
      if (state === 'shown') {
        // This just saves some time, as we don't use the "before-leaving" state really
        setState('leaving')

        // setState('before-leaving')
      }

      if (state === 'before-leaving') {
        // This just saves some time, as we don't use the "before-leaving" state really
        setState('leaving')

        // scheduleState.current('leaving', beforeDuration)
      }

      if (state === 'leaving') {
        scheduleState.current('hidden', animationDuration)
      }

      if (state === 'hidden') {
        // let the "Fade in part" start again
        setDirty(false)
      }

      if (state === 'before-entering') {
        setState('hidden')
      }

      if (state === 'entering') {
        setState('leaving')
      }
    } else if (shown) {
      // Fade in part
      if (state === 'hidden') {
        setState('before-entering')
        setPortalContent(nextContent)
      }

      if (state === 'before-entering') {
        scheduleState.current('entering', beforeDuration)
      }

      if (state === 'entering') {
        scheduleState.current('shown', animationDuration)
      }

      if (state === 'before-leaving') {
        setState('shown')
      }

      if (state === 'leaving') {
        setState('entering')
      }
    }
  }, [state, shown, nextContent, portalContent, dirty])

  function show(children: React.ReactNode) {
    setNextContent(children)
    setShown(true)

    setContentStack((old) => [...old, children])

    // If something is already shown -> mark the content as dirty
    if (shown && state !== 'hidden') {
      setDirty(true)
    }
  }

  function hide() {
    if (shown) {
      if (contentStack.length <= 1) {
        setShown(false)
        setContentStack([])
      } else {
        const newContentStack = contentStack.slice(0, contentStack.length - 1)
        setNextContent(newContentStack[newContentStack.length - 1])
        setContentStack(newContentStack)
        setDirty(true)
      }
    }
  }

  return (
    <OverlayReactContext.Provider value={{ show, hide }}>
      <BasePortal portalId={'overlay'}>
        {state !== 'hidden' && (
          <OverlayWrapper
            className={classNames({
              visible: visibleStates.includes(state),
            })}
          >
            {portalContent}
          </OverlayWrapper>
        )}
      </BasePortal>

      {children}
    </OverlayReactContext.Provider>
  )
}
