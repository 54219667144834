import { useQuery } from '@tanstack/react-query'
import { userProfileApi } from '../userProfileApi'

async function readUserProfileFn() {
  const res = await userProfileApi.getUserProfile()
  if (!res.success) {
    throw new Error('Could not read')
  }
  return res.payload
}

export function useReadUserProfile() {
  const query = useQuery({
    queryKey: ['readUserProfile'],
    queryFn: readUserProfileFn,
  })
  return {
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
    userProfile: query.data,
    isFetched: query.isFetched,
  }
}
