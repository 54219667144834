import { forwardRef } from 'react'

interface Props {
  handleChange?: (value: FileList) => void
  accept?: string // which file type is allowed to be selected
  multiple?: boolean // allow user to select multiple files
}

export type FileUploadRef = {} & HTMLInputElement

export const FileUpload = forwardRef<FileUploadRef, Props>(
  ({ handleChange, accept = 'image/*', multiple = false }, ref) => {
    return (
      <input
        disabled={handleChange === undefined}
        ref={ref}
        type='file'
        accept={accept}
        multiple={multiple}
        style={{ display: 'none' }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (e.target.files && e.target.files[0] && handleChange) {
            handleChange(e.target.files)
          }
        }}
      />
    )
  },
)

FileUpload.displayName = 'FileUpload'
