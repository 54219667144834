import { LottieShowOnceOverProps } from './Lottie.types'

export function createDisposableAnimationElement(
  x: number,
  y: number,
  width: number,
  height: number,
): HTMLDivElement {
  const element = document.createElement('div')
  element.style.position = 'absolute'
  element.style.left = `${x}px`
  element.style.top = `${y}px`
  element.style.width = `${width}px`
  element.style.height = `${height}px`
  return element
}

export interface LottieAnimationRectangle {
  x: number
  y: number
  width: number
  height: number
}

export function computeAnimationRectangle(
  reference: HTMLElement,
  { width, height, vAlign = 'center', hAlign = 'center' }: LottieShowOnceOverProps,
): LottieAnimationRectangle {
  const rect = reference.getBoundingClientRect()

  let x = rect.x
  if (hAlign === 'center') {
    x += rect.width / 2 - width / 2
  } else if (hAlign === 'end') {
    x += rect.width - width
  }

  let y = rect.y
  if (vAlign === 'center') {
    y += rect.height / 2 - height / 2
  } else if (vAlign === 'end') {
    y += rect.height - height
  }

  return {
    x,
    y,
    width,
    height,
  }
}
