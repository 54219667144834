import { useMemo } from 'react'
import { IWhitelistedUser } from '@valuecase/common'

export const notificationLabels = {
  allComments: 'Comments',
  dueToday: 'Task due reminder',
  milestoneDone: 'Milestone completed',
  taskAssigned: 'Task assigned',
}

export type WhitelistedUserNotificationsSettings = {
  allNotificationsEnabled: boolean
  allNotificationsDisabled: boolean
  someNotificationsEnabled: boolean
}

/**
 * This hook returns the notification status based on the user's notification settings
 * @param settings WhitelistedUserNotificationsSettings
 * @returns notification status
 */
export const useNotificationStatus = (
  settings: IWhitelistedUser['WhitelistedUserNotificationsSettings'],
): WhitelistedUserNotificationsSettings => {
  return useMemo(() => {
    if (!settings) {
      return {
        allNotificationsEnabled: false,
        allNotificationsDisabled: true,
        someNotificationsEnabled: false,
      }
    }

    const { id, whitelistedUserId, unsubscribed, ...notifications } = settings

    const notificationKeys = Object.keys(notifications)
    const activeNotifications = notificationKeys.filter(
      (key) => notifications[key as keyof typeof notifications],
    )

    const allNotificationsEnabled = activeNotifications.length === notificationKeys.length
    const allNotificationsDisabled = activeNotifications.length === 0
    const someNotificationsEnabled = !allNotificationsDisabled && !allNotificationsEnabled

    return {
      allNotificationsEnabled,
      allNotificationsDisabled,
      someNotificationsEnabled,
    }
  }, [settings])
}

export const getNotificationText = ({
  status,
  settings,
}: {
  status: WhitelistedUserNotificationsSettings
  settings: IWhitelistedUser['WhitelistedUserNotificationsSettings']
}) => {
  if (!settings) {
    return 'All notifications off'
  }
  const { allNotificationsEnabled, allNotificationsDisabled, someNotificationsEnabled } = status

  if (allNotificationsEnabled) {
    return 'All notifications on'
  } else if (allNotificationsDisabled) {
    return 'All notifications off'
  } else if (someNotificationsEnabled) {
    const { id, whitelistedUserId, ...notifications } = settings
    const activeNotifications = Object.keys(notifications)
      .filter((key) => notifications[key as keyof typeof notifications])
      .map((key) => notificationLabels[key as keyof typeof notificationLabels])

    return (
      activeNotifications[0] +
      (activeNotifications.length > 1 ? `, ${activeNotifications.slice(1).join(', ')}` : '')
    )
  }
}
