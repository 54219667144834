import { FC, useCallback, useEffect, useState } from 'react'
import { useModalControls } from '@valuecase/ui-components'
import { useUpdateTemplate } from '../hooks/useUpdateTemplate'
import TemplateEditorView, { TemplateEditorSteps } from './TemplateEditorView'
import styled from 'styled-components'
import { EditTemplateData } from './TemplateEditorModal'
import { useTrackEvent } from '../../../mixpanel/useTrackEvent'
import { useNotifications } from '@/utils/Notifications/Notifications'
import { useCurrentTenantQuery } from '@/modules/tenant/hooks/useReadTenant'
import useReadTeams from '@/modules/teams/hooks/useReadTeams'

export interface TemplateEditorProps {
  templateData: EditTemplateData
  setIsModalPersistent: React.Dispatch<React.SetStateAction<boolean>>
}

export const TemplateEditorForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`

const TemplateEditor: FC<TemplateEditorProps> = ({ setIsModalPersistent, templateData }) => {
  const { close } = useModalControls()
  const { trackEvent } = useTrackEvent()
  const [newTemplateName, setNewTemplateName] = useState<string>(templateData.title)
  const [newTemplateUsage, setNewTemplateUsage] = useState<string>(templateData.description || '')
  const { tenant } = useCurrentTenantQuery()
  const [newTemplateIcon, setNewTemplateIcon] = useState<string>(templateData.icon ?? '🗒️')
  const { success, error: errorNotification } = useNotifications()
  const [currentStep, goToStep] = useState<TemplateEditorSteps>('editInfo')
  const { data: teams } = useReadTeams()
  const [selectedTeamIds, setSelectedTeamIds] = useState<Set<string> | undefined>(
    templateData.teamIds?.length ? new Set(templateData.teamIds) : new Set(),
  )
  const { updateTemplate, error } = useUpdateTemplate({
    onSuccess: () => {
      close({ ignorePersistence: true })
      success('Template edited successfully')
    },
    onError: () => {
      setIsModalPersistent(false)
      goToStep('editInfo')
      errorNotification('Template could not be edited')
    },
  })

  const editTemplate = useCallback(() => {
    setIsModalPersistent(true)
    goToStep('loading')

    const source = 'emoji-native' as const

    updateTemplate({
      templateId: templateData.rootNodeId,
      description: newTemplateUsage,
      title: newTemplateName,
      icon: newTemplateIcon,
      teamIds: selectedTeamIds ? Array.from(selectedTeamIds) : [],
    })
  }, [
    setIsModalPersistent,
    updateTemplate,
    templateData.rootNodeId,
    newTemplateUsage,
    newTemplateName,
    newTemplateIcon,
    selectedTeamIds,
  ])

  //trigger callback when the user finishes typing in the DescriptionTextArea
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (
        //user deletes old description
        (newTemplateUsage === '' && templateData?.description !== '') ||
        //user finishes typing a new description
        (newTemplateUsage !== '' && templateData?.description !== newTemplateUsage)
      ) {
        trackEvent({
          event: 'templates-newtemplate-edit_description',
          eventProperties: {
            indexPageName: 'templates',
            isNewTemplate: false,
            templateID: templateData.id,
          },
        })
      }
    }, 500)

    return () => clearTimeout(timeout)
  }, [
    newTemplateUsage,
    setNewTemplateUsage,
    trackEvent,
    templateData?.description,
    templateData.id,
  ])

  return (
    <TemplateEditorForm
      onSubmit={(event) => {
        // Form element used for browser semantics, but submission
        // actually handled via createNewTemplate callback
        event.preventDefault()
      }}
    >
      <TemplateEditorView
        error={error}
        newTemplateName={newTemplateName}
        newTemplateUsage={newTemplateUsage}
        newTemplateIcon={newTemplateIcon}
        setNewTemplateName={setNewTemplateName}
        setNewTemplateUsage={setNewTemplateUsage}
        setNewTemplateIcon={setNewTemplateIcon}
        editTemplate={editTemplate}
        currentStep={currentStep}
        goToStep={goToStep}
        teams={teams}
        selectedTeamIds={selectedTeamIds}
        setSelectedTeamIds={setSelectedTeamIds}
        tenantName={tenant?.payload?.tenantName || ''}
      />
    </TemplateEditorForm>
  )
}

export default TemplateEditor
