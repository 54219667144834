import { S } from './TopbarRightSide.styled'
import { Button, MenuDropdown, MenuDropdownItem } from '@valuecase/ui-components'
import { FC } from 'react'
import { Auth, createAuthReturnPath } from '../../../../../auth/auth'
import { useNavigate } from '@tanstack/react-router'
import {
  generatePageNameForMixpanelBasedOnOurRoutes,
  useTrackEvent,
} from '../../../../../mixpanel/useTrackEvent'
import { useCurrentLocation } from '../../../hooks/useCurrentLocation'
import { useHasPermission } from '@/auth/permissions'
import { STORAGE_FILTERS_KEY } from '@/modules/spaces/hooks/useSpaceFiltering'

interface Props {
  imgUrl?: string
  name: string
  role?: string
}

const TopbarRightSide: FC<Props> = ({ imgUrl, name, role }) => {
  const nav = useNavigate()
  const currentLocation = useCurrentLocation()
  const { trackEvent } = useTrackEvent()
  const isSuperAdmin = useHasPermission('ALL_TENANTS_ADMIN')

  return (
    <S.TopbarRightSide
      onClick={() => {
        nav({ to: '/mySettings/profileAccount' })
      }}
    >
      <S.UserInfoContainer>
        {imgUrl !== undefined ? (
          <S.ProfileImage $imgUrl={imgUrl} />
        ) : (
          //TODO
          <>no img</>
        )}
        <S.NameAndRoleContainer>
          <S.Name>{name}</S.Name>
          <S.Role>{role}</S.Role>
        </S.NameAndRoleContainer>
      </S.UserInfoContainer>
      <S.DropdownTrigger>
        <MenuDropdown
          Trigger={<Button icon='chevron-down-outline' style='flat' />}
          placement={'bottom-start'}
        >
          <MenuDropdownItem
            onClick={() => {
              trackEvent({
                event: 'topbar-visit_profile',
                eventProperties: {
                  indexPageName: generatePageNameForMixpanelBasedOnOurRoutes(currentLocation),
                },
              })
              nav({ to: '/mySettings/profileAccount' })
            }}
            label='Go to user profile'
            LeftIcon={'person-circle-outline'}
            variant='default'
          />
          {isSuperAdmin && (
            <MenuDropdownItem
              onClick={() => nav({ to: '/tenants-admin' })}
              label='Tenants management'
              LeftIcon={'beer-outline'}
              variant='default'
            />
          )}
          <MenuDropdownItem
            onClick={() => {
              trackEvent({
                event: 'topbar-logout',
                eventProperties: {
                  indexPageName: generatePageNameForMixpanelBasedOnOurRoutes(currentLocation),
                },
              })
              // Delete all saved space filters to have a fresh start when logging in again
              localStorage.removeItem(STORAGE_FILTERS_KEY)
              Auth.logout(createAuthReturnPath())
            }}
            label='Logout'
            LeftIcon={'log-out-outline'}
            variant='danger'
          />
        </MenuDropdown>
      </S.DropdownTrigger>
    </S.TopbarRightSide>
  )
}

export default TopbarRightSide
