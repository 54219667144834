import { useTranslation } from 'react-i18next'
import { DateTime } from 'luxon'
import { formatDateShort } from '@valuecase/common'
import { useCallback } from 'react'

/**
 * Returns a function that formats a DateTime to a string like "x days ago". Uses translations.
 */
export function useFormatDateTimeDistance() {
  const { t } = useTranslation()

  /**
   * TODO use Luxon toRelative function here
   */
  const formatDateTimeDistance = useCallback(
    (date1: DateTime, date2: DateTime) => {
      if (!date1.isValid || !date2.isValid) {
        return ''
      }
      const diff = date1
        .diff(date2)
        .shiftTo('years', 'months', 'days', 'hours', 'minutes', 'seconds')
      const years = Math.floor(diff.years)
      const months = Math.floor(diff.months)
      const days = Math.floor(diff.days)
      const hours = Math.floor(diff.hours)
      const minutes = Math.floor(diff.minutes)
      const seconds = diff.seconds < 0 ? 0 : Math.floor(diff.seconds)

      if (years === 1) return t('utils.date.oneYearAgo')
      if (years > 0) return t('utils.date.xYearsAgo', { n: years })
      if (months === 1) return t('utils.date.oneMonthAgo')
      if (months > 0) return t('utils.date.xMonthsAgo', { n: months })
      if (days === 1) return t('utils.date.oneDayAgo')
      if (days > 0) return t('utils.date.xDaysAgo', { n: days })
      if (hours === 1) return t('utils.date.oneHourAgo')
      if (hours > 0) return t('utils.date.xHoursAgo', { n: hours })
      if (minutes === 1) return t('utils.date.oneMinuteAgo')
      if (minutes > 0) return t('utils.date.xMinutesAgo', { n: minutes })
      if (seconds === 1) return t('utils.date.oneSecondAgo')
      return t('utils.date.xSecondsAgo', { n: seconds })
    },
    [t],
  )

  return {
    formatDateTimeDistance,
  }
}

export function useDayDifferenceCalculator({ language }: { language?: string } = {}) {
  const { t } = useTranslation()

  const daysDiffFromNowOrFormatted = useCallback(
    ({
      to,
      locale,
      labelledRelativeDatesRange = 7,
    }: {
      to: DateTime
      locale: string | undefined
      // If -1, all dates will be shown as date stamps in given locale time, e.g. 01/01/2024
      // If 0, "Today" will get a label, all other dates will be shown as datestamps in given
      //   locale
      // If 1, "Today", "Tomorrow", and "Yesterday" will get labels, all other dates will be shown
      //   as datestamps in given locale
      // If 2 or more, "Today", "Tomorrow", "Yesterday", "In X days", and "X days ago" will get
      //   labels up to the value of the labelledRelativeDatesRange. Dates beyond this range will be
      //   shown as datestamps in given locale.
      labelledRelativeDatesRange?: number
    }) => {
      // Set the 'to' date to the local timezone, then truncate to the start of the day (midnight)
      const incomingLocalDate = to
        .setZone(DateTime.local().zoneName, { keepLocalTime: true })
        .startOf('day')

      // Get the current local date, truncated to the start of the day (midnight)
      const localMidnight = DateTime.now().setZone(DateTime.local().zoneName).startOf('day')

      const daysBetween = incomingLocalDate.diff(localMidnight, 'days').days
      const daysBetweenAbs = Math.abs(daysBetween)
      let formatted = ''

      if (daysBetweenAbs > labelledRelativeDatesRange) {
        formatted = formatDateShort(to, locale)
      } else if (daysBetween > 0) {
        if (daysBetween > 1) {
          formatted = t('utils.date.inXDays', { n: Math.abs(daysBetweenAbs), lng: language })
        } else {
          formatted = t('utils.date.tomorrow', language ? { lng: language } : undefined)
        }
      } else if (daysBetween < 0) {
        if (daysBetween < -1) {
          formatted = t('utils.date.xDaysAgo', { n: Math.abs(daysBetweenAbs), lng: language })
        } else {
          formatted = t('utils.date.yesterday', language ? { lng: language } : undefined)
        }
      } else {
        formatted = t('utils.date.today', language ? { lng: language } : undefined)
      }

      return {
        days: daysBetween,
        message: formatted,
      }
    },
    [language, t],
  )

  return {
    daysDiffFromNowOrFormatted,
  }
}

export function convertLocalToUTCDate(date: Date | string | null) {
  if (!date) {
    return null
  }
  date = new Date(date)
  if (date.toString() === 'Invalid Date') {
    console.warn('Invalid date when converting local to utc date', date)
    return null
  }
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  return date
}

export function convertUTCToLocalDate(date: Date | null) {
  if (!date) {
    return date
  }
  if (date.toString() === 'Invalid Date') {
    console.warn('Invalid date when converting local to utc date', date)
    return null
  }
  date = new Date(date)
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  return date
}
