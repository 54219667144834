import styled from 'styled-components'

import { placeholderBackgroundColor } from '../wireframeStyles/wireframeStyleValues'

export const ProfileSummaryLayout = styled.div`
  display: flex;
  margin-top: 32px;
  margin-bottom: 41px;
  gap: 24px;
  align-items: center;
  img {
    height: 96px;
    width: 96px;
  }
  h4 {
    font-weight: 650;
    font-size: 14px;
    line-height: 145%;
  }
`

export const ProfileImageWrapper = styled.div<{ $profilePhotoUrl?: string }>`
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${placeholderBackgroundColor};
  background-image: ${(props) =>
    props.$profilePhotoUrl ? `url(${props.$profilePhotoUrl})` : undefined};
  background-size: cover;
`

export const ProfileDetails = styled.div`
  display: grid;
  max-width: 50em;
  grid-template-columns: max-content 1fr;
  row-gap: 1em;
  column-gap: 2em;
  & > div:nth-child(odd) {
    font-weight: 600;
    line-height: 150%;
    color: var(--theme-grey-s4);
  }
  & > div:nth-child(even) {
    font-weight: 500;
    font-size: 14px;
    line-height: 145%;
    color: var(--theme-grey-s6);
  }
`
