export type InputIconPosition = 'start' | 'end'

export enum InputSize {
  LARGE = 'large',
  // todo: rename to medium
  MEDIUM = 'normal',
  SMALL = 'small',
}
export type InputPopupRenderFunction = (
  props: InputPopupRenderFunctionProps,
) => React.ReactElement | undefined

export interface InputPopupRenderFunctionProps {
  value: string
  focussed: boolean
  inputWidth: number
}
