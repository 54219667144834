import { CommonTranslations } from '../CommonTranslations'

const en: CommonTranslations = {
  root: {
    utils: {
      date: {
        inXDays: 'In {{n}} days',
        tomorrow: 'Tomorrow',
        today: 'Today',
        yesterday: 'Yesterday',
        xYearsAgo: '{{n}} years ago',
        oneYearAgo: '1 year ago',
        xMonthsAgo: '{{n}} months ago',
        oneMonthAgo: '1 month ago',
        xDaysAgo: '{{n}} days ago',
        oneDayAgo: '1 day ago',
        xHoursAgo: '{{n}} hours ago',
        oneHourAgo: '1 hour ago',
        xMinutesAgo: '{{n}} minutes ago',
        oneMinuteAgo: '1 minute ago',
        xSecondsAgo: '{{n}} seconds ago',
        oneSecondAgo: '1 second ago',
      },
    },
  },
}

export default en
