import { createContext, useContext, useRef, useEffect, useState, ReactNode } from 'react'

interface HTMLElementContextType {
  container: HTMLDivElement | null
}

const ThemedPortalContainerContext = createContext<HTMLElementContextType | undefined>(undefined)

export const ThemedPortalContainerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const [container, setContainer] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    setContainer(ref.current)
  }, [])

  return (
    <ThemedPortalContainerContext.Provider value={{ container }}>
      <div className={'h-full'} ref={ref}>
        {children}
      </div>
    </ThemedPortalContainerContext.Provider>
  )
}

export const useThemedPortalContainerContext = (): HTMLElementContextType => {
  const context = useContext(ThemedPortalContainerContext)
  if (!context) {
    return {
      container: null,
    }
  }
  return context
}
