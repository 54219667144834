import { useQuery } from '@tanstack/react-query'
import BillingApi from '../../../api/resources/BillingApi'

const fetchSeatAllowance = async () => {
  const res = await BillingApi.getSeatLimit()
  return res.payload
}

const useReadSeatAllowance = () => {
  return useQuery(['seatAllowance'], () => fetchSeatAllowance(), {
    staleTime: Infinity,
    cacheTime: Infinity,
  })
}

export default useReadSeatAllowance
