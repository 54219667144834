import { IntegrationItemSelector } from '../IntegrationItemSelector'
import { useCallback, useMemo, useState } from 'react'
import { ColumnHelper, SortingState } from '@tanstack/react-table'
import { LabelRadioInput } from '@valuecase/ui-components/src/components/ui/label-radio-input'
import { useIntegrationSearch } from '../hooks/useIntegrationSearch'
import { useReadSpaceIntegrationIdMappings } from '@/modules/spaces/hooks/useReadSpaceIntegrationIdMappings'
import { useSearchSalesforceObjects } from './useSearchSalesforceObjects'
import {
  SalesforceAccount,
  SalesforceCase,
  SalesforceContact,
  SalesforceIntegrationObjectTypeMapping,
  SalesforceObject,
  SalesforceObjectType,
  SalesforceOpportunity,
  formatDateShort,
} from '@valuecase/common'
import { DateTime } from 'luxon'

const salesforceObjectTabs = ['Opportunities', 'Cases', 'Accounts', 'Contacts'] as const

export type SalesforceObjectTab = (typeof salesforceObjectTabs)[number]

/**
 * Shows a list of Salesforce objects and allows the user to select one.
 */
export function SalesforceObjectSelector({
  onObjectSelected,
  selectedObject,
  spaceId,
}: {
  onObjectSelected: (
    object: SalesforceIntegrationObjectTypeMapping[SalesforceObjectType],
    objectType: SalesforceObjectType,
  ) => void
  selectedObject?: SalesforceIntegrationObjectTypeMapping[SalesforceObjectType]
  spaceId?: string
}) {
  const { data: existingMappings } = useReadSpaceIntegrationIdMappings(spaceId)
  const [activeTab, setActiveTab] = useState<SalesforceObjectTab>('Opportunities')
  const [objectType, setObjectType] = useState<SalesforceObjectType>('Opportunity')
  const createDateColumnId = 'createDate'
  const [sorting, onSortingChange] = useState<SortingState>([
    { id: createDateColumnId, desc: true },
  ])
  const limit = 100
  const alreadyLinkedIds = useMemo(() => {
    if (!Array.isArray(existingMappings) || !existingMappings.length) {
      return new Set()
    }
    return new Set(
      existingMappings
        .filter((m) => m.thirdParty === 'Salesforce' && m.thirdPartyEntityType === objectType)
        .map((m) => m.thirdPartyId),
    )
  }, [existingMappings, objectType])

  const opportunitySearch = useIntegrationSearch<
    SalesforceIntegrationObjectTypeMapping,
    'Opportunity'
  >({
    readFunction: useSearchSalesforceObjects,
    enabled: activeTab === 'Opportunities',
    limit,
    objectType: 'Opportunity',
  })
  const caseSearch = useIntegrationSearch<SalesforceIntegrationObjectTypeMapping, 'Case'>({
    readFunction: useSearchSalesforceObjects,
    enabled: activeTab === 'Cases',
    limit,
    objectType: 'Case',
  })
  const accountSearch = useIntegrationSearch<SalesforceIntegrationObjectTypeMapping, 'Account'>({
    readFunction: useSearchSalesforceObjects,
    enabled: activeTab === 'Accounts',
    limit,
    objectType: 'Account',
  })
  const contactSearch = useIntegrationSearch<SalesforceIntegrationObjectTypeMapping, 'Contact'>({
    readFunction: useSearchSalesforceObjects,
    enabled: activeTab === 'Contacts',
    limit,
    objectType: 'Contact',
  })

  const renderColumns = useCallback(
    (columnHelper: ColumnHelper<SalesforceObject>) => {
      const columns = [
        columnHelper.display({
          id: 'checkbox',
          maxSize: 25,
          header(props) {
            return <div {...props} className='max-w-48'></div>
          },
          cell: (ctx) => {
            const isAlreadyLinked = alreadyLinkedIds.has(ctx.row.original.Id)
            return (
              <LabelRadioInput
                // If already linked, then move it out of radio group into it's own little group
                // where it can be preselected
                name={isAlreadyLinked ? ctx.row.original.Id : 'salesforceObject'}
                disabled={isAlreadyLinked}
                value={ctx.row.original.Id}
                checked={isAlreadyLinked ? true : selectedObject?.Id === ctx.row.original.Id}
                // Read only because change to state is handled by the parent component
                readOnly={true}
                screenReaderText={`Select ${activeTab.toLowerCase()} ${
                  ctx.row.original.Id || ctx.row.original.Id
                }`}
              />
            )
          },
          meta: {
            cellClass: 'border-r border-solid border-grey-s2',
          },
        }),
      ]

      // Add columns specific to SalesforceOpportunity: Opportunity Name Company Owner Created
      if (activeTab === 'Opportunities') {
        columns.push(
          columnHelper.accessor('Name', {
            id: 'objectName',
            header: `${objectType} Name`,
            size: 160,
            cell: (ctx) => (
              <span className='font-semibold'>
                {(ctx.row.original as SalesforceOpportunity).Name}
              </span>
            ),
          }) as any,
        )
        columns.push(
          columnHelper.accessor('Account.Name', {
            id: 'accountName',
            header: (h) => 'Company',
            size: 100,
            cell: (ctx) => (ctx.row.original as SalesforceOpportunity).Account?.Name,
          }) as any, // As any to workaround typing issue - it thinks all column defs need to apply
          // here which is a hard problem to solve
        )
        columns.push(
          columnHelper.accessor('Owner.Name', {
            id: 'ownerName',
            header: 'Owner',
            size: 90,
            cell: (ctx) => (
              <span className='font-semibold'>
                {(ctx.row.original as SalesforceOpportunity).Owner?.Name || ''}
              </span>
            ),
          }) as any, // As any to workaround typing issue - it thinks all column defs need to apply
          // here which is a hard problem to solve
        )
        columns.push(
          columnHelper.accessor('CreatedDate', {
            id: createDateColumnId,
            header: 'Created',
            size: 80,
            cell: (ctx) => (
              <span className='font-semibold'>
                {formatDateShort(
                  DateTime.fromJSDate((ctx.row.original as SalesforceOpportunity).CreatedDate),
                )}
              </span>
            ),
          }) as any, // As any to workaround typing issue - it thinks all column defs need to apply
          // here which is a hard problem to solve
        )
      }
      // Add columns case Name, Company, Owner, Created
      if (activeTab === 'Cases') {
        columns.push(
          columnHelper.accessor('Subject', {
            id: 'subject',
            header: 'Subject',
            size: 160,
            cell: (ctx) => (
              <span className='font-semibold'>{(ctx.row.original as SalesforceCase).Subject}</span>
            ),
          }) as any,
        )
        columns.push(
          columnHelper.accessor('Account.Name', {
            id: 'accountName',
            header: 'Company',
            size: 100,
            cell: (ctx) => (ctx.row.original as SalesforceCase).Account?.Name,
          }) as any, // As any to workaround typing issue - it thinks all column defs need to apply
          // here which is a hard problem to solve
        )
        columns.push(
          columnHelper.accessor('Contact.Name' as any, {
            id: 'contactName',
            header: 'Contact',
            size: 90,
            cell: (ctx) => (ctx.row.original as SalesforceCase).Contact?.Name,
          }) as any, // As any to workaround typing issue - it thinks all column defs need to apply
          // here which is a hard problem to solve
        )
        columns.push(
          columnHelper.accessor('CreatedDate', {
            id: createDateColumnId,
            header: 'Created',
            size: 80,
            cell: (ctx) => (
              <span className='font-semibold'>
                {formatDateShort(
                  DateTime.fromJSDate((ctx.row.original as SalesforceCase).CreatedDate),
                )}
              </span>
            ),
          }) as any, // As any to workaround typing issue - it thinks all column defs need to apply
          // here which is a hard problem to solve
        )
      }
      // Add columns Company Name, Company Owner, Created
      if (activeTab === 'Accounts') {
        columns.push(
          columnHelper.accessor('Name', {
            id: 'name',
            header: 'Company Name',
            size: 180,
            cell: (ctx) => (
              <span className='font-semibold'>{(ctx.row.original as SalesforceAccount).Name}</span>
            ),
          }) as any,
        )
        columns.push(
          columnHelper.accessor('Owner.Name', {
            id: 'ownerName',
            header: 'Owner',
            size: 130,
            cell: (ctx) => (
              <span className='font-semibold'>
                {(ctx.row.original as SalesforceAccount).Owner?.Name || ''}
              </span>
            ),
          }) as any, // As any to workaround typing issue - it thinks all column defs need to apply
          // here which is a hard problem to solve
        )
        columns.push(
          columnHelper.accessor('CreatedDate', {
            id: createDateColumnId,
            header: 'Created',
            size: 80,
            cell: (ctx) => (
              <span className='font-semibold'>
                {formatDateShort(
                  DateTime.fromJSDate((ctx.row.original as SalesforceAccount).CreatedDate),
                )}
              </span>
            ),
          }) as any, // As any to workaround typing issue - it thinks all column defs need to apply
          // here which is a hard problem to solve
        )
      }
      // Add columns for Name, E-Mail, Company, Created
      if (activeTab === 'Contacts') {
        columns.push(
          columnHelper.accessor('Name', {
            id: 'name',
            header: 'Name',
            size: 160,
            cell: (ctx) => (
              <span className='font-semibold'>{(ctx.row.original as SalesforceContact).Name}</span>
            ),
          }) as any,
        )
        columns.push(
          columnHelper.accessor('Email', {
            id: 'email',
            header: 'E-Mail',
            size: 100,
            cell: (ctx) => (
              <span className='font-semibold'>{(ctx.row.original as SalesforceContact).Email}</span>
            ),
          }) as any,
        )
        columns.push(
          columnHelper.accessor('Account.Name', {
            id: 'accountName',
            header: 'Company',
            size: 90,
            cell: (ctx) => (ctx.row.original as SalesforceContact).Account?.Name,
          }) as any, // As any to workaround typing issue - it thinks all column defs need to apply
          // here which is a hard problem to solve
        )
        columns.push(
          columnHelper.accessor('CreatedDate', {
            id: createDateColumnId,
            header: 'Created',
            size: 80,
            cell: (ctx) => (
              <span className='font-semibold'>
                {formatDateShort(
                  DateTime.fromJSDate((ctx.row.original as SalesforceContact).CreatedDate),
                )}
              </span>
            ),
          }) as any, // As any to workaround typing issue - it thinks all column defs need to apply
          // here which is a hard problem to solve
        )
      }

      return columns
    },
    [activeTab, alreadyLinkedIds, objectType, selectedObject?.Id],
  )

  const {
    data,
    initialLoadComplete,
    searchTerm,
    onSearchTermInput,
    searchStatus,
    searchErrorMessage,
    hasMoreData,
  } =
    activeTab === 'Opportunities'
      ? opportunitySearch
      : activeTab === 'Cases'
        ? caseSearch
        : activeTab === 'Accounts'
          ? accountSearch
          : contactSearch

  return (
    <IntegrationItemSelector
      items={(data || []) as any[]}
      tabs={salesforceObjectTabs}
      itemType={objectType}
      activeTab={activeTab}
      setActiveTab={(value) => {
        if (salesforceObjectTabs.includes(value as SalesforceObjectTab)) {
          const tabValue = value as SalesforceObjectTab
          setActiveTab(tabValue)
          setObjectType(
            tabValue === 'Opportunities'
              ? 'Opportunity'
              : tabValue === 'Cases'
                ? 'Case'
                : tabValue === 'Accounts'
                  ? 'Account'
                  : 'Contact',
          )
        } else {
          throw new Error('Invalid tab ' + value + ' selected.')
        }
      }}
      searchStatus={searchStatus}
      searchTerm={searchTerm}
      initialLoadComplete={initialLoadComplete}
      searchErrorMessage={searchErrorMessage}
      selectItem={(item, itemType) => {
        if (alreadyLinkedIds.has(item.id)) {
          return
        }
        onObjectSelected(item, itemType as SalesforceObjectType)
      }}
      selectedItem={selectedObject}
      onSearchItems={onSearchTermInput}
      renderColumns={renderColumns as any}
      searchPlaceholder={`Search ${activeTab.toLowerCase()}...`}
      sorting={sorting}
      onSortingChange={onSortingChange}
      footerText={
        hasMoreData &&
        `Displaying the ${limit} most recent ${objectType}. Search to view all ${objectType}.`
      }
    />
  )
}
