import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'
import { useMutation, useQueryClient } from '@tanstack/react-query'

type ChangeSpaceOwnerInput = {
  spaceId: string
  ownerId: string
}
const changeSpaceOwner = ({ spaceId, ownerId }: ChangeSpaceOwnerInput) =>
  spacesApi.patch(`/spaces/${spaceId}/change-owner`, { ownerId })

export const useChangeSpaceOwner = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: changeSpaceOwner,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['spaces'] })
    },
  })
  return {
    isSuccess: mutation.isSuccess,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    changeSpaceOwner: mutation.mutate,
    updatedSpace: mutation.data,
    changeSpaceOwnerAsync: mutation.mutateAsync,
  }
}
