type SettingsInfoProps = {
  title: string
  description: string | React.ReactElement
}

export const SettingsInfo = ({ title, description }: SettingsInfoProps) => {
  return (
    <div className='flex flex-col gap-2'>
      <h3 className='font-bold text-sm text-grey-s6'>{title}</h3>
      {typeof description === 'string' ? (
        <p className='max-w-[350px] text-sm text-grey-s5 leading-6'>{description}</p>
      ) : (
        description
      )}
    </div>
  )
}
