import { useQuery } from '@tanstack/react-query'
import SellerApi from '../../../api/SellerApi'

async function readHubSpotIntegrationStatusFn() {
  const res = await SellerApi.get<{ status: 'enabled' | 'disabled' }, undefined>(
    'integrations/hubspot/status',
  )
  return res.payload.status
}

export function useReadHubSpotIntegrationStatus() {
  const query = useQuery({
    queryKey: ['readHubSpotIntegrationStatus'],
    queryFn: readHubSpotIntegrationStatusFn,
  })

  return {
    isLoading: query.isLoading,
    isReloading: query.isRefetching && query.isStale,
    isError: query.isError,
    error: query.error,
    hubSpotIntegrationStatus: query.data,
    isFetched: query.isFetched,
    refetch: query.refetch,
  }
}
