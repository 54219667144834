import styled from 'styled-components'

const compensateSurroundingPadding = 6

export const MenuDropdownSeparator = styled.div`
  width: calc(100% + ${compensateSurroundingPadding * 2}px);
  height: 1px;
  background: var(--theme-grey-s2);
  margin: 6px -${compensateSurroundingPadding}px;
`
