import React, { useContext } from 'react'

interface BasePortalContainerContextType {
  container: HTMLElement | null
}

const BasePortalContainerContext = React.createContext<BasePortalContainerContextType>({
  container: null,
})

export interface BasePortalContainerProviderProps {
  container: HTMLElement | null
  children?: React.ReactNode
}

export function BasePortalContainerProvider({
  container,
  children,
}: BasePortalContainerProviderProps) {
  return (
    <BasePortalContainerContext.Provider value={{ container }}>
      {children}
    </BasePortalContainerContext.Provider>
  )
}

export function useBasePortalContainer() {
  return useContext(BasePortalContainerContext)
}
