import { Button, ModalControls, SimpleModal, useOverlay } from '@valuecase/ui-components'
import { FC, useCallback, useMemo, useRef } from 'react'

export function useDeleteUploadedImage() {
  const overlay = useOverlay()

  const show = useMemo(() => {
    return (onDelete: () => void, type: 'logo' | 'background' | 'favicon') => {
      overlay.show(<DeleteUploadedImageModal onDelete={onDelete} type={type} />)
    }
  }, [overlay])

  return {
    show,
  }
}

type Props = {
  onDelete: () => void
  type: 'logo' | 'background' | 'favicon'
}

const DeleteUploadedImageModal: FC<Props> = ({ onDelete, type }) => {
  const controls = useRef<ModalControls | null>(null)

  const handleClick = useCallback(() => {
    onDelete()
    controls.current?.close()
  }, [onDelete])

  const title = useMemo(() => {
    if (type === 'logo') {
      return 'Delete Logo'
    } else if (type === 'background') {
      return 'Delete Space Background'
    } else {
      return 'Delete Favicon'
    }
  }, [type])

  const descriptionWord = useMemo(() => {
    if (type === 'logo') {
      return 'logo'
    } else if (type === 'background') {
      return 'background image'
    } else {
      return 'favicon'
    }
  }, [type])

  return (
    <SimpleModal
      controlsRef={controls}
      header={
        <div className='flex w-full justify-center mb-3'>
          <h2 className='text-2xl font-extrabold text-grey-s6'>{title}</h2>
        </div>
      }
      body={
        <div>
          <p className={'text-sm text-grey-s5 mb-14 text-center'}>
            You&apos;re about to delete the {descriptionWord}. This is non-reversible and will be
            applied to all spaces immediately. Do you want to proceed?
          </p>
          <div className={'flex justify-center gap-3'}>
            <Button
              onClick={() => {
                controls.current?.close()
              }}
              label={'Cancel'}
              icon={'close-circle-outline'}
              style={'gray-outlined'}
              cssStyle={{ minWidth: '156px' }}
            />
            <Button
              onClick={handleClick}
              cssStyle={{ paddingLeft: '3rem', paddingRight: '3rem' }}
              label={'Delete'}
              icon={'trash-outline'}
              style={'warning'}
            />
          </div>
        </div>
      }
    />
  )
}
