import { Logtail } from '@logtail/browser'
import { useMemo } from 'react'

class Logger {
  private _logger: Logtail | Console

  constructor() {
    // eslint-disable-next-line
    // @ts-ignore
    if (import.meta.env.VITE_LOGTAIL_TOKEN) {
      // eslint-disable-next-line
      // @ts-ignore
      this._logger = new Logtail(import.meta.env.VITE_LOGTAIL_TOKEN)
    } else {
      this._logger = console
    }
  }

  debug(message?: any, ...optionalParams: any[]): void {
    this._logger.debug(message, ...optionalParams)
  }

  error(message?: any, ...optionalParams: any[]): void {
    this._logger.error(message, ...optionalParams)
  }

  log(message?: any, ...optionalParams: any[]): void {
    this._logger.log(message, ...optionalParams)
  }

  warn(message?: any, ...optionalParams: any[]): void {
    this._logger.warn(message, optionalParams)
  }

  info(message?: any, ...optionalParams: any[]): void {
    this._logger.info(message, ...optionalParams)
  }
}

export function createLogger(): Logger {
  return new Logger()
}

export function useLogger(): { logger: Logger } {
  const logger = useMemo(() => new Logger(), [])
  return { logger }
}
