import { cn } from '../../lib/utils'
import * as React from 'react'

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  size?: 'default' | 'large'
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, size = 'default', ...props }, ref) => {
    return (
      <textarea
        className={cn(
          'flex w-full rounded-md border border-grey-s2 bg-white text-xs',
          size === 'large' ? 'min-h-[44px]' : 'min-h-[36px]',
          'h-fit',
          'px-3 py-2',
          'placeholder:text-grey-s4 text-xs',
          'focus:outline-none focus:ring-4 focus:ring-blue-s3 focus:ring-opacity-50 focus:ring-offset-0 focus:border-blue-s4',
          'hover:ring-4 hover:ring-blue-s3 hover:ring-opacity-50 hover:ring-offset-0',
          'disabled:cursor-not-allowed disabled:opacity-50 disabled:bg-grey-s2 disabled:hover-none disabled:ring-0',
          'transition-shadow',
          className,
        )}
        ref={ref}
        {...props}
      />
    )
  },
)
Textarea.displayName = 'Textarea'

export { Textarea }
