import { useMutation, useQueryClient } from '@tanstack/react-query'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'
import { CreateSpaceIntegrationIdMappingInput } from './types'

type CreateSpaceIntegrationIdMappingQueryInput = {
  spaceId: string
  payload: {
    integrationIdMappings: CreateSpaceIntegrationIdMappingInput[]
  }
}

/**
 * Creates a space integration id mapping - e.g. connecting space to hubspot deal id.
 */
export async function createSpaceIntegrationIdMapping({
  spaceId,
  payload,
}: CreateSpaceIntegrationIdMappingQueryInput) {
  await spacesApi.post(`/spaces/${encodeURIComponent(spaceId)}/integration-id-mappings`, payload)
  return spaceId
}

export function useCreateSpaceIntegrationAttributes(props?: {
  onSuccess: () => void
  onError: () => void
}) {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: createSpaceIntegrationIdMapping,
    onSuccess: (spaceId) => {
      queryClient.invalidateQueries({ queryKey: ['spaceIntegrationIdMappings', spaceId] })
      props?.onSuccess()
    },
    onError: () => props?.onError(),
  })
  return {
    isLoading: mutation.isLoading,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    error: mutation.error,
    createSpaceIntegrationIdMapping: async (
      integrationAttributesInput: CreateSpaceIntegrationIdMappingQueryInput,
    ) => mutation.mutate(integrationAttributesInput),
  }
}
