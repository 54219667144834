import { useEffect, useState } from 'react'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import { BuyerVisits } from './charts/BuyerVisits'
import { CreatedSpaces } from './charts/CreatedSpaces'
import { SellerAnalytics } from './charts/SellerAnalytics'
import { TimeRange } from './charts/types'
import {
  PanelTabsList,
  PanelTabsTrigger,
  Tabs,
} from '@valuecase/ui-components/src/components/ui/tabs'

const Analytics = () => {
  const [mixpanel_pageVisitedTracked, set_mixpanel_pageVisitedTracked] = useState(false)
  const { trackEvent } = useTrackEvent()
  const [timeRange, setTimeRange] = useState<TimeRange>('30d')

  //mixpanel track  page visit
  useEffect(() => {
    if (!mixpanel_pageVisitedTracked) {
      trackEvent({
        event: 'dashboard-visit',
        eventProperties: {
          indexPageName: 'Analytics page',
        },
      }).finally(() => {
        set_mixpanel_pageVisitedTracked(true)
      })
    }
  }, [mixpanel_pageVisitedTracked, trackEvent, set_mixpanel_pageVisitedTracked])

  return (
    <div className='py-11 w-full flex flex-col gap-4'>
      <div className={'flex flex-row justify-between'}>
        <div className={'flex flex-col w-140'}>
          <h1 className={'text-2xl font-bold'}>Analytics</h1>
          <p className={'text-sm text-grey-s5 mt-2 leading-6'}>
            Maximize sales potential with analytics – visualize user interactions, comprehend
            prospect behavior & optimize for revenue growth. More coming soon!
          </p>
        </div>
      </div>
      <div className='w-full border-grey-s2 border-t-[1px] mt-6' />

      <div className={'w-full flex justify-end'}>
        <Tabs value={timeRange} onValueChange={(v) => setTimeRange(v as TimeRange)}>
          <PanelTabsList>
            <PanelTabsTrigger value={'7d'}>Last 7 days</PanelTabsTrigger>
            <PanelTabsTrigger value={'30d'}>Last 30 days</PanelTabsTrigger>
            <PanelTabsTrigger value={'3m'}>Last 3 months</PanelTabsTrigger>
            <PanelTabsTrigger value={'6m'}>Last 6 months</PanelTabsTrigger>
          </PanelTabsList>
        </Tabs>
      </div>

      <div className='flex flex-col md:flex-row gap-4'>
        <div className='flex flex-1 relative'>
          <CreatedSpaces range={timeRange} />
        </div>

        <div className='flex flex-1 relative'>
          <BuyerVisits range={timeRange} />
        </div>
      </div>
      <div className='w-full'>
        <SellerAnalytics range={timeRange} />
      </div>
    </div>
  )
}

export default Analytics
