import { SettingsInfo } from './SettingsInfo'
import { useEffect, useState } from 'react'
import { useCompanyVisualSettings } from './hooks/useCompanyVisualSettings'
import { useNotifications } from '../../utils/Notifications/Notifications'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import { InputColorPicker } from '@valuecase/ui-components/src/components/ui/color-picker'

export const PrimaryColorSetting = () => {
  const { trackEvent } = useTrackEvent()
  const [color, setColor] = useState('#2A4CFA')
  const { companySettings, updateCompanySettings, isQueryFetched } = useCompanyVisualSettings({
    onUpdateSuccess: () => {
      trackEvent({
        event: 'settings-color-update',
        eventProperties: {
          indexPageName: 'Company Settings',
        },
      })
      success('Primary color updated')
    },
  })

  const { success } = useNotifications()

  useEffect(() => {
    if (companySettings?.primaryColor) {
      setColor(companySettings?.primaryColor)
    }
  }, [companySettings])

  return (
    <div className='flex justify-between w-full items-start'>
      <SettingsInfo
        title='Color'
        description='Set your brand color, and see it applied throughout your spaces - from buttons to text highlights and more.'
      />
      <div className={'w-[172px]'}>
        <InputColorPicker
          value={color}
          onColorChange={(newColor) => {
            if (newColor === color) {
              return
            }

            setColor(newColor)
            updateCompanySettings({ primaryColor: newColor })
          }}
        />
      </div>
    </div>
  )
}
