import styled from 'styled-components'

const BadgeBox = styled.span<{ $backgroundColor: string; $color: string }>`
  background-color: transparent;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
  bottom: 2px;
  left: 8px;

  .badge-wrapper {
    border: 0px none;
    height: 21px;
    width: 84px;
  }

  .badge {
    align-items: center;
    background-color: ${({ $backgroundColor: backgroundColor }) => backgroundColor};
    border-radius: 6px;
    display: flex;
    gap: 4px;
    height: 21px;
    justify-content: center;
    overflow: hidden;
    padding: 4px 6px;
    width: fit-content;
  }

  .text-wrapper {
    color: ${({ $color: color }) => color};
    font-family: 'Open Sans', Helvetica;
    font-size: 10px;
    font-weight: 700;
    line-height: 14px;
    position: relative;
    top: 1px;
    white-space: nowrap;
    width: fit-content;
  }

  .vector {
    height: 10.91px;
    min-width: 16px;
    position: relative;
  }
`

export const IntegrationBadge = ({
  text,
  backgroundColor,
  color,
  LogoSvg,
}: {
  text: string
  color: string
  backgroundColor: string
  LogoSvg: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
}) => {
  return (
    <BadgeBox className='box' $backgroundColor={backgroundColor} $color={color}>
      <span className='badge-wrapper'>
        <span className='badge'>
          {text && <span className='text-wrapper'>{text}</span>}
          <LogoSvg className='vector' />
        </span>
      </span>
    </BadgeBox>
  )
}
