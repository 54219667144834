import { useMutation } from '@tanstack/react-query'
import { spacesApi } from '../../../../api/spacesApi/spacesAPIclient'
import { queryClient } from '../../../../main'

export default function useChangeSpaceOwnerOfSellersSpaces() {
  return useMutation({
    mutationFn: (props: { oldSellerId: string; newSellerId: string }) => {
      return spacesApi.patch(`/spaces/ownership/seller/${props.oldSellerId}`, {
        newSellerId: props.newSellerId,
      })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['spaces'] })
    },
  })
}
