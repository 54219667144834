import styled from 'styled-components'

export const OverlayWrapper = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.2s ease;

  &.visible {
    opacity: 1;
  }
`
