import React from 'react'
import { S } from './Loading.styled'

export interface LoadingProps {
  show?: boolean
}

export type LoadableContentMode = 'hideContent' | 'blurContent'

export interface LoadableContentProps {
  loading: boolean
  children?: React.ReactNode
  mode?: LoadableContentMode
  text?: string
  loadingClassName?: string
}

export function Loader({ show = true }: LoadingProps) {
  if (!show) return <></>
  return (
    <S.Padder>
      <S.Spinner />
    </S.Padder>
  )
}

export function FullLoader({ show = true }: LoadingProps) {
  if (!show) return <></>
  return (
    <S.FullArea>
      <S.Spinner />
    </S.FullArea>
  )
}

export const Spinner = () => <S.Spinner />

export function LoadableContent({
  loading,
  children,
  mode = 'hideContent',
  text,
  loadingClassName,
}: LoadableContentProps) {
  if (mode === 'blurContent') {
    return (
      <S.BlurModeContainer $blur={loading}>
        <S.BlurLayer $blur={loading}>
          {loading && (
            <div className='flex flex-col items-center'>
              <S.Spinner />
              {text && <p className='text-grey-s6 text-sm mt-2'>{text}</p>}
            </div>
          )}
        </S.BlurLayer>
        <S.BlurContentContainer $blur={loading}>{children}</S.BlurContentContainer>
      </S.BlurModeContainer>
    )
  }
  if (loading) {
    return (
      <div
        className={`flex flex-col items-center justify-center w-full h-full ${loadingClassName}`}
      >
        <div>
          <FullLoader />
          {text && <p className='text-grey-s6 text-sm mt-2'>{text}</p>}
        </div>
      </div>
    )
  }
  return <>{children}</>
}
