import { Axios, AxiosInstance } from 'axios'
import React, { createContext, ReactNode, useContext, useMemo } from 'react'

type SpaceApiContextType = { spacesApi: AxiosInstance | Axios }

const SpacesApiContext = createContext<Partial<SpaceApiContextType>>({ spacesApi: undefined })

/**
 * Provider for the Spaces API
 */
export const SpacesApiProvider: React.FC<{
  children: ReactNode
  spacesApi: AxiosInstance | Axios
}> = ({ children, spacesApi }) => {
  const providerValue = useMemo(() => ({ spacesApi }), [spacesApi])
  return <SpacesApiContext.Provider value={providerValue}>{children}</SpacesApiContext.Provider>
}

/**
 * Hook to access the Spaces API, must be used within children of SpacesApiProvider component
 */
export const useSpacesApi = (): SpaceApiContextType => {
  const context = useContext(SpacesApiContext)
  if (!context.spacesApi) {
    throw new Error('useSpacesApi must be used within a SpacesApiProvider')
  }
  // Cast goes from Partial to full type, safe because of the check above
  return context as SpaceApiContextType
}
