import {
  EmptyState,
  EmptyStateDescription,
  EmptyStateHeader,
} from '@valuecase/ui-components/src/components/ui/empty'
import DudeWithASignThinking from './assets/dude-with-a-sign-thinking.svg?react'

const TeamMembersEmptyState = () => {
  return (
    <div className='w-full h-fit py-4 flex flex-row items-center justify-center bg-white rounded-lg'>
      <EmptyState>
        <EmptyStateHeader>
          <DudeWithASignThinking className='w-32 h-32' />
          <EmptyStateDescription>This team has no members yet</EmptyStateDescription>
        </EmptyStateHeader>
      </EmptyState>
    </div>
  )
}

export default TeamMembersEmptyState
