import {
  LoadableContent,
  SimpleModal,
  useModalControls,
  useOverlay,
} from '@valuecase/ui-components'
import { useCallback, useEffect, useRef, useState } from 'react'
import ChangeSpaceOwnerBody from './ChangeSpaceOwnerBody'
import ConfirmDeleteUserBody from './ConfirmDeleteUserBody'
import useChangeUserStatusMutation from './useChangeUserStatus'
import useChangeSpaceOwnerOfSellersSpacesMutation from './useChangeSpaceOwnerOfSellersSpaces'
import { useNotifications } from '../../../../utils/Notifications/Notifications'
import { useQueryClient } from '@tanstack/react-query'

export type DeleteUserProps = {
  user: {
    id: string
    email: string
    fullName?: string
  }
}

export default function DeleteUserModal(props: DeleteUserProps) {
  return <SimpleModal body={<DeleteUserModalBody {...props} />}></SimpleModal>
}

export function DeleteUserModalBody(props: DeleteUserProps) {
  const [newSpaceOwner, setNewSpaceOwner] = useState<{ id: string; name: string } | undefined>()

  const { success, error } = useNotifications()

  const { mutateAsync: changeSpaceOwnership, isLoading: isChangeSpaceOwnershipLoading } =
    useChangeSpaceOwnerOfSellersSpacesMutation()
  const { mutateAsync: changeUserStatus, isLoading: isChangeUserStatusLoading } =
    useChangeUserStatusMutation()

  const { close } = useModalControls()

  const queryClient = useQueryClient()
  const [loading, setLoading] = useState(false)

  const deletionRefreshTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)

  useEffect(() => {
    return () => {
      if (deletionRefreshTimeout.current) {
        clearTimeout(deletionRefreshTimeout.current)
        deletionRefreshTimeout.current = null
      }
    }
  }, [])

  const onDelete = useCallback(async () => {
    if (!newSpaceOwner) {
      return
    }

    setLoading(true)

    try {
      await changeSpaceOwnership({ oldSellerId: props.user.id, newSellerId: newSpaceOwner.id })
      success('Space ownership successfully changed.')
    } catch (e) {
      error('Changing space ownership failed.')
      close()
      return
    }

    try {
      await changeUserStatus({ userId: props.user.id, newStatus: 'inactive' })
      success('User successfully deleted.')
    } catch (e) {
      error('User deletion failed.')
      close()
      return
    }

    // The Auth0 backend takes a few seconds to process the user deletion, so we show a loading spinner
    deletionRefreshTimeout.current = setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ['readUsers'] })
      setLoading(false)
      close()
    }, 5000)
  }, [
    newSpaceOwner,
    changeSpaceOwnership,
    props.user.id,
    success,
    error,
    close,
    changeUserStatus,
    queryClient,
  ])

  return (
    <LoadableContent
      mode={'blurContent'}
      loading={
        !!newSpaceOwner && (isChangeUserStatusLoading || isChangeSpaceOwnershipLoading || loading)
      }
    >
      {newSpaceOwner ? (
        <ConfirmDeleteUserBody
          onCancel={close}
          onDelete={onDelete}
          previousOwner={{ name: props.user.fullName ?? props.user.email }}
          newSpaceOwner={newSpaceOwner}
        />
      ) : (
        <ChangeSpaceOwnerBody {...props} onCancel={close} onChangeSpaceOwner={setNewSpaceOwner} />
      )}
    </LoadableContent>
  )
}

export type UseDeleteUserModal = {
  show: (props: DeleteUserProps) => void
}

export function useDeleteUserModal(): UseDeleteUserModal {
  const overlay = useOverlay()

  return {
    show: useCallback(
      (props) => {
        overlay.show(<DeleteUserModal {...props} />)
      },
      [overlay],
    ),
  }
}
