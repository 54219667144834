import { useMutation, useQueryClient } from '@tanstack/react-query'
import SellerApi from '../../../api/SellerApi'

async function disconnectHubSpotIntegrationFn() {
  await SellerApi.delete<undefined, undefined>('integrations/hubspot', undefined)
}

export function useDeleteHubSpotIntegration(opts: { onSuccess?: () => void } = {}) {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: disconnectHubSpotIntegrationFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['readHubSpotIntegrationStatus'] })
    },
  })
  return {
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    deleteHubSpotIntegration: () => mutation.mutate(),
    deleteHubSpotIntegrationAsync: async () => {
      await mutation.mutateAsync()
    },
  }
}
