import styled from 'styled-components'

const SpacesPageHeader = styled.div`
  margin-top: 44px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 65px;
  gap: 24px;
  @media (max-width: 800px) {
    flex-direction: column;
    gap: 32px;
    font-size: 24px;
  }
`

const Right = styled.div``

const Greeting = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex-grow: 1;
`

const YouAreAwesome = styled.div<{ $centered: boolean }>`
  color: ${({ theme }) => theme.grey.s4};
  width: ${({ $centered: centered }) => (centered ? '100%' : 'auto')};
  text-align: ${({ $centered: centered }) => (centered ? 'center' : 'inherit')};
  @media (max-width: 700px) {
    font-size: 8px;
  }
`
const WelcomeBack = styled.div`
  color: ${({ theme }) => theme.grey.s6};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-right: 16px;
  @media (max-width: 700px) {
    font-size: 24px;
  }
`

const Welcome = styled.div`
  font-weight: 350;
  font-size: 32px;
  line-height: 44px;
  color: ${({ theme }) => theme.grey.s6};
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  b {
    font-weight: 700;
  }
  p {
    margin: 0;
  }
`

export const S = { SpacesPageHeader, Right, Greeting, WelcomeBack, Welcome, YouAreAwesome }
