import { useQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { spacesApi } from '../../api/spacesApi/spacesAPIclient'
import { useAuthState } from '../../auth/auth'

export function useGetTenantUsedCustomProperties() {
  const authState = useAuthState()
  const [tenantId, setTenantId] = useState<string | undefined>()

  function getTenantUsedCustomPropertiesQueryFn() {
    return () => spacesApi.get(`/templates/all-used-custom-properties`)
  }

  useEffect(() => {
    if (authState.authorized) {
      setTenantId(authState.tenant)
    }
  }, [authState])

  const query = useQuery({
    queryKey: ['template-used-custom-properties'],
    queryFn: getTenantUsedCustomPropertiesQueryFn(),
    enabled: !!tenantId,
  })

  return {
    isLoading: query.isLoading,
    isReloading: query.isRefetching && query.isStale,
    isError: query.isError,
    error: query.error,
    tenantUsedCustomProperties: query.data?.data,
    isFetched: query.isFetched,
  }
}
