import { cn } from '../../lib/utils'

import { ComponentPropsWithoutRef, forwardRef, HTMLAttributes } from 'react'
import { IonIcon } from '../../Icons/IonIcon'

const Chip = ({
  className,
  variant = 'default',
  ...props
}: HTMLAttributes<HTMLButtonElement> & { variant?: 'default' | 'ghost' }) => {
  return (
    <button
      className={cn(
        'flex gap-2 items-center text-grey-s5 hover:bg-grey-s2 transition-colors rounded-lg h-7 py-1 pl-[5px] pr-2',
        'focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-primary-s4',
        { 'bg-grey-s1': variant === 'default' },
        className,
      )}
      {...props}
    ></button>
  )
}

const ChipIcon = ({ className, ...props }: ComponentPropsWithoutRef<typeof IonIcon>) => {
  return <IonIcon className={cn('w-3 h-3 ml-[3px]', className)} {...props} />
}

const ChipLabel = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement> & { size?: 'default' | 'small' }
>(({ className, size = 'default', ...props }, ref) => (
  <p
    ref={ref}
    className={cn({ 'text-xs': size === 'default', 'text-xxs': size === 'small' }, className)}
    {...props}
  />
))
ChipLabel.displayName = 'ChipLabel'

const ChipClose = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div role='button' ref={ref} className={'outline-none flex items-center'} {...props}>
      <IonIcon
        name={'close'}
        className={cn('w-3 h-3 hover:text-grey-s6 transition-colors cursor-pointer', className)}
      />
    </div>
  ),
)
ChipClose.displayName = 'ChipClose'

export { Chip, ChipIcon, ChipLabel, ChipClose }
