import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CreateTemplateFromSpaceQueryTemplateInput } from '../types/CreateTemplateQueryTemplateInput'
import { CreateTemplateReqDTO } from './types/CreateTemplateReqDTO'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'

export async function createTemplateFromSpaceQueryFn(
  templateInput: CreateTemplateFromSpaceQueryTemplateInput,
) {
  const { ...template } = templateInput
  const templateDto: CreateTemplateReqDTO = {
    data: {
      description: template.description,
      title: template.title,
      icon: template.icon,
    },
  }
  const response = await spacesApi.post(`/templates/from-space/${template.spaceId}`, templateDto)
  return response.data
}

export function useCreateTemplateFromSpace(props?: {
  onSuccess: (data: any) => void
  onError: () => void
}) {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: createTemplateFromSpaceQueryFn,
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['readTemplates'] })
      if (props?.onSuccess) {
        props.onSuccess(data)
      }
    },
    onError: () => {
      if (props?.onError) {
        props.onError()
      }
    },
  })
  return {
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    createTemplate: (templateInput: CreateTemplateFromSpaceQueryTemplateInput) =>
      mutation.mutate(templateInput),
  }
}
