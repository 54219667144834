import SellerApi from '@/api/SellerApi'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UpdateTeamWithMembersPayload, teamWithMembersSchema } from '@valuecase/common'

const updateTeam = async (team: UpdateTeamWithMembersPayload) => {
  const res = await SellerApi.put(`teams/${team.id}`, team)
  return teamWithMembersSchema.parse(res.payload)
}

const useUpdateTeam = () => {
  const queryClient = useQueryClient()

  return useMutation(
    (team: UpdateTeamWithMembersPayload) => {
      return updateTeam(team)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['teams'])
      },
    },
  )
}

export default useUpdateTeam
