import {
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { TeamWithMembers, User } from '@valuecase/common'
import {
  Button,
  DataTable,
  InitialsAvatar,
  InitialsAvatarSize,
  MenuDropdown,
  MenuDropdownItem,
} from '@valuecase/ui-components'
import { useReadUsers } from '../userManagement/hooks/useReadUsers'
import { useMemo, useState } from 'react'
import AddMembersToTeamDialog from './dialogs/AddMembersToTeamDialog'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'
import EditTeamDialog from './dialogs/EditTeamDialog'
import DeleteTeamDialog from './dialogs/DeleteTeamDialog'
import useReadTeams from './hooks/useReadTeams'
import { cn } from '@valuecase/ui-components/src/lib/utils'
import RemoveUserFromTeamDialog from './dialogs/RemoveUserFromTeamDialog'
import TeamMembersEmptyState from './TeamMembersEmptyState'

const TeamTable = ({ team }: { team: TeamWithMembers }) => {
  const { users, isLoading } = useReadUsers()
  const [addMembersDialogIsOpen, setAddMembersDialogIsOpen] = useState(false)
  const [editTeamDialogIsOpen, setEditTeamDialogIsOpen] = useState(false)
  const [deleteTeamDialogIsOpen, setDeleteTeamDialogIsOpen] = useState(false)
  const [deleteUserFromTeamId, setDeleteUserFromTeamId] = useState<string | null>(null)
  const teamMembers = useMemo(
    () => users?.filter((user) => team.memberIds.includes(user.id)),
    [users, team.memberIds],
  )
  const { data: teams } = useReadTeams()

  const columnHelper = useMemo(() => createColumnHelper<User>(), [])

  const columns = useMemo(
    () => [
      columnHelper.accessor('fullName', {
        header: 'Members',
        cell: (info) => (
          <div className='flex flex-row gap-3 items-center'>
            {info.row.original.profilePictureUrl ? (
              <div className='h-10 w-10 bg-grey-s1 border border-grey-s2 rounded-full'>
                <img
                  className='w-full h-full rounded-full'
                  src={info.row.original.profilePictureUrl}
                  alt='Profile'
                />
              </div>
            ) : (
              <InitialsAvatar
                text={info.getValue() ?? 'N/A'}
                size={InitialsAvatarSize.MEDIUM}
                isSeller={true}
              />
            )}

            {info.getValue()}
          </div>
        ),
        enableSorting: false,
      }),
      columnHelper.accessor('email', {
        header: 'Email Address',
        cell: (info) => info.getValue(),
        enableSorting: false,
      }),
      {
        header: 'Teams',
        cell: (info: any) => {
          const userTeams = teams?.filter((team) => team.memberIds.includes(info.row.original.id))
          const sortedUserTeams = userTeams?.sort((a, b) => a.name.localeCompare(b.name))
          const userTeamNames = sortedUserTeams?.map((userTeam, index) => (
            <span key={userTeam.id} className={cn({ 'font-bold': userTeam.id === team.id })}>
              {userTeam.name}
              {index !== sortedUserTeams.length - 1 && ', '}
            </span>
          ))
          return <>{userTeamNames}</>
        },
        enableSorting: false,
      },
      {
        id: 'actions',
        size: 100,
        enableHiding: false,
        cell: (info: any) => {
          const userId = info.row.original.id
          return (
            <MenuDropdown
              Trigger={<Button icon='ellipsis-vertical' style='flat-sidebar' />}
              placement={'bottom-start'}
            >
              <MenuDropdownItem
                onClick={() => setDeleteUserFromTeamId(userId)}
                label='Delete from team'
                LeftIcon={'trash'}
                variant='danger'
              ></MenuDropdownItem>
            </MenuDropdown>
          )
        },
      },
    ],

    [columnHelper, teams, team],
  )
  const table = useReactTable({
    data: teamMembers ?? [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  return (
    <div>
      <div className='w-full h-fit flex flex-col gap-4 bg-white py-4'>
        <div className='flex flex-row justify-between items-center px-4'>
          <p className='text-sm font-bold text-grey-s6'>Team members</p>
          <div className='flex gap-1'>
            <Button2
              variant={'subtle'}
              leadingIcon='add'
              onClick={() => setAddMembersDialogIsOpen(true)}
            >
              Add members
            </Button2>
            <MenuDropdown
              Trigger={<Button icon='ellipsis-vertical' style='flat-sidebar' />}
              placement={'bottom-start'}
            >
              <MenuDropdownItem
                onClick={() => setEditTeamDialogIsOpen(true)}
                label='Edit'
                LeftIcon={'create-outline'}
                variant='default'
              />
              <MenuDropdownItem
                onClick={() => setDeleteTeamDialogIsOpen(true)}
                label='Delete'
                LeftIcon={'trash'}
                variant='danger'
              />
            </MenuDropdown>
          </div>
        </div>
        <DataTable table={table} emptyState={<TeamMembersEmptyState />} isLoading={isLoading} />
        <AddMembersToTeamDialog
          open={addMembersDialogIsOpen}
          onOpenChange={(open) => setAddMembersDialogIsOpen(open)}
          team={team}
        />
        <EditTeamDialog
          team={team}
          open={editTeamDialogIsOpen}
          onOpenChange={(open) => setEditTeamDialogIsOpen(open)}
        />
        <DeleteTeamDialog
          team={team}
          open={deleteTeamDialogIsOpen}
          onOpenChange={(open) => setDeleteTeamDialogIsOpen(open)}
        />
        <RemoveUserFromTeamDialog
          team={team}
          userId={deleteUserFromTeamId}
          onClose={() => setDeleteUserFromTeamId(null)}
        />
      </div>
    </div>
  )
}

export default TeamTable
