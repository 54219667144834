import { useQuery } from '@tanstack/react-query'
import AuthApi from '../../../api/resources/AuthApi'

export type Role = {
  id: string
  name: string
  description: string
  order?: number
}

async function readAvailableRolesQueryFn() {
  const res = await AuthApi.listAvailableRoles()
  return res.payload as Role[]
}

export function useReadAvailableRoles() {
  const query = useQuery({
    queryKey: ['readAvailableRoles'],
    queryFn: readAvailableRolesQueryFn,
  })

  return {
    isLoading: query.isLoading,
    isReloading: query.isRefetching && query.isStale,
    isError: query.isError,
    error: query.error,
    roles: query.data,
    isFetched: query.isFetched,
    refetch: query.refetch,
  }
}
