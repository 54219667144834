import extractInitials from './InitialsAvatarUtils'
import styled from 'styled-components'
import { useMemo } from 'react'

export enum InitialsAvatarSize {
  LARGE = 'large',
  MEDIUM = 'normal',
  SMALL = 'small',
  EXTRA_SMALL = 'extra-small',
}

export interface InitialsAvatarProps {
  text: string
  size?: InitialsAvatarSize
  maxInitials?: number
  colorIndex?: number
  cssColor?: string
  isSeller: boolean
  isGrey?: boolean
}

/**
 *
 * @deprecated use AvatarThumbnail instead
 */
export function InitialsAvatar({
  text,
  size = InitialsAvatarSize.MEDIUM,
  maxInitials = 2,
  isSeller,
  isGrey = false,
}: InitialsAvatarProps) {
  const initials = useMemo(() => extractInitials(text, { max: maxInitials }), [text, maxInitials])

  return (
    <StyledAvatar $size={size} $isSeller={isSeller} $isGrey={isGrey}>
      <span>{initials}</span>
    </StyledAvatar>
  )
}

const colors = ['var(--theme-primary-s5)', '#FF7777', '#82C691', '#FFA077', '#AC8FE9']

interface InitialsAvatarLook {
  width: number
  fontSize: number
}

const SizeLooks: { [key in InitialsAvatarSize]: InitialsAvatarLook } = {
  [InitialsAvatarSize.LARGE]: {
    width: 40,
    fontSize: 18,
  },
  [InitialsAvatarSize.MEDIUM]: {
    width: 32,
    fontSize: 14,
  },
  [InitialsAvatarSize.SMALL]: {
    width: 24,
    fontSize: 10,
  },
  [InitialsAvatarSize.EXTRA_SMALL]: {
    width: 20,
    fontSize: 8,
  },
}

interface StyledAvatarProps {
  $size: InitialsAvatarSize
  $isSeller: boolean
  $isGrey: boolean
}

const StyledAvatar = styled.span<StyledAvatarProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border: 1px solid;
  border-radius: 100px;
  user-select: none;
  font-weight: 600;

  ${({ $size: size, $isSeller: isSeller, $isGrey: isGrey }) => {
    const look = SizeLooks[size]

    function getBackgroundColor() {
      if (isGrey) {
        return isSeller ? 'var(--theme-grey-s4)' : 'var(--theme-grey-s1)'
      }

      return isSeller ? 'var(--theme-primary-s5)' : 'var(--theme-primary-s1)'
    }

    function getBorderColor() {
      if (isGrey) {
        return 'var(--theme-grey-s4)'
      }

      return 'var(--theme-primary-s5)'
    }

    function getTextColor() {
      if (isGrey) {
        return isSeller ? 'white' : 'var(--theme-grey-s4)'
      }

      return isSeller ? 'white' : 'var(--theme-primary-s5)'
    }

    return `
      width: ${look.width}px;
      height: ${look.width}px;
      font-size: ${look.fontSize}px;
      background: ${getBackgroundColor()};
      border-color: ${getBorderColor()};
      color: ${getTextColor()};

      padding-bottom: ${Math.ceil(look.fontSize / 10)}px;
    `
  }}
`
