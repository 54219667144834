import { useQuery } from '@tanstack/react-query'
import SellerApi from '../../../api/SellerApi'
import { GetTenantApiResponse } from '@valuecase/seller-backend'
import { useAuthState } from '../../../auth/auth'
import { useEffect, useState } from 'react'

export function useCurrentTenantQuery() {
  const authState = useAuthState()
  const [tenantId, set_tenantId] = useState<string | undefined>()
  function getTenantQueryFn() {
    return () => SellerApi.get<GetTenantApiResponse, undefined>(`/current-tenant`)
  }

  useEffect(() => {
    if (authState.authorized) {
      set_tenantId(authState.tenant)
    }
  }, [authState])

  const query = useQuery({
    queryKey: ['current-tenant'],
    queryFn: getTenantQueryFn(),
    enabled: !!tenantId, //query won't fire up until tenantId exists. See: https://tanstack.com/query/v4/docs/react/guides/dependent-queries
  })

  return {
    isLoading: query.isLoading,
    isReloading: query.isRefetching && query.isStale,
    isError: query.isError,
    error: query.error,
    tenant: query.data,
    isFetched: query.isFetched,
  }
}
