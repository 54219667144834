import { useReadUsers } from '@/modules/userManagement/hooks/useReadUsers'
import { TeamWithMembers } from '@valuecase/common'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  Input2,
} from '@valuecase/ui-components'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'
import { useCallback, useMemo, useState } from 'react'
import useCreateTeam from '../hooks/useCreateTeam'
import useSelectedMembers from '../hooks/useSelectedMembers'
import UserSelection from './UserSelection'

const CreateTeamDialog = ({
  open,
  onOpenChange,
  onTeamCreated,
}: {
  open: boolean
  onOpenChange: (open: boolean) => void
  onTeamCreated: (team: TeamWithMembers) => void
}) => {
  const [teamName, setTeamName] = useState('')
  const { toggleMember, selectedMembers, resetSelectedMembers } = useSelectedMembers()
  const { users } = useReadUsers()
  const createTeam = useCreateTeam()

  const resetData = useCallback(() => {
    setTeamName('')
    resetSelectedMembers()
  }, [resetSelectedMembers])

  const handleCreateTeam = useCallback(async () => {
    const memberIds = [...selectedMembers].map((member) => member.id)
    const newTeam = await createTeam.mutateAsync({
      name: teamName,
      memberIds,
    })

    onTeamCreated(newTeam)
    resetData()
  }, [selectedMembers, createTeam, teamName, onTeamCreated, resetData])

  const onHandleClose = useCallback(() => {
    resetData()
    onOpenChange(false)
  }, [resetData, onOpenChange])

  const cannotCreateTeam = useMemo(() => teamName === '', [teamName])

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className='flex flex-col items-center p-8 '>
        <div className='flex flex-col gap-2 items-center w-fit h-fit'>
          <DialogTitle>Create Team</DialogTitle>
          <DialogDescription>Create and add members to your new team</DialogDescription>
        </div>

        <div className='flex flex-col w-full'>
          <div className='flex flex-col gap-2'>
            <p className='text-xs font-semibold text-grey-s4'>Team name</p>
            <Input2
              placeholder='Name your team...'
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
            />
          </div>
        </div>

        <div className='flex flex-col w-full gap-8'>
          <div className='flex flex-col gap-2 h-full'>
            <p className='text-xs font-semibold text-grey-s4'>Add members</p>

            <UserSelection
              selectedMembers={selectedMembers}
              toggleMember={toggleMember}
              users={users ?? []}
            />
          </div>
          <div className='flex w-full justify-center'>
            <div className='flex flex-row gap-3 items-center w-full px-5'>
              <Button2
                leadingIcon='close-circle-outline'
                variant={'outlined'}
                onClick={onHandleClose}
                className='w-full'
              >
                Cancel
              </Button2>
              <Button2 className='w-full ' disabled={cannotCreateTeam} onClick={handleCreateTeam}>
                Create Team
              </Button2>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default CreateTeamDialog
