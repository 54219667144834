import SellerApi from '@/api/SellerApi'
import { useQuery } from '@tanstack/react-query'
import { TeamWithMembers, teamWithMembersListSchema } from '@valuecase/common'

const fetchTeams = async () => {
  const res = await SellerApi.get(`teams`)
  const parsed = teamWithMembersListSchema.parse(res.payload)
  return parsed as TeamWithMembers[]
}

const useReadTeams = () => {
  return useQuery(['teams'], () => fetchTeams(), {
    staleTime: Infinity,
    cacheTime: Infinity,
  })
}

export default useReadTeams
