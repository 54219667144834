import React from 'react'

export type ModalHeaderProps = {
  title: string
  text: React.ReactNode
}

export default function ModalHeader({ title, text }: ModalHeaderProps) {
  return (
    <div className={'w-full max-w-[360px]'}>
      <div role='heading' className={'text-2xl leading-8 font-extrabold text-center text-grey-s6'}>
        {title}
      </div>

      <div className={'mt-[8px] text-[14px] leading-[150%] text-center text-grey-s5'}>{text}</div>
    </div>
  )
}
