import {
  IonIcon,
  Skeleton,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@valuecase/ui-components'
import { FC, MouseEventHandler, ReactElement } from 'react'
import { TaskCard } from './TaskCard'
import { TActionPlanTaskExpanded, TActionPlanTasksQuery } from '@valuecase/common'
import {
  TaskCardBodySection,
  TaskCardTaskSubtitle,
  TaskCardContentBackground,
  TaskCardTaskTitle,
  TaskCardTopLabel,
  TaskColumnContentBackground,
  TaskCardBottomSection,
  TaskCardDateLabel,
} from './tasks-ui-helpers'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'

export type TaskColumnDataProps = {
  id: string
  emptyState: ReactElement
  iconClassName: string
  iconName: string
  isLoading: boolean
  tasks: TActionPlanTaskExpanded[]
  tasksCount: number
  taskStatus: TActionPlanTasksQuery['taskStatus']
  title: string
  tooltip?: string
  onViewAllClick: MouseEventHandler<HTMLButtonElement>
}

export const TaskColumnView: FC<TaskColumnDataProps> = ({
  id,
  iconClassName,
  iconName,
  isLoading,
  tasks,
  taskStatus,
  tasksCount,
  title,
  emptyState,
  tooltip,
  onViewAllClick,
}) => {
  return (
    <div
      id={id}
      className='flex flex-col items-start gap-2 relative grow min-h-full min-w-64 w-full'
    >
      <div className='flex items-center gap-2 relative self-stretch w-full'>
        <IonIcon name={iconName} className={iconClassName} />
        <div className='relative text-sm leading-5 w-fit font-bold text-grey-s6 whitespace-nowrap'>
          {!!tooltip && (
            <Tooltip>
              <TooltipTrigger>{title}</TooltipTrigger>
              <TooltipContent align='start'>{tooltip}</TooltipContent>
            </Tooltip>
          )}
          {!tooltip && title}
        </div>
        <div className='inline-flex min-w-4 h-4 items-center justify-center gap-1 px-1 py-0 relative bg-grey-s2 rounded overflow-hidden'>
          <div className='relative w-fit text-xxs font-semibold text-grey-s5 whitespace-nowrap'>
            {tasksCount}
          </div>
        </div>
      </div>
      {/* Show loading state if tasks are being fetched;
          Keep in sync with TaskCardView.tsx element structure */}
      {isLoading && (
        <TaskColumnContentBackground>
          <TaskCardContentBackground>
            <Skeleton className='min-w-4 w-4 h-4 mr-3' />
            <Skeleton className='min-w-8 w-8 h-8' />
            <TaskCardBodySection className='ml-2'>
              <TaskCardTopLabel className='h-3.5'>
                <Skeleton className='w-1/6 h-1.5' />
              </TaskCardTopLabel>
              <TaskCardTaskTitle className='min-h-[18px]'>
                <Skeleton className='w-3/4 h-4' />
              </TaskCardTaskTitle>
              <TaskCardTaskSubtitle className='h-3.5'>
                <Skeleton className='w-1/6 h-1.5' />
              </TaskCardTaskSubtitle>
              <TaskCardBottomSection className='h-4'>
                <div className='flex-1 relative'>{/* Assignee info goes here */}</div>
                <TaskCardDateLabel>
                  <Skeleton className='w-16 h-3' />
                </TaskCardDateLabel>
              </TaskCardBottomSection>
            </TaskCardBodySection>
          </TaskCardContentBackground>
        </TaskColumnContentBackground>
      )}
      {/* Show empty state if no tasks found for the status filter of this column */}
      {!isLoading && !tasks.length && emptyState}
      {/* Show tasks if they exist */}
      {!isLoading && !!tasks.length && (
        <div className='flex flex-col items-start gap-2 p-1 relative self-stretch w-full bg-grey-s1 rounded-[10px] min-h-full grow'>
          <div className='flex flex-col items-start gap-2 relative w-full min-h-full'>
            {tasks.map((task) => (
              <TaskCard key={task.id} task={task} taskStatus={taskStatus} />
            ))}
          </div>
          <div className='flex items-center justify-end gap-1 relative self-stretch w-full'>
            <Button2
              className='w-full text-grey-s5 font-medium'
              leadingIcon='open-outline'
              variant={'plain'}
              onClick={onViewAllClick}
            >
              View all
            </Button2>
          </div>
        </div>
      )}
    </div>
  )
}
