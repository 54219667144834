import * as React from 'react'
import { Command as CommandPrimitive } from 'cmdk'
import { cn } from '../../lib/utils'
import { ComponentPropsWithoutRef, ElementRef, forwardRef, HTMLAttributes } from 'react'
import { Input2 } from './input'
import { CheckMark } from './checkmark/CheckMark'

const Command = forwardRef<
  ElementRef<typeof CommandPrimitive>,
  ComponentPropsWithoutRef<typeof CommandPrimitive>
>(({ className, ...props }, ref) => (
  <CommandPrimitive ref={ref} className={cn('flex flex-col', className)} {...props} />
))
Command.displayName = CommandPrimitive.displayName

const CommandInput = forwardRef<
  ElementRef<typeof CommandPrimitive.Input>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Input>
>(({ className, ...props }, ref) => (
  // eslint-disable-next-line react/no-unknown-property
  <div className={cn('flex items-center mb-4', className)} cmdk-input-wrapper=''>
    <Input2 inputSize={'large'} leadingIcon={'search'} asChild>
      <CommandPrimitive.Input ref={ref} {...props} />
    </Input2>
  </div>
))

CommandInput.displayName = CommandPrimitive.Input.displayName

const CommandList = forwardRef<
  ElementRef<typeof CommandPrimitive.List>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.List>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.List
    ref={ref}
    className={cn('overflow-y-auto overflow-x-hidden', className)}
    {...props}
  />
))

CommandList.displayName = CommandPrimitive.List.displayName

const CommandEmpty = forwardRef<
  ElementRef<typeof CommandPrimitive.Empty>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Empty>
>(({ className, ...props }, ref) => <CommandPrimitive.Empty ref={ref} {...props} />)

CommandEmpty.displayName = CommandPrimitive.Empty.displayName

const CommandItem = forwardRef<
  ElementRef<typeof CommandPrimitive.Item>,
  ComponentPropsWithoutRef<typeof CommandPrimitive.Item> & { noHover?: boolean }
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Item
    ref={ref}
    className={cn(
      'flex select-none items-center gap-2 first:mt-0 mt-2 p-2 rounded transition-colors',
      {
        'hover:bg-grey-s1 cursor-pointer': !props.noHover,
      },
      className,
    )}
    {...props}
  />
))

CommandItem.displayName = CommandPrimitive.Item.displayName

const CommandCheckboxItem = forwardRef<
  ElementRef<typeof CommandItem>,
  ComponentPropsWithoutRef<typeof CommandItem> & { selected: boolean }
>(({ className, children, selected, disabled, ...props }, ref) => (
  <CommandItem
    ref={ref}
    className={cn(
      'relative flex select-none p-2 pr-3 rounded outline-none transition-colors focus:bg-grey-s1 cursor-pointer',
      className,
      { 'text-grey-s3': disabled },
    )}
    noHover={disabled}
    disabled={disabled}
    {...props}
  >
    {/*  todo: think about converting it to a button*/}
    <CheckMark checked={selected} disabled={disabled} />
    {children}
  </CommandItem>
))
CommandCheckboxItem.displayName = CommandItem.displayName

const CommandGroup = React.forwardRef<
  React.ElementRef<typeof CommandPrimitive.Group>,
  React.ComponentPropsWithoutRef<typeof CommandPrimitive.Group>
>(({ className, ...props }, ref) => (
  <CommandPrimitive.Group
    ref={ref}
    className={cn(
      'overflow-hidden [&_[cmdk-group-heading]]:first:mt-0 [&_[cmdk-group-heading]]:my-2 [&_[cmdk-group-heading]]:text-xs [&_[cmdk-group-heading]]:font-semibold [&_[cmdk-group-heading]]:text-grey-s4',
      className,
    )}
    {...props}
  />
))

CommandGroup.displayName = CommandPrimitive.Group.displayName

const CommandItemCheckboxDetails = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLParagraphElement> & {
    name: string
    subtitle?: string
  }
>(({ name, subtitle, children, ...props }, ref) => {
  return (
    <div ref={ref} className={'flex flex-col gap-1 text-sm'} {...props}>
      <span className={'font-medium'}>{name}</span>
      {subtitle && <span className={'text-xs text-grey-s5'}>{subtitle}</span>}
      {children}
    </div>
  )
})

CommandItemCheckboxDetails.displayName = 'CommandItemCheckboxDetails'

export {
  Command,
  CommandInput,
  CommandList,
  CommandEmpty,
  CommandItem,
  CommandCheckboxItem,
  CommandGroup,
  CommandItemCheckboxDetails,
}
