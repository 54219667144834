import { Outlet } from '@tanstack/react-router'
import { defaultTaskSearchTimeRange } from '@valuecase/ui-components'
import { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react'

interface TasksContextValue {
  searchTimeRange?: SearchTimeRange | undefined
  setSearchTimeRange: (searchTimeRange?: SearchTimeRange | undefined) => void
}

type SearchTimeRange = { months: number; days?: never } | { months?: never; days: number }

const TasksContext = createContext<TasksContextValue | undefined>(undefined)

/**
 * Provides a context to access the search time range for tasks across task screens.
 */
export const useTasksContext = () => {
  const context = useContext(TasksContext)
  if (!context) {
    throw new Error('useTasksContext must be used within a TasksProvider')
  }
  return context
}

const TasksProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [searchTimeRange, setSearchTimeRange] = useState<SearchTimeRange | undefined>(
    defaultTaskSearchTimeRange,
  )

  const value = useMemo(
    () => ({ searchTimeRange, setSearchTimeRange }),
    [searchTimeRange, setSearchTimeRange],
  )

  return <TasksContext.Provider value={value}>{children}</TasksContext.Provider>
}

export const TasksLayout: FC = () => (
  <TasksProvider>
    <Outlet />
  </TasksProvider>
)
