import { Button } from '@valuecase/ui-components'
import styled from 'styled-components'

const PageNavigationIndicatorContainer = styled.div`
  display: flex;
  gap: 12px;

  .page-indicator {
    width: 8px;
    height: 8px;
    border-radius: 100%;
  }

  .page-indicator.inactive {
    background: ${({ theme }) => theme.grey.s2};
  }
  .page-indicator.completed {
    background: ${({ theme }) => theme.primary.s3};
  }
  .page-indicator.active {
    background: ${({ theme }) => theme.primary.s5};
  }
`

type PageNavigationIndicatorProps = {
  maxSteps: number
  currentStep: number
}

function PageNavigationIndicator({ maxSteps, currentStep }: PageNavigationIndicatorProps) {
  // If there is only one step, don't show the indicator (otherwise, it's just a single dot)
  if (maxSteps === 1) {
    return <div />
  }
  return (
    <PageNavigationIndicatorContainer>
      {Array.from({ length: maxSteps }).map((_, index) => {
        return (
          <div
            key={index}
            className={`page-indicator
            ${currentStep === index + 1 && 'active'}
            ${currentStep > index + 1 && 'inactive'}
            ${currentStep < index + 1 && 'completed'}`}
          />
        )
      })}
    </PageNavigationIndicatorContainer>
  )
}

const NavigationContainer = styled.div`
  display: flex;
  display: grid;
  grid-template-columns: 3fr max-content 3fr;
  width: calc(100% + 64px);
  margin-left: -32px;
  justify-content: space-between;
  padding: 24px 32px;
  align-items: center;

  .navigation-back {
    button {
      color: ${({ theme }) => theme.grey.s6};
    }
    .ion-icon {
      color: ${({ theme }) => theme.grey.s4};
    }

    button:hover {
      background: ${({ theme }) => theme.grey.s1};
    }
  }
`

type NavigationProps = {
  step: number
  maxSteps: number
  isNextButtonDisabled: boolean
  nextButtonLabel: string
  showSkipButton?: boolean
  nextButtonIcon?: string
  onNext: () => void
  onBack: () => void
  onSkip?: () => void
  intercomTarget?: string
}

export function Navigation({
  step,
  isNextButtonDisabled,
  onNext,
  onBack,
  onSkip,
  maxSteps,
  nextButtonLabel,
  showSkipButton = false,
  intercomTarget,
}: NavigationProps) {
  return (
    <NavigationContainer>
      <div className='navigation-back'>
        {step > 1 && (
          <Button label='Back' onClick={onBack} style='primary-flat' icon='arrow-back-outline' />
        )}
      </div>
      <PageNavigationIndicator maxSteps={maxSteps} currentStep={step} />
      <div className='justify-self-end'>
        {showSkipButton && (
          <Button
            cssStyle={{ marginRight: '8px' }}
            label='Skip'
            onClick={() => (onSkip ? onSkip() : onNext())}
            icon='arrow-forward-outline'
            iconPosition='right'
            style={'outlined'}
          />
        )}
        <Button
          intercomTarget={intercomTarget}
          label={nextButtonLabel}
          disabled={isNextButtonDisabled}
          onClick={onNext}
          icon={'arrow-forward-outline'}
          iconPosition='right'
        />
      </div>
    </NavigationContainer>
  )
}
