import { MenuDropdownItem, ModalControls, SimpleModal, useOverlay } from '@valuecase/ui-components'
import { useRef } from 'react'
import { SpaceArchivingFlow, SpaceArchivingFlowProps } from './SpaceArchivingFlow'

export function SpaceArchivingTrigger(props: SpaceArchivingFlowProps) {
  const overlay = useOverlay()
  const controls = useRef<ModalControls | null>(null)
  function onClick() {
    overlay.show(
      <SimpleModal
        controlsRef={controls}
        header={
          <h2 className={'color-grey-s2 font-extrabold text-2xl my-6 text-center'}>
            {props.isCurrentlyArchived ? 'Unarchive Space' : 'Archive Space'}
          </h2>
        }
        body={
          <SpaceArchivingFlow
            {...props}
            onClose={() => {
              controls.current?.close()
            }}
          />
        }
        maxWidth='554px'
        wide={true}
      />,
    )
  }

  return (
    <MenuDropdownItem
      LeftIcon={'file-tray-outline'}
      label={props.isCurrentlyArchived ? 'Unarchive space' : 'Archive space'}
      onClick={onClick}
    />
  )
}
