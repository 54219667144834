import { useMutation, useQueryClient } from '@tanstack/react-query'
import { SpaceDTO, SpaceInput } from './types'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'
import { useAuthState } from '../../../auth/auth'
import { createLogger } from '@/logging/Logger'

async function postSpaceToBackend(spaceInput: SpaceInput, sub: string) {
  const createSpaceDto: SpaceDTO = {
    templateId: spaceInput.parentTemplateId,
    data: {
      companyName: spaceInput.companyName,
      contactFirstName: spaceInput.contactFirstName,
      contactLastName: spaceInput.contactLastName,
      companyWebsite: spaceInput.websiteUrl,
      language: spaceInput.language,
      companyLogo_s3ObjectKey: spaceInput.logo,
    },
    ownerId: sub,
    integrationIds: spaceInput.integrationIdMappings,
    customProperties: spaceInput.customProperties,
    teamIds: spaceInput.teamIds,
  }

  return await spacesApi.post(`/spaces`, createSpaceDto)
}

export const useCreateSpace = (props?: {
  onSuccess: (space: {
    id: string
    rootNode: null
    type: string
    data: {
      language: string
      companyName: string
      meetingLink: null
      contactLastName: string
      contactFirstName: string
    }
    unversionedData: {
      auth: {
        token: string
      }
    }
    metadata: {
      ownerId: string
      parentTemplateId: string
    }
    children: any[]
    childrenIds: string[]
    createdAt: string
    parentIds: string[]
    virtualData: {}
  }) => void
}) => {
  const auth = useAuthState()
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: (spacesInput: SpaceInput) => {
      if (!auth.authorized) {
        createLogger().error('Authorization missing during space creation.')
        throw new Error('Authorization missing')
      }
      return postSpaceToBackend(spacesInput, auth.sub)
    },
    onSuccess: (space) => {
      queryClient.invalidateQueries({ queryKey: ['spaces'] })

      if (props?.onSuccess) {
        props.onSuccess(space.data)
      }
    },
    onError: (error) => {
      createLogger().error('Failed to create space:')
      if (error && typeof error === 'object' && (error as any).response?.data?.message) {
        createLogger().error(JSON.stringify((error as any).response.data.message))
      } else {
        createLogger().error(JSON.stringify(error))
      }
    },
  })
  return {
    isSuccess: mutation.isSuccess,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    createSpace: (spaceInput: SpaceInput) => mutation.mutate(spaceInput),
    createdSpace: mutation.data,
  }
}
