import { S } from './Topbar.styled'
import TopbarRightSide from './components/topbarRightSide/TopbarRightSide'
import VLCSlogo from './assets/valuecase-logo.svg?react'
import { FC, useMemo } from 'react'
import { useAuthState } from '../../../auth/auth'
import { useCurrentTenantQuery } from '../../tenant/hooks/useReadTenant'
const Topbar: FC = () => {
  const authState = useAuthState()
  const { tenant, isFetched, isReloading, isLoading } = useCurrentTenantQuery()

  const logo = useMemo(() => {
    if (tenant?.payload?.whitelabelTenantLogoUrl) {
      return <img src={tenant?.payload.whitelabelTenantLogoUrl} style={{ marginLeft: '40px' }} />
    }
    return <VLCSlogo style={{ marginLeft: '40px' }} />
  }, [tenant?.payload?.whitelabelTenantLogoUrl])

  if (!authState.authorized || !isFetched || !tenant || isReloading || isLoading) return <></> //TODO Handle these states properly
  return (
    <>
      <S.Topbar>
        {logo}
        <TopbarRightSide
          name={authState.name}
          imgUrl={authState.profilePictureUrl || ''}
          role={authState.role?.name}
        />
      </S.Topbar>
      <S.CompanyName>
        <span>{tenant?.payload?.tenantName || ''}</span>
      </S.CompanyName>
    </>
  )
}

export default Topbar
