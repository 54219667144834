import axios from 'axios'
import { ClientProblemDetailSuperType, createTypeSafeAxios } from '@tectonique/api-standards-client'
import { ProblemDetailSuperType } from '@valuecase/seller-backend'
import { addAccessTokenRequestInterceptor } from '../auth/auth'

const SellerApiAxios = axios.create({
  baseURL: `/dashboard/api`,
})

// Let the SellerApi Axios instance use the latest access token
addAccessTokenRequestInterceptor(SellerApiAxios)

const typeSafeAxios = createTypeSafeAxios<ProblemDetailSuperType | ClientProblemDetailSuperType>(
  SellerApiAxios,
)

export const handleProblemDetail = typeSafeAxios.handleProblemDetail
export const problemDetailHandler = typeSafeAxios.createProblemDetailHandler
export default typeSafeAxios.verbs
