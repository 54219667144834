export default {
  lottieContainer: 1000,
  inputPopup: 570,
  rte: 560,
  baseDropdown: 460,
  overlay: 455,
  deprecatedOldModals: 450,
  filePopup: 440,
  previewEscapeHint: 430,
  basePortalDefault: 420,
  sortableDraggedItem: 400,

  topbar: {
    smallScreen: 310,
    bigScreen: 300,
    bigScreenOverlay: 301,
  },
}
