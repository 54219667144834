import styled from 'styled-components'

export const UserProfileEditorLayout = styled.div``

export const UserProfileEditorInputGrid = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 44px;
  column-gap: 1em;
  row-gap: 1em;
  align-items: center;
  background: var(--theme-grey-s1);
  padding: 24px 32px;
  label {
    color: var(--theme-grey-s6);
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    width: 248px;
  }
  img {
    width: 44px;
    height: 44px;
    border: 1px solid var(--theme-grey-s2);
    border-radius: 8px;
    object-fit: fill;
  }
  input {
    height: 44px;
  }
`

export const UserProfileEditorFooter = styled.div`
  display: flex;
  justify-content: end;
  padding-right: 32px;
  padding-top: 24px;
`

export const MultipleElementsInGridRow = styled.div`
  display: flex;
  gap: 8px;
`
