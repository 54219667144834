import React, { FC, useEffect, useRef, useState } from 'react'
import {
  MultipleElementsInGridRow,
  UserProfileEditorFooter,
  UserProfileEditorInputGrid,
  UserProfileEditorLayout,
} from './UserProfileEditorParts'
import { UserProfileEditorViewProps } from '../types/UserProfileEditorViewProps'
import { Button, Input, InputSize } from '@valuecase/ui-components'
import styled from 'styled-components'
import { useTrackEvent } from '../../../mixpanel/useTrackEvent'

const Header = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-bottom: 40px;
  gap: 8px;

  h1 {
    font-size: 24px;
    font-weight: 900;
    color: var(--theme-grey-s6);
    margin: 0;
    padding: 0;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: var(--theme-grey-s5);
    margin: 0;
    padding: 0;
  }
`

const UserProfileEditorView: FC<UserProfileEditorViewProps> = ({
  error,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  titleDescription,
  setTitleDescription,
  phone,
  setPhone,
  meetingLink,
  setMeetingLink,
  contactEmail,
  setContactEmail,
  updateUserProfile,
  handleProfilePhotoSelected,
  profilePhoto,
  resetProfilePhoto,
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const [profilePhotoAsDataUrl, setProfilePhotoAsDataUrl] = useState<string | undefined>(undefined)
  const { trackEvent } = useTrackEvent()

  useEffect(() => {
    if (!profilePhoto) {
      setProfilePhotoAsDataUrl(undefined)
      return
    }
    readFile(profilePhoto).then((dataUrl) => {
      setProfilePhotoAsDataUrl(dataUrl)
    })
  }, [profilePhoto])

  async function readFile(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.onload = function () {
        resolve(fileReader.result as string)
      }
      fileReader.onerror = function () {
        reject(`Error reading file ${file.name}.`)
      }
      fileReader.readAsDataURL(file)
    })
  }

  return (
    <UserProfileEditorLayout>
      <Header>
        <h1>Profile Information</h1>
        <p>Edit your profile here.</p>
      </Header>
      {!!error && typeof error === 'object' && typeof error.message === 'string' && (
        <div
          style={{
            fontWeight: 'bold',
            color: 'darkred',
          }}
        >
          {error.message}
        </div>
      )}
      {!!error && (typeof error !== 'object' || !error.message) && (
        <div
          style={{
            fontWeight: 'bold',
            color: 'darkred',
          }}
        >
          {'Failed to create template, please try again later'}
        </div>
      )}
      <UserProfileEditorInputGrid>
        <label htmlFor='profilePhoto'>Profile Picture</label>
        {!profilePhotoAsDataUrl && (
          <>
            <Button
              label={'Upload'}
              icon={'cloud-upload-outline'}
              iconPosition={'right'}
              onClick={() => {
                if (!fileInputRef.current) return
                fileInputRef.current.click()
              }}
            />
            <input
              style={{ display: 'none' }}
              ref={fileInputRef}
              type='file'
              accept='image/*'
              multiple={false}
              onChange={handleProfilePhotoSelected}
            />
          </>
        )}
        {profilePhotoAsDataUrl && (
          <MultipleElementsInGridRow>
            <img src={profilePhotoAsDataUrl} />
            <Button
              label={'Delete image'}
              icon={'trash-outline'}
              iconPosition={'right'}
              onClick={resetProfilePhoto}
              style={'warning-outlined'}
            />
          </MultipleElementsInGridRow>
        )}
        <label>Name</label>
        <MultipleElementsInGridRow>
          <Input
            size={InputSize.LARGE}
            value={firstName}
            placeholder={'First Name'}
            onInput={(input) => setFirstName(input)}
          />
          <Input
            size={InputSize.LARGE}
            value={lastName}
            placeholder={'Last Name'}
            onInput={(input) => setLastName(input)}
          />
        </MultipleElementsInGridRow>
        <label>Job title</label>
        <Input
          placeholder={'Enter title description'}
          value={titleDescription}
          onInput={(input) => setTitleDescription(input)}
          size={InputSize.LARGE}
        />
        <label>Phone Number</label>
        <Input
          value={phone}
          placeholder={'Enter phone number'}
          onInput={(input) => setPhone(input)}
          size={InputSize.LARGE}
        />
        <label>Book-a-meeting Link</label>
        <Input
          placeholder={'Enter link'}
          value={meetingLink}
          onInput={(input) => setMeetingLink(input)}
          size={InputSize.LARGE}
        />
        <label>Contact email</label>
        <Input
          placeholder={'Enter contact email'}
          value={contactEmail}
          onInput={(input) => setContactEmail(input)}
          size={InputSize.LARGE}
        />
      </UserProfileEditorInputGrid>
      <UserProfileEditorFooter>
        <Button
          label='Apply changes'
          icon={'chevron-forward-outline'}
          iconPosition={'right'}
          onClick={() => {
            trackEvent({
              event: 'profile-edit-confirm',
              eventProperties: {
                indexPageName: 'Edit User Profile',
              },
            })
            updateUserProfile()
          }}
          cssStyle={{ marginBottom: '24px' }}
        />
      </UserProfileEditorFooter>
    </UserProfileEditorLayout>
  )
}

export default UserProfileEditorView
