import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReusableTable from '../../reusabletable/ReusableTable'

import useReadTeams from '@/modules/teams/hooks/useReadTeams'
import { createColumnHelper } from '@tanstack/react-table'
import { TeamWithMembers, User } from '@valuecase/common'
import { ListRolesApiResponse } from '@valuecase/seller-backend'
import {
  AlertDialog,
  AlertDialogContent,
  Button,
  IonIcon,
  MenuDropdown,
  MenuDropdownItem,
  MenuDropdownSeparator,
} from '@valuecase/ui-components'
import { useTheme } from 'styled-components'
import { useAuthState } from '../../../auth/auth'
import { useTrackEvent } from '../../../mixpanel/useTrackEvent'
import { useNotifications } from '../../../utils/Notifications/Notifications'
import { useReadAvailableRoles } from '../../auth/hooks/useReadAvailableRoles'
import HeaderCol from '../../reusabletable/header/Header.column'
import { useCancelUser } from '../hooks/useCancelUser'
import { useInviteUser } from '../hooks/useInviteUser'
import { useUpdateUserRole } from '../hooks/useUpdateUserRole'
import { useDeleteUserModal } from '../modals/deleteUser/DeleteUserModal'
import EditUserModal from '../modals/editUser/EditUserModal'
import {
  ActiveUserCountIcon,
  CurrentUserIcon,
  UserListIcon,
  UserRoleButton,
  UserRoleButtonTopText,
  UserRoleDescription,
  UserRoleSelectionItem,
  UserRoleSelectionItemDescription,
  UserRoleSelectionItemHeader,
  UserRoleSelectionWrapper,
  UserRoleWrapper,
  UsersListEmailTextWrapper,
  UsersListIconBox,
  UsersListInvitedPlacedholder,
  UsersListNameAndDescriptionWrapper,
  UsersListNameTextWrapper,
  UsersListNameWrapper,
  UsersListTableWrapper,
} from './UsersLists.styled'
import { useLDflags } from '@/launchdarkly/Launchdarkly'
import { useQueryClient } from '@tanstack/react-query'
import { VLoaderAnimation } from '@/ui-components/VLoader/VLoader'

interface Props {
  users: User[]
}

const UsersList: FC<Props> = ({ users }) => {
  const { trackEvent } = useTrackEvent()
  const columnHelper = createColumnHelper<User>()
  const authState = useAuthState()
  const theme = useTheme()
  const { flags } = useLDflags()
  const { updateUserRole } = useUpdateUserRole()
  const { roles } = useReadAvailableRoles()
  const userId = authState.authorized === true && authState.sub
  const roleName = (authState.authorized === true && authState.role?.name) || ''
  const { success } = useNotifications()
  const { show: showDeleteUserModal } = useDeleteUserModal()
  //Used to track "users-role-change" mixpanel event
  const [oldRoleForMixpanel, set_oldRoleForMixpanel] = useState<string | undefined>(undefined)
  const [selectedUser, setSelectedUser] = useState<User | null>(null)
  const { data: teams } = useReadTeams()

  const renderTeamsList = useCallback(
    (userId: string): JSX.Element => {
      const userTeams = teams?.filter((team) => team.memberIds.includes(userId))
      const sortedUserTeams = userTeams?.sort((a, b) => a.name.localeCompare(b.name))
      const userTeamNames = sortedUserTeams?.map((userTeam) => userTeam.name).join(', ')
      return <div className='text-xs'>{userTeamNames}</div>
    },
    [teams],
  )

  const queryClient = useQueryClient()

  const { inviteUser } = useInviteUser(() => {
    success('User invite resent')
  })

  const [cancellingInvite, setCancellingInvite] = useState(false)
  const cancellingRefreshTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)

  const { cancelUser } = useCancelUser(() => {
    // The Auth0 backend takes a few seconds to process invite cancellation, so we show a loading spinner
    cancellingRefreshTimeout.current = setTimeout(() => {
      queryClient.invalidateQueries({ queryKey: ['readUsers'] })
      setCancellingInvite(false)
      success('User invite canceled')
    }, 5000)
  })

  useEffect(() => {
    return () => {
      if (cancellingRefreshTimeout.current) {
        clearTimeout(cancellingRefreshTimeout.current)
        cancellingRefreshTimeout.current = null
      }
    }
  }, [])

  const roleIdToRoleMap = useMemo(
    () =>
      new Map<string, ListRolesApiResponse[0]>(
        roles?.map((role) => [role.id, role as ListRolesApiResponse[0]]),
      ),
    [roles],
  )

  const cancelUserInvite = useCallback(
    (userId: any) => {
      cancelUser(userId)
    },
    [cancelUser],
  )

  const resendInvite = useCallback(
    (user: any) => {
      const splitName = user.fullName.split(' ')
      const firstName = splitName[0]
      const lastName = splitName[1]
      inviteUser({
        email: user.email,
        firstName,
        lastName,
        roleId: user.roleId,
        teamIds: [],
      })
    },
    [inviteUser],
  )

  const columns = useMemo(() => {
    const columns = [
      columnHelper.accessor('fullName', {
        id: 'fullName',
        header: (h) => (
          <HeaderCol headerContext={h} label='Active Users' minHeight='44px'>
            <ActiveUserCountIcon>{users.length}</ActiveUserCountIcon>
          </HeaderCol>
        ),
        cell: (ctx) => (
          <UsersListNameWrapper>
            <UsersListIconBox>
              {!!ctx.row.original.profilePictureUrl && (
                <UserListIcon src={ctx.row.original.profilePictureUrl} />
              )}
            </UsersListIconBox>
            <UsersListNameAndDescriptionWrapper>
              <UsersListNameTextWrapper>
                {ctx.row.original.invited ? (
                  <UsersListInvitedPlacedholder>Invitation sent</UsersListInvitedPlacedholder>
                ) : (
                  ctx.renderValue()
                )}
              </UsersListNameTextWrapper>
            </UsersListNameAndDescriptionWrapper>
            {userId === ctx.row.original.id ? <CurrentUserIcon>YOU</CurrentUserIcon> : null}
          </UsersListNameWrapper>
        ),
        enableSorting: false,
        sortingFn: 'alphanumeric',
      }),
      columnHelper.accessor('email', {
        id: 'email',
        header: (h) => (
          <HeaderCol
            headerContext={h}
            label='Email'
            onClick={() => {
              trackEvent({
                event: 'users-sort',
                eventProperties: {
                  indexPageName: 'User Management',
                  columnName: 'Email',
                },
              })
            }}
          />
        ),
        cell: (ctx) => <UsersListEmailTextWrapper>{ctx.renderValue()}</UsersListEmailTextWrapper>,
        size: flags?.['teams-management'] ? 250 : 300,
        enableSorting: true,
        invertSorting: false,
      }),
      columnHelper.display({
        id: 'role',
        header: (h) => <HeaderCol headerContext={h} label='Role & Permissions' />,
        cell: (ctx) => {
          // If some users are available from auth0 but they do not have authorizations
          // in the local db, then their user role is unknown and we cannot assign new
          // roles to them.
          const userRoleIsKnown = !!ctx.row.original.roleId
          const isOwnUserRow = ctx.row.original.id === userId
          const userRoleName =
            (ctx.row.original.roleId && roleIdToRoleMap.get(ctx.row.original.roleId)?.name) ||
            'Unknown'

          return (
            <div className={'text-xs'}>
              {!userRoleIsKnown && 'Unknown role'}
              {userRoleIsKnown && isOwnUserRow && (
                // Special row for the current user because they cannot edit their own role
                <UserRoleWrapper>
                  <UserRoleButton disabled={true}>
                    <UserRoleButtonTopText>{userRoleName}</UserRoleButtonTopText>
                    <UserRoleDescription>
                      Permissions:{' '}
                      {!ctx.row.original.roleId
                        ? 'Unknown'
                        : roleIdToRoleMap.get(ctx.row.original.roleId)?.description || ''}
                    </UserRoleDescription>
                  </UserRoleButton>
                </UserRoleWrapper>
              )}
              {userRoleIsKnown && !isOwnUserRow && (
                // Drop down showing available roles, allowing the role of current user to be changed
                <UserRoleWrapper>
                  <MenuDropdown
                    Trigger={
                      <UserRoleButton
                        onClick={() => {
                          set_oldRoleForMixpanel(
                            roleIdToRoleMap.get(ctx.row.original.roleId as string)?.name,
                          )
                        }}
                      >
                        <UserRoleButtonTopText>
                          {(ctx.row.original.roleId &&
                            roleIdToRoleMap.get(ctx.row.original.roleId)?.name) ||
                            'Unknown'}
                          <IonIcon
                            name='chevron-down'
                            style={{
                              height: '16px',
                              width: '16px',
                              marginLeft: '4px',
                              position: 'relative',
                              top: '4px',
                              color: theme.grey.s4,
                            }}
                          />
                        </UserRoleButtonTopText>
                        <UserRoleDescription>
                          Permissions:{' '}
                          {!ctx.row.original.roleId
                            ? 'Unknown'
                            : roleIdToRoleMap.get(ctx.row.original.roleId)?.description || ''}
                        </UserRoleDescription>
                      </UserRoleButton>
                    }
                    placement={'bottom-start'}
                  >
                    <UserRoleSelectionWrapper>
                      {!!roles &&
                        roles.map((role, i) => (
                          <div key={`${ctx.row.original.id}-${role.id}`}>
                            {i !== 0 && <MenuDropdownSeparator />}
                            <UserRoleSelectionItem
                              onClick={() => {
                                trackEvent({
                                  event: 'users-role-change',
                                  eventProperties: {
                                    indexPageName: 'User Management',
                                    permissionTypeSet: role.name,
                                    oldPermissionType: oldRoleForMixpanel,
                                  },
                                })
                                set_oldRoleForMixpanel(undefined)
                                updateUserRole({
                                  roleId: role.id,
                                  userId: ctx.row.original.id,
                                })
                              }}
                            >
                              <UserRoleSelectionItemHeader>
                                {role.name}
                                {role.id === ctx.row.original.roleId && (
                                  <IonIcon
                                    name='checkmark'
                                    style={{
                                      height: '20px',
                                      width: '20px',
                                      color: theme.grey.s4,
                                      marginLeft: '8px',
                                      position: 'relative',
                                      top: '4px',
                                    }}
                                  />
                                )}
                              </UserRoleSelectionItemHeader>
                              <UserRoleSelectionItemDescription>
                                {role.description}
                              </UserRoleSelectionItemDescription>
                            </UserRoleSelectionItem>
                          </div>
                        ))}
                    </UserRoleSelectionWrapper>
                  </MenuDropdown>
                </UserRoleWrapper>
              )}
            </div>
          )
        },
        size: flags?.['teams-management'] ? 250 : 450,
        enableSorting: true,
        invertSorting: false,
      }),
    ]
    if (flags?.['teams-management']) {
      columns.push(
        columnHelper.display({
          id: 'teams',
          size: 250,
          header: (h) => <HeaderCol headerContext={h} label='Teams' />,
          cell: (ctx) => {
            return renderTeamsList(ctx.row.original.id)
          },
        }),
      )
    }
    columns.push(
      columnHelper.display({
        id: 'actionColumn',
        header: (h) => (
          <HeaderCol
            // width={`${((window.innerWidth / 100) * 5.63).toString()}px`} //'108px' in figma
            headerContext={h}
            label='Actions'
          />
        ),
        size: 66,
        cell: (ctx) => {
          const spaceId = ctx.row.original.id
          const isUserActive = (ctx.row.original as any).active == true
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'start',
                paddingRight: '24px',
                gap: '12px',
              }}
            >
              <div>
                <MenuDropdown
                  Trigger={<Button icon='ellipsis-vertical' style='flat-sidebar' />}
                  placement={'bottom-start'}
                >
                  {!isUserActive && (
                    <>
                      <MenuDropdownItem
                        label='Resend invite'
                        onClick={() => {
                          trackEvent({
                            event: 'users-newuser-resend',
                            eventProperties: {
                              indexPageName: 'User Management',
                            },
                          })
                          resendInvite(ctx.row.original)
                        }}
                        LeftIcon={'send-outline'}
                        variant='default'
                      />
                      <MenuDropdownItem
                        onClick={() => {
                          trackEvent({
                            event: 'users-newuser-cancel_existing_invite',
                            eventProperties: {
                              indexPageName: 'User Management',
                            },
                          })
                          setCancellingInvite(true)
                          cancelUserInvite(ctx.row.original.id)
                        }}
                        label='Cancel user invite'
                        LeftIcon={'close-circle-outline'}
                        variant='danger'
                      />
                    </>
                  )}

                  {isUserActive && (
                    <MenuDropdownItem
                      label='Edit'
                      onClick={() => {
                        setSelectedUser(ctx.row.original)
                      }}
                      LeftIcon={'create-outline'}
                      variant='default'
                    />
                  )}

                  {isUserActive && (
                    <MenuDropdownItem
                      label='Delete user'
                      onClick={() => {
                        showDeleteUserModal({
                          user: {
                            id: ctx.row.original.id,
                            email: ctx.row.original.email,
                            fullName: ctx.row.original.fullName,
                          },
                        })
                      }}
                      LeftIcon={'trash-outline'}
                      variant='danger'
                    />
                  )}
                </MenuDropdown>
              </div>
            </div>
          )
        },
      }),
    )

    return columns
  }, [
    columnHelper,
    flags,
    users.length,
    userId,
    trackEvent,
    roleIdToRoleMap,
    theme.grey.s4,
    roles,
    oldRoleForMixpanel,
    updateUserRole,
    renderTeamsList,
    resendInvite,
    cancelUserInvite,
    showDeleteUserModal,
  ])

  return (
    <UsersListTableWrapper>
      <ReusableTable
        transparentBackground={true}
        showOuterBorder={false}
        maxWidth={1112} // From Figma
        defaultSortedBasedOnColumnId={'email'}
        defaultSortDirection='asc'
        filterBasedOnColumnId={'fullName'}
        data={users}
        columns={columns}
        searchPlaceholder={'Search users...'}
        enablePagination={false}
        onFinishTypingInSearchInput={() => {
          trackEvent({
            event: 'users-search',
            eventProperties: {
              indexPageName: 'User Management',
            },
          })
        }}
      />
      <EditUserModal
        teams={teams as TeamWithMembers[]}
        user={selectedUser}
        onClose={() => setSelectedUser(null)}
      />
      <AlertDialog open={cancellingInvite}>
        <AlertDialogContent className={'min-h-[500px]'}>
          <VLoaderAnimation />
        </AlertDialogContent>
      </AlertDialog>
    </UsersListTableWrapper>
  )
}

export default UsersList
