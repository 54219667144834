import { cn } from '../../lib'

export const Divider = ({ text, className }: { text?: string; className?: string }) => {
  return (
    <div className={cn('flex items-center gap-2', className)}>
      <div className='flex-grow h-[1px] bg-grey-s2'></div>
      {!!text && (
        <>
          <div className='text-grey-s4 font-semibold text-xs uppercase'>{text}</div>
          <div className='flex-grow h-[1px] bg-grey-s2'></div>
        </>
      )}
    </div>
  )
}
