import ModalHeader from './ModalHeader'
import { Button } from '@valuecase/ui-components'
import SelectNewSpaceOwner from '../../../../ui-components/users/SelectNewSpaceOwner'
import { useState } from 'react'

export type ChangeSpaceOwnerBodyProps = {
  user: {
    id: string
    email: string
    fullName?: string
  }
  onCancel: () => void
  onChangeSpaceOwner: (newSpaceOwner: { id: string; name: string }) => void
}

export default function ChangeSpaceOwnerBody({
  user,
  onCancel,
  onChangeSpaceOwner,
}: ChangeSpaceOwnerBodyProps) {
  const [newSpaceOwner, setNewSpaceOwner] = useState<{ id: string; name: string } | undefined>()

  return (
    <div className={'flex flex-col items-center gap-12'}>
      <ModalHeader
        title={'Change space owner'}
        text={
          <span>
            <span className={'font-bold'}>{user.fullName ?? user.email}</span> will no longer have
            access to Valuecase. In order to do so, select another user to take over ownership of
            the users spaces.
          </span>
        }
      />

      <div className={'w-full max-w-[380px]'}>
        <SelectNewSpaceOwner
          onNewSpaceOwnerSelected={setNewSpaceOwner}
          currentSpaceOwnerId={user.id}
        />
      </div>

      <div className={'flex flex-row justify-evenly items-stretch gap-2'}>
        <Button
          label={'Cancel'}
          icon={'close-circle-outline'}
          style={'gray-outlined'}
          cssStyle={{ minWidth: '155px' }}
          onClick={onCancel}
        />

        <Button
          disabled={!newSpaceOwner}
          label={'Change owner'}
          icon={'shuffle-outline'}
          cssStyle={{ minWidth: '155px' }}
          onClick={() => {
            if (newSpaceOwner) {
              onChangeSpaceOwner(newSpaceOwner)
            }
          }}
        />
      </div>
    </div>
  )
}
