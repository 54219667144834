import styled from 'styled-components'

export const Container = styled.div`
  .divider {
    width: 100%;
    height: 10px;
    border-top: 1px solid ${({ theme }) => theme.grey.s2};
  }

  .page-header {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .title {
      font-size: 24px;
      font-weight: 750;
      color: ${({ theme }) => theme.grey.s6};
      margin: 0;
    }

    .subtitle {
      font-size: 14px;
      color: ${({ theme }) => theme.grey.s5};
      margin: 0;
      line-height: 155%;

      a {
        text-decoration: none;
        color: ${({ theme }) => theme.primary.s5};
        font-weight: 600;
      }
      a:visited {
        color: ${({ theme }) => theme.primary.s5};
      }
    }
  }

  .activity-feed {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 20px;
    flex: 1;
    position: relative;

    .filter-container {
      display: flex;
      width: 100%;
      position: absolute;
      top: -30px;
      justify-content: flex-end;
    }

    .empty-state {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      p {
        color: ${({ theme }) => theme.grey.s5};
      }

      .day-entry {
        margin-top: 56px;
      }
    }
    .day-entry {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .day-title {
        font-size: 14px;
        font-weight: 650;
        margin: 0;
        text-transform: capitalize;
      }

      .day-entries {
        .activity-empty-state-placeholder {
          width: 100%;
        }
        a {
          text-decoration: none;
        }
        .activity-container {
          width: 100%;
          border: 1px solid ${({ theme }) => theme.grey.s2};
          background-color: white;
          border-top: 0px;
          padding: 8px;
          display: flex;
          font-size: 14px;
          gap: 12px;
          align-items: center;

          &:hover {
            background-color: ${({ theme }) => theme.grey.s1};
          }

          p {
            margin: 0;
          }

          .space-logo {
            margin-left: 8px;
            min-width: 44px;
            height: 44px;
            width: 44px;
            border-radius: 8px;
            overflow: hidden;
            border: 1px solid ${({ theme }) => theme.grey.s2};
            display: flex;
            background-color: ${({ theme }) => theme.grey.s1};

            img {
              width: 100%;
              height: auto;
              margin-top: auto;
              margin-bottom: auto;
            }
          }

          .right-side {
            display: flex;
            width: 100%;
            flex-direction: column;
            margin-top: 4px;
            margin-bottom: 4px;
            position: relative;

            .bottom-row {
              display: flex;
              gap: 8px;
              align-items: center;

              p {
                margin: 0;
                color: ${({ theme }) => theme.grey.s5};
                line-height: 19px;
                font-size: 14px;
              }
              a {
                text-decoration: none;
              }
              a:hover {
                text-decoration: underline;
              }
            }

            .activity-title {
              display: flex;
              gap: 4px;
              align-items: center;
              color: ${({ theme }) => theme.grey.s5};

              .activity-icon {
                display: flex;
                align-content: center;
                color: ${({ theme }) => theme.primary.s4};
              }

              .activity-body {
                font-size: 14px;
                line-height: 19px;
                color: ${({ theme }) => theme.grey.s6};
              }

              .bold {
                font-weight: 700;
                color: ${({ theme }) => theme.grey.s6};
              }

              .italic {
                font-style: italic;
                color: ${({ theme }) => theme.grey.s6};
              }
            }

            .row-indicator {
              position: absolute;
              right: 16px;
              top: 50%;
              transform: translateY(-50%);
              display: flex;
              align-items: center;
              gap: 12px;

              .new-indicator {
                background-color: ${({ theme }) => theme.primary.s2};
                padding: 4px 6px;
                border-radius: 4px;

                p {
                  margin: 0;
                  font-size: 12px;
                  font-weight: 600;
                  color: ${({ theme }) => theme.primary.s5};
                }
              }
            }
          }
        }

        a:first-child .activity-container {
          border-radius: 8px 8px 0 0;
          border: 1px solid ${({ theme }) => theme.grey.s2};
        }

        a:last-child .activity-container {
          border-radius: 0 0 8px 8px;
        }
      }
    }
  }
`
