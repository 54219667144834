import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui'
import { useEffect, useState } from 'react'

const DEFAULT_FONT = 'Open Sans'

export const customFonts = [
  'Alexandria',
  'Montserrat',
  'Roboto',
  'Roboto Slab',
  'Space Grotesk',
  'Inter',
] as const

const supportedFonts = [DEFAULT_FONT, ...customFonts] as const

const supportedFontSpecs: {
  [K in (typeof customFonts)[number]]: string
} = {
  Montserrat: 'ital,wght@0,100..900;1,100..900',
  'Roboto Slab': 'wght@100..900',
  'Space Grotesk': 'wght@100..900',
  Alexandria: 'wght@100..900',
  Roboto: 'ital,wght@0,100..900;1,100..900',
  Inter: 'ital,wght@0,100..900;1,100..900',
}

export const FontPicker = ({
  value,
  onValueChange,
  disabled,
  className,
}: {
  value: (typeof customFonts)[number] | null
  onValueChange: (
    newValue:
      | { fontFamily: null }
      | { fontFamily: (typeof customFonts)[number]; fontFamilySpec: string },
  ) => void
  disabled?: boolean
  className?: string
}) => {
  const [internalValue, setInternalValue] = useState<(typeof supportedFonts)[number]>(DEFAULT_FONT)

  useEffect(() => {
    setInternalValue(value || DEFAULT_FONT)
  }, [value])

  return (
    <Select
      disabled={disabled}
      value={internalValue}
      onValueChange={(newValue) => {
        if (newValue === DEFAULT_FONT) {
          onValueChange({
            fontFamily: null,
          })
          return
        }
        onValueChange({
          fontFamily: newValue as (typeof customFonts)[number],
          fontFamilySpec: supportedFontSpecs[newValue as (typeof customFonts)[number]],
        })

        setInternalValue(newValue as (typeof supportedFonts)[number])
      }}
    >
      <SelectTrigger className={className}>
        <SelectValue placeholder='Select a font family' />
      </SelectTrigger>
      <SelectContent>
        {supportedFonts.map((font) => (
          <SelectItem key={font} value={font}>
            {font}
            {font === DEFAULT_FONT && <span className={'text-grey-s4 ml-1'}>(Default)</span>}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  )
}
