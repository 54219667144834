import { FC, useMemo } from 'react'
import {
  TemplateCreatorFactsWrapper,
  TemplateCreatorPreviewImage,
  TemplateCreatorPreviewImageWrapper,
  TemplateCreatorTemplateFactsDescription,
  TemplateCreatorTemplateFactsHeader,
  TemplateCreatorTemplateFactsHeaderIcon,
  TemplateCreatorTemplateFactsHeaderText,
} from './TemplateCreatorParts.styled'
import { TemplateCreatorTemplateFactsProps } from '../types/TemplateCreatorTemplateFactsProps'
import blankTemplateSrc from './blank.jpg'
import { formatDateShort } from '@valuecase/common'
import { DateTime } from 'luxon'
import { useCurrentTenantQuery } from '@/modules/tenant/hooks/useReadTenant'
export const TemplateCreatorFacts: FC<TemplateCreatorTemplateFactsProps> = ({
  template,
  isAnEmptyTemplate: startFromScratch,
}) => {
  const { tenant } = useCurrentTenantQuery()

  const showTenantTemplateDetails = useMemo(() => {
    return template?.createdAt !== undefined && template?.spacesCreated !== undefined
  }, [template])

  const previewImageUrl = Array.isArray(template?.previewImages) && template?.previewImages[0]?.url
  return (
    <TemplateCreatorFactsWrapper>
      {!!template && (
        <>
          {startFromScratch && (
            <TemplateCreatorPreviewImageWrapper>
              <TemplateCreatorPreviewImage src={blankTemplateSrc} />
            </TemplateCreatorPreviewImageWrapper>
          )}
          {previewImageUrl && (
            <TemplateCreatorPreviewImageWrapper>
              <TemplateCreatorPreviewImage src={previewImageUrl} />
            </TemplateCreatorPreviewImageWrapper>
          )}
          <TemplateCreatorTemplateFactsHeader>
            <TemplateCreatorTemplateFactsHeaderIcon>
              {template.emojiIcon || '🗒️'}
            </TemplateCreatorTemplateFactsHeaderIcon>{' '}
            <TemplateCreatorTemplateFactsHeaderText>
              {template?.title}
            </TemplateCreatorTemplateFactsHeaderText>
          </TemplateCreatorTemplateFactsHeader>
          <TemplateCreatorTemplateFactsDescription>
            {template.description}
          </TemplateCreatorTemplateFactsDescription>

          {showTenantTemplateDetails && (
            <div className={'flex flex-wrap gap-2 px-8 py-4'}>
              <div
                className={
                  // that's a legacy style which is just aligned to the create space dialog, replace once
                  'py-1.5 px-[7px] rounded-lg bg-primary-s2 text-xxs font-[750] text-[rgb(119,_147,_255)]'
                }
              >
                created{' '}
                {formatDateShort(DateTime.fromISO(template.createdAt), tenant?.payload?.locale)}
              </div>

              <div
                className={
                  'py-1.5 px-[7px] rounded-lg bg-primary-s2 text-xxs font-[750] text-[rgb(119,_147,_255)]'
                }
              >
                <p>{template.language || 'English'}</p>
              </div>

              <div
                className={
                  'py-1.5 px-[7px] rounded-lg bg-primary-s2 text-xxs font-[750] text-[rgb(119,_147,_255)]'
                }
              >
                {template.spacesCreated} {template.spacesCreated === 1 ? 'Space' : 'Spaces'}
              </div>
            </div>
          )}
        </>
      )}
    </TemplateCreatorFactsWrapper>
  )
}
