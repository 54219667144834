import { IonIcon } from '@valuecase/ui-components'
import { S } from './NoSpacesFound.styled'
import TriggerCreateSpaceFlow from './spaceCreationFlow/SpaceCreatorTrigger'
import SpacesEmptyState from '../assets/spaces_empty_state.png'
import { useNavigate } from '@tanstack/react-router'
import classNames from 'classnames'
import { useHasPermission } from '../../../auth/permissions'

function GoToTemplatesButton() {
  const nav = useNavigate()
  return (
    <button className={'templates-button'} onClick={() => nav({ to: '/templates' })}>
      <IonIcon name={'grid-outline'} style={{ width: '18px', height: '18px' }} />
      <p>Go to templates</p>
    </button>
  )
}

interface NoSpacesFoundProps {
  hasTemplates?: boolean
}
const NoSpacesFound = ({ hasTemplates }: NoSpacesFoundProps) => {
  const canManageTemplates = useHasPermission('TEMPLATES_MANAGE')
  return (
    <S.NoSpacesFound>
      {hasTemplates ? (
        <TriggerCreateSpaceFlow />
      ) : (
        <div className={'centered'}>
          {canManageTemplates && <GoToTemplatesButton />}
          <p
            className={classNames({
              'templates-description': true,
              cannotManageTemplates: !canManageTemplates,
            })}
          >
            {canManageTemplates
              ? 'Create a template first in order to create your first space.'
              : 'You have no templates yet. Please ask the person in your team who is responsible for templates to create one.'}
          </p>
        </div>
      )}
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
        <img
          src={SpacesEmptyState}
          className='spaces-empty-state-placeholder'
          alt='empty spaces list'
        />
      </div>
    </S.NoSpacesFound>
  )
}

export default NoSpacesFound
