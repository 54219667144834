import styled from 'styled-components'

const SpaceNameColumn = styled.div`
  gap: 12px;
  display: flex;
  align-items: center;
`

const SpaceLogo = styled.div<{ $imgUrl: string }>`
  display: inline-block;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url(${({ $imgUrl: imgUrl }) => imgUrl});
  background-size: contain;
  background-color: ${({ theme }) => theme.grey.s1};
  border: 1px solid ${({ theme }) => theme.grey.s2};
  overflow: hidden;
`
const VerticalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`
const SpaceName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.grey.s6};
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-right: 32px;
`
const ParentTemplateName = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.grey.s5};
`

export const S = {
  SpaceNameColumn,
  SpaceLogo,
  VerticalContainer,
  SpaceName,
  ParentTemplateName,
}
