import { HeaderFooterScrollableModal } from '@valuecase/ui-components'
import { FC, useState } from 'react'
import UserProfileEditor from './UserProfileEditor'
import { UserProfileEditorModalProps } from '../types/UserProfileEditorModalProps'

const UserProfileEditorModal: FC<UserProfileEditorModalProps> = ({ controlsRef }) => {
  const [modalIsPersistent, setModalIsPersistent] = useState<boolean>(false)

  return (
    <HeaderFooterScrollableModal
      persistent={modalIsPersistent}
      controlsRef={controlsRef}
      wide={true}
      needExplicitHeight={{ min: '300px', preferred: '620px' }}
    >
      <UserProfileEditor setIsModalPersistent={setModalIsPersistent} />
    </HeaderFooterScrollableModal>
  )
}

export default UserProfileEditorModal
