import toast, { ToastOptions } from 'react-hot-toast'
import styled from 'styled-components'

import { IonIcon } from '@valuecase/ui-components'
import { createLogger } from '../../logging/Logger'

export interface Notifications {
  success: (message?: string) => void
  error: (message?: string) => void

  asyncNotification:
    | (<T>(
        promise: Promise<T>,
        successMessage?: string,
        errorMessage?: string,
        toastOptions?: ToastOptions,
      ) => Promise<T>)
    | (<T>(
        promise: Promise<T>,
        options?: {
          successMessage?: string
          errorMessage?: string
          toastOptions?: ToastOptions
        },
      ) => Promise<T>)
}

const SuccessConfig: ToastOptions = {
  duration: 2500,
  position: 'bottom-right',
}

const ErrorConfig: ToastOptions = {
  duration: 6000,
  position: 'bottom-right',
}

const Notification = styled.div`
  display: flex;
  column-gap: 16px;
  min-height: 32px;
  align-items: center;
  justify-content: space-between;

  box-shadow: 4px 12px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px;

  background: var(--theme-light);
  padding: 8px 16px;

  color: var(--theme-grey-s6);
  font-size: 12px;
  font-weight: 500;
  line-height: 150%;

  opacity: 0;
  transition: opacity 0.2s ease;

  &.visible {
    opacity: 1;
  }

  .ionicon {
    font-size: 20px;
  }

  &.success {
    .ionicon {
      color: var(--theme-success-s5);
    }
  }

  &.error {
    .ionicon {
      color: var(--theme-warning-s5);
    }
  }
`

function renderSuccess(visible: boolean, message?: string) {
  return (
    <Notification className={'success ' + (visible ? 'visible' : '')}>
      <IonIcon name='checkmark-circle-outline' />
      {message ?? 'Action succeeded.'}
    </Notification>
  )
}

function renderError(visible: boolean, message?: string) {
  return (
    <Notification className={'error ' + (visible ? 'visible' : '')}>
      <IonIcon name='alert-circle-outline' />
      {message ?? 'Action succeeded.'}
    </Notification>
  )
}

function success(message?: string, customOptions?: ToastOptions) {
  toast.custom((t) => renderSuccess(t.visible, message), { ...SuccessConfig, ...customOptions })
}

function error(message?: string, customOptions?: ToastOptions, hasBeenLogged = false) {
  if (!hasBeenLogged && message) {
    const logger = createLogger()
    logger.warn(message)
  }
  toast.custom((t) => renderError(t.visible, message), { ...ErrorConfig, ...customOptions })
}

function asyncNotification<T>(
  promise: Promise<T>,
  successMessage?: string,
  errorMessage?: string,
  toastOptions?: ToastOptions,
): Promise<T>

function asyncNotification<T>(
  promise: Promise<T>,
  options: {
    successMessage?: string
    errorMessage?: string
    toastOptions?: ToastOptions
  },
): Promise<T>

function asyncNotification<T>(
  promise: Promise<T>,
  successMessageOrOptions?: any,
  errorMessage?: any,
  toastOptions?: any,
): Promise<T> {
  const resolvedSuccessMessage = successMessageOrOptions?.successMessage ?? successMessageOrOptions
  const resolvedErrorMessage = successMessageOrOptions?.errorMessage ?? errorMessage
  const resolvedToastOptions = successMessageOrOptions?.toastOptions ?? toastOptions
  const logger = createLogger()

  return promise
    .then((result) => {
      success(resolvedSuccessMessage, resolvedToastOptions)
      return result
    })
    .catch((reason) => {
      logger.error(reason)
      error(resolvedErrorMessage, resolvedToastOptions, true)
      throw reason
    })
}

/**
 * @deprecated use useNotifications from @valuecase/ui-components
 */
export function useNotifications(): Notifications {
  return {
    success,
    error,
    asyncNotification,
  }
}
