import SellerApi from '@/api/SellerApi'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const deleteTeam = async (teamId: string) => {
  await SellerApi.delete(`teams/${teamId}`)
}

const useDeleteTeam = () => {
  const queryClient = useQueryClient()

  return useMutation((teamId: string) => deleteTeam(teamId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['teams'])
    },
  })
}

export default useDeleteTeam
