import * as pdfjs from 'pdfjs-dist'
import pdfJSWorkerURL from 'pdfjs-dist/build/pdf.worker?url'
pdfjs.GlobalWorkerOptions.workerSrc = pdfJSWorkerURL

// Preload pdfjs worker
window
  .fetch(pdfjs.GlobalWorkerOptions.workerSrc)
  .then(() => {
    console.log('📑 Preloading pdf.js worker succeeded ✅')
  })
  .catch(() => {
    console.log('📑 Preloading pdf.js worker failed ❌')
  })
