import { useState, useCallback } from 'react'

const ImageColorAnalysisAlgVersion = 3

export interface ImageColorAnalysis {
  original: {
    width: number
    height: number
  }

  downsized: {
    dataUrl: string
    width: number
    height: number
    scaleFactor: number
  }

  averageLuminosity: number

  // array of rows of HSV's values -> so spots[x][y]
  spotLuminosities: number[][]
}

export function createAnalysisCacheKey(s3ObjectKey?: string): string {
  return `image-color-analysis--alg-${ImageColorAnalysisAlgVersion}--${s3ObjectKey}--cached`
}

/**
 * Computes the average luminosity of an image and the luminosity of each pixel.
 * @throws Error
 */
export async function computeImageAnalysis(
  imageSource: string | HTMLImageElement, // the image can be a url or html img element
): Promise<ImageColorAnalysis> {
  const canvas = document.createElement('canvas')
  const context2D = canvas.getContext('2d')

  if (!context2D) {
    throw new Error('No 2D context available.')
  }

  // Defaults
  const scaleFactor = 0.04

  // Load image
  const loadImage = (_imageSource: string | HTMLImageElement) => {
    return new Promise<HTMLImageElement | null>((resolve, reject) => {
      if (typeof _imageSource !== 'string') {
        if (_imageSource.complete) {
          // If the image is already loaded, we can resolve/reject immediately
          if (_imageSource.naturalWidth === 0) {
            return reject(new Error('Image in complete state but failed to load.'))
          }
          console.debug('Using pre-loaded image for analysis.')
          resolve(_imageSource)
        } else {
          console.debug('Waiting for image to load before analysis.')
          // If the image is not loaded yet, we need to wait for the load/error events
          _imageSource.addEventListener('load', () =>
            // Set timeout used to work around potential Safari bug which seems to think an
            // image is fully transparent in some scenarios
            setTimeout(() => resolve(_imageSource), 10),
          )
          _imageSource.addEventListener('error', () => reject(new Error('Image failed to load.')))
        }
      } else {
        // If the image is a string, we need to load it first
        console.debug('Loading image from URL')
        const image = new Image()
        image.crossOrigin = 'anonymous'
        image.addEventListener('load', () =>
          // Set timeout used to work around potential Safari bug which seems to think an
          // image is fully transparent in some scenarios
          setTimeout(() => resolve(image), 10),
        )
        image.addEventListener('error', () => reject(new Error('Image failed to load.')))
        image.src = _imageSource
      }
      setTimeout(() => resolve(null), 10000) // Resolve after 10 seconds if not already resolved
    })
  }
  const image = await loadImage(imageSource)

  if (!image) {
    throw new Error('Could not load image within 60s')
  }

  // Resize image
  const scaledHeight = Math.ceil(image.height * scaleFactor)
  const scaledWidth = Math.round(scaleFactor * image.width)

  canvas.width = scaledWidth
  canvas.height = scaledHeight
  context2D.clearRect(0, 0, canvas.width, canvas.height)

  context2D.drawImage(image, 0, 0, scaledWidth, scaledHeight)

  const scaledDataUrl = canvas.toDataURL('image/png')

  // Obtain the "luminosity" values
  const pixelData = context2D.getImageData(0, 0, canvas.width, canvas.height).data
  const spots: number[][] = []
  for (let x = 0; x < scaledWidth; x++) {
    spots.push([])
  }

  let luminositySum = 0

  for (let i = 0; i < pixelData.length; i += 4) {
    const pixel = i / 4
    // const x = Math.floor(pixel / scaledWidth)
    const y = pixel % scaledWidth

    const r = pixelData[i]
    const g = pixelData[i + 1]
    const b = pixelData[i + 2]
    const a = pixelData[i + 3] / 255

    const perceivedLuminosity = Math.sqrt(0.299 * r * r + 0.587 * g * g + 0.114 * b * b) / 255

    const alphaWeightedLuminosity = perceivedLuminosity * a + (1 - a)
    luminositySum += alphaWeightedLuminosity

    spots[y].push(alphaWeightedLuminosity)
  }

  const averageLuminosity = luminositySum / (scaledHeight * scaledWidth)

  console.debug('Image analysis complete')

  return {
    original: {
      width: image.width,
      height: image.height,
    },

    downsized: {
      width: scaledWidth,
      height: scaledHeight,
      scaleFactor,
      dataUrl: scaledDataUrl,
    },

    averageLuminosity,
    spotLuminosities: spots,
  }
}

export function useImageColorAnalysis() {
  const [isLoading, setIsLoading] = useState(false)
  const [imageColorAnalysis, setResult] = useState<ImageColorAnalysis | undefined>(undefined)
  const [error, setError] = useState<any | undefined>(undefined)

  const triggerImageColorAnalysis = useCallback(
    async (image: string | HTMLImageElement): Promise<void> => {
      setIsLoading(true)
      try {
        const result = await computeImageAnalysis(image)
        setResult(result)
      } catch (err) {
        console.error('Error during image analysis', err)
        setError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [],
  )

  return { isLoading, triggerImageColorAnalysis, imageColorAnalysis, error }
}

export interface ImageArea {
  xRelative: number
  yRelative: number

  widthRelative: number
  heightRelative: number
}

function zeroToOne(value: number) {
  return Math.min(Math.max(value, 0), 1)
}

export function getLuminosityOfArea(
  imageAnalysis: ImageColorAnalysis,
  { xRelative, yRelative, widthRelative, heightRelative }: ImageArea,
): number {
  const xStart = Math.floor((imageAnalysis.downsized.width - 1) * zeroToOne(xRelative))
  const yStart = Math.floor((imageAnalysis.downsized.height - 1) * zeroToOne(yRelative))

  const width = Math.ceil(imageAnalysis.downsized.width * zeroToOne(widthRelative))
  const height = Math.ceil(imageAnalysis.downsized.height * zeroToOne(heightRelative))

  let luminositySum = 0

  for (let x = 0; x < width; x++) {
    for (let y = 0; y < height; y++) {
      luminositySum += imageAnalysis.spotLuminosities[xStart + x][yStart + y]
    }
  }

  return luminositySum > 0 ? luminositySum / (width * height) : 0
}
