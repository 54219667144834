import { Team } from '@valuecase/common'
import useDeleteTeam from '../hooks/useDeleteTeam'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
} from '@valuecase/ui-components'
import { useCurrentTenantQuery } from '@/modules/tenant/hooks/useReadTenant'

const DeleteTeamDialog = ({
  team,
  open,
  onOpenChange,
}: {
  team: Team
  open: boolean
  onOpenChange: (open: boolean) => void
}) => {
  const deleteTeam = useDeleteTeam()
  const { tenant } = useCurrentTenantQuery()

  const handleDeleteTeam = async () => {
    await deleteTeam.mutateAsync(team.id)
    onOpenChange(false)
  }

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader iconName='trash'>Delete team?</AlertDialogHeader>
        <AlertDialogDescription>
          By removing this team, all spaces, templates, and members will be assigned to your default
          company team &quot;Everyone in {tenant?.payload?.tenantName}&quot;, provided they are not
          already part of another team.
        </AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleDeleteTeam}>Delete</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default DeleteTeamDialog
