import { FC, useCallback, useRef, useState } from 'react'
import { Button, Hairline, Input, InputSize } from '@valuecase/ui-components'
import {
  TemplateCreatorCurrentStepWrapper,
  TemplateCreatorFooter,
  TemplateCreatorHeaderSubtitle,
  TemplateCreatorHeaderTitle,
  TemplateCreatorHeaderWrapper,
  TemplateCreatorLayout,
  TemplateCreatorNameInputWrapper,
  TemplateCreatorNameLabelWrapper,
  TemplateCreatorNextButtonWrapper,
  TemplateCreatorPreviewIconInputWrapper,
  TemplateCreatorPreviewImageLabelWrapper,
  TemplateCreatorStep2Layout,
  TemplateCreatorUsageInputWrapper,
  TemplateCreatorUsageLabelWrapper,
  TemplateCreatorPreviewIconButton,
  TemplateCreatorPreviewIconHint,
  TemplateCreatorInfoInputLabel,
  TemplateCreatorUsageTextarea,
  TemplateCreatorErrorWrapper,
  TemplateCreatorFooterRight,
} from './TemplateFromSpaceCreatorParts.styled'
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react'
import { createPortal } from 'react-dom'
import { VLoaderAnimation } from '../../../ui-components/VLoader/VLoader'
import { useTrackEvent } from '../../../mixpanel/useTrackEvent'
import { TemplateFromSpaceCreatorViewProps } from '../types/TemplateFromSpaceCreatorViewProps'

const TemplateCreatorView: FC<TemplateFromSpaceCreatorViewProps> = ({
  error,
  newTemplateName,
  newTemplateUsage,
  newTemplateIcon,
  setNewTemplateName,
  setNewTemplateUsage,
  setNewTemplateIcon,
  createNewTemplate,
  currentStep,
  goToStep,
}) => {
  const { trackEvent } = useTrackEvent()
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const emojiButtonRef = useRef<null | HTMLButtonElement>(null)
  const goToNextStep = useCallback(() => {
    switch (currentStep) {
      case 'enterInfo':
        createNewTemplate()
        goToStep('loading')
        break
      default:
        break
    }
  }, [createNewTemplate, currentStep, goToStep])

  return (
    <>
      <TemplateCreatorHeaderWrapper>
        <TemplateCreatorHeaderTitle>Create Template</TemplateCreatorHeaderTitle>
        <TemplateCreatorHeaderSubtitle>
          Edit information before creating a new template.
        </TemplateCreatorHeaderSubtitle>
      </TemplateCreatorHeaderWrapper>
      <Hairline />
      <TemplateCreatorLayout>
        <TemplateCreatorCurrentStepWrapper>
          {currentStep === 'enterInfo' && (
            <TemplateCreatorStep2Layout>
              <TemplateCreatorPreviewImageLabelWrapper>
                <TemplateCreatorInfoInputLabel>Preview icon</TemplateCreatorInfoInputLabel>
              </TemplateCreatorPreviewImageLabelWrapper>
              <TemplateCreatorPreviewIconInputWrapper>
                <TemplateCreatorPreviewIconButton
                  ref={emojiButtonRef}
                  onClick={(event) => {
                    event.preventDefault()
                    setShowEmojiPicker(true)
                  }}
                >
                  {newTemplateIcon.emojiNative}
                </TemplateCreatorPreviewIconButton>
                <TemplateCreatorPreviewIconHint>
                  Click to change the icon.
                </TemplateCreatorPreviewIconHint>
                {showEmojiPicker &&
                  createPortal(
                    <div
                      onClick={(event) => {
                        event.preventDefault()
                        event.stopPropagation()
                        setShowEmojiPicker(false)
                      }}
                      style={{
                        position: 'fixed',
                        width: '100vw',
                        height: '100vh',
                        zIndex: '9999',
                        top: 0,
                        right: 0,
                      }}
                    >
                      <div
                        // Prevent click propagating to avoid early closer of emoji selection
                        onClick={(event) => event.stopPropagation()}
                        style={{
                          width: '350px',
                          height: '450px',
                          position: 'fixed',
                          top: emojiButtonRef.current
                            ? emojiButtonRef.current.getBoundingClientRect().y + 34 + 'px'
                            : '0px',
                          left: emojiButtonRef.current
                            ? emojiButtonRef.current.getBoundingClientRect().x + 34 + 'px'
                            : '0px',
                        }}
                      >
                        <EmojiPicker
                          onEmojiClick={(data) => {
                            trackEvent({
                              event: 'templates-newtemplate-change_icon',
                              eventProperties: {
                                indexPageName: 'templates',
                                isNewTemplate: true,
                                templateID: 'TemplateNotYetCreated',
                              },
                            })
                            setNewTemplateIcon({
                              source: 'emoji-native',
                              emojiNative: data.emoji,
                            })
                          }}
                          emojiStyle={EmojiStyle.NATIVE}
                        />
                      </div>
                    </div>,
                    document.body,
                  )}
              </TemplateCreatorPreviewIconInputWrapper>
              <TemplateCreatorNameLabelWrapper>
                <TemplateCreatorInfoInputLabel>Template name</TemplateCreatorInfoInputLabel>
              </TemplateCreatorNameLabelWrapper>
              <TemplateCreatorNameInputWrapper>
                <Input
                  value={newTemplateName}
                  onInput={(input) => setNewTemplateName(input)}
                  size={InputSize.LARGE}
                  placeholder='Enter template name'
                />
              </TemplateCreatorNameInputWrapper>
              <TemplateCreatorUsageLabelWrapper>
                <TemplateCreatorInfoInputLabel>Template description</TemplateCreatorInfoInputLabel>
              </TemplateCreatorUsageLabelWrapper>
              <TemplateCreatorUsageInputWrapper>
                <TemplateCreatorUsageTextarea
                  placeholder='Enter description'
                  value={newTemplateUsage}
                  onChange={(event) => setNewTemplateUsage(event.target.value)}
                ></TemplateCreatorUsageTextarea>
              </TemplateCreatorUsageInputWrapper>
            </TemplateCreatorStep2Layout>
          )}
          {currentStep === 'loading' && (
            <div
              style={{
                minHeight: '30vh',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <VLoaderAnimation />
            </div>
          )}
          {currentStep === 'success' && <div>Your template has been created</div>}
        </TemplateCreatorCurrentStepWrapper>
      </TemplateCreatorLayout>
      <Hairline />
      {currentStep !== 'loading' && currentStep !== 'success' && (
        <TemplateCreatorFooter>
          <div />
          <div />
          <TemplateCreatorFooterRight>
            {!!error && typeof error === 'object' && typeof error.message === 'string' && (
              <TemplateCreatorErrorWrapper>{error.message}</TemplateCreatorErrorWrapper>
            )}
            {!!error && (typeof error !== 'object' || !error.message) && (
              <TemplateCreatorErrorWrapper>
                {'Failed to create template, please try again later'}
              </TemplateCreatorErrorWrapper>
            )}
            {!error && <div></div>}
            <TemplateCreatorNextButtonWrapper>
              <Button
                icon={currentStep === 'selectTemplate' ? 'chevron-forward' : 'arrow-forward'}
                iconPosition='right'
                label={'Create'}
                onClick={goToNextStep}
                disabled={currentStep === 'enterInfo' && !newTemplateName}
              />
            </TemplateCreatorNextButtonWrapper>
          </TemplateCreatorFooterRight>
        </TemplateCreatorFooter>
      )}
    </>
  )
}

export default TemplateCreatorView
