import { useNotifications } from '../../utils/Notifications/Notifications'
import { SettingsInfo } from './SettingsInfo'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import { Input } from '@valuecase/ui-components'
import { useCompanyVisualSettings } from './hooks/useCompanyVisualSettings'
import { useEffect, useState } from 'react'

export const BuyerIntercomSetting = () => {
  const { success } = useNotifications()
  const { trackEvent } = useTrackEvent()
  const [intercomId, setIntercomId] = useState('')
  const { companySettings, updateCompanySettings } = useCompanyVisualSettings({
    onUpdateSuccess: () => {
      success('Intercom ID updated')
    },
  })

  useEffect(() => {
    setIntercomId(companySettings?.intercomId || '')
  }, [companySettings])

  function updateIntercomId() {
    trackEvent({
      event: 'settings-update_buyer_intercom_id',
      eventProperties: {
        indexPageName: 'Company Settings',
      },
    })
    updateCompanySettings({ intercomId })
  }

  function handleInput(e: string) {
    setIntercomId(e)
  }

  return (
    <div className='flex justify-between w-full items-start'>
      <SettingsInfo
        title='Intercom'
        description='Connect your Intercom account to your spaces to enable live chat with your customers.'
      />

      <div className='relative flex h-9 w-52'>
        <Input
          className=''
          placeholder='Intercom ID'
          value={intercomId}
          onBlur={updateIntercomId}
          onInput={handleInput}
        />
      </div>
    </div>
  )
}
