import { PermissionCheckers, useAuthState } from './auth'
import { useMemo } from 'react'
import { Permission } from '@valuecase/seller-backend'

export function usePermissionCheckers(): PermissionCheckers {
  return useAuthState((state) => ({
    hasPermission: state.hasPermission,
    hasPermissions: state.hasPermissions,
    hasSomePermission: state.hasSomePermission,
  }))
}

export function useHasPermissions(...requiredPermissions: Permission[]): boolean {
  const authState = useAuthState()
  return useMemo(() => {
    return authState.hasPermissions(...requiredPermissions)

    // We explicitly want to compare requiredPermissions via JSON
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, JSON.stringify(requiredPermissions)])
}

export function useHasPermission(requiredPermission: Permission): boolean {
  const authState = useAuthState()
  return useMemo(() => {
    return authState.hasPermission(requiredPermission)
  }, [authState, requiredPermission])
}

export function useHasSomePermission(...permissions: Permission[]): boolean {
  const authState = useAuthState()
  return useMemo(() => {
    return authState.hasSomePermission(...permissions)

    // We explicitly want to compare permissions via JSON
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, JSON.stringify(permissions)])
}
