import { FC } from 'react'
import { S } from './SpaceName.cell.styled'
import { IonIcon } from '@valuecase/ui-components'
interface Props {
  spaceName: any
  logoUrl: string
  parentTemplateName: string
  archived: boolean
  className?: string
}
/**
 * @deprecated replace with AvatarThumbnail
 */
const SpaceNameCell: FC<Props> = (props) => {
  return (
    <S.SpaceNameColumn className={props.className}>
      {props.archived ? (
        <div
          className={
            'flex items-center justify-center w-10 h-10 border border-solid border-grey-s2 rounded-md bg-grey-s1'
          }
        >
          <IonIcon name={'archive'} className={'w-6 h-6 text-grey-s4'} />
        </div>
      ) : (
        <S.SpaceLogo $imgUrl={props.logoUrl} />
      )}
      <S.VerticalContainer className={'overflow-hidden'}>
        <S.SpaceName>{props.spaceName}</S.SpaceName>
        <S.ParentTemplateName
          className={'overflow-hidden overflow-ellipsis whitespace-nowrap w-full'}
        >
          {props.parentTemplateName}
        </S.ParentTemplateName>
      </S.VerticalContainer>
    </S.SpaceNameColumn>
  )
}

export default SpaceNameCell
