import { useTrackEvent } from '@/mixpanel/useTrackEvent'
import { useSpaceFilter } from '@/modules/spaces/hooks/useSpaceFiltering'
import { Input2 } from '@valuecase/ui-components'
import React, { useEffect, useState } from 'react'
import { useDebounce } from 'usehooks-ts'

const SpaceNameFilter: React.FC = () => {
  const { setSpaceName, spaceName } = useSpaceFilter()
  const [inputValue, setInputValue] = useState(spaceName ?? '')
  const debouncedSearchTerm = useDebounce(inputValue, 300)
  const { trackEvent } = useTrackEvent()

  useEffect(() => {
    setSpaceName(debouncedSearchTerm)
    trackEvent({
      event: 'spaces-search',
      eventProperties: {
        indexPageName: 'Space List',
      },
    })
    // Inserting trackEvent into useEffect deps list causes infinite loop. Tracked it down to the query object in useReadTenant.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, setSpaceName])

  return (
    <div className={'flex min-w-[220px] max-w-[350px] grow shrink'}>
      <Input2
        leadingIcon='search-outline'
        placeholder={'Search spaces...'}
        value={inputValue}
        onInput={(e) => setInputValue(e.currentTarget.value)}
      />
    </div>
  )
}

export default SpaceNameFilter
