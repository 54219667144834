import { FC, useMemo, MouseEvent, CSSProperties, useState, useEffect, useRef } from 'react'
import { useTheme } from 'styled-components'

import CheckMark from '../../../assets/CheckMarkInCircle.svg?react'
import styled from 'styled-components'

type StrokeLinecap = 'round' | 'inherit' | 'butt' | 'square' | undefined

const cleanPercentage = (percentage: number): number => {
  const tooLow = !Number.isFinite(+percentage) || percentage < 0
  const tooHigh = percentage > 100
  return tooLow ? 0 : tooHigh ? 100 : +percentage
}

interface CircleProps {
  color: string
  radius: number
  strokeWidth: number
  xy: number
  progress?: number
  strokeLinecap?: StrokeLinecap
}

const Circle: FC<CircleProps> = ({
  color,
  radius,
  strokeWidth,
  xy,
  progress = 100,
  strokeLinecap: SL,
}) => {
  const [strokeLinecap, setStrokeLinecap] = useState<StrokeLinecap>()
  const initialValue = useRef<number | null>(null)

  const r = radius
  const circumference = 2 * Math.PI * r
  const dash = (progress * circumference) / 100

  useEffect(() => {
    if (initialValue.current === null) {
      initialValue.current = progress
      setStrokeLinecap(progress === 0 ? undefined : SL)
    } else if (progress === 0) {
      setTimeout(() => {
        setStrokeLinecap(undefined)
      }, 300)
    } else {
      setStrokeLinecap(SL)
    }
  }, [SL, progress])

  return (
    <circle
      r={r}
      cx={xy} // up down rotate
      cy={xy} // left right
      fill='transparent'
      stroke={color}
      strokeWidth={strokeWidth}
      strokeDasharray={`${dash} ${circumference - dash}`}
      transform={`rotate(-90 ${xy} ${xy})`} // starts from top
      style={{ transition: 'all 0.3s' }}
      strokeLinecap={strokeLinecap}
    ></circle>
  )
}

interface TextProps {
  content: string
}

const Text: FC<TextProps> = ({ content }) => {
  const theme = useTheme()
  return (
    <text
      x='50%'
      y='50%'
      style={{ fontSize: '10px', lineHeight: '14px', fontWeight: '700' }}
      dominantBaseline='central'
      textAnchor='middle'
      fill={theme.grey.s5}
    >
      {content}
    </text>
  )
}

interface Props {
  percentage: number
  color?: string
  options?: {
    size?: number // radius size
    strokeWidth?: number // width of stroke in px
    fontSize?: string | number // if string then pass '10px' else
    secondaryColor?: string // color name or hex value; colour of the circle
    text?: string // if true display  text in the middle of progress circle
    checkMark?: boolean // have checkmark icon if percentage hundred and over
    percentageZero?: 'empty' | 'none' // if percentage zero: empty - default, just show empty circle, none - display nothing
    strokeLinecap?: StrokeLinecap
  }
  onClick?: (e: MouseEvent<SVGSVGElement>) => void
  style?: CSSProperties
}

const CheckMarkStyled = styled(CheckMark)<{ $color: string }>`
  path {
    fill: ${({ $color: color }) => color};
  }
`

export const CircularProgressIndicator: FC<Props> = ({
  percentage,
  color = '#7793FF',
  options,
  ...rest
}) => {
  const progress = cleanPercentage(percentage)
  const radius = useMemo(() => options?.size || 8.75, [options])
  const strokeWidth = useMemo(() => options?.strokeWidth || 2, [options])
  const heightWidth = useMemo(() => radius * 2 + strokeWidth, [radius, strokeWidth])
  const xy = useMemo(() => heightWidth / 2, [heightWidth])

  if (percentage <= 0 && options?.percentageZero === 'none') {
    return <></>
  }
  if (percentage >= 100 && options?.checkMark) {
    return <CheckMarkStyled width={heightWidth} height={heightWidth} $color={color} {...rest} />
  }

  return (
    <svg width={heightWidth} height={heightWidth} {...rest}>
      <Circle
        color={options?.secondaryColor || '#EDEDF1'}
        radius={radius}
        strokeWidth={strokeWidth}
        xy={xy}
      />
      <Circle
        color={color}
        progress={progress}
        radius={radius}
        strokeWidth={strokeWidth}
        xy={xy}
        strokeLinecap={options?.strokeLinecap}
      />
      {options?.text && <Text content={options.text} />}
    </svg>
  )
}
