import { FC, ReactNode } from 'react'
import styled from 'styled-components'

const Badges: FC<{ children: ReactNode }> = ({ children }) => {
  return <BadgesContainer>{children}</BadgesContainer>
}

const BadgesContainer = styled.div<{ $justifyContent?: string }>``

export default Badges
