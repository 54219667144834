import i18next, { ResourceLanguage } from 'i18next'
import I18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import en from './locales/en'
import de from './locales/de'
import fr from './locales/fr'

export const defaultNS = 'root'

export const resources = {
  en: en as unknown as ResourceLanguage,
  de: de as unknown as ResourceLanguage,
  fr: fr as unknown as ResourceLanguage,
} as const

i18next
  .use(I18nextBrowserLanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    defaultNS,
    debug: false,
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage'],
    },
    resources,
    interpolation: {
      escapeValue: false,
    },
  })
