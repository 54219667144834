import { TUpdateUserTeamsPayload, User } from '@valuecase/common'
import { UpdateUserRoleBodyInput as UpdateUserRoleInput } from '../../modules/userManagement/types/UpdateUserRoleInput'
import SellerApi from '../SellerApi'
import { InviteUserBody, InviteUserResponse, ChangeUserBody } from '@valuecase/seller-backend'

export default {
  listUserOfCurrentTenant: () => SellerApi.get<User[], undefined>('/users'),
  updateUserRole: (updateUserRole: UpdateUserRoleInput) =>
    SellerApi.patch<undefined, Omit<UpdateUserRoleInput, 'userId'>, undefined>(
      `/users/${encodeURIComponent(updateUserRole.userId)}/role`,
      {
        roleId: updateUserRole.roleId,
      },
    ),
  inviteUser: (data: InviteUserBody) =>
    SellerApi.put<InviteUserResponse, InviteUserBody, undefined>('/users/invitation', data),

  deleteInvitedUser: (userId: string) =>
    SellerApi.delete<undefined, undefined>(`/users/invitation/${encodeURIComponent(userId)}`),

  changeUserStatus: (userId: string, body: ChangeUserBody) =>
    SellerApi.patch<undefined, ChangeUserBody, undefined>(`/users/${userId}/status`, body),
  updateUserTeams: (userId: string, body: TUpdateUserTeamsPayload) =>
    SellerApi.patch<undefined, { teamIds: string[] }, undefined>(`/users/${userId}/teams`, body),
}
