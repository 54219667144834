import styled from 'styled-components'

export const UsersListWrapper = styled.div`
  padding: 16px 0;
`

export const UsersList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
`
export const Filters = styled.div`
  display: flex;
  gap: 8px;
`
export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 21px;
`

export const UsersListIconBox = styled.span`
  display: inline-block;
  font-size: 24px;
  height: 40px;
  width: 40px;
  min-width: 40px;
  background: ${({ theme }) => theme.grey.s1};
  border: 1px solid ${({ theme }) => theme.grey.s2};
  border-radius: 8px;
  margin: 8px 0 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 20px;
`

export const UserListIcon = styled.img`
  display: inline-block;
  width: 100%;
  height: auto;
`

export const UsersListNameWrapper = styled.span`
  display: flex;
  align-items: center;
  overflow: hidden;
`

export const UsersListNameAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  overflow: hidden;
`

export const UsersListNameTextWrapper = styled.span`
  font-weight: 600;
  font-size: 12px;
  line-height: 19px;
  color: ${({ theme }) => theme.grey.s6};
  max-height: 19px;
  overflow: hidden;
  padding-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const UsersListEmailTextWrapper = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.grey.s6};
`

export const UserRoleButton = styled.button`
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  display: block;
  max-width: 100%;
  text-align: left;

  &:hover {
    background-color: transparent;
  }

  &:disabled {
    cursor: auto;
  }
`

export const UserRoleButtonTopText = styled.span`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  color: ${({ theme }) => theme.grey.s6};
`

export const UserRoleSelectionWrapper = styled.div`
  width: 371px;
  display: flex;
  flex-direction: column;
`

export const UserRoleSelectionItem = styled.button`
  padding: 8px 12px;
  display: block;
  cursor: pointer;
  border: none;
  background-color: transparent;
  margin: 8px 1px 2px 1px;
  text-align: left;
  border-radius: 6px;
  width: calc(100% - 2px);

  &:first-child {
    margin-top: 1px;
  }

  &:hover {
    background-color: ${({ theme }) => theme.grey.s1};
  }
`

export const UserRoleSelectionItemHeader = styled.div`
  font-weight: 500;
  font-size: 14px;
  position: relative;
  line-height: 24px;
  color: ${({ theme }) => theme.grey.s6};
`

export const UserRoleSelectionItemDescription = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: ${({ theme }) => theme.grey.s4};
`

export const UserRoleDescription = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  height: 16px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-top: 2px;
  color: ${({ theme }) => theme.grey.s4};
`

export const UserRoleWrapper = styled.div`
  max-width: 200px;
  overflow: hidden;
  width: fit-content;

  > span {
    max-width: 100%;
    display: block;
    overflow: hidden;
  }
`

export const UsersListInvitedPlacedholder = styled.span`
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.grey.s5};
`

export const UsersListDescriptionTextWrapper = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.grey.s5};
  max-height: 19px;
  overflow: hidden;
  padding-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const UsersListTableWrapper = styled.div`
  margin-top: 16px;
  padding-bottom: 24px;
`

export const ActiveUserCountIcon = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 4px 2px 4px;
  gap: 2px;
  height: 16px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.grey.s2};
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 120%;
  color: ${({ theme }) => theme.grey.s4};
  margin-left: 4px;
`

export const CurrentUserIcon = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 4px 2px 4px;
  gap: 2px;
  height: 16px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.primary.s2};
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 120%;
  color: ${({ theme }) => theme.primary.s4};
  margin-left: 4px;
`
