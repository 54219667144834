import { SettingsInfo } from './SettingsInfo'

export const SubdomainSetting = () => {
  return (
    <div className='flex justify-between w-full items-start'>
      <SettingsInfo
        title='Sub Domain'
        description='Your subdomain is part of the space sharing link, enabling you to share customized URLs with your customer. This will be editable soon. '
      />

      <div className='relative flex h-9'>
        <input
          className=' w-52 bg-grey-1 rounded-lg border-[1px] border-grey-s2 pl-4 text-grey-s3 text-sm outline-none'
          value='Insert subdomain'
          disabled
        />
      </div>
    </div>
  )
}
