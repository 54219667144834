import React from 'react'
import styled, { DefaultTheme, ThemeProvider, useTheme } from 'styled-components'
import { themeToCssVars } from './ThemeUtils'

export interface ValuecaseThemeProviderProps {
  children: React.ReactNode
  theme: DefaultTheme
  // TODO Get rid of height here, ugly code
  height?: string
}

export default function ValuecaseThemeProvider({
  children,
  theme,
  height,
}: ValuecaseThemeProviderProps) {
  return (
    <ThemeProvider theme={theme}>
      <CssVarsWrapper $height={height}>{children}</CssVarsWrapper>
    </ThemeProvider>
  )
}

export function useThemeAsCssVars(): string {
  const theme = useTheme()
  return themeToCssVars({ theme })
}

const CssVarsWrapper = styled.div<{ $height?: string }>`
  ${({ $height: height }) => height && `height: ${height};`}

  ${({ theme }: { theme: any }) => {
    return themeToCssVars({
      theme,
    })
  }}
`
