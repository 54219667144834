import styled from 'styled-components'
/**
 *
 *  Th(headerCol)
 *  Td(SpaceNameCell)
 *
 *
 *
 */
const TableWrapper = styled.div<{ $transparentBackground?: boolean; $showOuterBorder?: boolean }>`
  ${({ theme, $showOuterBorder: showOuterBorder }) =>
    showOuterBorder ? `border: 1px solid ${theme.grey.s2};` : ''}
  border-radius: 8px;
  ${({ $transparentBackground: transparentBackground }) =>
    transparentBackground ? '' : 'background: white'};
  overflow: auto;
  max-width: 100%;
  width: 100%;
`

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  > tbody tr:last-child {
    border-bottom: none;
  }
  > thead tr {
    border-top: none;
  }
`

const Th = styled.th`
  padding: 0;
  min-height: 40px;
  height: 40px;
  max-height: 40px;
`
const Td = styled.td<{ $rowHeight?: number }>`
  padding: 0;
  min-height: ${({ $rowHeight: rowHeight }) => (rowHeight ? `${rowHeight}px` : '72px')};
  height: ${({ $rowHeight: rowHeight }) => (rowHeight ? `${rowHeight}px` : '72px')};
  max-height: ${({ $rowHeight: rowHeight }) => (rowHeight ? `${rowHeight}px` : '72px')};
  overflow: hidden;
`
//body
const Tr = styled.tr<{ $isClickable?: boolean; $rowHeight?: number }>`
  border: 1px solid ${({ theme }) => theme.grey.s2};
  border-right: none;
  border-left: none;
  padding: 0;
  min-height: ${({ $rowHeight: rowHeight }) => (rowHeight ? `${rowHeight}px` : '72px')};
  max-height: ${({ $rowHeight: rowHeight }) => (rowHeight ? `${rowHeight}px` : '72px')};
  ${({ $isClickable: isClickable }) => (isClickable ? 'cursor: pointer;' : '')}

  ${({ $isClickable: isClickable }) => (isClickable ? '&:hover { background: #F9FBFF; }' : '')}
`

const TableOuterAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
`

const FiltersAndTableWrapper = styled.div``

const FilterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`

const FilterInputContainer = styled.div`
  width: 100%;
  max-width: 352px;
`

const SearchRowElementsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
`

const EmptySearchResultWrapper = styled.div<{ $maxHeight?: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 304px);
  max-height: ${({ $maxHeight: maxHeight }) => (maxHeight ? `${maxHeight}px` : 'none')};
  min-height: 167px;
`

const EmptySearchResultIconWrapper = styled.div`
  display: flex;
  justify-content: center;
`

const EmptySearchResultHeading = styled.p`
  font-style: normal;
  font-weight: 350;
  font-size: 24px;
  line-height: 35px;
  text-align: center;
  max-width: 365px;
  color: ${({ theme }) => theme.grey.s4};
  margin: 16px 0 0 0;
`

const EmptySearchResultSubHeading = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: ${({ theme }) => theme.grey.s3};
  margin: 12px 0 0 0;
`

export const S = {
  FilterContainer,
  FilterInputContainer,
  TableWrapper,
  TableOuterAreaWrapper,
  EmptySearchResultIconWrapper,
  EmptySearchResultHeading,
  EmptySearchResultWrapper,
  EmptySearchResultSubHeading,
  FiltersAndTableWrapper,
  SearchRowElementsContainer,
  Table,
  Th,
  Tr,
  Td,
}
