import { ElementRef, forwardRef, HTMLAttributes } from 'react'
import { cn } from '../../lib/utils'
import { IonIcon } from '../../Icons/IonIcon'
import { Button2, Button2Props } from './button'

const EmptyState = ({
  children,
  className,
  icon,
  iconSize,
  ...props
}: HTMLAttributes<HTMLDivElement> & {
  icon?: string
  iconSize?: 'small' | 'default' | 'large'
}) => {
  const _iconSize = iconSize ?? 'default'

  return (
    <div className={cn('flex flex-col items-center justify-center gap-4', className)} {...props}>
      {icon && (
        <IonIcon
          name={icon}
          className={cn('text-primary-s2', {
            'w-10 h-10': _iconSize === 'small',
            'w-16 h-16': _iconSize === 'default',
            'w-32 h-32': _iconSize === 'large',
          })}
        />
      )}
      {children}
    </div>
  )
}

const EmptyStateHeader = ({ children, className, ...props }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div className={cn('flex flex-col items-center text-center gap-1', className)} {...props}>
      {children}
    </div>
  )
}

const EmptyStateTitle = ({ children, className, ...props }: HTMLAttributes<HTMLHeadingElement>) => {
  return (
    <h2 className={cn('text-sm font-bold text-grey-s5', className)} {...props}>
      {children}
    </h2>
  )
}

const EmptyStateDescription = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLParagraphElement>) => {
  return (
    <p className={cn('text-xs text-grey-s4', className)} {...props}>
      {children}
    </p>
  )
}

const EmptyStateAction = forwardRef<ElementRef<typeof Button2>, Button2Props>(
  ({ children, ...props }, ref) => {
    return (
      <Button2 ref={ref} variant={'subtle'} size={'small'} {...props}>
        {children}
      </Button2>
    )
  },
)
EmptyStateAction.displayName = 'EmptyStateAction'

export { EmptyState, EmptyStateHeader, EmptyStateTitle, EmptyStateDescription, EmptyStateAction }
