import { TeamUnion } from '@valuecase/common'
import { Button, Hairline } from '@valuecase/ui-components'
import { Dispatch, FC, SetStateAction, useCallback } from 'react'
import { VLoaderAnimation } from '../../../ui-components/VLoader/VLoader'
import TemplateInputForm from '../TemplateInputForm'
import {
  TemplateEditorCurrentStepWrapper,
  TemplateEditorErrorWrapper,
  TemplateEditorFooter,
  TemplateEditorFooterRight,
  TemplateEditorHeaderSubtitle,
  TemplateEditorHeaderTitle,
  TemplateEditorHeaderWrapper,
  TemplateEditorLayout,
  TemplateEditorNextButtonWrapper,
} from './TemplateEditorParts.styled'

export type TemplateEditorSteps = 'editInfo' | 'loading' | 'success'

export interface TemplateEditorViewProps {
  error?: any
  newTemplateName: string
  newTemplateUsage: string
  newTemplateIcon: string
  setNewTemplateName: (name: string) => void
  setNewTemplateUsage: (usage: string) => void
  setNewTemplateIcon: (icon: string) => void
  editTemplate: () => void
  goToStep: (step: TemplateEditorSteps) => void
  currentStep: TemplateEditorSteps
  teams?: TeamUnion[]
  selectedTeamIds?: Set<string>
  setSelectedTeamIds: Dispatch<SetStateAction<Set<string> | undefined>>
  tenantName: string
}

const TemplateEditorView: FC<TemplateEditorViewProps> = ({
  error,
  newTemplateName,
  newTemplateUsage,
  newTemplateIcon,
  setNewTemplateName,
  setNewTemplateUsage,
  setNewTemplateIcon,
  editTemplate,
  currentStep,
  goToStep,
  teams,
  selectedTeamIds,
  setSelectedTeamIds,
  tenantName,
}) => {
  const goToNextStep = useCallback(() => {
    switch (currentStep) {
      case 'editInfo':
        editTemplate()
        goToStep('loading')
        break
      default:
        break
    }
  }, [currentStep, editTemplate, goToStep])

  return (
    <>
      <TemplateEditorHeaderWrapper>
        <TemplateEditorHeaderTitle>Edit Template</TemplateEditorHeaderTitle>
        <TemplateEditorHeaderSubtitle>
          Edit information about this template
        </TemplateEditorHeaderSubtitle>
      </TemplateEditorHeaderWrapper>

      <Hairline />

      <TemplateEditorLayout>
        <TemplateEditorCurrentStepWrapper>
          {currentStep === 'editInfo' && (
            <TemplateInputForm
              newTemplateName={newTemplateName}
              setNewTemplateName={setNewTemplateName}
              newTemplateUsage={newTemplateUsage}
              setNewTemplateUsage={setNewTemplateUsage}
              newTemplateIcon={newTemplateIcon}
              setNewTemplateIcon={setNewTemplateIcon}
              teams={teams}
              selectedTeamIds={selectedTeamIds}
              setSelectedTeamIds={setSelectedTeamIds}
              tenantName={tenantName}
            />
          )}
          {currentStep === 'loading' && (
            <div
              style={{
                minHeight: '30vh',
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <VLoaderAnimation />
            </div>
          )}
          {currentStep === 'success' && <div>Your template has been created</div>}
        </TemplateEditorCurrentStepWrapper>
      </TemplateEditorLayout>
      <Hairline />
      {currentStep !== 'loading' && currentStep !== 'success' && (
        <TemplateEditorFooter>
          <TemplateEditorFooterRight>
            {!!error && typeof error === 'object' && typeof error.message === 'string' && (
              <TemplateEditorErrorWrapper>{error.message}</TemplateEditorErrorWrapper>
            )}
            {!!error && (typeof error !== 'object' || !error.message) && (
              <TemplateEditorErrorWrapper>
                {'Failed to edit template, please try again later'}
              </TemplateEditorErrorWrapper>
            )}
            {!error && <div></div>}
            <TemplateEditorNextButtonWrapper>
              <Button
                icon={'chevron-forward'}
                iconPosition='right'
                label='Save'
                disabled={!!teams?.length && !selectedTeamIds}
                onClick={goToNextStep}
              />
            </TemplateEditorNextButtonWrapper>
          </TemplateEditorFooterRight>
        </TemplateEditorFooter>
      )}
    </>
  )
}

export default TemplateEditorView
