import { DayFlag, DayPicker, SelectionState, UI } from 'react-day-picker'

import { cn } from '../../lib'
import { IonIcon } from '../../Icons/IonIcon'
import { ComponentProps, useMemo } from 'react'
import { convertLocalToUTCDate } from '../../utils'
import { useTranslation } from 'react-i18next'
import { es, de, enUS, nl, fr } from 'react-day-picker/locale'

export type CalendarProps = ComponentProps<typeof DayPicker>

export function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  const { i18n } = useTranslation()

  const calendarLocale = useMemo(() => {
    switch (i18n.language) {
      case 'en':
        return enUS
      case 'de':
        return de
      case 'fr':
        return fr
      case 'nl':
        return nl
      case 'es':
        return es
      default:
        return enUS
    }
  }, [i18n.language])

  return (
    <DayPicker
      locale={calendarLocale}
      showOutsideDays={showOutsideDays}
      className={cn('relative', className)}
      classNames={{
        [UI.Month]: cn(
          '[&_.range-start:not(.range-end)]:bg-white [&_.range-start:not(.range-end)]:text-primary-s6',
          '[&_.range-start:not(.range-end)]:border [&_.range-start:not(.range-end)]:border-primary-s4',
          '[&_.range-end]:bg-primary-s4 [&_.range-end]:text-white/85',
        ),
        [UI.Months]: 'flex gap-7',
        [UI.MonthCaption]: 'flex justify-center items-center h-6 text-xs font-semibold mb-4',
        [UI.Nav]: 'absolute flex justify-between items-center h-6 top-0 right-0 left-0',
        [UI.PreviousMonthButton]:
          'h-6 w-6 flex items-center justify-center hover:bg-grey-s2 rounded-lg',
        [UI.NextMonthButton]:
          'h-6 w-6 flex items-center justify-center hover:bg-grey-s2 rounded-lg',
        [UI.Weekdays]: 'flex gap-3 text-grey-s4 text-center mb-3',
        [UI.Weekday]: 'w-6 h-6 font-normal flex items-center justify-center',
        [UI.Weeks]: 'flex flex-col gap-3',
        [UI.Week]: 'flex w-full gap-3',
        [UI.Day]: cn(
          'h-6 w-6 rounded-lg text-sm flex items-center justify-center relative transition-colors',
          'hover:bg-grey-s2',
        ),
        [UI.DayButton]: 'w-full h-full',
        [SelectionState.range_start]: 'range-start',
        [SelectionState.range_middle]: 'aria-selected:bg-primary-s2 aria-selected:text-grey-s6',
        [SelectionState.range_end]: 'range-end',
        [SelectionState.selected]: 'bg-primary-s4 text-white/85 hover:bg-primary-s4',
        [DayFlag.today]: 'bg-grey-s1',
        [DayFlag.outside]: 'text-grey-s3 hover:bg-grey-s1',
        [DayFlag.disabled]: 'text-grey-s3 hover:bg-white',
        [DayFlag.hidden]: 'invisible',
        ...classNames,
      }}
      components={{
        Chevron: ({ ...props }) =>
          props.orientation === 'left' ? (
            <IonIcon name={'chevron-back'} className='h-4 w-4 text-grey-s5' />
          ) : (
            <IonIcon name={'chevron-forward'} className='h-4 w-4 text-grey-s5' />
          ),
      }}
      weekStartsOn={1}
      {...props}
    />
  )
}
Calendar.displayName = 'Calendar'

type DatePickerProps = {
  date?: Date
  onChange: (date?: Date) => void
  disableWeekends?: boolean
}

export function UTCCalendar({ date, onChange, disableWeekends }: DatePickerProps) {
  return (
    <Calendar
      mode={'single'}
      selected={date}
      onSelect={(date) => onChange(convertLocalToUTCDate(date ?? null) ?? undefined)}
      defaultMonth={date}
      disabled={disableWeekends ? { dayOfWeek: [0, 6] } : undefined}
    />
  )
}

export type DateRangePickerProps = {
  startDate?: Date
  endDate?: Date
  onChange: (dateRange: { startDate?: Date; endDate?: Date }) => void
  disableWeekends?: boolean
  numberOfMonths?: number
  defaultMonth?: Date
}

export function UTCRangeCalendar({
  startDate,
  endDate,
  onChange,
  disableWeekends,
  numberOfMonths,
  defaultMonth,
}: DateRangePickerProps) {
  return (
    <Calendar
      mode={'range'}
      selected={{ from: startDate, to: endDate }}
      defaultMonth={defaultMonth ? defaultMonth : endDate}
      numberOfMonths={numberOfMonths}
      onSelect={(range) => {
        if (!range) {
          return
        }

        const { from, to } = range

        if (startDate && endDate && startDate.getTime() !== endDate.getTime()) {
          if (startDate.getTime() !== from?.getTime()) {
            onChange({
              startDate: convertLocalToUTCDate(from ?? null) ?? undefined,
              endDate: convertLocalToUTCDate(from ?? null) ?? undefined,
            })
          } else {
            onChange({
              startDate: convertLocalToUTCDate(to ?? null) ?? undefined,
              endDate: convertLocalToUTCDate(to ?? null) ?? undefined,
            })
          }
          return
        }

        onChange({
          startDate: convertLocalToUTCDate(from ?? null) ?? undefined,
          endDate: convertLocalToUTCDate(to ?? null) ?? undefined,
        })
      }}
      disabled={disableWeekends ? { dayOfWeek: [0, 6] } : undefined}
    />
  )
}
