import { CommonTranslations } from '../CommonTranslations'
import en from './common-en'

const override: any = {
  root: {
    utils: {
      date: {
        inXDays: 'En {{n}} días',
        tomorrow: 'Mañana',
        today: 'Hoy',
        yesterday: 'Ayer',
        xYearsAgo: 'hace {{n}} años',
        oneYearAgo: 'hace un año',
        xMonthsAgo: 'hace {{n}} meses',
        oneMonthAgo: 'hace un mes',
        xDaysAgo: 'hace {{n}} días',
        oneDayAgo: 'hace un día',
        xHoursAgo: 'hace {{n}} horas',
        oneHourAgo: 'hace una hora',
        xMinutesAgo: 'hace {{n}} minutos',
        oneMinuteAgo: 'hace un minuto',
        xSecondsAgo: 'hace {{n}} segundos',
        oneSecondAgo: 'hace un segundo',
      },
    },
  },
}

const es: CommonTranslations = {
  ...en,
  ...override,
}

export default es
