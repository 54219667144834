import { TeamWithMembers, User } from '@valuecase/common'
import { useReadUsers } from '@/modules/userManagement/hooks/useReadUsers'
import { Dialog, DialogContent, DialogTitle, Input2 } from '@valuecase/ui-components'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'
import { useCallback, useState } from 'react'
import useUpdateTeam from '../hooks/useUpdateTeam'
import UserSelection from './UserSelection'
import useSelectedMembers from '../hooks/useSelectedMembers'

const EditTeamDialog = ({
  team,
  open,
  onOpenChange,
}: {
  team: TeamWithMembers
  open: boolean
  onOpenChange: (open: boolean) => void
}) => {
  const { users } = useReadUsers()
  const { selectedMembers, toggleMember } = useSelectedMembers(
    team?.memberIds
      .map((memberId) => users?.find((user) => user.id === memberId))
      .filter((user) => user !== undefined) as User[],
  )
  const [teamName, setTeamName] = useState(team.name)

  const editTeam = useUpdateTeam()

  const handleEditTeam = useCallback(async () => {
    const memberIds = [...selectedMembers]?.map((member) => member.id)
    await editTeam.mutateAsync({
      ...team,
      name: teamName,
      memberIds,
    })

    onOpenChange(false)
  }, [selectedMembers, editTeam, team, teamName, onOpenChange])

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className='flex flex-col items-center p-8 '>
        <div className='flex flex-col gap-2 items-center w-fit h-fit'>
          <DialogTitle>Edit team</DialogTitle>
        </div>

        <div className='flex flex-col w-full'>
          <div className='flex flex-col gap-2'>
            <p className='text-xs font-semibold text-grey-s4'>Team name</p>
            <Input2
              placeholder='Name your team...'
              value={teamName}
              onChange={(e) => setTeamName(e.target.value)}
            />
          </div>
        </div>

        <div className='flex flex-col w-full gap-8'>
          <div className='flex flex-col gap-2 h-full'>
            <p className='text-xs font-semibold text-grey-s4'>Add members</p>

            <UserSelection
              users={users ?? []}
              selectedMembers={selectedMembers}
              toggleMember={toggleMember}
            />
          </div>
          <div className='flex w-full justify-center'>
            <div className='flex flex-row gap-3 items-center w-full px-5'>
              <Button2
                leadingIcon='close-circle-outline'
                variant={'outlined'}
                onClick={() => onOpenChange(false)}
                className='w-full'
              >
                Cancel
              </Button2>
              <Button2 className='w-full ' onClick={handleEditTeam}>
                Save
              </Button2>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default EditTeamDialog
