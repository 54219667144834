import { DefaultTheme } from 'styled-components'

// TypeScript support -> change the type declaration of "DefaultTheme"
// in the file "./DefaultTheme.d.ts"
// -> then our theme type is "available" in styled components too :)

const ValuecaseDefaultTheme: DefaultTheme = {
  primary: {
    s1: '#F4F6FF',
    s2: '#E9EDFF',
    s3: '#D1DBFF',
    s4: '#7793FF',
    s5: '#2A4CFA',
    s6: '#0A1CD3',
  },
  grey: {
    s1: '#F3F5F8',
    s2: '#E0E2E7',
    s3: '#C9CCD4',
    s4: '#9FA2AB',
    s5: '#767880',
    s6: '#31333A',
  },
  success: {
    s1: '#F2FDFA',
    s2: '#DBFAF1',
    s3: '#C2EEE1',
    s4: '#70D8B9',
    s5: '#33C79B',
    s6: '#0EAB7C',
  },
  warning: {
    s1: '#FFF6F6',
    s2: '#FFE5E6',
    s3: '#FCCBCD',
    s4: '#F8868A',
    s5: '#F45359',
    s6: '#D3242A',
  },

  white: '#fff',
  light: '#fff',
  dark: '#31323A', //grey6

  textGrey: '#5F6068',
}

export default ValuecaseDefaultTheme
