import React, { useCallback, useMemo } from 'react'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { parse } from 'date-fns'
import { DateTime } from 'luxon'
import {
  ActionPlanTasksOverTimeQueryFilter,
  useActionPlanTasksOverTimeQuery,
} from './action-plan-analytics.helpers'
import { Skeleton } from '@valuecase/ui-components'

type TaskCompletionChartProps = {
  groupBy: GroupBy
  filter: ActionPlanTasksOverTimeQueryFilter
}

export type GroupBy = 'biweek' | 'month'

export const TaskCompletionChart: React.FC<TaskCompletionChartProps> = ({ filter, groupBy }) => {
  const actionPlanTasksOverTimeQuery = useActionPlanTasksOverTimeQuery({
    filter,
    groupBy,
    enabled: true,
  })

  const chartData = useMemo(() => {
    const chartDataRaw = actionPlanTasksOverTimeQuery.data?.data as {
      date: string
      count: number
    }[]
    if (!chartDataRaw || chartDataRaw.length === 0) {
      return []
    }
    const dateToCountMap = new Map<string, number>(
      chartDataRaw.map((item) => [
        (
          DateTime.fromISO(item.date, {
            zone: 'utc',
          }) as DateTime<true>
        ).toISODate(),
        item.count,
      ]),
    )
    const minDate = DateTime.fromISO(chartDataRaw[0].date, { zone: 'utc' })
    const maxDate = DateTime.fromISO(chartDataRaw[chartDataRaw.length - 1].date, { zone: 'utc' })
    let currentDate = minDate
    const chartDataFilled = []
    while (currentDate <= maxDate) {
      const dateStr = currentDate.toISODate()
      if (dateStr) {
        chartDataFilled.push({
          date: dateStr,
          count: dateToCountMap.get(dateStr) || 0,
        })
      }
      if (groupBy === 'biweek') {
        currentDate = currentDate.plus({ weeks: 2 })
      } else if (groupBy === 'month') {
        currentDate = currentDate.plus({ [groupBy]: 1 })
      } else {
        throw new Error('Invalid groupBy')
      }
    }
    return chartDataFilled
  }, [actionPlanTasksOverTimeQuery.data?.data, groupBy])

  const formatXAxis = useCallback(
    (tickItem: string) => {
      const date = DateTime.fromISO(tickItem)
      return groupBy === 'biweek' ? `${date.toFormat('MMM dd')}` : date.toFormat('MMM')
    },
    [groupBy],
  )

  const totalDataPoints = useMemo(() => chartData.length, [chartData])

  const customTickFormatter = useCallback(
    (value: string, index: number) => {
      const date = parse(value, 'yyyy-MM-dd', new Date())
      if (groupBy === 'month') {
        return index === 0 || date.getDate() === 1 ? formatXAxis(value) : ''
      } else {
        // For bi-weekly, show approximately 10 labels
        const interval = Math.max(Math.floor(totalDataPoints / 10), 1)

        // Always show first and last label
        if (index === 0 || index === totalDataPoints - 1) {
          return formatXAxis(value)
        }

        return index % interval === 0 ? formatXAxis(value) : ''
      }
    },
    [groupBy, formatXAxis, totalDataPoints],
  )

  const getYAxisDomain = useMemo(() => {
    const maxCount = Math.max(...chartData.map((item) => item.count))
    return [0, maxCount * 1.2]
  }, [chartData])

  const shouldShowDotsAndLabels = useMemo(() => {
    return totalDataPoints <= 20
  }, [totalDataPoints])

  return (
    <div className='space-y-4'>
      {actionPlanTasksOverTimeQuery.isLoading && <Skeleton className='h-[144px] rounded-lg' />}
      {!actionPlanTasksOverTimeQuery.isLoading && (
        <ResponsiveContainer width='100%' height={144}>
          <LineChart
            data={chartData}
            className='bg-background rounded-md'
            margin={{ top: 32, right: 16, bottom: 4, left: 16 }}
          >
            <CartesianGrid vertical={false} stroke='var(--theme-grey-s2)' />
            <XAxis
              dataKey='date'
              tickFormatter={customTickFormatter}
              stroke='var(--theme-grey-s6)'
              tickLine={false}
              axisLine={false}
              tick={{ fontSize: 10, fill: '#60646C' }}
              textAnchor={'middle'}
            />
            <YAxis hide={true} domain={getYAxisDomain} />
            <Line
              animationDuration={200}
              dataKey='count'
              type='monotone'
              stroke='var(--theme-blue-s4)'
              strokeWidth={3}
              dot={shouldShowDotsAndLabels ? { fill: 'var(--theme-blue-s4)', r: 2 } : false}
              label={
                shouldShowDotsAndLabels
                  ? {
                      position: 'top',
                      fill: 'var(--theme-grey-s6)',
                      fontSize: 10,
                      fontWeight: '400',
                    }
                  : false
              }
              connectNulls
            />
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload && payload.length) {
                  const date = DateTime.fromISO(payload[0].payload.date)
                  return (
                    <div className='bg-white p-1 border rounded shadow flex flex-col gap-[2px]'>
                      <div className='flex items-center gap-1'>
                        <span className='text-xxs leading-[14px] font-semibold text-grey-s6'>
                          {groupBy === 'biweek'
                            ? `${date.toFormat('MMM d')} - ${date.plus({ weeks: 1 }).toFormat('MMM d')}`
                            : date.toFormat('MMMM')}
                        </span>
                        <span className='text-xxs leading-[14px] text-grey-s5'>
                          {payload[0].value}
                        </span>
                      </div>
                      <span className='text-xxs leading-[14px] text-grey-s5'>Tasks</span>
                    </div>
                  )
                }
                return null
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </div>
  )
}
