import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { IonIcon } from '../../Icons/IonIcon'
import { cn } from '../../lib'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTrigger,
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Input2,
  OneLineTruncatedText,
  ScrollArea,
  ScrollBar,
  Skeleton,
  Tree,
  useNotifications,
} from '../../components/ui'
import { Button2 } from '../../components/ui/button'
import { useAssetLibraryFolders, useMutateAssetLibraryFolder } from './hooks'
import { TAssetLibraryFolder } from '@valuecase/common'

export const AssetFolderButton = ({
  folder,
  onClick,
  showActions,
}: {
  folder: TAssetLibraryFolder
  onClick?: () => void
  showActions?: boolean
}) => {
  const { success, error } = useNotifications()
  const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)
  const [hasOpenDialog, setHasOpenDialog] = useState(false)

  const { updateFolder } = useMutateAssetLibraryFolder()

  const handleDialogItemOpenChange = useCallback((open: boolean) => {
    setHasOpenDialog(open)
    if (!open) {
      setDropdownMenuOpen(false)
    }
  }, [])

  return (
    <div
      role='button'
      className={cn(
        'group hover:shadow transition-all',
        'bg-white rounded-lg text-left flex justify-between p-2 text-xs font-semibold min-h-10 items-center gap-2',
      )}
      onClick={onClick}
    >
      <div className={'flex gap-2 items-center'}>
        <IonIcon
          name='folder-open'
          className='w-5 h-5 text-grey-s3 group-hover:text-grey-s4 flex-shrink-0'
        />
        <OneLineTruncatedText>{folder.name}</OneLineTruncatedText>
      </div>
      {showActions && (
        <DropdownMenu
          open={dropdownMenuOpen}
          onOpenChange={(open) => {
            setDropdownMenuOpen(open)
          }}
          modal={false}
        >
          <DropdownMenuTrigger asChild>
            <Button2
              size={'small'}
              variant={'plain'}
              leadingIcon={'ellipsis-vertical'}
              onClick={(e) => {
                e.stopPropagation()
              }}
            />
          </DropdownMenuTrigger>
          <DropdownMenuContent
            align={'end'}
            onClick={(e) => {
              e.stopPropagation()
            }}
            className={cn({
              hidden: hasOpenDialog || !dropdownMenuOpen,
            })}
          >
            <RenameFolderDialog folder={folder} onOpenChange={handleDialogItemOpenChange}>
              <DropdownMenuItem
                onSelect={(event) => {
                  event.preventDefault()
                }}
              >
                <div className={'flex gap-2 items-center'}>
                  <IonIcon name={'c_rename'} className={'w-4 h-4 text-grey-s4'} />
                  Rename
                </div>
              </DropdownMenuItem>
            </RenameFolderDialog>
            <MoveAssetOrFolderDialog
              onNewFolderSelected={async (folderId) => {
                try {
                  await updateFolder({
                    ...folder,
                    parentFolderId: folderId,
                  })
                  success('Folder moved successfully')
                } catch (e) {
                  console.error(e)
                  error('Failed to move folder')
                }
              }}
              onOpenChange={handleDialogItemOpenChange}
              folderOrAsset={'folder'}
              folderId={folder.id}
              currentFolderId={folder.parentFolderId ?? null}
            >
              <DropdownMenuItem
                onSelect={(event) => {
                  event.preventDefault()
                }}
              >
                <div className={'flex gap-2 items-center'}>
                  <IonIcon name={'return-up-forward'} className={'w-4 h-4 text-grey-s4'} />
                  Move
                </div>
              </DropdownMenuItem>
            </MoveAssetOrFolderDialog>
            <DeleteFolderDialog folderId={folder.id} onOpenChange={handleDialogItemOpenChange}>
              <DropdownMenuItem
                onSelect={(event) => {
                  event.preventDefault()
                }}
              >
                <div className={'flex gap-2 items-center'}>
                  <IonIcon name={'trash-outline'} className={'w-4 h-4 text-warning-s4'} />
                  <p className={'text-warning-s5'}>Delete</p>
                </div>
              </DropdownMenuItem>
            </DeleteFolderDialog>
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </div>
  )
}

export const AddNewFolderDialog = ({
  parentFolderId,
  open,
  onOpenChange,
}: {
  parentFolderId: string | null
  open: boolean
  onOpenChange?: (open: boolean) => void
}) => {
  const { createFolder } = useMutateAssetLibraryFolder()
  const { success, error } = useNotifications()

  const [newFolderName, setNewFolderName] = useState('')

  const create = useCallback(async () => {
    if (!newFolderName) {
      return
    }
    onOpenChange?.(false)
    try {
      await createFolder({
        name: newFolderName.trim(),
        parentFolderId,
      })
      setNewFolderName('')
      success('Folder created successfully')
    } catch (e) {
      console.error(e)
      error('Failed to create folder')
    }
  }, [newFolderName, createFolder, parentFolderId, onOpenChange, success, error])

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => {
        onOpenChange?.(open)
        if (!open) {
          setNewFolderName('')
        }
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Folder</DialogTitle>
        </DialogHeader>

        <form
          onSubmit={(e) => {
            e.preventDefault()
            create()
          }}
        >
          <div className='flex flex-col gap-2'>
            <label htmlFor='folderName' className={'text-xs text-grey-s5'}>
              Folder Name
            </label>
            <Input2
              id='folderName'
              type='text'
              name='folderName'
              value={newFolderName}
              onInput={(e) => setNewFolderName(e.currentTarget.value)}
            />
          </div>
        </form>

        <div className={'flex justify-end gap-3'}>
          <Button2
            variant={'plain'}
            onClick={() => {
              onOpenChange?.(false)
              setNewFolderName('')
            }}
          >
            Cancel
          </Button2>
          <Button2
            disabled={!newFolderName}
            type={'submit'}
            leadingIcon={'checkmark'}
            variant={'solid'}
            onClick={create}
          >
            Add Folder
          </Button2>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export const RenameFolderDialog = ({
  folder,
  children,
  onOpenChange,
}: {
  folder: TAssetLibraryFolder
  children?: ReactNode
  onOpenChange?: (open: boolean) => void
}) => {
  const { success, error } = useNotifications()
  const { updateFolder } = useMutateAssetLibraryFolder()

  const [newFolderName, setNewFolderName] = useState(folder.name)

  const rename = useCallback(async () => {
    if (!newFolderName) {
      return
    }
    try {
      onOpenChange?.(false)
      await updateFolder({
        id: folder.id,
        name: newFolderName.trim(),
      })
      success('Folder renamed successfully')
    } catch (e) {
      console.error(e)
      error('Failed to rename folder')
    }
  }, [newFolderName, onOpenChange, updateFolder, folder.id, success, error])

  return (
    <Dialog onOpenChange={onOpenChange}>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent onClick={(e) => e.stopPropagation()}>
        <DialogHeader>
          <DialogTitle>Rename Folder</DialogTitle>
        </DialogHeader>

        <form
          onSubmit={(e) => {
            e.preventDefault()
            rename()
          }}
        >
          <div className='flex flex-col gap-2'>
            <label htmlFor='folderName' className={'text-xs text-grey-s5'} autoFocus={false}>
              Folder Name
            </label>
            <Input2
              id='folderName'
              type='text'
              name='folderName'
              value={newFolderName}
              onInput={(e) => setNewFolderName(e.currentTarget.value)}
            />
          </div>
        </form>

        <div className={'flex justify-end gap-3'}>
          <Button2
            variant={'plain'}
            onClick={() => {
              onOpenChange?.(false)
              setNewFolderName(folder.name)
            }}
          >
            Cancel
          </Button2>
          <Button2
            type={'submit'}
            disabled={!newFolderName}
            leadingIcon={'checkmark'}
            variant={'solid'}
            onClick={rename}
          >
            Save changes
          </Button2>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export const DeleteFolderDialog = ({
  folderId,
  children,
  onOpenChange,
}: {
  folderId: string
  children?: ReactNode
  onOpenChange?: (open: boolean) => void
}) => {
  const { success, error } = useNotifications()
  const { deleteFolder } = useMutateAssetLibraryFolder()

  return (
    <AlertDialog onOpenChange={onOpenChange}>
      <AlertDialogTrigger>{children}</AlertDialogTrigger>
      <AlertDialogContent onClick={(e) => e.stopPropagation()}>
        <AlertDialogHeader iconName='trash'>Delete Folder?</AlertDialogHeader>
        <AlertDialogDescription>
          Are you sure you want to delete this folder? Please note that all included assets will
          also be permanently deleted.
        </AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction
            leadingIcon={'trash'}
            onClick={async () => {
              try {
                await deleteFolder(folderId)
                success('Folder deleted successfully')
              } catch (e) {
                console.error(e)
                error('Failed to delete folder')
              }
            }}
          >
            Delete
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export const MoveAssetOrFolderDialog = ({
  onOpenChange,
  currentFolderId,
  onNewFolderSelected,
  children,
  disabled,
  ...props
}: {
  currentFolderId: string | null
  onNewFolderSelected: (folderId: string | null) => void
  onOpenChange?: (open: boolean) => void
  disabled?: boolean
  children?: ReactNode
} & ({ folderOrAsset: 'folder'; folderId: string } | { folderOrAsset: 'asset' })) => {
  const { data: folders, isLoading } = useAssetLibraryFolders({})
  const folderOrAsset = props.folderOrAsset

  const [selectedItemId, setSelectedItemId] = useState<string | null | undefined>()

  useEffect(() => {
    setSelectedItemId(currentFolderId)
  }, [currentFolderId])

  const prefilteredFolders = useMemo(() => {
    if (props.folderOrAsset === 'folder') {
      // a folder can not be moved to its own subfolder
      return folders?.filter((folder) => folder.id !== props.folderId)
    }

    return folders
  }, [folders, props])

  const folderTreeItems = useMemo(
    () =>
      prefilteredFolders?.map((folder) => ({
        name: folder.name,
        id: folder.id,
        parentFolderId: folder.parentFolderId ?? null,
      })) ?? [],
    [prefilteredFolders],
  )

  return (
    <Dialog onOpenChange={onOpenChange}>
      <DialogTrigger disabled={disabled}>{children}</DialogTrigger>
      <DialogContent
        onClick={(e) => e.stopPropagation()}
        onOpenAutoFocus={(e) => e.preventDefault()}
      >
        <DialogHeader>
          <DialogTitle>Move {folderOrAsset === 'folder' ? 'Folder' : 'Asset'}</DialogTitle>
        </DialogHeader>

        {isLoading && (
          <div className={'flex gap-1 w-full flex-col'}>
            <Skeleton className={'w-full h-10'} />
            <Skeleton className={'w-full h-10'} />
            <Skeleton className={'w-full h-10'} />
          </div>
        )}

        {!isLoading && (
          <ScrollArea maxHeight={400}>
            <button
              className={
                'flex items-center justify-between p-2 font-medium text-sm hover:bg-grey-s1 w-full rounded-md'
              }
              onClick={() => setSelectedItemId(null)}
            >
              <div className={'flex items-center gap-2'}>
                <div className={'flex items-center justify-center w-6 h-6'}>
                  <IonIcon name='folder-open-outline' className='h-4 w-4 text-grey-s4' />
                </div>
                <div className={'flex items-center gap-1'}>
                  <span>Library (No folder)</span>
                  {currentFolderId === null && (
                    <span className={'text-grey-s4 font-normal'}>Current location</span>
                  )}
                </div>
              </div>
              {selectedItemId === null && (
                <IonIcon className={'w-4 h-4 text-primary-s5'} name={'checkmark'} />
              )}
            </button>
            <Tree
              items={folderTreeItems}
              currentItem={currentFolderId}
              selectedItem={selectedItemId}
              onSelectedChange={setSelectedItemId}
              noChildrenTooltip={'Disabled - No folders inside'}
            />
            <ScrollBar orientation={'vertical'} />
          </ScrollArea>
        )}

        <div className={'flex justify-end gap-3'}>
          <Button2
            variant={'plain'}
            onClick={() => {
              onOpenChange?.(false)
            }}
          >
            Cancel
          </Button2>
          <Button2
            disabled={selectedItemId === undefined || selectedItemId === currentFolderId}
            leadingIcon={'return-up-forward'}
            variant={'solid'}
            onClick={() => {
              if (selectedItemId === undefined) {
                throw new Error('selected item id is undefined')
              }
              onNewFolderSelected(selectedItemId)
              onOpenChange?.(false)
            }}
          >
            Move
          </Button2>
        </div>
      </DialogContent>
    </Dialog>
  )
}
