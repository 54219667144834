import styled from 'styled-components'

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const SimpleSidebarItem = styled.div<{ $isHighlighted?: boolean }>`
  display: flex;
  padding: 16px 24px;
  border-radius: 12px;
  background: ${({ $isHighlighted: isHighlighted, theme }) =>
    isHighlighted ? theme.primary.s1 : theme.white};
  cursor: ${({ $isHighlighted: isHighlighted }) => (isHighlighted ? 'default' : 'pointer')};
  &:hover {
    background: ${({ theme }) => theme.primary.s1};
  }
`
const Container = styled.div`
  display: flex;
  align-items: center;
`

const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
const Label = styled.div`
  margin-left: 12px;
  font-style: normal;
  font-weight: 550;
  font-size: 14px;
  line-height: 140%;
  color: ${({ theme }) => theme.grey.s6};
  font-stretch: 100;
`

const SidebarItemChildren = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 35px;
  box-shadow: -2px 0px 0px 0px ${({ theme }) => theme.grey.s2};
  gap: 20px;
`
export const S = {
  SidebarItem: SimpleSidebarItem, //TODO
  Label,
  Container,
  RootContainer,
  SidebarItemChildren,
}
