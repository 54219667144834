import { useOverlay, SimpleModal, ModalControls } from '@valuecase/ui-components'
import { useCallback, useEffect, useRef } from 'react'
import '../../assets/modalStyles.css'
import { useTrackEvent } from '../../../../mixpanel/useTrackEvent'
import { SpaceCreationFlowWrapper } from './SpaceCreationFlowWrapper'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'

const TriggerCreateSpaceFlow = ({
  shouldOpenModalExternally,
}: {
  shouldOpenModalExternally?: boolean
}) => {
  const { trackEvent } = useTrackEvent()
  const overlay = useOverlay()
  const controls = useRef<ModalControls | null>(null)

  const open = useCallback(() => {
    return overlay.show(
      <SimpleModal
        controlsRef={controls}
        additionalStyleClasses={{
          layout: 'spacecreationflow-modal',
          baseModal: 'spacecreationflow-modal__base',
        }}
        body={<SpaceCreationFlowWrapper />}
        onCloseCallback={handleOnCloseCallback}
        maxWidth='978px'
        wide={true}
      />,
    )
  }, [overlay])

  // NOTE: In DEV, this will trigger two times the modal due to how useEffect is ran in DEV.
  // NOTE: In PROD, this will trigger one time the modal.
  useEffect(() => {
    if (!shouldOpenModalExternally) return
    open()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldOpenModalExternally])

  function handleOnCloseCallback() {
    const queryString = window.location.search
    if (queryString) {
      const urlParams = new URLSearchParams(queryString)
      const createSpaceFromTemplate = urlParams.get('createSpaceFromTemplate')

      if (createSpaceFromTemplate) {
        urlParams.delete('createSpaceFromTemplate')
      }

      if (urlParams.size === 0) {
        window.history.replaceState({}, '', `${window.location.pathname}`)
      } else {
        window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`)
      }
    }
  }

  return (
    <>
      <Button2
        className={'mt-2'}
        data-intercom-target='Create New Space Button'
        trailingIcon={'add'}
        onClick={() => {
          trackEvent({
            event: 'spaces-newspace-open',
            eventProperties: {
              indexPageName: 'Spaces',
            },
          })
          open()
        }}
      >
        Create New Space
      </Button2>
    </>
  )
}

export default TriggerCreateSpaceFlow
