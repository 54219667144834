import { useEffect, useState } from 'react'
import { spacesApi } from './spacesAPIclient'

export function useSignedUrlForS3Key(s3Key?: string): { isLoading: boolean; imageUrl?: string } {
  const [imageUrl, setImageUrl] = useState<string | undefined>()

  useEffect(() => {
    if (!s3Key) {
      setImageUrl(undefined)
      return
    }

    let cancelled = false

    spacesApi.get(`files/presignedGet?key=${encodeURIComponent(s3Key)}`).then((res) => {
      if (cancelled) return

      setImageUrl(res.data.presignedGet)
    })

    return () => {
      cancelled = true
    }
  }, [s3Key])

  return {
    isLoading: !imageUrl,
    imageUrl,
  }
}

export function useSignedImageUrl(
  s3KeyOrLocationObject?: string | { source: 'S3'; s3Key: string } | null,
) {
  return useSignedUrlForS3Key(
    !s3KeyOrLocationObject
      ? undefined
      : typeof s3KeyOrLocationObject === 'string'
        ? s3KeyOrLocationObject
        : s3KeyOrLocationObject.s3Key,
  )
}
