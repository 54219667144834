import styled from 'styled-components'

export const TemplateListWrapper = styled.div`
  padding: 16px 0;
`

export const TemplateList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-y: scroll;
`
export const Filters = styled.div`
  display: flex;
  gap: 8px;
`
export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 21px;
`

export const TemplateListIconBox = styled.span`
  display: inline-block;
  font-size: 24px;
  height: 44px;
  width: 44px;
  min-width: 44px;
  background: ${({ theme }) => theme.grey.s1};
  border: 1px solid ${({ theme }) => theme.grey.s2};
  border-radius: 8px;
  margin: 8px 0 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TemplateListNameWrapper = styled.span`
  display: flex;
  align-items: center;
  overflow: hidden;
`

export const TemplateListNameAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  overflow: hidden;
`

export const TemplateListNameTextWrapper = styled.span`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.grey.s6};
  max-height: 19px;
  overflow: hidden;
  padding-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const TemplatelistDescriptionTextWrapper = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: ${({ theme }) => theme.grey.s5};
  max-height: 19px;
  overflow: hidden;
  padding-right: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const TemplateListTableWrapper = styled.div`
  margin-top: 16px;
  padding-bottom: 24px;
`
