import React, { MouseEventHandler, ReactNode } from 'react'
import {
  autoUpdate,
  Middleware,
  Placement,
  shift,
  useFloating,
} from '@floating-ui/react-dom-interactions'
import styled from 'styled-components'
import { flip } from '@floating-ui/react-dom'
import { BasePortal } from '../../Portal/BasePortal'
import ZIndices from '../../../../global-styles/ZIndices'

export interface DropdownProps {
  trigger: ReactNode
  children?: ReactNode
  placements?: Placement[]
  opened?: boolean
  onChange?: (opened: boolean) => void
  additionalMiddleware?: Middleware[]
  middleware?: Middleware[]
  onTriggerClick?: MouseEventHandler<HTMLSpanElement | undefined>
}

export function BaseDropdown({
  trigger,
  children,
  placements = ['bottom'],
  additionalMiddleware = [],
  middleware,
  opened,
  onChange,
  onTriggerClick,
}: DropdownProps) {
  const {
    x,
    y,
    reference: triggerRef,
    floating: popupRef,
    strategy,
  } = useFloating({
    open: opened,
    onOpenChange: onChange,
    whileElementsMounted: autoUpdate,
    strategy: 'absolute',
    placement: placements[0],
    middleware: middleware || [flip(), shift({ padding: 40 }), ...additionalMiddleware],
  })

  return (
    <>
      <span ref={triggerRef} onClick={onTriggerClick}>
        {trigger}
      </span>

      <BasePortal
        portalId={DropdownKey}
        ignoreBasePortalProviderContainer={true}
        zIndex={ZIndices.baseDropdown}
      >
        {opened && (
          <DropdownBackdrop
            onClickOutside={() => {
              onChange?.(false)
            }}
          >
            <span
              ref={popupRef}
              style={{
                position: strategy,
                zIndex: 3,
                top: y ?? 0,
                left: x ?? 0,
                display: 'flex',
              }}
              onClick={(e) => e.stopPropagation()}
            >
              {children}
            </span>
          </DropdownBackdrop>
        )}
      </BasePortal>
    </>
  )
}

const DropdownKey = 'dropdown-portal'

interface DropdownBackdropProps {
  onClickOutside: () => void
  children?: React.ReactNode
}

const DropdownBackdrop = ({ onClickOutside, children }: DropdownBackdropProps) => {
  return (
    <StyledDropdownBackdrop
      onClick={(e) => {
        e.stopPropagation()
        onClickOutside()
      }}
    >
      {children}
    </StyledDropdownBackdrop>
  )
}

const StyledDropdownBackdrop = styled.div`
  height: 100%;
  width: 100%;
  background-color: transparent;
  pointer-events: auto;
`
