import { WeaklyTypedActivity } from '@valuecase/common'

export interface PlaceholderValuePair {
  key: 'contactFirstName' | 'contactLastName' | 'companyName'
  value?: string
}

export interface PlaceholdableProps {
  renderActualValues: boolean
  placeholderValues: PlaceholderValuePair[]
}

export const defaultPlaceholderValuePairs: PlaceholderValuePair[] = [
  {
    key: 'contactFirstName',
  },
  {
    key: 'contactLastName',
  },
  {
    key: 'companyName',
  },
]

export interface PlaceholderValuePairsInterface {
  defaultPairs: () => PlaceholderValuePair[]
  defaultPlaceholdableProps: PlaceholdableProps
  renderActualValues: boolean
}

export function placeholderValuePairs(data: WeaklyTypedActivity): PlaceholderValuePairsInterface {
  function defaultPairs() {
    return defaultPlaceholderValuePairs.map(({ key }) => ({
      key,
      value: data.rootNode.versions[0].asset.data[key],
    }))
  }

  return {
    renderActualValues: true,
    defaultPairs,
    defaultPlaceholdableProps: {
      placeholderValues: defaultPairs(),
      renderActualValues: true,
    },
  }
}

export function getPersonalisationValue(
  key: string,
  value: string | undefined,
  renderActualValues?: boolean,
): string {
  if (key === 'contactLastName') {
    return renderActualValues && value ? value : 'Contact: Last Name'
  }
  if (key === 'contactFirstName') {
    return renderActualValues && value ? value : 'Contact: First Name'
  }
  if (key === 'companyName') {
    return renderActualValues && value ? value : 'Contact: Company Name'
  }
  return renderActualValues && value ? value : key
}
