import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import SellerApi from '../../../api/SellerApi'
import { AuthState, useAuthState } from '../../../auth/auth'
import { useCurrentTenantQuery } from '../../tenant/hooks/useReadTenant'
import { GetCompanyImprintResponse } from '@valuecase/seller-backend'

type UseCompanyImprintProps = {
  onUpdateSuccess?: () => void
}

type CompanyImprintMutationData = {
  content: string
}

const queryKey = ['companyImprint']

async function queryFn(tenantId: string | undefined) {
  if (!tenantId) {
    return null
  }
  const res = await SellerApi.get<GetCompanyImprintResponse, undefined>(
    `/tenants/${tenantId}/imprint`,
  )
  return res.payload
}

async function mutationFn(auth: AuthState, tenantId: string, data: CompanyImprintMutationData) {
  if (!auth.authorized) {
    throw new Error('Authorization missing')
  }
  return await SellerApi.patch(`/tenants/${tenantId}/imprint`, data)
}

export function useCompanyImprint(props?: UseCompanyImprintProps) {
  const auth = useAuthState()
  const queryClient = useQueryClient()
  const { tenant, isFetched } = useCurrentTenantQuery()

  const query = useQuery({
    queryKey: queryKey,
    queryFn: () => queryFn(tenant?.payload?.id),
    retry: 1,
    staleTime: 1000 * 60, // 60 seconds
    enabled: isFetched && !!tenant?.payload?.id,
  })

  const mutation = useMutation({
    mutationFn: (data: CompanyImprintMutationData) => {
      if (!auth.authorized || !tenant?.payload?.id) {
        throw new Error('Authorization missing')
      }
      return mutationFn(auth, tenant.payload.id, data)
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey })

      if (props?.onUpdateSuccess) {
        props.onUpdateSuccess()
      }
    },
  })

  return {
    // query
    isQueryLoading: query.isLoading,
    isQueryReloading: query.isRefetching && query.isStale,
    isQueryError: query.isError,
    refetchQuery: query.refetch,
    queryError: query.error,
    companyImprint: query.data,
    isQueryFetched: query.isFetched,

    // mutation
    isMutationSuccess: mutation.isSuccess,
    isMutationLoading: mutation.isLoading,
    isMutationError: mutation.isError,
    mutationError: mutation.error,
    updateCompanyImprint: (data: CompanyImprintMutationData) => mutation.mutate(data),
  }
}
