import styled from 'styled-components'
import React from 'react'
import { DefaultTheme, ThemeProvider, useTheme } from 'styled-components'
import { themeToCssVars } from './ThemeUtils'

export interface ValuecaseThemeProviderProps {
  children: React.ReactNode
  theme: DefaultTheme
}

export default function ValuecaseThemeProvider({ children, theme }: ValuecaseThemeProviderProps) {
  return (
    <ThemeProvider theme={theme}>
      <CssVarsWrapper>{children}</CssVarsWrapper>
    </ThemeProvider>
  )
}

export function useThemeAsCssVars(): string {
  const theme = useTheme()
  return themeToCssVars({ theme })
}

const CssVarsWrapper = styled.div`
  height: 100%;

  ${({ theme }: { theme: any }) => {
    return themeToCssVars({
      theme,
    })
  }}
`
