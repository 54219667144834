import { HeaderFooterScrollableModal, ModalControls } from '@valuecase/ui-components'
import { FC, useMemo, useState } from 'react'
import TemplateDelete from './TemplateDelete'
import { TemplateCreatorTemplateDetails } from '../types/TemplateCreatorTemplateDetails'

export interface TemplateDeleteModalProps {
  controlsRef: React.MutableRefObject<ModalControls | null>
  template: TemplateCreatorTemplateDetails
  onSuccessCallback?: () => void
}

const TemplateDeleteModal: FC<TemplateDeleteModalProps> = ({
  controlsRef,
  template,
  onSuccessCallback,
}) => {
  const [modalIsPersistent, setModalIsPersistent] = useState<boolean>(false)

  const height = useMemo(() => (template.archived ? '275px' : '308px'), [template.archived])

  return (
    <HeaderFooterScrollableModal
      persistent={modalIsPersistent}
      controlsRef={controlsRef}
      needExplicitHeight={{ min: height, preferred: height }}
    >
      <TemplateDelete
        onSuccessCallback={onSuccessCallback}
        setIsModalPersistent={setModalIsPersistent}
        template={template}
      />
    </HeaderFooterScrollableModal>
  )
}

export default TemplateDeleteModal
