import { useTrackEvent } from '@/mixpanel/useTrackEvent'
import { TeamUnion } from '@valuecase/common'
import { Input, InputSize } from '@valuecase/ui-components'
import { cn } from '@valuecase/ui-components/src/lib/utils'
import EmojiPicker, { EmojiStyle } from 'emoji-picker-react'
import { Dispatch, FC, SetStateAction, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import TeamSelector from '../teams/TeamSelector'
import {
  TemplateCreatorInfoInputLabel,
  TemplateCreatorNameInputWrapper,
  TemplateCreatorNameLabelWrapper,
  TemplateCreatorPreviewIconButton,
  TemplateCreatorPreviewIconHint,
  TemplateCreatorPreviewIconInputWrapper,
  TemplateCreatorPreviewImageLabelWrapper,
  TemplateCreatorUsageInputWrapper,
  TemplateCreatorUsageLabelWrapper,
  TemplateCreatorUsageTextarea,
} from './TemplateFromSpaceCreator/TemplateFromSpaceCreatorParts.styled'

const TemplateInputForm: FC<{
  teams?: TeamUnion[]
  selectedTeamIds: Set<string> | undefined
  setSelectedTeamIds: Dispatch<SetStateAction<Set<string> | undefined>>
  tenantName: string
  newTemplateName: string
  setNewTemplateName: (newTemplateName: string) => void
  newTemplateUsage: string
  setNewTemplateUsage: (newTemplateUsage: string) => void
  newTemplateIcon: string
  setNewTemplateIcon: (newTemplateIcon: string) => void
}> = ({
  teams,
  selectedTeamIds,
  setSelectedTeamIds,
  tenantName,
  newTemplateName,
  setNewTemplateName,
  newTemplateUsage,
  setNewTemplateUsage,
  newTemplateIcon,
  setNewTemplateIcon,
}) => {
  const hasTeams = useMemo(() => teams && teams.length > 0, [teams])
  const [showEmojiPicker, setShowEmojiPicker] = useState(false)
  const emojiButtonRef = useRef<null | HTMLButtonElement>(null)
  const { trackEvent } = useTrackEvent()
  return (
    <div
      className={cn(
        'w-full h-full overflow-auto px-8 py-6 bg-grey-s1',
        'grid gap-y-4 items-center',
        'grid-rows-[repeat(5,max-content)_1fr]',
        'sm:grid-rows-[max-content_max-content_1fr]',
        'sm:grid-cols-[224px_1fr]',
        'sm:gap-x-6',
        {
          'sm:grid-rows-[repeat(2,max-content)_1fr]': !hasTeams,
          'sm:grid-rows-[repeat(3,max-content)_1fr]': hasTeams,
          "[grid-template-areas:'teamsLabel_teamsInput''iconLabel_iconInput''nameLabel_nameInput''usageLabel_usageInput']":
            hasTeams,
          "[grid-template-areas:'iconLabel_iconInput''nameLabel_nameInput''usageLabel_usageInput']":
            !hasTeams,
        },
      )}
    >
      {hasTeams && (
        <>
          <div className='[grid-area:teamsLabel] font-semibold text-sm text-grey-s6'>
            Team access
          </div>
          <div className='[grid-area:teamsInput] flex'>
            <TeamSelector
              selectedTeamIds={selectedTeamIds}
              teams={teams}
              setSelectedTeamIds={setSelectedTeamIds}
              tenantName={tenantName}
            />
          </div>
        </>
      )}
      <TemplateCreatorPreviewImageLabelWrapper>
        <TemplateCreatorInfoInputLabel>Preview icon</TemplateCreatorInfoInputLabel>
      </TemplateCreatorPreviewImageLabelWrapper>
      <TemplateCreatorPreviewIconInputWrapper>
        <TemplateCreatorPreviewIconButton
          ref={emojiButtonRef}
          onClick={(event) => {
            event.preventDefault()
            setShowEmojiPicker(true)
          }}
        >
          {newTemplateIcon}
        </TemplateCreatorPreviewIconButton>
        <TemplateCreatorPreviewIconHint>Click to change the icon.</TemplateCreatorPreviewIconHint>
        {showEmojiPicker &&
          createPortal(
            <div
              onClick={(event) => {
                event.preventDefault()
                event.stopPropagation()
                setShowEmojiPicker(false)
              }}
              style={{
                position: 'fixed',
                width: '100vw',
                height: '100vh',
                zIndex: '9999',
                top: 0,
                right: 0,
              }}
            >
              <div
                // Prevent click propagating to avoid early closer of emoji selection
                onClick={(event) => event.stopPropagation()}
                style={{
                  width: '350px',
                  height: '450px',
                  position: 'fixed',
                  top: emojiButtonRef.current
                    ? emojiButtonRef.current.getBoundingClientRect().y + 34 + 'px'
                    : '0px',
                  left: emojiButtonRef.current
                    ? emojiButtonRef.current.getBoundingClientRect().x + 34 + 'px'
                    : '0px',
                }}
              >
                <EmojiPicker
                  onEmojiClick={(data) => {
                    trackEvent({
                      event: 'templates-newtemplate-change_icon',
                      eventProperties: {
                        indexPageName: 'templates',
                        isNewTemplate: true,
                        templateID: 'TemplateNotYetCreated',
                      },
                    })
                    setNewTemplateIcon(data.emoji)
                  }}
                  emojiStyle={EmojiStyle.NATIVE}
                />
              </div>
            </div>,
            document.body,
          )}
      </TemplateCreatorPreviewIconInputWrapper>
      <TemplateCreatorNameLabelWrapper>
        <TemplateCreatorInfoInputLabel>Template name</TemplateCreatorInfoInputLabel>
      </TemplateCreatorNameLabelWrapper>
      <TemplateCreatorNameInputWrapper>
        <Input
          value={newTemplateName}
          onInput={(input) => setNewTemplateName(input)}
          size={InputSize.LARGE}
          placeholder='Enter template name'
        />
      </TemplateCreatorNameInputWrapper>
      <TemplateCreatorUsageLabelWrapper>
        <TemplateCreatorInfoInputLabel>Template description</TemplateCreatorInfoInputLabel>
      </TemplateCreatorUsageLabelWrapper>
      <TemplateCreatorUsageInputWrapper>
        <TemplateCreatorUsageTextarea
          placeholder='Enter description'
          value={newTemplateUsage}
          onChange={(event) => setNewTemplateUsage(event.target.value)}
        ></TemplateCreatorUsageTextarea>
      </TemplateCreatorUsageInputWrapper>
    </div>
  )
}

export default TemplateInputForm
