import { FC, FunctionComponent, ReactNode, SVGProps } from 'react'
import { S } from './MenuDropdown.styled'
import { IonIcon } from '../../../../Icons/IonIcon'

export type DropdownItemVariant = 'default' | 'success' | 'danger'

export interface DropdownItemProps {
  label: string
  onClick: () => void
  LeftIcon?: string | FunctionComponent<SVGProps<SVGSVGElement>>
  RightIcon?: string | FunctionComponent<SVGProps<SVGSVGElement>>
  hoverIconColor?: string
  disabled?: boolean
  children?: ReactNode
  variant?: DropdownItemVariant
}

export const MenuDropdownItem: FC<DropdownItemProps> = ({
  LeftIcon,
  RightIcon,
  label,
  children,
  variant = 'default',
  hoverIconColor,
  ...props
}) => {
  const TypedLeftIcon = LeftIcon as FunctionComponent<SVGProps<SVGSVGElement>>
  const TypedRightIcon = RightIcon as FunctionComponent<SVGProps<SVGSVGElement>>

  return (
    <S.MenuItem {...props} $hoverIconColor={hoverIconColor} role='menuitem' className={variant}>
      <div className={'left-content'}>
        {LeftIcon && typeof LeftIcon === 'function' && <TypedLeftIcon />}
        {LeftIcon && typeof LeftIcon === 'string' && <IonIcon name={LeftIcon} />}

        {label}
        {children}
      </div>

      {RightIcon && (
        <div className={'right-content'}>
          {typeof RightIcon === 'function' && <TypedRightIcon />}
          {typeof RightIcon === 'string' && <IonIcon name={RightIcon} />}
        </div>
      )}
    </S.MenuItem>
  )
}
