import {
  Button,
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
  IonIcon,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from '@valuecase/ui-components'
import { useCurrentLocation } from '../../../hooks/useCurrentLocation'
import { MouseEvent, ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import {
  generatePageNameForMixpanelBasedOnOurRoutes,
  useTrackEvent,
} from '../../../../../mixpanel/useTrackEvent'
import { useLDflags } from '@/launchdarkly/Launchdarkly'
import { cn } from '@/utils/Cn'
import { ButtonLink } from '@valuecase/ui-components/src/components/ui/button'
import ValuecaseLogo from '../../../../../assets/valuecase-logo.svg?react'
import { useCurrentTenantQuery } from '@/modules/tenant/hooks/useReadTenant'

const useSupportBoxOpen = (): [boolean, (value: boolean) => void] => {
  const STORAGE_KEY = 'seller-supportBoxOpen'

  // Retrieve the initial value from localStorage or default to true
  const getInitialValue = (): boolean => {
    const storedValue = localStorage.getItem(STORAGE_KEY)
    return storedValue ? JSON.parse(storedValue) : true
  }

  const [supportBoxOpen, setSupportBoxOpen] = useState<boolean>(getInitialValue)

  // Update localStorage whenever the state changes
  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(supportBoxOpen))
  }, [supportBoxOpen])

  return [supportBoxOpen, setSupportBoxOpen]
}

const NewReleaseLink = ({ children, className }: { className?: string; children: ReactNode }) => {
  const currentLocation = useCurrentLocation()
  const { trackEvent } = useTrackEvent()

  const trackClickNewReleases = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      trackEvent({
        event: 'sidebar-newreleases-open',
        eventProperties: {
          indexPageName: generatePageNameForMixpanelBasedOnOurRoutes(currentLocation),
        },
      })
    },
    [currentLocation, trackEvent],
  )

  return (
    <a
      className={cn('flex items-center transition-colors hover:text-[#7793FF]', className)}
      id='sidebar-newreleases'
      onClick={trackClickNewReleases}
      href='https://app.valuecase.com/spaces/valuecase_changelog?token=77908694bd2cf97a'
      target='_blank'
      rel='noreferrer'
    >
      {children}
    </a>
  )
}

const HelpCenterLink = ({ children, className }: { className?: string; children: ReactNode }) => {
  const currentLocation = useCurrentLocation()
  const { trackEvent } = useTrackEvent()

  const trackClickHelpCenter = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      trackEvent({
        event: 'sidebar-helpcenter-open',
        eventProperties: {
          indexPageName: generatePageNameForMixpanelBasedOnOurRoutes(currentLocation),
        },
      })
    },
    [currentLocation, trackEvent],
  )

  return (
    <a
      className={cn('flex items-center transition-colors hover:text-[#7793FF]', className)}
      onClick={trackClickHelpCenter}
      href='https://help.valuecase.de/en'
      target='_blank'
      rel='noreferrer'
    >
      {children}
    </a>
  )
}

const Footer = () => {
  const { flags } = useLDflags()
  const { tenant } = useCurrentTenantQuery()

  const showReferralButton = useMemo(() => flags && flags['show-referral'], [flags])
  const [supportBoxOpen, setSupportBoxOpen] = useSupportBoxOpen()

  return (
    <div className={'flex flex-col gap-2 mb-4 pt-8 mx-4'}>
      {showReferralButton && (
        <div className={'my-2'}>
          <Button
            icon={'heart'}
            style={'success'}
            label={'Refer & earn 250€+'}
            onClick={() => {
              window.Intercom('startTour', 6964)
            }}
          />
        </div>
      )}
      {flags?.['show-seller-help-center'] && (
        <Collapsible
          id='sidebar-support-box'
          open={supportBoxOpen}
          onOpenChange={setSupportBoxOpen}
          className={'bg-[#151D39] rounded-lg text-white p-3'}
        >
          <CollapsibleTrigger className={'w-full'}>
            <div className={'w-full flex items-center justify-between font-bold px-1'}>
              {supportBoxOpen && <span>News & Support</span>}
              {!supportBoxOpen && <span>Support</span>}

              <div className={'flex items-center gap-6'}>
                {!supportBoxOpen && (
                  <div className={'flex items-center gap-3'}>
                    <Tooltip>
                      <TooltipTrigger>
                        <NewReleaseLink>
                          <IonIcon name={'rocket-outline'} className={'w-4 h-4'} />
                        </NewReleaseLink>
                      </TooltipTrigger>
                      <TooltipContent side='top' sideOffset={22}>
                        New releases
                      </TooltipContent>
                    </Tooltip>
                    <Tooltip>
                      <TooltipTrigger>
                        <HelpCenterLink>
                          <IonIcon name={'help-circle-outline'} className={'w-4 h-4'} />
                        </HelpCenterLink>
                      </TooltipTrigger>
                      <TooltipContent side='top' sideOffset={22}>
                        Help center
                      </TooltipContent>
                    </Tooltip>
                    <Tooltip>
                      <TooltipTrigger>
                        <button
                          className={'flex items-center transition-colors hover:text-[#7793FF]'}
                        >
                          <IonIcon
                            name={'chatbox-ellipses-outline'}
                            className={'w-4 h-4'}
                            onClick={(e) => {
                              e.stopPropagation()
                              window.Intercom('show')
                            }}
                          />
                        </button>
                      </TooltipTrigger>
                      <TooltipContent side='top' sideOffset={22}>
                        Chat with us
                      </TooltipContent>
                    </Tooltip>
                  </div>
                )}
                {!supportBoxOpen && (
                  <IonIcon
                    name={'chevron-up'}
                    className={'w-4 h-4  transition-colors hover:text-[#7793FF]'}
                  />
                )}
                {supportBoxOpen && (
                  <IonIcon
                    name={'chevron-down'}
                    className={'w-4 h-4 transition-colors hover:text-[#7793FF]'}
                  />
                )}
              </div>
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent className={'text-xs font-medium'}>
            <div className={'flex flex-col gap-2 mt-4'}>
              <div className={'flex px-1'}>
                <NewReleaseLink className={'flex gap-2'}>
                  <IonIcon name={'rocket-outline'} className={'w-4 h-4'} />
                  <span>New releases</span>
                </NewReleaseLink>
              </div>
              <div className={'flex px-1'}>
                <HelpCenterLink className={'flex gap-2'}>
                  <IonIcon name={'help-circle-outline'} className={'w-4 h-4'} />
                  <span>Help center</span>
                </HelpCenterLink>
              </div>
              <div className={'flex px-1'}>
                <button
                  className={'flex gap-2 transition-colors hover:text-[#7793FF]'}
                  onClick={() => {
                    window.Intercom('show')
                  }}
                >
                  <IonIcon name={'chatbox-ellipses-outline'} className={'w-4 h-4'} />
                  <span>Chat with us</span>
                </button>
              </div>
            </div>
          </CollapsibleContent>
          {flags?.['trial-shared-space-button'] && !!tenant?.payload?.onboardingSharedPageUrl && (
            <ButtonLink
              href={tenant?.payload?.onboardingSharedPageUrl}
              target='_blank'
              rel='noreferrer'
              className={'mt-4 w-full'}
              trailingIcon={'arrow-forward'}
            >
              <div className={'flex gap-2 items-center'}>
                <ValuecaseLogo className={'w-4 h-4'} />
                Go to shared space
              </div>
            </ButtonLink>
          )}
        </Collapsible>
      )}
    </div>
  )
}

export default Footer
