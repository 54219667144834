import { CommonTranslations } from '../CommonTranslations'
import en from './common-en'

const override: any = {
  root: {
    utils: {
      date: {
        inXDays: 'In {{n}} Tagen',
        tomorrow: 'Morgen',
        today: 'Heute',
        yesterday: 'Gestern',
        xYearsAgo: 'vor {{n}} Jahren',
        oneYearAgo: 'vor einem Jahr',
        xMonthsAgo: 'vor {{n}} Monaten',
        oneMonthAgo: 'vor einem Monat',
        xDaysAgo: 'vor {{n}} Tagen',
        oneDayAgo: 'vor einem Tag',
        xHoursAgo: 'vor {{n}} Stunden',
        oneHourAgo: 'vor einer Stunde',
        xMinutesAgo: 'vor {{n}} Minuten',
        oneMinuteAgo: 'vor einer Minute',
        xSecondsAgo: 'vor {{n}} Sekunden',
        oneSecondAgo: 'vor einer Sekunde',
      },
    },
  },
}

const de: CommonTranslations = {
  ...en,
  ...override,
}

export default de
