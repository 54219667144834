import styled from 'styled-components'
const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 280px;
  min-width: 280px;
  max-width: 280px;
  background: white;
  gap: 12px;
  overflow: auto;
  padding-top: 24px;
  border-right: 1px solid ${({ theme }) => theme.grey.s2};
`

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: 16px;
  margin-right: 16px;
`

export const S = { Sidebar, List }
