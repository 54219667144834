import { useMemo } from 'react'
import { useSpacesQuery } from '../spaces/hooks/useReadSpaces'
import { useAuthState } from '@/auth/auth'

export const useProgressBarQuery = ({ spaceCreatedAfter }: { spaceCreatedAfter?: Date }) => {
  const auth = useAuthState()
  const ownerIds = useMemo(() => {
    return auth.authorized ? [auth.sub] : []
  }, [auth])
  const { spaces, isLoading } = useSpacesQuery({
    useUrlSearchParams: false,
    filters: {
      spaceType: 'active',
      ownerIds,
      createdAfter: spaceCreatedAfter,
    },
    sorting: {
      sortBy: 'updatedAt',
      sortDirection: 'desc',
    },
    page: 1,
    pageSize: 2000,
    includeSpaceScore: false,
    enabled: !!ownerIds.length,
    keepPreviousData: true,
  })

  const result = useMemo(() => ({ spaces, isLoading }), [spaces, isLoading])
  return result
}
