import React, { FunctionComponent, ReactNode, SVGProps } from 'react'
import styled from 'styled-components'
import { IonIcon } from '@valuecase/ui-components'

interface DisabledMenuDropdownItemProps {
  label: string
  LeftIcon?: string | FunctionComponent<SVGProps<SVGSVGElement>>
  RightIcon?: string | FunctionComponent<SVGProps<SVGSVGElement>>
  children?: ReactNode
}

const DisabledMenuItem = styled.div`
  white-space: nowrap;
  z-index: 1;
  border-radius: 8px;

  padding: 6px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 240px;

  .left-content {
    display: flex;
    align-items: center;
    & > *:first-child {
      margin-right: 8px;
    }

    svg {
      height: 20px;
    }

    .ionicon {
      font-size: 20px;
    }
  }

  .right-content {
    display: flex;
    align-items: center;

    svg {
      height: 20px;
    }

    .ionicon {
      font-size: 20px;
    }
  }

  color: var(--theme-grey-s4);
  .left-content,
  .right-content {
    path {
      fill: var(--theme-grey-s2);
    }

    .ionicon {
      color: var(--theme-grey-s2);
    }
  }
  cursor: default;
`

export function DisabledMenuDropdownItem({
  LeftIcon,
  RightIcon,
  label,
  children,
}: DisabledMenuDropdownItemProps) {
  const TypedLeftIcon = LeftIcon as FunctionComponent<SVGProps<SVGSVGElement>>
  const TypedRightIcon = RightIcon as FunctionComponent<SVGProps<SVGSVGElement>>

  return (
    <DisabledMenuItem role='menuitem'>
      <div className={'left-content'}>
        {LeftIcon && typeof LeftIcon === 'function' && <TypedLeftIcon />}
        {LeftIcon && typeof LeftIcon === 'string' && <IonIcon name={LeftIcon} />}

        {label}
        {children}
      </div>

      {RightIcon && (
        <div className={'right-content'}>
          {typeof RightIcon === 'function' && <TypedRightIcon />}
          {typeof RightIcon === 'string' && <IonIcon name={RightIcon} />}
        </div>
      )}
    </DisabledMenuItem>
  )
}
