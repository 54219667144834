import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'
import { useMutation, useQueryClient } from '@tanstack/react-query'

type ArchiveSpaceDTO = { spaceId: string; archived: boolean }

function archiveSpace(dto: ArchiveSpaceDTO) {
  return spacesApi.patch(`/spaces/${dto.spaceId}`, {
    archived: dto.archived,
  })
}

export function useChangeSpacedArchivedStatus() {
  const queryClient = useQueryClient()
  const mutation = useMutation<unknown, unknown, ArchiveSpaceDTO>({
    mutationFn: archiveSpace,
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['spaces'] })
    },
  })

  return {
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    changeSpaceArchivedStatus: (spaceId: string, archived: boolean) =>
      mutation.mutateAsync({ spaceId, archived }),
  }
}
