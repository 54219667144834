import TeamSelector from '@/modules/teams/TeamSelector'
import { TeamUnion } from '@valuecase/common'
import { Button, Hairline, Input, InputSize } from '@valuecase/ui-components'
import { useMemo, useState } from 'react'
import styled from 'styled-components'
import { z } from 'zod'
import FilesDropArea from './FilesDropArea'
import FilesDropAreaBrowse from './FilesDropAreaBrowse'
import { Step1DataType } from './SpaceCreationFlow'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  .form-container-wrapper {
    height: 406px;
    margin-left: -32px;
    width: calc(100% + 64px);
  }

  .form-container {
    padding: 24px 32px;
    height: 406px;
    background: ${({ theme }) => theme.grey.s1};
    display: flex;
    flex-direction: column;
    gap: 16px;

    .row {
      width: 100%;
      display: flex;
      align-items: center;

      .form-label {
        width: 27.15%;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 14px;
        color: ${({ theme }) => theme.grey.s6};

        p {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
`

const FormRightSide = styled.div`
  flex: 1;
  display: flex;
  gap: 8px;

  .no-logo-section {
    display: flex;
    gap: 24px;
    align-items: center;

    .drop-container {
      display: flex;
      height: 178px;
      width: 264px;
      max-width: 40%;
    }

    .divider {
      width: 1px;
      height: 100%;
      background: ${({ theme }) => theme.grey.s2};
    }

    .logo-input-container {
      display: flex;
      gap: 8px;
      align-items: flex-end;

      .label {
        font-size: 14px;
        color: ${({ theme }) => theme.grey.s5};

        b {
          font-weight: 700;
        }
      }

      .input-container {
        display: flex;
        max-width: 242px;
        flex-direction: column;
        position: relative;

        .website-error {
          position: absolute;
          color: ${({ theme }) => theme.warning.s5};
          margin: 0;
          padding: 0;
          bottom: 0;
          transform: translateY(calc(100% + 8px));
        }
      }
    }
  }

  .logo-section {
    display: flex;
    gap: 16px;
    align-items: flex-end;
    height: 178px;

    .logo-display {
      width: 178px;
      height: 178px;
      overflow: hidden;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.grey.s2};
      background: ${({ theme }) => theme.grey.s1};
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      button {
        align-self: flex-end;
      }
    }
  }
`

type Step1Props = {
  values: Step1DataType
  logoUrl: string
  inputHandler: (key: keyof Step1DataType, value: string | File | null) => void
  setLogoUrl: React.Dispatch<React.SetStateAction<string>>
  getLogoFromUrl: () => Promise<void>
  handleUploadFile: (file: File) => Promise<void>
  selectedTeamIds: Set<string> | undefined
  setSelectedTeamIds: React.Dispatch<React.SetStateAction<Set<string> | undefined>>
  teams?: TeamUnion[]
  tenantName?: string
}

const httpRegex =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{2,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/

const UrlSchema = z.string().url()

export function Step1({
  values,
  inputHandler,
  logoUrl,
  setLogoUrl,
  handleUploadFile,
  getLogoFromUrl,
  selectedTeamIds,
  setSelectedTeamIds,
  teams,
  tenantName,
}: Step1Props) {
  const [websiteError, setWebsiteError] = useState<string>('')
  const hasTeams = useMemo(() => teams && teams.length > 0, [teams])

  function handleWebsiteInput(val: string) {
    setLogoUrl(val)
  }

  function handleDeleteLogo() {
    inputHandler('logo', '')
    inputHandler('logoBlob', null)
  }

  function handleWebsiteInputBlur() {
    if (isWebsiteValid()) {
      getLogoFromUrl()
    } else {
      setWebsiteError('Please input a correct URL')
    }
  }

  function isWebsiteValid() {
    let url = logoUrl
    if (!url.includes('http://') && !url.includes('https://')) {
      url = `https://${url}`
    }
    const isValid = httpRegex.test(url)
    return isValid
  }

  return (
    <Container>
      <div className='form-container-wrapper'>
        <Hairline />
        <div className='form-container'>
          {hasTeams && (
            <div className='row'>
              <div className='form-label'>
                <p>Team access</p>
              </div>
              <FormRightSide>
                <TeamSelector
                  selectedTeamIds={selectedTeamIds}
                  teams={teams}
                  setSelectedTeamIds={setSelectedTeamIds}
                  tenantName={tenantName}
                />
              </FormRightSide>
            </div>
          )}
          <div className='row'>
            <div className='form-label'>
              <p>Company name</p>
            </div>
            <FormRightSide>
              <Input
                intercomTarget='Company Name Input Field'
                size={InputSize.LARGE}
                placeholder='Enter company name'
                value={values.companyName}
                onInput={(val) => inputHandler('companyName', val)}
              />
            </FormRightSide>
          </div>

          <div className='row'>
            <div className='form-label'>
              <p>Contact name</p>
            </div>
            <FormRightSide>
              <Input
                intercomTarget='First Name Input Field'
                size={InputSize.LARGE}
                placeholder='First name'
                value={values.contactFirstName}
                onInput={(val) => inputHandler('contactFirstName', val)}
              />
              <Input
                intercomTarget='Last Name Input Field'
                size={InputSize.LARGE}
                placeholder='Last name'
                value={values.contactLastName}
                onInput={(val) => inputHandler('contactLastName', val)}
              />
            </FormRightSide>
          </div>

          <div className='row'>
            <div className='form-label' style={{ alignSelf: 'start', paddingTop: '12.5px' }}>
              <p>Company logo</p>
            </div>
            <FormRightSide>
              {!values.logoBlob && (
                <div className='no-logo-section'>
                  <div className='drop-container'>
                    <FilesDropArea fillParent onFilesDropped={handleUploadFile}>
                      <FilesDropAreaBrowse
                        supportedMimeTypes={'image/*'}
                        supportedText={'PNG, JPG, SVG'}
                        onFilesSelected={handleUploadFile}
                        allowMultipleFiles={false}
                      />
                    </FilesDropArea>
                  </div>
                  <div className='divider' />
                  <div className='logo-input-container'>
                    <div className='input-container'>
                      <p className='label mb-4'>
                        <b>Or</b> enter your stakeholder&apos;s website URL to load the logo
                        automatically.
                      </p>
                      <Input
                        intercomTarget='Insert Link Input Field'
                        size={InputSize.MEDIUM}
                        placeholder='Insert link'
                        value={logoUrl}
                        onInput={handleWebsiteInput}
                        onBlur={handleWebsiteInputBlur}
                        blurOnEnter={true}
                      />
                      <p className='website-error'>{websiteError}</p>
                    </div>
                    <Button
                      label='Load logo'
                      iconPosition='right'
                      icon='arrow-forward-outline'
                      style='outlined'
                      onClick={getLogoFromUrl}
                      disabled={!isWebsiteValid()}
                    />
                  </div>
                </div>
              )}
              {values.logoBlob && (
                <div className='logo-section'>
                  <div className='logo-display'>
                    <img src={URL.createObjectURL(values.logoBlob)} alt='company logo' />
                  </div>
                  <Button
                    label='Delete logo'
                    onClick={handleDeleteLogo}
                    style='warning-outlined'
                    icon='trash-outline'
                    iconPosition='right'
                  />
                </div>
              )}
            </FormRightSide>
          </div>
        </div>
        <Hairline />
      </div>
    </Container>
  )
}
