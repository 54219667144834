import { FC, ReactElement } from 'react'

export const EmptyStateColumnWrapper: FC<{
  children: ReactElement
}> = ({ children }) => {
  return (
    <div className='flex w-full h-full bg-grey-s1 rounded-[10px] p-1 justify-center grow items-center'>
      {children}
    </div>
  )
}
