import axios, { AxiosResponse } from 'axios'
import { useState, useMemo, useCallback, useEffect } from 'react'
import { Semaphore } from 'async-mutex'

export type ConcurrentRequestStatus = 'pending' | 'fulfilled' | 'rejected'

export interface ConcurrentRequestResult<T> {
  status: ConcurrentRequestStatus
  data?: T
  error?: any
}

export interface ConcurrentRequestItem {
  requestKey: string
  requestValue: string
}

/**
 * Hook to perform concurrent requests
 */
export function useConcurrentRequests<T>(
  {
    enabled,
    items,
    maxConcurrent,
    performRequest,
  }: {
    enabled: boolean
    items: ConcurrentRequestItem[]
    maxConcurrent: number
    performRequest: (item: string, signal: AbortSignal) => Promise<AxiosResponse<T>>
  }, // Function to perform the request
): [Map<string, ConcurrentRequestResult<T>>, () => void] {
  const [requests, setRequests] = useState<Map<string, ConcurrentRequestResult<T>>>(
    new Map(items.map((item) => [item.requestKey, { status: 'pending' }])),
  )
  const abortControllers: Map<string, AbortController> = useMemo(() => new Map(), [])

  /**
   * This callback is a function for executing one of the given requests to run concurrently.
   */
  const executeRequest = useCallback(
    async (item: ConcurrentRequestItem) => {
      const controller = new AbortController()
      abortControllers.set(item.requestKey, controller)

      try {
        setRequests((prev) => new Map(prev).set(item.requestKey, { status: 'pending' }))
        // Pass in the abort signal to the request - this can be used to be aborted if the component
        // unmounts
        const response = await performRequest(item.requestValue, controller.signal)
        setRequests((prev) =>
          new Map(prev).set(item.requestKey, { status: 'fulfilled', data: response.data }),
        )
      } catch (error) {
        if (!axios.isCancel(error)) {
          setRequests((prev) => new Map(prev).set(item.requestKey, { status: 'rejected', error }))
        }
      }
    },
    [abortControllers, performRequest],
  )

  useEffect(() => {
    // If no items to concurrently request, then return.
    if (!enabled || !items || items.length === 0) {
      return
    }
    setRequests(new Map(items.map((item) => [item.requestKey, { status: 'pending' }])))

    // This semaphore allows maxConcurrent requests to be executed at a time. Once that threshold is
    // reached, the next requests will be queued (by awaiting the semaphore.acquire() call).
    // E.g. if maxConcurrent is 3, then the first 3 requests will be executed immediately, and the
    // next 3 requests will be queued. Once one of the first 3 requests finishes, the next queued
    // request will be executed, and so on.
    const semaphore = new Semaphore(maxConcurrent)
    const executeConcurrentRequests = async () => {
      const promises = items.map(async (item) => {
        const [, release] = await semaphore.acquire()
        try {
          await executeRequest(item)
        } finally {
          release()
        }
      })

      await Promise.allSettled(promises)
    }

    executeConcurrentRequests()

    return () => {
      abortControllers.forEach((controller) => controller.abort())
    }
  }, [items, maxConcurrent, executeRequest, abortControllers, enabled])

  // Use this function to cancel all pending requests, e.g. when the component unmounts.
  const cancelAllRequests = useCallback(() => {
    abortControllers.forEach((controller) => controller.abort())
  }, [abortControllers])

  return [requests, cancelAllRequests]
}
