import { UpdateUserProfileBody } from '@valuecase/seller-backend'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UpdateUserProfileBodyInput } from '../types/UpdateUserProfileBodyInput'
import { userProfileApi } from '../userProfileApi'
import { uploadFileToS3 } from '@/api/s3'

export async function updateUserProfileFn(userProfile: UpdateUserProfileBodyInput) {
  const { spacesProfile, ...coreProfile } = userProfile
  const { photo, currentPhoto, ...coreSpacesProfile } = spacesProfile
  const updatedProfile: UpdateUserProfileBody = Object.assign({}, coreProfile, {
    spacesProfile: Object.assign({}, coreSpacesProfile),
  })

  if (updatedProfile.spacesProfile && photo) {
    updatedProfile.spacesProfile.photo = {
      s3Key: (await uploadFileToS3(photo)).s3ObjectKey,
      source: 'S3',
    }
  } else if (updatedProfile.spacesProfile && currentPhoto) {
    updatedProfile.spacesProfile.photo = currentPhoto
  }
  const res = await userProfileApi.updateUserProfile(updatedProfile)
  if (!res.success) {
    throw new Error('Failed to update user profile')
  }
  return res.payload
}

export function useUpdateUserProfile(opts: { onSuccess?: () => void } = {}) {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: updateUserProfileFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['readUserProfile'] })
      if (opts.onSuccess) {
        opts.onSuccess()
      }
    },
  })
  return {
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    updateUserProfile: (userProfile: UpdateUserProfileBodyInput) => mutation.mutate(userProfile),
    updateUserProfileAsync: (userProfile: UpdateUserProfileBodyInput) =>
      mutation.mutateAsync(userProfile),
  }
}
