import { ChangeEvent, ImgHTMLAttributes, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Button2 } from '../../components/ui/button'
import { cn } from '../../lib'
import { Loader } from '../../components/legacy'
import { Input2, Textarea } from '../../components/ui'

export function AssetPreview({ children }: { children?: ReactNode }) {
  return <div className='flex flex-col gap-16'>{children}</div>
}

export function AssetPreviewGrid({ children }: { children?: ReactNode }) {
  return <div className={'w-full max-w-[486px] grid grid-cols-2 gap-4'}>{children}</div>
}

export function AssetPreviewThumbnailWrapper({
  children,
  description,
}: {
  children?: ReactNode
  description?: string
}) {
  const { t } = useTranslation()

  return (
    <div className={'flex flex-col gap-2'}>
      <p className={'text-grey-s4 text-xs font-semibold'}>
        {t('components.AddFileModalBody.previewImage', { defaultValue: 'Preview image' })}
      </p>
      {children}
      {description && <p className={'text-grey-s4 text-xs'}>{description}</p>}
    </div>
  )
}

export function AssetPreviewThumbnailLoadingPlaceholder() {
  const { t } = useTranslation()

  return (
    <div
      className={cn(
        'flex flex-col justify-center items-center p-4 bg-grey-s1 rounded-lg',
        'min-h-[104px] border border-solid border-grey-s2 aspect-[275/144]',
      )}
    >
      <Loader />
      <h4 className={cn('font-semibold text-xs text-center')}>
        {t('components.AddFileModalBody.generatingThumbnailMessage', {
          defaultValue: 'Generating thumbnail...',
        })}
      </h4>
    </div>
  )
}

export function AssetPreviewThumbnailImage({
  className,
  ...props
}: ImgHTMLAttributes<HTMLImageElement>) {
  return (
    <img
      alt={'Thumbnail'}
      className={cn(
        'object-cover rounded-lg object-top border border-grey-s2 min-h-[104px]',
        'aspect-[275/144]',
        className,
      )}
      {...props}
    />
  )
}

export function AssetPreviewMetadata({
  titlePlaceholder,
  titleValue,
  disabled,
  onTitleInput,
  showDescription,
  descriptionValue,
  onDescriptionInput,
}: {
  titlePlaceholder?: string
  titleValue?: string
  disabled?: boolean
  onTitleInput?: (e: ChangeEvent<HTMLInputElement>) => void
  showDescription?: boolean
  descriptionValue?: string
  onDescriptionInput?: (value: ChangeEvent<HTMLTextAreaElement>) => void
}) {
  const { t } = useTranslation()

  return (
    <div className='flex flex-1 flex-col justify-between gap-3'>
      <div className='flex flex-col gap-2'>
        <p className={'text-grey-s4 text-xs font-semibold'}>
          {t('components.AddFileModalBody.previewName', { defaultValue: 'Preview name' })}
        </p>
        <Input2
          placeholder={titlePlaceholder}
          disabled={disabled}
          onInput={onTitleInput}
          value={titleValue}
        />
      </div>
      {showDescription && (
        <div className='flex flex-col'>
          <div className={'flex flex-col gap-2'}>
            <p className={'text-grey-s4 text-xs font-semibold'}>
              {t('components.AddFileModalBody.previewDescription', { defaultValue: 'Description' })}
            </p>
            <Textarea
              className={'h-[120px] resize-none'}
              disabled={disabled}
              value={descriptionValue}
              onInput={onDescriptionInput}
              maxLength={250}
            />
          </div>
          <p className={'text-grey-s4 text-xxs'}>Max. 250 characters</p>
        </div>
      )}
    </div>
  )
}

export function AssetPreviewActions({
  children,
  onCancel,
}: {
  children?: ReactNode
  onCancel: () => void
}) {
  const { t } = useTranslation()

  return (
    <div className='flex w-full basis-1/2 gap-2'>
      <Button2
        className={'w-full'}
        onClick={onCancel}
        leadingIcon={'close-circle-outline'}
        variant={'danger'}
      >
        {t('components.AddFileModalBody.cancelButtonLabel', { defaultValue: 'Cancel' })}
      </Button2>
      {children}
    </div>
  )
}
