import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuButton,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from '@valuecase/ui-components'
import { FC, useCallback, useMemo } from 'react'
import { useTasksContext } from './TasksContext'

// Labels expect always plural case due to available options
// If adding non-plural options, adjust the buttonLabelText logic
const allTime = 'All time'
const months12 = 'months12'
const months6 = 'months6'
const months3 = 'months3'
const days30 = 'days30'

export const TaskListTimeFilter: FC = () => {
  const { searchTimeRange, setSearchTimeRange } = useTasksContext()
  const timeRange = useMemo(() => {
    return searchTimeRange ? JSON.stringify(searchTimeRange) : allTime
  }, [searchTimeRange])

  const handleTimeRangeChange = useCallback(
    (value: string) => {
      switch (value) {
        case allTime:
          setSearchTimeRange(undefined)
          break
        case months12:
          setSearchTimeRange({ months: 12 })
          break
        case months6:
          setSearchTimeRange({ months: 6 })
          break
        case months3:
          setSearchTimeRange({ months: 3 })
          break
        case days30:
          setSearchTimeRange({ days: 30 })
          break
      }
    },
    [setSearchTimeRange],
  )

  const buttonLabelText = useMemo(() => {
    if (!searchTimeRange) {
      return allTime
    }
    // Always plural case due to available options
    if (typeof searchTimeRange.months === 'number') {
      return `Spaces created in the last ${searchTimeRange.months} months`
    }
    if (typeof searchTimeRange.days === 'number') {
      return `Spaces created in the last ${searchTimeRange.days} days`
    }
  }, [searchTimeRange])

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DropdownMenuButton
          id='dashboard-tasks-spaces-created-after-filter'
          className={'max-w-72'}
          icon='time'
        >
          {buttonLabelText}
        </DropdownMenuButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='start'>
        <DropdownMenuRadioGroup value={timeRange} onValueChange={handleTimeRangeChange}>
          <DropdownMenuRadioItem value={allTime}>All time</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value={months12}>Last 12 months</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value={months6}>Last 6 months</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value={months3}>Last 3 months</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value={days30}>Last 30 days</DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default TaskListTimeFilter
