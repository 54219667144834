import { RouterProvider } from '@tanstack/react-router'
import { router } from './modules/navigation/Router'
import { OverlayProvider, SpacesApiProvider } from '@valuecase/ui-components'
import { Auth, createAuthReturnPath, useAuthState } from './auth/auth'
import { useEffect } from 'react'
import mixpanel from 'mixpanel-browser'
import { useCurrentTenantQuery } from './modules/tenant/hooks/useReadTenant'
import { spacesApi } from './api/spacesApi/spacesAPIclient'

export function App() {
  const authState = useAuthState()
  const tenant = useCurrentTenantQuery()

  //redirect to login page if !authorized
  useEffect(() => {
    if (!authState.loading && !authState.authorized) {
      Auth.login(createAuthReturnPath())
    }
  }, [authState.authorized, authState.loading])

  //Init Mixpanel
  useEffect(() => {
    if (import.meta.env.VITE_MIXPANEL_ENABLED === 'true' && import.meta.env.VITE_MIXPANEL_ID) {
      mixpanel.init(import.meta.env.VITE_MIXPANEL_ID, {
        //TODO it's not really an id. It's a token.
        debug: true,
        disable_persistence: true,
        ignore_dnt: true,
      })
    }
  }, [])

  useEffect(() => {
    if (import.meta.env.VITE_MIXPANEL_ENABLED === 'true' && authState.authorized) {
      const mixpanelId = authState.email?.includes('@valuecase.')
        ? `internal_${authState.sub}`
        : authState.sub
      mixpanel.identify(mixpanelId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.authorized])

  if (authState.loading || !authState.authorized || !tenant.isFetched) {
    return (
      // <div style={{ width: '100%', height: '100%', display: 'grid', placeItems: 'center' }}>
      //   Loading...
      // </div>
      <></> //commented code above as it causes the ui to flicker on load
    )
  }

  return (
    <SpacesApiProvider spacesApi={spacesApi}>
      <OverlayProvider>
        <RouterProvider basepath='dashboard' router={router} />
      </OverlayProvider>
    </SpacesApiProvider>
  )
}
