import { useMutation, useQueryClient } from '@tanstack/react-query'
import { spacesApi } from '../../api/spacesApi/spacesAPIclient'

type Data = {
  tenantId: string
  customPropertyId: string
}

export async function deleteCustomPropertyFn(data: Data) {
  return await spacesApi.delete(`/tenants/${data.tenantId}/variables/${data.customPropertyId}`)
}

export function useDeleteCustomProperty(onSuccess: () => void) {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: deleteCustomPropertyFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tenant-custom-properties'] })
      if (onSuccess) onSuccess()
    },
  })
  return {
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    deleteCustomProperty: (data: Data) => mutation.mutate(data),
  }
}
