import { useCallback, useState } from 'react'
import { OverflowDetectorHook, useOverflowDetector } from './OverflowDetector'

export type ScrollByFunction = (
  percentage: number,
  options?: {
    direction?: 'horizontal' | 'vertical'
    smooth?: boolean
    endTolerance?: number
    onScrollEnd?: () => void
  },
) => void

export interface OverflowDetectorAndScrollControlsHook extends OverflowDetectorHook {
  /**
   * Scrolls by default the preferred direction axis. The scroll distance is computed
   * by multiplying the given percentage (1 = 100%, -1 = -100%) with the overflow element's width/height.
   *
   * A number less than zero means "scroll backward", a number greater than zero means "scroll forward".
   *
   * @param percentage Percentage of overflow element's width/height to scroll as number (1 = 100%, -1 = -100%).
   * @param options Options settings. If "direction" is set, this direction's axis is scrolled.
   */
  scrollBy: ScrollByFunction
}

export function useOverflowDetectorAndScrollControls(): OverflowDetectorAndScrollControlsHook {
  const [element, setElement] = useState<HTMLElement | null>(null)

  const { ref: overflowDetectorRefCallback, vertical, horizontal } = useOverflowDetector()

  const scrollRef = useCallback<(element: HTMLElement | null) => void>(
    (element) => {
      overflowDetectorRefCallback(element)
      setElement(element)
    },
    [overflowDetectorRefCallback],
  )

  const scrollBy = useCallback<ScrollByFunction>(
    (percentage, options) => {
      if (!element || percentage === 0) {
        return
      }

      const {
        direction = 'horizontal',
        smooth = true,
        onScrollEnd,
        endTolerance = 0,
      } = options ?? {}

      if (direction === 'horizontal') {
        // check if end
        if (
          (percentage > 0 && horizontal.overflowEnd <= endTolerance) ||
          (percentage < 0 && horizontal.overflowStart <= endTolerance)
        ) {
          element.scrollBy({
            left: horizontal.size * 1.1 * Math.sign(percentage),
            behavior: smooth === true ? 'smooth' : undefined,
          })

          onScrollEnd?.()
        } else {
          // or scroll
          const byWidth = horizontal.visibleSize * percentage

          element.scrollBy({
            left: byWidth,
            behavior: smooth === true ? 'smooth' : undefined,
          })
        }
      } else {
        // check if end
        if (
          (percentage > 0 && vertical.overflowEnd <= endTolerance) ||
          (percentage < 0 && vertical.overflowStart <= endTolerance)
        ) {
          element.scrollBy({
            top: vertical.size * 1.1 * Math.sign(percentage),
            behavior: smooth === true ? 'smooth' : undefined,
          })

          onScrollEnd?.()
        } else {
          // or scroll
          const byHeight = vertical.visibleSize * percentage

          element.scrollBy({
            top: byHeight,
            behavior: smooth === true ? 'smooth' : undefined,
          })
        }
      }
    },
    [element, horizontal, vertical],
  )

  return {
    ref: scrollRef,
    scrollBy,
    vertical,
    horizontal,
  }
}
