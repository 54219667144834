export function themeToCssVars({
  theme,
  prefix = '--theme-',
}: {
  theme: any
  prefix?: string
}): string {
  let cssVariables = ''

  Object.keys(theme ?? {}).forEach((themeVariableKey) => {
    const value = theme[themeVariableKey]

    if (typeof value === 'string') {
      cssVariables += `${prefix}${themeVariableKey}: ${value};\n`
    } else if (typeof value === 'object') {
      cssVariables += `${themeToCssVars({
        theme: value,
        prefix: `${prefix}${themeVariableKey}-`,
      })}\n`
    }
  })

  return cssVariables
}
