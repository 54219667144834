import { useAuthState } from '../../../../auth/auth'
import { S } from './SpacesPageHeader.styled'
import Wave from './assets/wave.svg?react'
import TriggerCreateSpaceFlow from '../spaceCreationFlow/SpaceCreatorTrigger'
import { useHasPermission } from '../../../../auth/permissions'
import { useSearch } from '@tanstack/react-router'

interface SpacesPageHeaderProps {
  isSpaceListEmpty: boolean
}
export default function SpacesPageHeader({ isSpaceListEmpty }: SpacesPageHeaderProps) {
  const auth = useAuthState()

  const name = auth.authorized ? auth.given_name ?? auth.name : ''
  const hasPermissionToCreateSpaces = useHasPermission('SPACES_CREATE')
  // Likely a bug in the router forcing strict false and casting, revisit with new versions of
  // @tanstack/react-router
  const searchParams = useSearch({ strict: false }) as unknown as {
    createSpaceFromTemplate?: string
  }
  const createSpaceFromTemplate = searchParams?.createSpaceFromTemplate

  return (
    <S.SpacesPageHeader>
      <S.Greeting>
        {isSpaceListEmpty ? (
          <S.Welcome>
            <Wave
              style={{
                paddingLeft: '8px',
                display: 'inline',
                width: '48px',
                height: '48px',
                verticalAlign: 'bottom',
              }}
            />
            <p>
              Welcome to Valuecase, <b>{name}</b>
            </p>
            <p>Nice to meet you!</p>
          </S.Welcome>
        ) : (
          <S.WelcomeBack className={'text-4xl'}>
            Hey there,{' '}
            <span className={'font-bold'} style={{ whiteSpace: 'pre' }}>
              {name}{' '}
              <Wave
                style={{
                  paddingLeft: '8px',
                  display: 'inline',
                  width: '48px',
                  height: '48px',
                  verticalAlign: 'bottom',
                }}
              />
            </span>{' '}
          </S.WelcomeBack>
        )}
        <S.YouAreAwesome className={'text-sm font-normal leading-6'} $centered={isSpaceListEmpty}>
          {isSpaceListEmpty
            ? 'You have no spaces yet, let’s change that.'
            : "It's great to see you again!"}
        </S.YouAreAwesome>
      </S.Greeting>
      {!isSpaceListEmpty && (
        <S.Right>
          {hasPermissionToCreateSpaces && (
            <TriggerCreateSpaceFlow shouldOpenModalExternally={!!createSpaceFromTemplate} />
          )}
        </S.Right>
      )}
    </S.SpacesPageHeader>
  )
}
