import React, { Suspense } from 'react'

const ViewAllTenantsComponent = React.lazy(() => import('./ViewAllTenantsComponent'))

const TenantsAdmin = () => {
  return (
    <div
      style={{
        padding: '2em',
        width: '100%',
        margin: '0 auto',
      }}
    >
      <Suspense fallback={<div>Loading...</div>}>
        <ViewAllTenantsComponent />
      </Suspense>
    </div>
  )
}

export default TenantsAdmin
