export function copyToClipboard(value: string): Promise<void> {
  if ('clipboard' in navigator) {
    return navigator.clipboard.writeText(value).catch(() => {
      return copyToClipboardLegacy(value)
    })
  }

  return Promise.resolve(copyToClipboard(value))
}

function copyToClipboardLegacy(value: string) {
  const textArea = document.createElement('textarea')
  textArea.value = value

  textArea.style.top = '0'
  textArea.style.left = '0'
  textArea.style.position = 'fixed'

  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand('copy')
    if (!successful) throw new Error('Copying to clipboard using textarea failed.')
  } finally {
    document.body.removeChild(textArea)
  }
}
