import SellerApi from '../SellerApi'
import { TBillingDetails } from '@valuecase/common'

export default {
  createCustomerPortalSession: () =>
    SellerApi.post<{ url: string }, undefined, undefined>('/billing/customer-portal', undefined),

  upsertBillingDetails: (tenantId: string, billingDetails: TBillingDetails) =>
    SellerApi.post<undefined, TBillingDetails, undefined>(
      `/billing/${encodeURIComponent(tenantId)}`,
      billingDetails,
    ),

  getSeatLimit: () => SellerApi.get<number | null, undefined>('/billing/seat-limit', undefined),
}
