import { Button, IonIcon } from '@valuecase/ui-components'

import { queryClient } from '@/main'
import { useNotifications } from '@/utils/Notifications/Notifications'
import { useNavigate } from '@tanstack/react-router'
import {
  formatForceEnglishDateWithLabel,
  formatTime,
  TActivityWithNode,
  WeaklyTypedActivity,
} from '@valuecase/common'
import {
  PanelTabsList,
  PanelTabsTrigger,
  Tabs,
} from '@valuecase/ui-components/src/components/ui/tabs'
import { DateTime } from 'luxon'
import { useEffect, useState } from 'react'
import { getSubWithoutPrefix, useAuthState } from '../../auth/auth'
import { useHasPermission } from '../../auth/permissions'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import { useCurrentTenantQuery } from '../tenant/hooks/useReadTenant'
import { Container } from './ActivityFeed.styled'
import ActivityEmptyState from './assets/activity_empty_state.png'
import { useActivityFeed, useActivityFeedCount } from './hooks/useActivityFeed'
import { useMarkAllAsRead } from './hooks/useMarkAllAsRead'
import { getActivityTitle, getIcon } from './utils'

type ProcessedActivities = {
  [key: string]: (TActivityWithNode & { isNew: boolean })[]
}

function processActivity(activityList: TActivityWithNode[]) {
  const processedActivities: ProcessedActivities = {}

  for (const activity of activityList) {
    const date = DateTime.fromISO(activity.createdAt).toISODate() || ''
    const spaceLastRead = activity?.rootNode?.data.lastActivityRead
      ? new Date(activity?.rootNode?.data?.lastActivityRead).valueOf()
      : 0
    if (!processedActivities[date]) {
      processedActivities[date] = []
    }
    const newActivity = {
      ...activity,
      isNew: new Date(activity.createdAt).valueOf() > spaceLastRead,
    }
    processedActivities[date].push(newActivity)
  }

  return processedActivities
}

function getCompanyInitials(companyName: string) {
  const split = companyName.split(' ')
  if (split.length >= 2) {
    return `${split[0].charAt(0)}${split[1].charAt(0)}`
  } else {
    return `${split[0].charAt(0)}${split[0].charAt(1)}`
  }
}

function companyLogoPlaceholder(companyName: string) {
  const initials = getCompanyInitials(companyName.toLowerCase())
  // Auth0 Gravatars only work with 1-2 English-alphabet lowercase initials
  const spaceLogoPlaceholder = /^[a-z]{1,2}$/.test(initials)
    ? `https://s.gravatar.com/avatar/21ac5ea4cf2a19ad776ac70b8671f274?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2F${encodeURIComponent(
        initials,
      )}.png`
    : // If we don't have 1-2 English-alphabet initials, default to blank image
      'https://s.gravatar.com/avatar/00000000000000000000000000000000?d=blank&f=y'
  return spaceLogoPlaceholder
}

type LocalData = {
  page: number
  perPage: number
  activities: TActivityWithNode[]
  shouldReload: boolean
}

const ActivityFeed = () => {
  const [activityFeedVisitedTracked, setActivityFeedVisitedTracked] = useState(false)
  const { tenant } = useCurrentTenantQuery()
  const [showJustMyActivities, setShowJustMyActivities] = useState<string>('true')
  const auth = useAuthState()
  const [localData, setLocalData] = useState<LocalData>({
    page: 0,
    perPage: 20,
    activities: [],
    shouldReload: false,
  })
  const [loadMoreEnabled, setLoadMoreEnabled] = useState(true)
  const { success, error: errorNotification } = useNotifications()
  const { activities: serverActivities, refetch: refetchActivities } = useActivityFeed(
    showJustMyActivities === 'true',
    localData.page,
    localData.perPage,
  )
  const { refetch: refetchUnreadActivitiesCount, unreadCount } = useActivityFeedCount()
  const [processedActivities, setProcessedActivities] = useState<ProcessedActivities>({})
  const [spacesWithUnread, setSpacesWithUnread] = useState<string[]>([])
  const [loaded, setLoaded] = useState(false)
  const navigate = useNavigate()
  const canFilter = useHasPermission('SPACES_ALL_ADMINISTRATION')
  const { trackEvent } = useTrackEvent()
  const { markAllAsRead } = useMarkAllAsRead({
    onSuccess: () => {
      success('Marked all activities as read')
      queryClient.invalidateQueries([
        'activityFeed',
        showJustMyActivities === 'true' ? 'own' : 'all',
        localData.page,
      ])
    },
    onError: () => {
      errorNotification('Error marking all activities as read')
    },
  })

  //mixpanel track activity feed page visit
  useEffect(() => {
    if (!activityFeedVisitedTracked) {
      trackEvent({
        event: 'dashboard-visit',
        eventProperties: {
          indexPageName: 'Activity Feed',
          activityFeedUnreadCount: unreadCount?.unreadActivitiesCount || 0,
        },
      }).finally(() => {
        setActivityFeedVisitedTracked(true)
      })
    }
  }, [activityFeedVisitedTracked, trackEvent, setActivityFeedVisitedTracked, unreadCount])

  useEffect(() => {
    if (serverActivities) {
      if (serverActivities.length === 0) {
        setLoadMoreEnabled(false)
      }
      setLocalData((data) => {
        return {
          ...data,
          activities:
            data.page === 0 ? [...serverActivities] : [...data.activities, ...serverActivities],
          shouldReload: false,
        }
      })
    }
  }, [serverActivities])

  useEffect(() => {
    setLocalData((data) => {
      return {
        ...data,
        page: 0,
        shouldReload: true,
      }
    })
    setLoadMoreEnabled(true)
  }, [showJustMyActivities])

  useEffect(() => {
    const newActivities = processActivity(localData.activities || [])
    setProcessedActivities(newActivities)

    if (newActivities && auth) {
      const newSpacesWithUnread = new Set<string>()

      for (const activity of localData.activities || []) {
        const spaceLastRead = activity?.rootNode?.data.lastActivityRead
          ? new Date(activity.rootNode.data.lastActivityRead).valueOf()
          : 0
        if (
          new Date(activity.createdAt).valueOf() > spaceLastRead &&
          activity?.rootNode?.metadata.ownerId === getSubWithoutPrefix(auth)
        ) {
          newSpacesWithUnread.add(activity.rootNodeId as string)
        }
      }
      setSpacesWithUnread([...newSpacesWithUnread])
      setTimeout(() => {
        setLoaded(true)
      }, 800)
    }
  }, [auth, localData.activities])

  useEffect(() => {
    async function refreshUnreadActivitiesCount() {
      refetchUnreadActivitiesCount()
    }

    addEventListener('beforeunload', refreshUnreadActivitiesCount)

    return () => {
      if (loaded) {
        refreshUnreadActivitiesCount()
        removeEventListener('beforeunload', refreshUnreadActivitiesCount)
      }
    }
  }, [loaded, refetchUnreadActivitiesCount])

  function getLink(activity: TActivityWithNode) {
    try {
      let path = `${window.location.origin}/spaces/${activity.rootNodeId}`

      if (activity.type === 'SPACE_COMMENT') {
        return path
      }

      if (activity.node?.type === 'PAGE') {
        path = `${path}?page=${activity.node.id}`
      } else {
        path = `${path}?page=${activity.page?.id}`
      }

      if (activity.block?.id) {
        path = `${path}&block=${activity.block.id}`
      }

      if (activity.type === 'TASK_LINK_COPIED') {
        path = `${path}&task=${activity?.metadata?.taskId}`
      }
      return path
    } catch (e) {
      console.log(activity, e)
    }
  }

  function handleMarkAllRead() {
    const eventPayload = {
      event: 'activities-mark_all_read',
      eventProperties: {
        indexPageName: 'Activity Feed',
        activityFeedUnreadCount: unreadCount?.unreadActivitiesCount || 0,
      },
    }
    trackEvent(eventPayload)
    markAllAsRead()
  }

  return (
    <Container className={'flex flex-col w-full'}>
      <div className='flex flex-col py-11'>
        <div className={'flex flex-row justify-between'}>
          <div className={'flex flex-col w-118'}>
            <h1 className={'text-2xl font-bold'}>Activity Feed</h1>
            <p className={'text-sm text-grey-s5 mt-2 leading-6'}>
              Check all activities inside your spaces. Want these as slack notifications?
              <br />
              Access your settings{' '}
              <a className={'text-primary-s5 font-bold'} href='/dashboard/mySettings/integrations'>
                here
              </a>
              .
            </p>
          </div>
        </div>
        <div className='w-full border-grey-s2 border-t-[1px] mt-10' />
      </div>

      <div className='activity-feed'>
        {Object.keys(processedActivities).length === 0 && (
          <div className='empty-state'>
            <p>No activity yet – share spaces to see your stakeholder&apos;s activity here!</p>
            <Button
              style='outlined'
              label='Go to spaces'
              onClick={() => navigate({ to: '/spaces' })}
            />
            <div className='day-entry'>
              <div className='day-entries'>
                <img
                  src={ActivityEmptyState}
                  className='activity-empty-state-placeholder'
                  alt='activity feed empty state'
                />
              </div>
            </div>
          </div>
        )}

        {Object.keys(processedActivities).length > 0 && (
          <div className='filter-container flex gap-4 mt-0.5'>
            {unreadCount?.unreadActivitiesCount > 0 && showJustMyActivities === 'true' && (
              <Button
                onClick={handleMarkAllRead}
                label='Mark all as read'
                style='flat'
                icon='checkmark-done-outline'
              />
            )}
            {canFilter && (
              <Tabs
                value={showJustMyActivities}
                onValueChange={(v) => {
                  const eventPayload = {
                    event:
                      showJustMyActivities === 'true'
                        ? 'activities-filter-own'
                        : 'activities-filter-team',
                    eventProperties:
                      showJustMyActivities === 'false'
                        ? {
                            indexPageName: 'Activity Feed',
                            activityFeedUnreadCount: unreadCount?.unreadActivitiesCount || 0,
                          }
                        : {
                            //only include property when showJustMyActivities is false, as mentionned in the Mixpanel plan sGsheet
                            indexPageName: 'Activity Feed',
                          },
                  }
                  trackEvent(eventPayload)
                  setShowJustMyActivities(v)
                }}
              >
                <PanelTabsList>
                  <PanelTabsTrigger value={'true'}>My spaces</PanelTabsTrigger>
                  <PanelTabsTrigger value={'false'}>Team spaces</PanelTabsTrigger>
                </PanelTabsList>
              </Tabs>
            )}
          </div>
        )}
        {Object.keys(processedActivities).map((date) => {
          const activities = processedActivities[date]
          return (
            <div key={date} className='day-entry'>
              <h2 className='day-title'>
                {
                  // Long format dates show words, and in the seller context, all strings are english, therefore
                  // we force the locale to be english if not already
                  formatForceEnglishDateWithLabel(
                    DateTime.fromISO(date),
                    'long',
                    tenant?.payload?.locale,
                  )
                }
              </h2>
              <div className='day-entries'>
                {activities.map((activity) => {
                  const spaceData = activity?.rootNode?.versions[0].asset.data
                  return (
                    <a
                      href={getLink(activity)}
                      onClick={() => {
                        trackEvent({
                          event: 'activties-activity-view',
                          eventProperties: {
                            indexPageName: 'Activity Feed',
                          },
                        })
                      }}
                      key={activity.id}
                      rel='noreferrer'
                      target='_blank'
                    >
                      <div className='activity-container'>
                        <div className='space-logo'>
                          {/* conditionally render company logo or placeholder as separate imgs so
                          the crossOrigin attribute is consistent for the life of the img element */}
                          {!!spaceData.companyLogo_s3ObjectUrl && (
                            <img
                              crossOrigin='anonymous'
                              src={spaceData.companyLogo_s3ObjectUrl}
                              alt='company logo'
                            />
                          )}
                          {!spaceData.companyLogo_s3ObjectUrl && (
                            <img
                              src={companyLogoPlaceholder(
                                activity?.rootNode?.versions[0].asset.data.companyName || '',
                              )}
                              alt='company logo'
                            />
                          )}
                        </div>
                        <div className='right-side'>
                          <div className='activity-title'>
                            <div className='activity-icon'>
                              <IonIcon
                                className={'text-grey-s5 w-4 h-4'}
                                name={getIcon(activity.type)}
                              />
                            </div>
                            {/* Casting below because function needs to be refactored to support TActivityWithNode
                            but right now out of scope, leaving as TODO: @Ronald */}
                            {getActivityTitle(
                              activity as unknown as WeaklyTypedActivity,
                              tenant?.payload,
                            )}
                          </div>
                          <div className='bottom-row'>
                            <p>{spaceData.companyName || 'Unknown space'}</p>
                          </div>
                          <div className='row-indicator'>
                            {activity.isNew && (
                              <div className='new-indicator'>
                                <p>NEW</p>
                              </div>
                            )}
                            <p className={'text-xs text-grey-s5'}>
                              {formatTime(
                                DateTime.fromISO(activity.createdAt),
                                tenant?.payload?.locale,
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </a>
                  )
                })}
              </div>
            </div>
          )
        })}

        {processedActivities && Object.keys(processedActivities).length > 0 && (
          <div className='w-full flex justify-center'>
            <Button
              label='Load more'
              style='outlined'
              disabled={!loadMoreEnabled}
              onClick={() =>
                setLocalData((data) => {
                  return {
                    ...data,
                    page: data.page + 1,
                  }
                })
              }
            />
          </div>
        )}
      </div>
    </Container>
  )
}

export default ActivityFeed
