import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
    body, html, #root {
      width: 100%;
      height: 100%;
    }

    body, html {
      padding: 0;
      margin: 0;
      font-size: 16px;
      line-height: 24px;
      color: var(--theme-grey-s6);

      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      text-rendering: geometricPrecision;
    }

    * {
      box-sizing: border-box;
      font-family: 'Open Sans Variable', sans-serif;
    }

    :fullscreen {
      background: black !important;
    }

    :-webkit-full-screen {
      background: black !important;
    }

    /* Fix Safari fullscreen issues */
    body:has(:fullscreen) * {
      z-index: 0 !important;
    }
    body:has(:-webkit-full-screen) * {
      z-index: 0 !important;
    }
    .is-in-fullscreen * {
      z-index: 0 !important;
    }
`
