import { getSubWithoutPrefix, useAuthState } from '@/auth/auth'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'
import {
  SpaceOwner,
  useReadAvailableSpaceOwners,
} from '@/modules/spaces/hooks/useReadAvailableSpaceOwners'
import { useSpaceFilter } from '@/modules/spaces/hooks/useSpaceFiltering'
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuContentScrollable,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Input2,
  Highlight,
} from '@valuecase/ui-components'
import React, { useCallback, useMemo, useState } from 'react'

const SpaceOwnedByFilter: React.FC<{ inputIcon?: string | null }> = ({ inputIcon }) => {
  const auth = useAuthState()
  const { trackEvent } = useTrackEvent()
  const { setOwners, ownerIds } = useSpaceFilter()
  const { availableSpaceOwners } = useReadAvailableSpaceOwners()
  const selectedOwners = useMemo(() => ownerIds ?? [], [ownerIds]) as string[]
  const [searchTerm, setSearchTerm] = useState('')

  const sortedOwners = useMemo(() => {
    if (!availableSpaceOwners || availableSpaceOwners.length === 0) {
      return []
    }
    const sorted = [...(availableSpaceOwners as SpaceOwner[])].sort((a, b) => {
      const nameA = [a.firstName, a.lastName].join(' ').toLowerCase()
      const nameB = [b.firstName, b.lastName].join(' ').toLowerCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    return sorted
  }, [availableSpaceOwners])

  const ownerById = useCallback(
    (ownerId: string) => {
      const ownerIdWithoutPrefix = ownerId.replace('bubble|', '')

      const owner = sortedOwners?.find(
        (owner: any) => owner.id.replaceAll('bubble|', '') === ownerIdWithoutPrefix,
      )

      if (owner == undefined) {
        return null
      }

      const ownSpace = auth.authorized && auth.email === owner.email

      return {
        id: owner.id.replaceAll('bubble|', ''),
        ownSpace,
        ownerName: ownSpace ? 'My spaces' : [owner.firstName, owner.lastName].join(' '),
        ownerEmail: ownSpace ? undefined : owner.email,
      }
    },
    [auth, sortedOwners],
  )

  const authorizedOwner = useMemo(() => {
    const ownerId = getSubWithoutPrefix(auth)
    if (!ownerId) {
      return null
    }
    return ownerById(ownerId)
  }, [auth, ownerById])

  const filteredOwners = useMemo(() => {
    if (!searchTerm) {
      return sortedOwners
    }
    return sortedOwners.filter(
      (owner) =>
        `${owner.firstName} ${owner.lastName}`.toLowerCase().includes(searchTerm.toLowerCase()) &&
        owner.id !== authorizedOwner?.id,
    )
  }, [searchTerm, sortedOwners, authorizedOwner?.id])

  const toggleOwnerSelection = useCallback(
    (id: string) => {
      const isSelected = selectedOwners?.includes(id)

      setOwners(
        isSelected ? selectedOwners.filter((ownerId) => ownerId !== id) : [...selectedOwners, id],
      )

      if (isSelected) {
        trackEvent({
          event: 'spaces-filter-team',
          eventProperties: {
            indexPageName: 'Space List',
          },
        })
      } else {
        const owner = ownerById(id)

        if (!owner) {
          return
        }
        trackEvent({
          event: owner.ownSpace ? 'spaces-filter-own' : 'spaces-filter-team',
          eventProperties: {
            indexPageName: 'Space List',
          },
        })
      }
    },
    [selectedOwners, setOwners, ownerById, trackEvent],
  )

  const resetOwners = useCallback(() => {
    setOwners([])
  }, [setOwners])

  const ownerSelected = useCallback(
    (id: string) => {
      return selectedOwners?.includes(id)
    },
    [selectedOwners],
  )

  const ownerSelectionLabel = useMemo(() => {
    if (selectedOwners.length === 0) {
      return 'Any owner'
    }
    if (selectedOwners.length === 1) {
      const owner = sortedOwners?.find((o) => o.id.replace('bubble|', '') === selectedOwners[0])
      if (!owner) {
        return 'Owners'
      }
      return ownerById(owner.id)?.ownerName
    }
    return `Owners (${Math.min(selectedOwners.length)})`
  }, [selectedOwners, sortedOwners, ownerById])

  const handleOnOpenChange = useCallback((isOpen: boolean) => {
    if (isOpen) {
      setSearchTerm('')
    }
  }, [])

  return (
    <DropdownMenu onOpenChange={handleOnOpenChange}>
      <DropdownMenuTrigger asChild>
        <DropdownMenuButton icon={inputIcon === null ? undefined : 'person'}>
          {ownerSelectionLabel}
        </DropdownMenuButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent className='w-96' align='start'>
        <div className='p-1'>
          <Input2
            type='text'
            placeholder='Search owners'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => e.stopPropagation()} // Prevent dropdown from capturing key events
            trailingIcon='search'
          />
        </div>
        <DropdownMenuSeparator />
        <DropdownMenuCheckboxItem
          checked={authorizedOwner && ownerSelected(authorizedOwner?.id) ? true : undefined}
          onCheckedChange={() => authorizedOwner && toggleOwnerSelection(authorizedOwner.id)}
        >
          My spaces
        </DropdownMenuCheckboxItem>
        <DropdownMenuSeparator />
        <DropdownMenuCheckboxItem
          checked={selectedOwners?.length === 0}
          onCheckedChange={(checked) => {
            if (checked) {
              resetOwners()
            }
          }}
        >
          Any owner
        </DropdownMenuCheckboxItem>
        {filteredOwners && filteredOwners.length > 0 && <DropdownMenuSeparator />}
        <DropdownMenuContentScrollable>
          {filteredOwners?.map((owner) => {
            return (
              <DropdownMenuCheckboxItem
                key={owner?.id}
                checked={ownerSelected(owner?.id ?? '')}
                onCheckedChange={() => toggleOwnerSelection(owner?.id ?? '')}
                description={owner?.email}
                descriptionVariant={'stacked'}
              >
                <Highlight searchTerm={searchTerm}>
                  {owner.firstName} {owner.lastName}
                </Highlight>
              </DropdownMenuCheckboxItem>
            )
          })}
        </DropdownMenuContentScrollable>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default SpaceOwnedByFilter
