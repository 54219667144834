import { useQuery } from '@tanstack/react-query'
import { TemplateCreatorLibraryItemsData } from '../types/TemplateCreatorLibraryItemsData'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'
import { useMemo } from 'react'

async function readTemplatesQueryFn() {
  const res = await spacesApi.get(`/templates`)
  return res.data as TemplateCreatorLibraryItemsData
}

export function useReadTemplates() {
  const query = useQuery({
    queryKey: ['readTemplates'],
    queryFn: readTemplatesQueryFn,
  })

  return {
    isLoading: query.isLoading,
    isReloading: query.isRefetching && query.isStale,
    isError: query.isError,
    error: query.error,
    templates: query.data,
    isFetched: query.isFetched,
    refetch: query.refetch,
    unarchivedTemplates: useMemo(() => {
      return query.data?.filter((template) => !template.archived)
    }, [query.data]),
  }
}
