import { useEffect, useState } from 'react'

import { Button2 } from '@valuecase/ui-components/src/components/ui/button'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import { VLoaderAnimation } from '../../ui-components/VLoader/VLoader'
import { useReadUsers } from './hooks/useReadUsers'
import { InviteUserModal } from './InviteUserModal'
import UsersList from './UsersList/UsersList'

const UserManagement = () => {
  const [mixpanel_pageVisitedTracked, set_mixpanel_pageVisitedTracked] = useState(false)
  const { users, isLoading } = useReadUsers()
  const { trackEvent } = useTrackEvent()
  const [inviteUserModalIsOpen, setInviteUserModalIsOpen] = useState(false)

  //mixpanel track  page visit
  useEffect(() => {
    if (!mixpanel_pageVisitedTracked) {
      trackEvent({
        event: 'dashboard-visit',
        eventProperties: {
          indexPageName: 'User Management page',
        },
      }).finally(() => {
        set_mixpanel_pageVisitedTracked(true)
      })
    }
  }, [mixpanel_pageVisitedTracked, trackEvent, set_mixpanel_pageVisitedTracked])

  return (
    <div className={'flex flex-col py-11 w-full'}>
      <div className={'flex flex-row justify-between'}>
        <div className={'flex flex-col w-118'}>
          <h1 className={'text-2xl font-bold'}>User Management</h1>
          <p className={'text-sm text-grey-s5 mt-2 leading-6'}>
            Manage your team members with ease. Add or remove users, reassign spaces, change roles
            and permissions.
          </p>
        </div>
        <div className={'flex items-center'}>
          <Button2
            leadingIcon='add'
            onClick={() => {
              trackEvent({
                event: 'users-newuser-open_modal',
                eventProperties: {
                  indexPageName: 'User Management',
                },
              })
              setInviteUserModalIsOpen(true)
            }}
          >
            Add User
          </Button2>
        </div>
      </div>
      <div className='w-full border-grey-s2 border-t-[1px] mt-10' />
      {isLoading && (
        <div className={'p-16 grid place-items-center'}>
          <VLoaderAnimation />
        </div>
      )}
      <InviteUserModal
        isOpen={inviteUserModalIsOpen}
        onOpenChange={(open) => setInviteUserModalIsOpen(open)}
      />
      {users && <UsersList users={users} />}
    </div>
  )
}

export default UserManagement
