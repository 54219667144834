/**
 * Ascend to the parent node with the given type.
 * E.g. when called on an SVG node, with HTMLElement as the type, it will return the nearest
 * HTMLElement parent, going up the tree from the SVG node.
 */
export function ascendToParentWithType<T extends Node>(
  node: Node,
  type: { new (...args: any[]): T },
): T | null {
  let current = node.parentElement
  while (current && current instanceof type === false) {
    current = current.parentElement
  }
  return current as T | null
}
