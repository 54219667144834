import { useMutation, useQueryClient } from '@tanstack/react-query'
import { UpdateUserRoleBodyInput } from '../types/UpdateUserRoleInput'
import UserManagementApi from '../../../api/resources/UserManagementApi'

export async function updateUserRoleFn(userRolePatch: UpdateUserRoleBodyInput) {
  return await UserManagementApi.updateUserRole(userRolePatch)
}

export function useUpdateUserRole() {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: updateUserRoleFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['readUsers'] })
    },
  })
  return {
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    updateUserRole: (userRolePatch: UpdateUserRoleBodyInput) => mutation.mutateAsync(userRolePatch),
  }
}
