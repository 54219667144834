import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
  Button2,
} from '@valuecase/ui-components'
import { FC, useMemo } from 'react'
import {
  CellDetailsConfig,
  ActionPlanAnalyticsQueryFilter,
  useActionPlanAnalyticsCellDetailsQuery,
} from './action-plan-analytics.helpers'

export const ActionPlanAnalyticsCellDialog: FC<{
  open?: boolean
  onOpenChange?: (open: boolean) => void
  cellDetailsConfig: CellDetailsConfig | undefined
  filters: ActionPlanAnalyticsQueryFilter
}> = ({ open, onOpenChange, cellDetailsConfig, filters }) => {
  const queryFilters = useMemo(
    () => ({
      ...filters,
      cellDetailsConfig,
    }),
    [filters, cellDetailsConfig],
  )

  const cellDetailsQuery = useActionPlanAnalyticsCellDetailsQuery({
    filters: queryFilters,
  })

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{cellDetailsConfig?.title}</DialogTitle>
          <DialogDescription>{cellDetailsConfig?.description}</DialogDescription>
        </DialogHeader>
        <div className='max-h-[290px] overflow-y-auto'>
          <Table className='w-full'>
            <TableHeader>
              {(cellDetailsConfig?.cellType === 'quickest' ||
                cellDetailsConfig?.cellType === 'slowest' ||
                cellDetailsConfig?.cellType === 'days to completion') && (
                <TableRow>
                  <TableHead className='py-1'>Space</TableHead>
                  <TableHead className='py-1'>Days to completion</TableHead>
                  <TableHead className='py-1'></TableHead>
                </TableRow>
              )}
              {(cellDetailsConfig?.cellType === 'completed' ||
                cellDetailsConfig?.cellType === 'space count') && (
                <TableRow>
                  <TableHead className='py-1'>Space</TableHead>
                  <TableHead className='py-1'>Is Completed</TableHead>
                  <TableHead className='py-1'></TableHead>
                </TableRow>
              )}
            </TableHeader>
            {(cellDetailsConfig?.cellType === 'quickest' ||
              cellDetailsConfig?.cellType === 'slowest' ||
              cellDetailsConfig?.cellType === 'days to completion') && (
              <TableBody>
                {cellDetailsQuery.data?.map(
                  (space: {
                    spaceRootNodeId: string
                    spaceName: string
                    daysToComplete: number
                    spaceAuthToken: string
                  }) => (
                    <TableRow key={space.spaceRootNodeId}>
                      <TableCell className='py-1'>{space.spaceName}</TableCell>
                      <TableCell className='py-1'>{space.daysToComplete}</TableCell>
                      <TableCell className='py-1'>
                        <Button2
                          onClick={() => {
                            const url = new URL(location.origin)
                            url.pathname = `/spaces/${space.spaceRootNodeId}`
                            url.searchParams.set('authToken', space.spaceAuthToken)
                            window.open(url.toString(), '_blank')
                          }}
                          variant={'plain'}
                          trailingIcon='arrow-forward'
                          className='h-7'
                        >
                          Open
                        </Button2>
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            )}
            {(cellDetailsConfig?.cellType === 'completed' ||
              cellDetailsConfig?.cellType === 'space count') && (
              <TableBody>
                {cellDetailsQuery.data?.map(
                  (space: {
                    spaceRootNodeId: string
                    spaceName: string
                    daysToComplete: number | null
                    spaceAuthToken: string
                  }) => (
                    <TableRow key={space.spaceRootNodeId}>
                      <TableCell className='py-1'>{space.spaceName}</TableCell>
                      <TableCell className='py-1'>
                        {typeof space.daysToComplete === 'number' ? '✅' : 'No'}
                      </TableCell>
                      <TableCell className='py-1'>
                        <Button2
                          onClick={() => {
                            const url = new URL(location.origin)
                            url.pathname = `/spaces/${space.spaceRootNodeId}`
                            url.searchParams.set('authToken', space.spaceAuthToken)
                            window.open(url.toString(), '_blank')
                          }}
                          variant={'plain'}
                          trailingIcon='arrow-forward'
                          className='h-7'
                        >
                          Open
                        </Button2>
                      </TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            )}
          </Table>
        </div>
      </DialogContent>
    </Dialog>
  )
}
