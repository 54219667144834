import styled from 'styled-components'
import { CSSProperties, ReactNode, useState } from 'react'
import { flip, offset, useFloating } from '@floating-ui/react-dom'
import classNames from 'classnames'
import { Placement, shift } from '@floating-ui/react-dom-interactions'

type TooltipState = 'visible' | 'hidden' | 'transition-to-hidden'

interface TooltipProps {
  message: string
  trigger: ReactNode
  offsetHeight?: number
  placement?: Placement
  style?: CSSProperties
  arrow?: boolean
}

/**
 * @deprecated use Tooltip instead
 */
export function LegacyTooltip({
  message,
  trigger,
  offsetHeight,
  placement,
  style,
  arrow,
}: TooltipProps) {
  const [state, setState] = useState<TooltipState>('hidden')
  const { x, y, reference, floating, strategy } = useFloating({
    placement: placement ?? 'top-start',
    middleware: [flip(), offset(offsetHeight ?? 4), shift()],
  })

  const onLeave = () => {
    setState('transition-to-hidden')
    setTimeout(() => {
      setState('hidden')
    }, 200)
  }

  const onEnter = () => {
    setState('visible')
  }

  return (
    <>
      <div
        ref={reference}
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        onPointerEnter={onEnter}
        onPointerLeave={onLeave}
        style={style}
      >
        {trigger}
        {state !== 'hidden' && (
          <StyledTooltipBody
            $arrow={arrow}
            ref={floating}
            className={classNames({
              visible: state === 'visible',
            })}
            style={{
              position: strategy,
              zIndex: 3,
              top: y ?? 0,
              left: x ?? 0,
            }}
          >
            {message}
          </StyledTooltipBody>
        )}
      </div>
    </>
  )
}

const StyledTooltipBody = styled.div<{ $arrow?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 4px 8px 5px;
  gap: 10px;
  border-radius: 4px;
  background: var(--theme-grey-s6) !important;
  color: var(--theme-light);
  max-width: 128px;
  opacity: 0;
  transition: opacity 0.2s ease;

  font-size: 10px;
  line-height: 14px;

  &.visible {
    opacity: 1;
  }

  &:after {
    ${({ $arrow: arrow }) => {
      if (arrow) {
        return `
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: var(--theme-grey-s6) transparent transparent transparent;
        `
      }
    }}
  }
`
