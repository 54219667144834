// Did not want to relocate the rich text formatter stuff to common right now
// simple function works for this single use case
export const extractTitleFromQuillObject = (input: string): string | null => {
  try {
    const jsonData = JSON.parse(input)
    if (jsonData.__staticHtml) {
      const parser = new DOMParser()
      // Parses the HTML without executing the HTML (no scripts, etc)
      const doc = parser.parseFromString(jsonData.__staticHtml, 'text/html')
      return doc.body.textContent
    } else {
      console.warn('Could not extract action plan title')
      return null
    }
  } catch (err) {
    // Not perfect, but this is actually a valid use case - just means the title was plain string, not json.
    return input
  }
}
