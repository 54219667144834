import { useCurrentTenantQuery } from '@/modules/tenant/hooks/useReadTenant'
import { useQuery } from '@tanstack/react-query'
import SellerApi from '../../../api/SellerApi'

type PublicApiCredentials = {
  clientId: string
  key: string
} | null

const getPublicApiKey = async (tenantId: string | undefined): Promise<PublicApiCredentials> => {
  if (!tenantId) {
    return null
  }
  const res = await SellerApi.get<PublicApiCredentials, string>(
    `/tenants/${tenantId}/api-credentials`,
  )
  return res.payload
}

const usePublicApiKey = (): {
  data: PublicApiCredentials | null
  isLoading: boolean
  error: unknown
} => {
  const { tenant } = useCurrentTenantQuery()

  const { data, isLoading, error } = useQuery<PublicApiCredentials>(
    ['publicApiKey', tenant?.payload.id],
    () => getPublicApiKey(tenant?.payload.id),
    {
      enabled: !!tenant,
    },
  )

  return { data: data ?? null, isLoading, error }
}

export default usePublicApiKey
