import { SpaceFilterSpaceType, useSpaceFilter } from '@/modules/spaces/hooks/useSpaceFiltering'
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuButton,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from '@valuecase/ui-components'
import { useMemo } from 'react'

const SpaceTypeFilter = () => {
  const { spaceType, setSpaceType } = useSpaceFilter()

  const spaceTypeSelection = useMemo(() => spaceType, [spaceType])
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <DropdownMenuButton icon='file-tray-stacked'>
          {spaceTypeSelection === 'active' && 'Active spaces'}
          {spaceTypeSelection === 'archived' && 'Archived spaces'}
          {(spaceTypeSelection === 'all' || !spaceTypeSelection) && 'All spaces'}
        </DropdownMenuButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='start'>
        <DropdownMenuRadioGroup
          value={spaceTypeSelection as string}
          onValueChange={(value) => setSpaceType(value as SpaceFilterSpaceType)}
        >
          <DropdownMenuRadioItem value={'active'}>Active spaces</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value={'archived'}>Archived spaces</DropdownMenuRadioItem>
          <DropdownMenuRadioItem value={'all'}>All spaces</DropdownMenuRadioItem>
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default SpaceTypeFilter
