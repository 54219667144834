import {
  SalesforceObjectType,
  SalesforceIntegrationObjectTypeMapping,
  salesforceAccountListSchema,
  salesforceCaseListSchema,
  salesforceContactListSchema,
  salesforceOpportunityListSchema,
} from '@valuecase/common'
import SellerApi from '../../../api/SellerApi'
import { useSearchIntegrationItemsQuery } from '../hooks/useSearchIntegrationItemsQuery'

function getObjectTypeSegment(objectType: SalesforceObjectType) {
  const segment =
    objectType === 'Opportunity'
      ? 'opportunities'
      : objectType === 'Case'
        ? 'cases'
        : objectType === 'Account'
          ? 'accounts'
          : objectType === 'Contact'
            ? 'contacts'
            : null
  if (!segment) {
    throw new Error('Object type not found: ' + objectType)
  }
  return segment
}

function getSchema(objectType: SalesforceObjectType) {
  const schema =
    objectType === 'Opportunity'
      ? salesforceOpportunityListSchema
      : objectType === 'Case'
        ? salesforceCaseListSchema
        : objectType === 'Account'
          ? salesforceAccountListSchema
          : objectType === 'Contact'
            ? salesforceContactListSchema
            : null
  if (!schema) {
    throw new Error('Object type not found: ' + objectType)
  }
  return schema
}

async function searchSalesforceObjects<U extends SalesforceObjectType>(
  searchTerm: string,
  objectType: U,
) {
  const _objectTypeSegment = getObjectTypeSegment(objectType)
  const res = await SellerApi.get<any, undefined>(
    `integrations/salesforce/search?objectType=${_objectTypeSegment}&searchTerm=${encodeURIComponent(
      searchTerm,
    )}`,
  )
  const schema = getSchema(objectType)
  const parsedResult = schema.parse(res.payload?.records)
  return parsedResult as SalesforceIntegrationObjectTypeMapping[U][]
}

/**
 * Hook to search Salesforce opportunities accessible to the current user (via OAuth2).
 */
export function useSearchSalesforceObjects<U extends SalesforceObjectType>({
  enabled,
  searchTerm,
  objectType,
}: {
  enabled?: boolean
  searchTerm: string
  objectType: U
}) {
  return useSearchIntegrationItemsQuery<SalesforceIntegrationObjectTypeMapping, U>({
    queryKeyPrefix: 'searchSalesforceOpportunities',
    enabled,
    searchTerm,
    objectType,
    searchFunction: searchSalesforceObjects,
  })
}
