import { useCallback } from 'react'
import { useCurrentTenantQuery } from '../modules/tenant/hooks/useReadTenant'
import { useAuthState } from '../auth/auth'
import mixpanel, { Callback } from 'mixpanel-browser'

export type PagesUI =
  | 'Space List'
  | 'Template List'
  | 'Activity Feed'
  | 'Profile Account'
  | 'Mail Notifications' //more page names will be added, as more events are added to the app.

type TrackEventProps = {
  event: string
  eventProperties?: any
  callback?: Callback
}

export function useTrackEvent() {
  const tenant = useCurrentTenantQuery()
  const auth = useAuthState()

  //this might change when we start triggering events for unidentified users
  function getUserType() {
    if (auth.authorized && auth.role?.name === 'Administrator') {
      return 'Admin'
    }
    return 'seller'
  }
  const userType = getUserType()

  const trackEvent = useCallback(
    async (props: TrackEventProps) => {
      const { event, eventProperties = {}, callback } = props

      const baseEventProperties = {
        distinct_id: (auth.authorized && auth.sub) || 'anonymous',
        sellerCompany: tenant?.tenant?.payload?.tenantName,
        userType,
        userPermission: (auth.authorized && auth.role?.name) || 'unknown',
        app: 'Index',
      }

      const finalEventProperties = {
        ...baseEventProperties,
        ...eventProperties,
      }

      if (import.meta.env.VITE_MIXPANEL_ENABLED === 'true') {
        mixpanel.track(event, finalEventProperties, callback)
      } else {
        callback && callback(1)
      }
    },
    [auth, tenant, userType],
  )

  return {
    trackEvent,
  }
}

export const generatePageNameForMixpanelBasedOnOurRoutes = (
  currentLocation: string | undefined,
) => {
  if (!currentLocation) return 'undefined'
  switch (currentLocation) {
    case 'spaces':
      return 'Space List'

    case 'templates':
      return 'Template List'

    case 'taskDashboard':
      return 'Task Dashboard'

    case 'taskList':
      return 'Task List'

    case 'profileAccount':
      return 'Profile Account'

    case 'mailNotifications':
      return 'Mail Notifications'

    case 'integrations':
      return 'Integrations'

    case 'companySettings':
      return 'Company Settings'

    case 'userManagement':
      return 'User Management'

    default:
      return 'undefined'
  }
}
