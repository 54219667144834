import styled from 'styled-components'

const FormElementContainer = styled.div<{$fullWidth: boolean}>`
  display: inline-block;
  width: auto;
  position: relative;

  ${(props: { $fullWidth: boolean }) => {
    if (props.$fullWidth) {
      return 'display: block; width: 100%;'
    }

    return ''
  }}
`

export default FormElementContainer
