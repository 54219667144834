import React from 'react'
import styled from 'styled-components'

interface Props {
  textAtTheStartOfTheline?: string
  textAtTheEndOfTheline?: string
  percentage: number
  lineColor?: string
}
export const ProgressLine: React.FC<Props> = ({
  textAtTheStartOfTheline,
  textAtTheEndOfTheline,
  percentage,
  lineColor,
}) => {
  return (
    <Container>
      <Header>
        {textAtTheStartOfTheline && <LineText>{textAtTheStartOfTheline}</LineText>}
        {textAtTheEndOfTheline && <LineText>{textAtTheEndOfTheline}</LineText>}
      </Header>
      <Background>
        <PercentageIndicatorLine $percentage={percentage} $lineColor={lineColor} />
      </Background>
    </Container>
  )
}

const LineText = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
`

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const Background = styled.div`
  background: #ddddea;
  height: 4px;
  width: 100%;
  border-radius: 5px;
`
interface InfoProps {
  $percentage: number
  $lineColor?: string
}

const PercentageIndicatorLine = styled.div<InfoProps>`
  top: 0;
  left: 0;
  height: 4px;
  width: ${(props) => props.$percentage}%;
  transition: width 0.25s ease;
  background: ${(props) => (props.$lineColor ? props.$lineColor : props.theme.primary.s5)};
  border-radius: 5px;
`
