import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

export enum SwitchSize {
  NORMAL = 'normal',
  SMALL = 'small',
}

export interface SwitchProps {
  checked?: boolean
  onChange?: (checked: boolean) => void
  disabled?: boolean
  shouldUseHoverColor?: boolean
  expandVerticalClickArea?: boolean
  size?: SwitchSize
  duration?: number
  testIdentifer?: string
}

export function Switch({
  checked = false,
  onChange,
  disabled = false,
  size = SwitchSize.NORMAL,
  shouldUseHoverColor,
  expandVerticalClickArea,
  testIdentifer,
}: SwitchProps) {
  const [duration, setDuration] = useState(0.0)
  useEffect(() => {
    setTimeout(() => {
      setDuration(0.2)
    }, 250)
  }, [])

  const switchBox = useMemo(() => {
    return (
      <SwitchBox
        data-testid={testIdentifer ?? 'switch'}
        $sizeSetting={size}
        $duration={duration}
        className={classNames({
          checked,
          disabled,
          shouldUseHoverColor: shouldUseHoverColor && !disabled,
        })}
        onClick={(e) => {
          e.preventDefault()
          if (!disabled && !expandVerticalClickArea && onChange) {
            onChange(!checked)
          }
        }}
      >
        <span className='bullet'></span>
      </SwitchBox>
    )
  }, [
    checked,
    disabled,
    duration,
    expandVerticalClickArea,
    onChange,
    shouldUseHoverColor,
    size,
    testIdentifer,
  ])

  if (expandVerticalClickArea) {
    return (
      <ExpandedArea
        onClick={(e) => {
          e.preventDefault()
          if (!disabled && onChange) {
            onChange(!checked)
          }
        }}
        className={classNames({
          checked,
          disabled,
          shouldUseHoverColor,
        })}
      >
        {switchBox}
      </ExpandedArea>
    )
  }
  return switchBox
}

interface SwitchLook {
  width: number
  height: number
  padding: number
}

const SizeLooks: { [key in SwitchSize]: SwitchLook } = {
  [SwitchSize.NORMAL]: {
    width: 34,
    height: 20,
    padding: 2,
  },
  [SwitchSize.SMALL]: {
    width: 28,
    height: 16,
    padding: 2,
  },
}

const SwitchBox = styled.span<{ $duration?: number; $sizeSetting: SwitchSize }>`
  display: inline-block;
  position: relative;

  background: var(--theme-grey-s3);
  transition: background ${({ $duration: duration }) => duration ?? 0.2}s ease;
  cursor: pointer;

  .bullet {
    display: inline-block;
    position: absolute;

    background: var(--theme-light);
    border-radius: 100%;

    transition: left ${({ $duration: duration }) => duration ?? 0.2}s ease;
  }

  &.checked {
    background: var(--theme-primary-s5);
  }

  &.disabled {
    cursor: not-allowed;
    background: var(--theme-grey-s2);

    &.checked {
      background: var(--theme-primary-s3);
    }
  }

  &:not(.checked).shouldUseHoverColor:hover {
    background: var(--theme-grey-s4);
  }

  &.checked.shouldUseHoverColor:hover {
    background: var(--theme-primary-s6);
  }

  ${({ $sizeSetting: sizeSetting }) => {
    const look = SizeLooks[sizeSetting]
    const bulletSize = look.height - look.padding * 2

    return `
      height: ${look.height}px;
      max-height: ${look.height}px;
      width: ${look.width}px;
      padding: ${look.padding}px;
      border-radius: ${look.height / 2}px;
      
      .bullet {
        width: ${bulletSize}px;
        height: ${bulletSize}px;
        left: ${look.padding}px;
      }
      
      &.checked {
        .bullet {
          left: ${look.width - bulletSize - look.padding}px;
        }
      }
    `
  }}
`

const ExpandedArea = styled.div`
  padding: 8px 0;
  display: inline-flex;
  &:not(.checked).shouldUseHoverColor:hover {
    & > span {
      background: var(--theme-grey-s4);
    }
  }
  &.checked.shouldUseHoverColor:hover {
    & > span {
      background: var(--theme-primary-s6);
    }
  }
`
