import { useLDflags } from '@/launchdarkly/Launchdarkly'
import { useCurrentTenantQuery } from '@/modules/tenant/hooks/useReadTenant'
import { useReadUsers } from '@/modules/userManagement/hooks/useReadUsers'
import { VLoaderAnimation } from '@/ui-components/VLoader/VLoader'
import { useQueryClient } from '@tanstack/react-query'
import { InviteUserBodySchema, TeamUnion } from '@valuecase/common'
import {
  Banner,
  Button,
  cn,
  Dialog,
  DialogContent,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuContentScrollable,
  DropdownMenuTrigger,
  InitialsAvatar,
  InitialsAvatarSize,
  Input,
  InputSize,
  IonIcon,
  MenuDropdown,
  MenuDropdownSeparator,
} from '@valuecase/ui-components'
import { Chip, ChipClose, ChipLabel } from '@valuecase/ui-components/src/components/ui/chip'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'
import { useNotifications } from '../../utils/Notifications/Notifications'
import { useReadAvailableRoles } from '../auth/hooks/useReadAvailableRoles'
import useReadTeams from '../teams/hooks/useReadTeams'
import { useInviteUser } from './hooks/useInviteUser'
import useReadSeatAllowance from './hooks/useReadSeatAllowance'

type Role = {
  id: string
  name: string
  description: string | null | undefined
}

export const InviteUserModal = ({
  isOpen,
  onOpenChange,
}: {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
}) => {
  const { trackEvent } = useTrackEvent()
  const { success } = useNotifications()
  const [email, setEmail] = useState('')
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const { roles } = useReadAvailableRoles()
  const [selectedRole, setRole] = useState<null | Role>(null)
  const { flags } = useLDflags()

  const { inviteUser } = useInviteUser()

  const { tenant } = useCurrentTenantQuery()
  const { users, isLoading } = useReadUsers()

  const { data: teams } = useReadTeams()
  const [selectedTeams, setSelectedTeams] = useState<TeamUnion[]>([])

  const queryClient = useQueryClient()
  const [savingLoading, setSavingLoading] = useState(false)
  const savingRefreshTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)

  const { data: tenantSeatAllowance } = useReadSeatAllowance()

  useEffect(() => {
    if (savingRefreshTimeout.current) {
      clearTimeout(savingRefreshTimeout.current)
      savingRefreshTimeout.current = null
    }
  }, [])

  const handleInviteUser = useCallback(() => {
    if (selectedRole) {
      setSavingLoading(true)
      trackEvent({
        event: 'users-newuser-confirm',
        eventProperties: {
          indexPageName: 'User Management',
        },
      })

      inviteUser({
        email,
        firstName,
        lastName,
        roleId: selectedRole.id,
        teamIds: selectedTeams.map((team) => team.id),
      })

      savingRefreshTimeout.current = setTimeout(() => {
        queryClient.invalidateQueries({ queryKey: ['readUsers'] })
        setSavingLoading(false)
        success('User Invited')
        onOpenChange(false)
      }, 5000)
    }
  }, [
    selectedRole,
    trackEvent,
    inviteUser,
    email,
    firstName,
    lastName,
    selectedTeams,
    queryClient,
    success,
    onOpenChange,
  ])

  const isFormValid = useCallback(() => {
    return InviteUserBodySchema.safeParse({
      email,
      roleId: selectedRole?.id,
      firstName,
      lastName,
      teamIds: selectedTeams.map((team) => team.id),
    })
  }, [email, firstName, lastName, selectedRole, selectedTeams])

  const toggleTeam = useCallback((team: TeamUnion) => {
    setSelectedTeams((prevTeams) =>
      prevTeams.includes(team) ? prevTeams.filter((t) => t !== team) : [...prevTeams, team],
    )
  }, [])

  const showSeatAllowanceInfo = useMemo(() => {
    const usersToCount = (users ?? []).filter((user) => !user.email.includes('@valuecase.'))

    return !!tenantSeatAllowance && usersToCount.length + 1 > tenantSeatAllowance
  }, [tenantSeatAllowance, users])

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent className='flex flex-col items-center p-8'>
        {savingLoading && (
          <div className='flex items-center justify-center w-full min-h-[500px]'>
            <VLoaderAnimation />
          </div>
        )}
        {!savingLoading && (
          <div className='flex flex-col items-center gap-10'>
            <div className='flex flex-col items-center justify-center w-full gap-2'>
              <h1 className='font-extrabold text-2xl'>Invite User</h1>
              <p className='text-grey-s5 text-sm text-center w-96'>
                Set permissions for that user by selecting a role, add the e-mail address, and start
                collaborating.
              </p>
            </div>

            <div className='flex flex-col gap-2 w-96'>
              {showSeatAllowanceInfo && (
                <Banner variant={'blue'} icon={'information-circle-outline'}>
                  <strong>Heads-up:</strong> You&apos;ve reached the number of seats included in
                  your contract (<strong>{tenantSeatAllowance}</strong>). When adding this user, you
                  will be charged one additional seat.
                </Banner>
              )}
              <div className='relative w-full text-sm'>
                <MenuDropdown
                  Trigger={
                    <div
                      className='w-full border border-grey-s2 bg-white py-3 pr-3 pl-5 rounded-lg flex items-center justify-between
              hover:shadow-text-input transition-all'
                    >
                      <p className={`${!selectedRole ? 'text-grey-s4' : 'text-grey-s6'}`}>
                        {!selectedRole ? 'Select a role' : selectedRole.name}
                      </p>
                      <IonIcon
                        className={'text-grey-s4'}
                        name={isOpen ? 'chevron-up-outline' : 'chevron-down-outline'}
                      />
                    </div>
                  }
                  placement={'bottom-start'}
                >
                  <div className='w-[366px]'>
                    {!!roles &&
                      roles.map((role, i) => (
                        <div key={role.id} className='text-left w-full'>
                          {i !== 0 && <MenuDropdownSeparator />}
                          <button
                            onClick={() => {
                              trackEvent({
                                event: 'users-newuser-select_role',
                                eventProperties: {
                                  indexPageName: 'User Management',
                                  permissionTypeSet: role.name,
                                },
                              })
                              setRole({
                                id: role.id,
                                name: role.name,
                                description: role.description,
                              })
                            }}
                            className='w-full bg-transparent hover:bg-grey-s1 px-4 py-3 rounded-lg'
                          >
                            <p className='font-medium text-left text-sm text-grey-s6'>
                              {role.name}
                              {selectedRole && role.id === selectedRole.id && (
                                <IonIcon
                                  className={'text-grey-s4'}
                                  name='checkmark'
                                  style={{
                                    height: '20px',
                                    width: '20px',
                                    marginLeft: '8px',
                                    position: 'relative',
                                    top: '4px',
                                  }}
                                />
                              )}
                            </p>
                            <p className='relative text-grey-s4 text-left text-xs text-ellipsis max-w-[350px] mt-1 overflow-hidden'>
                              {role.description}
                            </p>
                          </button>
                        </div>
                      ))}
                  </div>
                </MenuDropdown>
              </div>
              {flags && flags['teams-management'] && !!teams?.length && (
                <>
                  <div className='flex flex-col gap-2'>
                    {teams && teams?.length > 0 && selectedTeams.length >= 1 && (
                      <div className='text-xs font-semibold text-grey-s4'>Teams</div>
                    )}
                    {teams?.length === 0 && (
                      <div className='flex flex-col gap-2'>
                        <div className='text-xs font-semibold text-grey-s4'>Teams</div>
                        <div className='text-xs font-regular text-grey-s3'>
                          You have no teams yet.
                        </div>
                      </div>
                    )}
                    <div className={'flex flex-wrap gap-2'}>
                      {[...selectedTeams].map((team) => {
                        return (
                          <Chip key={team.id}>
                            <InitialsAvatar
                              size={InitialsAvatarSize.EXTRA_SMALL}
                              text={team.name}
                              isSeller={true}
                            />
                            <ChipLabel>{team.name}</ChipLabel>
                            <ChipClose onClick={() => toggleTeam(team)} />
                          </Chip>
                        )
                      })}
                    </div>

                    {teams && !!teams.length && (
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <div
                            className='w-full border border-grey-s2 bg-white py-3 pr-3 pl-5 rounded-lg flex items-center justify-between
         hover:shadow-text-input transition-all'
                          >
                            <p
                              className={cn('text-sm', {
                                'text-grey-s6': selectedTeams.length > 0,
                                'text-grey-s4': selectedTeams.length === 0,
                              })}
                            >
                              {selectedTeams.length > 0
                                ? `Teams (${selectedTeams.length})`
                                : 'Select teams'}
                            </p>
                            <IonIcon
                              className={'text-grey-s4'}
                              name={isOpen ? 'chevron-up-outline' : 'chevron-down-outline'}
                            />
                          </div>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align={'start'} sideOffset={5} className='w-[366px]'>
                          <DropdownMenuContentScrollable>
                            {teams.map((team) => (
                              <div key={team.id} className='text-left w-full'>
                                <DropdownMenuCheckboxItem
                                  checked={[...selectedTeams].includes(team)}
                                  onCheckedChange={() => toggleTeam(team)}
                                  className='w-full bg-transparent hover:bg-grey-s1 px-4 py-3 rounded-lg'
                                >
                                  <p className='font-medium text-left text-sm text-grey-s6'>
                                    {team.name}
                                  </p>
                                </DropdownMenuCheckboxItem>
                              </div>
                            ))}
                          </DropdownMenuContentScrollable>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    )}
                  </div>
                </>
              )}

              <Input
                size={InputSize.LARGE}
                placeholder='Enter e-mail address'
                value={email}
                onInput={setEmail}
              />
              <div className='flex gap-2'>
                <Input
                  size={InputSize.LARGE}
                  placeholder='First name'
                  value={firstName}
                  onInput={setFirstName}
                />
                <Input
                  size={InputSize.LARGE}
                  placeholder='Last name'
                  value={lastName}
                  onInput={setLastName}
                />
              </div>
            </div>

            <div className='flex w-full gap-2 justify-center box-border'>
              <Button
                icon='close-circle-outline'
                iconPosition='left'
                label='Cancel'
                style='gray-outlined'
                fullWidth
                onClick={() => onOpenChange(false)}
                cssStyle={{ width: '156px' }}
              />
              <div className='w-48'>
                <Button
                  icon='rocket-outline'
                  iconPosition='right'
                  label='Send Invite'
                  fullWidth
                  onClick={handleInviteUser}
                  disabled={!isFormValid().success}
                />
              </div>
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  )
}
