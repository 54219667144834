import { FC, useMemo } from 'react'
import { TaskColumnView } from './TaskColumnView'
import { useUpcomingTasks } from '@valuecase/ui-components'
import { EmptyStateColumnWrapper } from './EmptyStateColumnWrapper'
import { useNavigate } from '@tanstack/react-router'
import { UpcomingTasksEmptyState } from './empty-states'

export const UpcomingTasksColumn: FC<{ spaceCreatedAfter?: Date }> = ({ spaceCreatedAfter }) => {
  const navigate = useNavigate()
  const tasksQuery = useUpcomingTasks({ spaceCreatedAfter })

  const emptyState = useMemo(() => {
    return (
      <EmptyStateColumnWrapper>
        <UpcomingTasksEmptyState />
      </EmptyStateColumnWrapper>
    )
  }, [])

  return (
    <TaskColumnView
      id='dashboard-tasks-upcoming-column'
      iconName='calendar'
      iconClassName='text-blue-s4'
      title='Upcoming Due Dates'
      taskStatus='upcoming'
      tasksCount={tasksQuery.data?.totalItems || 0}
      tasks={tasksQuery.data?.items || []}
      emptyState={emptyState}
      isLoading={tasksQuery.isLoading}
      onViewAllClick={() => navigate({ to: '/tasks/list', search: { taskStatus: 'upcoming' } })}
    />
  )
}
