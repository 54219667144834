import React, { createContext, useContext, useRef, useState } from 'react'

export const DesktopBreakpoint = 776
// export const DesktopBreakpoint = 550

interface WidthContextProps {
  width: number
}

const WidthContext = createContext<WidthContextProps>({
  width: 0,
})

export interface WidthContextProviderProps {
  children: React.ReactNode
}

export function WidthObserver({ children }: WidthContextProviderProps) {
  const resizeObserverRef = useRef<ResizeObserver | null>(null)
  const observedElementRef = useRef<HTMLDivElement | null>(null)
  const [width, setWidth] = useState(0)
  const [firstWidthSet, setFirstWidthSet] = useState(false)

  function cancelObservation() {
    if (resizeObserverRef.current) {
      resizeObserverRef.current.disconnect()
    }

    resizeObserverRef.current = null
    observedElementRef.current = null
  }

  function widthDetectorCallback(element: HTMLDivElement | null) {
    if (element) {
      if (observedElementRef.current && element !== observedElementRef.current) {
        cancelObservation()
      }

      setWidth(element.getBoundingClientRect().width)
      setFirstWidthSet(true)

      observedElementRef.current = element
      resizeObserverRef.current = new ResizeObserver(() => {
        if (observedElementRef.current) {
          setWidth(observedElementRef.current.getBoundingClientRect().width)
          setFirstWidthSet(true)
        }
      })

      resizeObserverRef.current?.observe(observedElementRef.current)
    } else {
      cancelObservation()
    }
  }

  return (
    <WidthContext.Provider value={{ width }}>
      <div className='width-observer' ref={widthDetectorCallback}></div>
      {firstWidthSet && children}
    </WidthContext.Provider>
  )
}

export function useWidth(): WidthContextProps {
  return useContext(WidthContext)
}

export interface BreakpointsResult {
  isMobile: boolean
  isDesktop: boolean
}

export function useBreakpoints(mobileWidth?: number): BreakpointsResult {
  const { width } = useWidth()

  return {
    isMobile: width < (mobileWidth ?? DesktopBreakpoint),
    isDesktop: width >= (mobileWidth ?? DesktopBreakpoint),
  }
}
