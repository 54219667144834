import { VLoaderAnimation } from '../../../ui-components/VLoader/VLoader'

type Props = {
  title: string
  total?: number
  isLoading: boolean
  children: React.ReactElement
  fixedHeight?: boolean
}

export function GenericChartContainer({
  title,
  total,
  isLoading,
  children,
  fixedHeight = true,
}: Props) {
  return (
    <div className='w-full flex flex-col gap-4 bg-white rounded-xl border border-grey-s2 py-4 px-5 relative overflow-hidden'>
      <div className='flex flex-col gap-2'>
        <h3 className='text-sm text-grey-s5 m-0'>{title}</h3>
        {total && <p className='font-bold text-grey-s6 text-3xl'>{total}</p>}
      </div>
      <div className={`w-full text-xs ${fixedHeight && 'h-[200px]'}`}>
        {isLoading && (
          <div className='w-full h-full flex flex-col gap-3 justify-center items-center'>
            <VLoaderAnimation />
          </div>
        )}
        {!isLoading && children}
      </div>
    </div>
  )
}
