import { useQuery } from '@tanstack/react-query'
import { TemplateCreatorLibraryItemsData } from '../types/TemplateCreatorLibraryItemsData'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'

async function readTemplatesQueryFn() {
  const res = await spacesApi.get(`/templates/public`)
  return res.data.map(
    (item: {
      id: string
      title: string
      public: true
      companyName: string
      description: string
      emojiIcon?: string
      rootNodeId: string
      previewImages?: {
        source: 'url'
        url: string
      }[]
      createdAt: string
      publicLibraryCategory: string
    }) => ({
      id: item.id,
      createdAt: item.createdAt,
      title: item.title,
      rootNodeId: item.rootNodeId,
      description: item.description,
      emojiIcon: item.emojiIcon,
      previewImages: item.previewImages,
      publicLibraryCategory: item.publicLibraryCategory,
    }),
  ) as TemplateCreatorLibraryItemsData
}

export function useReadPublicTemplates() {
  const query = useQuery({
    queryKey: ['readPublicTemplates'],
    queryFn: readTemplatesQueryFn,
  })

  return {
    isLoading: query.isLoading,
    isReloading: query.isRefetching && query.isStale,
    isError: query.isError,
    error: query.error,
    templates: query.data,
    isFetched: query.isFetched,
    refetch: query.refetch,
  }
}
