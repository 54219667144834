import { useMutation, useQueryClient } from '@tanstack/react-query'
import { DeleteTemplateQueryTemplateInput } from '../types/CreateTemplateQueryTemplateInput'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'

export async function deleteTemplateQueryFn(templateInput: DeleteTemplateQueryTemplateInput) {
  const { ...template } = templateInput
  await spacesApi.delete(`/templates/${template.templateId}`)
}

export function useDeleteTemplate(props?: { onSuccess: () => void; onError: () => void }) {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: deleteTemplateQueryFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['readTemplates'] })
      if (props?.onSuccess) {
        props.onSuccess()
      }
    },
    onError: () => {
      if (props?.onError) {
        props.onError()
      }
    },
  })
  return {
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    deleteTemplate: (templateInput: DeleteTemplateQueryTemplateInput) =>
      mutation.mutate(templateInput),
  }
}
