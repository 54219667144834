import { FC, useMemo } from 'react'
import { TaskColumnView } from './TaskColumnView'
import { useOverdueTasks } from '@valuecase/ui-components'
import { EmptyStateColumnWrapper } from './EmptyStateColumnWrapper'
import { useNavigate } from '@tanstack/react-router'
import { OverdueTasksEmptyState } from './empty-states'

export const OverdueTasksColumn: FC<{ spaceCreatedAfter?: Date }> = ({ spaceCreatedAfter }) => {
  const navigate = useNavigate()
  const tasksQuery = useOverdueTasks({ spaceCreatedAfter })

  const emptyState = useMemo(() => {
    return (
      <EmptyStateColumnWrapper>
        <OverdueTasksEmptyState />
      </EmptyStateColumnWrapper>
    )
  }, [])

  return (
    <TaskColumnView
      id='dashboard-tasks-overdue-column'
      iconName='calendar'
      iconClassName='text-red-s4'
      title='Overdue'
      taskStatus='overdue'
      tasksCount={tasksQuery.data?.totalItems || 0}
      tasks={tasksQuery.data?.items || []}
      emptyState={emptyState}
      isLoading={tasksQuery.isLoading}
      onViewAllClick={() => navigate({ to: '/tasks/list', search: { taskStatus: 'overdue' } })}
    />
  )
}
