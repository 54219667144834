import { SpaceCreationFlow, SpinnerContainer } from './SpaceCreationFlow'
import { useReadSalesforceIntegrationStatus } from '../../../integrations/salesforce/useReadSalesforceIntegrationStatus'
import { useReadHubSpotIntegrationStatus } from '../../../integrations/hubspot/useReadHubSpotIntegrationStatus'
import { useLDflags } from '../../../../launchdarkly/Launchdarkly'
import { useReadHubSpotCurrentUser } from '@/modules/integrations/hubspot/useReadHubSpotCurrentUser'
import { Spinner } from '@valuecase/ui-components'
import { Auth } from '@/auth/auth'
import { HubSpotIntegration } from './types/SpaceIntegration'
import { useMemo } from 'react'

/**
 * Shows a loader while loading data for space creation, then shows space creation flow when ready.
 * Specifically, it 1. determines if the Salesforce integration is enabled, so the space creation
 * flow can start on the select Salesforce opportunity step if it is; and 2. asserts, if a HubSpot
 * user ids is passed in the URL, that the current HubSpot user is the same as the one linked to
 * the current Valuecase account (or else it shows an error message advising a re-login or
 * re-connection is necessary).
 */
export function SpaceCreationFlowWrapper() {
  const { flags } = useLDflags()
  const hubSpotUserIdFromParams = new URLSearchParams(window.location.search).get('hs_user_id')
  const embedMode = hubSpotUserIdFromParams ? true : false

  const { salesforceIntegrationStatus } = useReadSalesforceIntegrationStatus()
  const { hubSpotIntegrationStatus } = useReadHubSpotIntegrationStatus()

  const {
    hubSpotCurrentUser,
    isLoading: isLoadingHubSpotUser,
    isFetched: isFetchedHubSpotCurrentUser,
  } = useReadHubSpotCurrentUser({ enabled: true })

  // The current format for the hs_user_id param is <hubSpotPortalId>_<hubSpotUserId>, previously
  // it was just <hubSpotUserId>. We need to check both formats to support users who have not yet
  // been migrated to the new format.
  const matchesNewUserIdFormat =
    !isLoadingHubSpotUser &&
    hubSpotCurrentUser &&
    `${hubSpotCurrentUser.hubSpotPortalId}_${hubSpotCurrentUser.hubSpotUserId}` ===
      hubSpotUserIdFromParams
  // TODO remove check for all user format once all users have been migrated to the new format
  const matchesOldUserIdFormat =
    !isLoadingHubSpotUser &&
    hubSpotCurrentUser &&
    `${hubSpotCurrentUser.hubSpotUserId}` === hubSpotUserIdFromParams?.split('_')[1]

  const isIncorrectHubSpotUser =
    !!hubSpotUserIdFromParams &&
    isFetchedHubSpotCurrentUser &&
    (!hubSpotCurrentUser || !(matchesNewUserIdFormat || matchesOldUserIdFormat))

  // Build enabled HubSpot integration object with portalId, otherwise build disabled HubSpot
  // with undefined portalId
  const hubSpotIntegration: HubSpotIntegration = useMemo(() => {
    return hubSpotIntegrationStatus === 'enabled' && hubSpotCurrentUser
      ? {
          name: 'hubSpot',
          status: 'enabled',
          portalId: hubSpotCurrentUser.hubSpotPortalId,
        }
      : {
          name: 'hubSpot',
          status: 'disabled',
        }
  }, [hubSpotIntegrationStatus, hubSpotCurrentUser])

  if (!flags || !salesforceIntegrationStatus || !hubSpotIntegrationStatus || isLoadingHubSpotUser) {
    return (
      <SpinnerContainer>
        <Spinner />
      </SpinnerContainer>
    )
  }

  if (isIncorrectHubSpotUser) {
    return (
      <SpinnerContainer>
        <div>
          <h1 className='mb-4 text-3xl font-bold'>Incorrect HubSpot User</h1>
          <p className='mb-3 text-xl'>
            The current HubSpot user is not the same as the HubSpot user linked to this Valuecase
            account.
          </p>
          <p className='mb-3 text-xl'>
            Please{' '}
            <a
              className='text-primary-s5 visited:text-primary-s5 font-semibold'
              href={Auth.getLogoutUrl('/dashboard')}
              target='_blank'
              rel='noreferrer noopener'
            >
              logout in a separate tab
            </a>
            , log in to Valuecase with the account linked to this HubSpot account, close this create
            space pop up and try again.
          </p>
          <p className='text-xl'>
            If the issue persists after logging in with the correct account, please try
            re-connecting to HubSpot in Valuecase:{' '}
            <a
              className='text-primary-s5 visited:text-primary-s5 font-semibold'
              href={'/dashboard/mySettings/integrations'}
              target='_blank'
              rel='noreferrer noopener'
            >
              My Settings {'>'} Integrations
            </a>
            .
          </p>
        </div>
      </SpinnerContainer>
    )
  }

  return (
    <SpaceCreationFlow
      flags={flags}
      integrations={[
        {
          name: 'salesforce',
          status: salesforceIntegrationStatus,
        },
        hubSpotIntegration,
      ]}
      embedMode={embedMode}
    />
  )
}
