import styled from 'styled-components'

const templateInfoInputToTwoColLayoutBreakpoint = 570 // pixels

export const TemplateCreatorLayout = styled.div`
  display: flex;
  height: 100%;
  overflow: auto;
  flex-direction: column;
`

export const TemplateCreatorErrorWrapper = styled.div`
  color: #f45359;
  font-size: 12px;
  padding: 16px;
  font-weight: 400;
  text-align: right;
  overflow: hidden;
  max-height: 97px;
`

export const TemplateCreatorForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
`

export const TemplateCreatorHeaderWrapper = styled.div`
  position: relative;
  padding: 0 8px 40px 8px;
`

export const TemplateCreatorHeaderTitle = styled.div`
  font-size: 24px;
  font-weight: 800;
  line-height: 33px;
  color: #31333a;
  text-align: center;
`

export const TemplateCreatorHeaderSubtitle = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.grey.s5};
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
`

export const TemplateCreatorStep1Layout = styled.div`
  flex-grow: 1;
  display: grid;
  grid-template-columns: 0.75fr 1fr;
  height: 100%;
`

export const TemplateCreatorCurrentStepWrapper = styled.div`
  min-height: 406px;
  width: 100%;
  display: flex;
  height: 100%;
`

export const TemplateCreatorExistingTemplateDetails = styled.div`
  overflow: auto;
`

export const TemplateCreatorStep2Layout = styled.div`
  width: 100%;
  display: grid;
  background-color: ${({ theme }) => theme.grey.s1};
  padding: 24px 32px 24px 32px;
  row-gap: 16px;
  grid-template-rows: repeat(5, max-content) 1fr;
  overflow: auto;
  height: 100%;
  @media (min-width: ${templateInfoInputToTwoColLayoutBreakpoint}px) {
    grid-template-areas:
      'iconLabel iconInput'
      'nameLabel nameInput'
      'usageLabel usageInput';
    grid-template-columns: 224px 1fr;
    grid-template-rows: max-content max-content 1fr;
    column-gap: 24px;
    align-items: center;
  }
`

export const TemplateCreatorLibraryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: rgba(243, 245, 248, 0.6);
  border-right: 1px solid ${({ theme }) => theme.grey.s2};
  overflow: auto;
`

export const TemplateCreatorTemplatePreviewWrapper = styled.div`
  overflow: auto;
`

export const TemplateCreatorFactsWrapper = styled.div``

export const TemplateCreatorFooter = styled.div`
  padding: 0 32px;
  min-height: 97px;
  display: grid;
  grid-template-columns: 3fr max-content 3fr;
  align-items: center;
`

export const TemplateCreatorFooterRight = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
`

export const TemplateCreatorUnorderedTemplateList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  row-gap: '.5em';
`

export const TemplateCreatorTemplateListItem = styled.li`
  margin: 0 10px 10px 12px;
  max-width: calc(100% - 22px);
  overflow: hidden;

  &:first-child {
    margin-top: 17px;
  }

  &:last-child {
    margin-bottom: 17px;
  }
`

export const TemplateCreatorTemplateListItemIcon = styled.span`
  font-size: 21px;
  margin-right: 11px;
`

export const TemplateCreatorTemplateListItemText = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const TemplateCreatorLibraryRadio = styled.input`
  display: none;
`

export const TemplateCreatorLibraryLabel = styled.label`
  font-weight: 600;
  color: ${({ theme }) => theme.grey.s6};
  display: block;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  min-height: 50px;
  cursor: pointer;
  border-radius: 10px;

  &:hover {
    background-color: ${({ theme }) => theme.grey.s2};
  }
`

export const TemplateCreatorLibraryItemCheckmark = styled.span`
  margin-left: auto;
  font-size: 24px;
  width: 24px;
  color: ${({ theme }) => theme.primary.s5};
  display: flex;
  align-items: center;
  margin-right: 8px;
`

export const TemplateCreatorTemplateFactsHeader = styled.h3`
  font-weight: bold;
  margin: 28px 32px 0 32px;
`

export const TemplateCreatorPaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TemplateCreatorBackButtonWrapper = styled.div`
  align-self: center;
  justify-self: flex-start;
`

export const TemplateCreatorNextButtonWrapper = styled.div`
  align-self: center;
  justify-self: flex-end;
`

export const TemplateCreatorPreviewImageLabelWrapper = styled.div`
  @media (min-width: ${templateInfoInputToTwoColLayoutBreakpoint}px) {
    padding-top: 11px;
    align-self: flex-start;
    grid-area: iconLabel;
  }
`

export const TemplateCreatorPreviewIconInputWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  @media (min-width: ${templateInfoInputToTwoColLayoutBreakpoint}px) {
    grid-area: iconInput;
  }
`
export const TemplateCreatorNameLabelWrapper = styled.div`
  @media (min-width: ${templateInfoInputToTwoColLayoutBreakpoint}px) {
    grid-area: nameLabel;
  }
`
export const TemplateCreatorNameInputWrapper = styled.div`
  @media (min-width: ${templateInfoInputToTwoColLayoutBreakpoint}px) {
    grid-area: nameInput;
  }
`
export const TemplateCreatorUsageLabelWrapper = styled.div`
  @media (min-width: ${templateInfoInputToTwoColLayoutBreakpoint}px) {
    align-self: flex-start;
    padding-top: 17px;
    grid-area: usageLabel;
  }
`
export const TemplateCreatorUsageInputWrapper = styled.div`
  display: flex;
  @media (min-width: ${templateInfoInputToTwoColLayoutBreakpoint}px) {
    align-self: flex-start;
    height: 100%;
    grid-area: usageInput;
  }
`

export const TemplateCreatorUsageTextarea = styled.textarea`
  color: ${({ theme }) => theme.grey.s6};
  height: 100%;
  border-radius: 8px;
  margin: 0 0;
  outline: none;
  border: none;
  background: transparent;
  resize: none;
  font-size: 14px;
  flex-grow: 1;
  padding: 16px 24px;
  display: flex;
  flex-grow: 1;
  border: 1px solid ${({ theme }) => theme.grey.s2};
  transition:
    0.2s ease box-shadow,
    0.2s ease border;
  border-radius: 8px;
  background: ${({ theme }) => theme.light};

  &::placeholder {
    color: ${({ theme }) => theme.grey.s4};
  }

  &:hover,
  &:focus {
    box-shadow: 0 0 0 4px ${({ theme }) => theme.primary.s2};
    outline: none;
  }

  &:hover {
    border-color: ${({ theme }) => theme.grey.s2};
  }

  &:focus {
    border-color: ${({ theme }) => theme.primary.s4};
  }
`

export const TemplateCreatorPreviewImageWrapper = styled.div`
  min-height: 184px;
`

export const TemplateCreatorPreviewImage = styled.img`
  display: block;
  border-radius: 8px;
  margin: 16px 16px 0 16px;
  box-sizing: border-box;
  width: calc(100% - 32px);
  height: auto;
`

export const TemplateCreatorTemplateFactsHeaderIcon = styled.span`
  font-size: 14px;
  font-weight: 700;
`

export const TemplateCreatorTemplateFactsHeaderText = styled.span`
  font-size: 14px;
  color: ${({ theme }) => theme.grey.s6};
`

export const TemplateCreatorTemplateFactsDescription = styled.div`
  margin: 4px 32px 0 32px;
  color: ${({ theme }) => theme.grey.s5};
  font-weight: 400;
  font-size: 14px;
`

export const TemplateCreatorPreviewIconButton = styled.button`
  width: 68px;
  height: 68px;
  font-size: 44px;
  border-radius: 8px;
  background: #fff;
  border: 1px solid ${({ theme }) => theme.grey.s2};
`

export const TemplateCreatorPreviewIconHint = styled.span`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.grey.s3};
  margin-left: 14px;
`

export const TemplateCreatorInfoInputLabel = styled.span`
  font-weight: 600;
  font-size: 14px;
  color: ${({ theme }) => theme.grey.s6};
`
