import { useNotifications } from '../../../../../utils/Notifications/Notifications'
import { useUpdateTemplate } from '../../../hooks/useUpdateTemplate'
import { Button } from '@valuecase/ui-components'
import { VLoaderAnimationFullSizeCentered } from '../../../../../ui-components/VLoader/VLoader'

export interface TemplateArchivingFlowProps {
  templateId: string
  data: {
    title: string
    description?: string
    icon: string
  }
  isCurrentlyArchived: boolean
  onClose?: () => void
}

export function TemplateArchivingFlow({
  templateId,
  data,
  isCurrentlyArchived,
  onClose,
}: TemplateArchivingFlowProps) {
  const { asyncNotification } = useNotifications()
  const { updateTemplateAsync: updateTemplate, isLoading } = useUpdateTemplate()

  if (isLoading) {
    return <VLoaderAnimationFullSizeCentered />
  }

  return (
    <div>
      <p className={'text-grey-s5 text-center mt-2 text-sm leading-5'}>
        {!isCurrentlyArchived && (
          <>
            You&apos;re about to archive the template <b className={'font-bold'}>{data.title}</b>
            .
            <br />
            This will make it unavailable for creating new spaces. Do you want to proceed?
          </>
        )}
        {isCurrentlyArchived && (
          <>
            You&apos;re about to unarchive the template{' '}
            <b className={'font-extrabold'}>{data.title}</b>.<br />
            The template will be available for space creation again.
            <br />
            Do you want to proceed?
          </>
        )}
      </p>
      <div className={'flex justify-center gap-2 mt-14'}>
        <Button
          label={'Cancel'}
          icon={'close-circle-outline'}
          onClick={() => {
            onClose?.()
          }}
          style={'gray-outlined'}
          cssStyle={{ width: '156px' }}
        />
        <Button
          onClick={async () => {
            const templateWillBeArchived = !isCurrentlyArchived
            await asyncNotification(
              updateTemplate({
                templateId,
                title: data.title,
                description: data.description,
                icon: data.icon,
                archived: templateWillBeArchived,
              }),
              templateWillBeArchived
                ? 'Template successfully archived'
                : 'Template successfully unarchived',
              templateWillBeArchived
                ? 'There was an error archiving the template. Please try again later.'
                : 'There was an error unarchiving the template. Please try again later.',
            )
            onClose?.()
          }}
          cssStyle={{ width: '155px', padding: '10px 12px 10px 12px' }} // The px is needed for IE11
          label={isCurrentlyArchived ? 'Yes, unarchive' : 'Yes, archive'}
          icon={'file-tray-outline'}
        />
      </div>
    </div>
  )
}
