import { FC, ReactNode } from 'react'
import { S } from './SidebarItemChildren.styled'

interface Props {
  id: string
  label: ReactNode | string
  isActive: boolean
  onClick: () => void
}
const SidebarItemChild: FC<Props> = (props) => {
  return (
    <S.Item id={props.id} onClick={props.onClick} $isHighlighted={props.isActive}>
      <S.Label $isHighlighted={props.isActive}>{props.label}</S.Label>
    </S.Item>
  )
}

export default SidebarItemChild
