import React, { CSSProperties, useEffect, useRef, useState } from 'react'
import { createGlobalStyle } from 'styled-components'
import classNames from 'classnames'
import styled from 'styled-components'

type AnimationMode = 'idle' | 'leave' | 'enter' | 'before-enter' | 'before-leave'

interface AnimateChangingChildrenProps {
  children?: React.ReactNode
  hideOverflow?: boolean
  animateInitialChildren?: boolean
  animationName?: string
  animationStepDuration?: number
  enableAnimation?: boolean
  customRootCss?: CSSProperties
  customContainerCss?: CSSProperties
}

const timeoutBuffer = 25

export function AnimateChangingChildren({
  children,
  hideOverflow = false,
  animateInitialChildren = false,
  animationName = 'default',
  animationStepDuration = 200,
  enableAnimation = true,
  customRootCss,
  customContainerCss,
}: AnimateChangingChildrenProps) {
  const initialChildrenHandledRef = useRef<boolean>()

  const nextChildrenRef = useRef<React.ReactNode | undefined>(undefined)
  const [childrenToDisplay, setChildrenToDisplay] = useState<React.ReactNode | undefined>(undefined)

  const [height, setHeight] = useState<number>(0)

  const ghostRef = useRef<HTMLDivElement | null>(null)
  const containerRef = useRef<HTMLDivElement | null>(null)

  const [mode, setMode] = useState<AnimationMode>('idle')
  const transitionTimeoutRef = useRef<NodeJS.Timeout | null>(null)

  function transitionContentTo(to: React.ReactNode) {
    const currentHeight = containerRef.current?.getBoundingClientRect().height ?? 0
    setHeight(currentHeight)
    setMode('before-leave')

    transitionTimeoutRef.current = setTimeout(() => {
      setMode('leave')
      const ghostHeight = ghostRef.current?.getBoundingClientRect().height ?? 0
      setHeight(ghostHeight)

      transitionTimeoutRef.current = setTimeout(() => {
        setMode('before-enter')

        transitionTimeoutRef.current = setTimeout(() => {
          setChildrenToDisplay(to)
          setMode('enter')

          transitionTimeoutRef.current = setTimeout(() => {
            setMode('idle')
            transitionTimeoutRef.current = null
          }, animationStepDuration + timeoutBuffer)
        }, timeoutBuffer)
      }, animationStepDuration + timeoutBuffer)
    }, timeoutBuffer)
  }

  useEffect(() => {
    const childrenKey = (children as any)?.key
    const nextChildrenKey = (nextChildrenRef.current as any)?.key

    // Do not animate
    if (
      children === childrenToDisplay ||
      (childrenKey && nextChildrenKey && childrenKey === nextChildrenKey)
    ) {
      return
    }

    // We need to store the ref
    nextChildrenRef.current = children

    if (!enableAnimation || (!initialChildrenHandledRef.current && !animateInitialChildren)) {
      setChildrenToDisplay(children)
    } else {
      if (transitionTimeoutRef.current) {
        clearTimeout(transitionTimeoutRef.current)
        transitionTimeoutRef.current = null
      }

      transitionContentTo(children)
    }

    initialChildrenHandledRef.current = true

    return () => {
      if (transitionTimeoutRef.current) {
        clearTimeout(transitionTimeoutRef.current)
        transitionTimeoutRef.current = null
      }
    }
  }, [children]) /* eslint-disable-line */ /* TODO @ fix exhaustive-deps */

  useEffect(() => {
    return () => {
      if (transitionTimeoutRef.current) {
        clearTimeout(transitionTimeoutRef.current)
        transitionTimeoutRef.current = null
      }

      setChildrenToDisplay(undefined)
      nextChildrenRef.current = undefined
      initialChildrenHandledRef.current = false
      setMode('idle')
      setHeight(0)
    }
  }, [])

  return (
    <>
      <DefaultAnimateChangingChildrenAnimation />

      <Root
        className={classNames({
          'hide-overflow': hideOverflow,
        })}
        style={customRootCss}
      >
        <GhostContainer ref={ghostRef}>
          <div />
          {children}
          <div />
        </GhostContainer>

        <Container
          ref={containerRef}
          style={{ height: mode !== 'idle' ? `${height}px` : undefined, ...customContainerCss }}
          className={`animate-changing-children--${animationName} animate-changing-children--${animationName}--${mode}`}
        >
          <div />
          {childrenToDisplay}
          <div />
        </Container>
      </Root>
    </>
  )
}

const Root = styled.div`
  position: relative;

  &.hide-overflow {
    overflow: hidden;
  }
`

const GhostContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  pointer-events: none;
  visibility: hidden;
  overflow: visible;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`

const DefaultAnimateChangingChildrenAnimation = createGlobalStyle`
  .animate-changing-children--default {
    transition: height 0.3s ease, opacity 0.15s ease;

    &--before-idle {
      opacity: 1;
    }
    
    &--before-leave {
      opacity: 1;
    }

    &--leave {
      opacity: 0;
    }

    &--before-enter {
      opacity: 0;
    }

    &--enter {
      opacity: 1;
    }
  }
`
