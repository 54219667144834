import { useEffect, useState } from 'react'
import { useDebounce } from 'usehooks-ts'

type UseReadIntegrationEntitiesProps<M, U extends keyof M> = {
  readFunction: (params: {
    enabled: boolean
    searchTerm: string
    limit: number
    objectType: U
  }) => {
    data?: M[U][]
    isFetched: boolean
    isLoading: boolean
    isError: boolean
  }
  enabled?: boolean
  minimumSearchTermLength?: number
  limit: number
  objectType: U
}

export type IntegrationSearchResults<EntityType> = {
  data?: EntityType[]
  initialLoadComplete: boolean
  searchTerm: string
  onSearchTermInput: (term: string) => void
  searchStatus: 'idle' | 'loading' | 'error'
  searchErrorMessage: string | undefined
  hasMoreData: boolean
}

/**
 * Hook to handle integration search functionality.
 *
 * @param readFunction - Function to read entities from the integration.
 * @param enabled - Whether or not the hook is enabled. Default true.
 * @param minimumSearchTermLength - Minimum length of the search term before a search is triggered. Default 2.
 * @returns IntegrationSearchResults
 */
export function useIntegrationSearch<M, U extends keyof M>({
  readFunction,
  enabled = true,
  minimumSearchTermLength = 2,
  limit,
  objectType,
}: UseReadIntegrationEntitiesProps<M, U>): IntegrationSearchResults<M[U]> {
  const [searchTerm, setSearchTerm] = useState('')
  // Trigger search when debounced search term changes, max once per second
  const debouncedSearchTerm = useDebounce(searchTerm, 1000)
  const [initialLoadComplete, setInitialLoadComplete] = useState(false)

  const { data, isFetched, isLoading, isError } = readFunction({
    enabled,
    searchTerm: debouncedSearchTerm.length >= minimumSearchTermLength ? debouncedSearchTerm : '',
    limit,
    objectType,
  })

  // Knowing when the initial load is complete is useful for displaing an initial spinner, but if
  // stale data is acceptable, we can still display the data while a new search is loading, so
  // subsequent searches should not reset the initial load complete state.
  useEffect(() => {
    if (isFetched && !initialLoadComplete) {
      setInitialLoadComplete(true)
    }
  }, [initialLoadComplete, isFetched])

  const searchStatus =
    isLoading || searchTerm !== debouncedSearchTerm ? 'loading' : isError ? 'error' : 'idle'

  const searchErrorMessage =
    searchStatus === 'error' ? `Could not connect. Try again later.` : undefined

  const onSearchTermInput = (term: string) => {
    setSearchTerm(term)
  }

  return {
    data,
    hasMoreData: (data || []).length >= limit,
    initialLoadComplete,
    searchTerm,
    onSearchTermInput,
    searchStatus,
    searchErrorMessage,
  }
}
