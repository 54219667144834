import {
  flexRender,
  Row,
  RowData,
  SortDirection,
  Table as TanstackTable,
} from '@tanstack/react-table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './table'

import { HTMLAttributes, useCallback, useEffect, useState } from 'react'
import { IonIcon } from '../../Icons/IonIcon'
import { cn } from '../../lib/utils'
import { Button } from '../legacy'

import {
  EmptyState,
  EmptyStateHeader,
  EmptyStateTitle,
  EmptyStateDescription,
  EmptyStateAction,
} from './empty'
import { Skeleton } from './skeleton'

const DataTablePaginationButton = ({
  index,
  activeIndex,
  onClick,
}: {
  index: number
  activeIndex: number
  onClick: () => void
}) => {
  return (
    <div style={{ width: '32px', height: '32px' }}>
      <Button
        fullWidth
        size='small'
        label={(index + 1).toString()}
        style={index === activeIndex ? 'primary' : 'flat-sidebar'}
        cssStyle={{ fontSize: '12px' }}
        onClick={onClick}
      />
    </div>
  )
}

interface DataTablePaginationProps extends HTMLAttributes<HTMLDivElement> {
  activeIndex: number
  onPageItemClick: (pageIndex: number) => void
  goToNextPage: () => void
  goToPreviousPage: () => void
  pageItems: number[]
}

export const Pagination = ({
  activeIndex,
  onPageItemClick,
  goToNextPage,
  goToPreviousPage,
  pageItems,
  className,
}: DataTablePaginationProps) => {
  const [paginationItems, set_paginationItems] = useState<JSX.Element[]>([])

  const onItemClick = useCallback(
    (pageIndex: number) => {
      onPageItemClick(pageIndex)
    },
    [onPageItemClick],
  )

  const paginationRules = {
    enableThreeDotsFromIndex: 3,
    enableFromPagesCount: 8,
  }

  //Render pagination
  /**
   - setPaginationJSX func is the main func.
   - It sets pagination jsx element and keeps updating its ui state
   - Keep the funcs defined here inside the useEffect.
   - If you decide to extract them, you have to wrap them with useCallback() Hooks.
   */
  useEffect(() => {
    const setPaginationJSX = () => {
      //the array containing the jsx elements rendered inside the pagination component
      let paginationItems: JSX.Element[] = []

      //add items on the left side of the activeIndex
      const left = createPaginationLeftSide(activeIndex)
      paginationItems = paginationItems.concat(left)
      // add activeIndex
      paginationItems.push(
        <DataTablePaginationButton
          key={activeIndex}
          activeIndex={activeIndex}
          index={activeIndex}
          onClick={() => onItemClick(activeIndex)}
        />,
      )
      // add items on the right side of the activeIndex
      const right = createPaginationRightSide(activeIndex, pageItems.length)
      paginationItems = paginationItems.concat(right)

      //setState
      set_paginationItems(paginationItems)
    }
    const createPaginationLeftSide = (activeIndex: number) => {
      const paginationItems: JSX.Element[] = [
        //add the left arrow navigation btn
        <Button
          key={'chevron-back'}
          icon='chevron-back-outline'
          style='flat'
          cssStyle={{
            background: 'white',
            border: '1px solid #E0E2E7',
            borderRadius: '8px',
            visibility: activeIndex === 0 ? 'hidden' : 'visible',
          }}
          size='small'
          onClick={goToPreviousPage}
        />,
      ]

      //Do we need three dots?
      if (activeIndex <= paginationRules.enableThreeDotsFromIndex) {
        //Don't render 3 dots on the left side
        for (let index = 0; index < activeIndex; index++) {
          paginationItems.push(
            <DataTablePaginationButton
              key={index}
              activeIndex={activeIndex}
              index={index}
              onClick={() => onItemClick(index)}
            />,
          )
        }
        return paginationItems
      } else {
        //render first element, 3 dots on left side then activeIndex-1
        paginationItems.push(
          <DataTablePaginationButton
            key={0}
            activeIndex={activeIndex}
            index={0}
            onClick={() => onItemClick(0)}
          />,
        )

        paginationItems.push(<div className={'font-medium mt-1'}>...</div>)

        paginationItems.push(
          <DataTablePaginationButton
            key={activeIndex - 1}
            activeIndex={activeIndex}
            index={activeIndex - 1}
            onClick={() => onItemClick(activeIndex - 1)}
          />,
        )

        return paginationItems
      }
    }

    const createPaginationRightSide = (activeIndex: number, pagesCount: number) => {
      const paginationItems: JSX.Element[] = []

      //We need a right side when activeIndex is not the last index
      if (activeIndex < pagesCount - 1) {
        //DO I NEED 3 dots? Add three dots when there are more than 3 items on the right side of the activeIndex btn
        if (pagesCount - (activeIndex + 1) > 3) {
          //YES
          paginationItems.push(
            <DataTablePaginationButton
              activeIndex={activeIndex}
              index={activeIndex + 1}
              onClick={() => onItemClick(activeIndex + 1)}
            />,
          )
          //An exceptional case
          if (activeIndex === 0) {
            paginationItems.push(
              <DataTablePaginationButton
                activeIndex={activeIndex}
                index={activeIndex + 2}
                onClick={() => onItemClick(activeIndex + 2)}
              />,
            )
          }
          paginationItems.push(<div className={'font-medium mt-1'}>...</div>)

          //always need add item after the three dots
          paginationItems.push(
            <DataTablePaginationButton
              activeIndex={activeIndex}
              index={pagesCount - 1}
              onClick={() => onItemClick(pagesCount - 1)}
            />,
          )
        } else {
          //No three dots needed
          for (let pageIndex = activeIndex + 1; pageIndex < pageItems.length; pageIndex++) {
            paginationItems.push(
              <DataTablePaginationButton
                key={pageIndex}
                activeIndex={activeIndex}
                index={pageIndex}
                onClick={() => onItemClick(pageIndex)}
              />,
            )
          }
        }

        //add the right arrow navigation btn
        paginationItems.push(
          <Button
            icon='chevron-forward-outline'
            style='flat'
            cssStyle={{
              background: 'white',
              border: '1px solid #E0E2E7',
              borderRadius: '8px',
              visibility: activeIndex === pagesCount - 1 ? 'hidden' : 'visible',
            }}
            size='small'
            key={'forward'}
            onClick={goToNextPage}
          />,
        )
        return paginationItems
      } else return []
    }
    setPaginationJSX()
  }, [
    activeIndex,
    goToNextPage,
    goToPreviousPage,
    onItemClick,
    pageItems.length,
    paginationRules.enableThreeDotsFromIndex,
  ])

  return (
    <div className={'w-full flex items-center justify-center'}>
      <div className={cn('flex gap-1', className)}>{paginationItems}</div>
    </div>
  )
}

export const DataTablePagination = <TData extends RowData>({
  table,
  paginationClickHandler,
  className,
}: {
  table: TanstackTable<TData>
  paginationClickHandler?: () => void
  className?: string
}) => {
  return (
    <Pagination
      className={className}
      goToNextPage={() => {
        table.setPageIndex(table.getState().pagination.pageIndex + 1)
        paginationClickHandler?.()
      }}
      goToPreviousPage={() => {
        table.setPageIndex(table.getState().pagination.pageIndex - 1)
        paginationClickHandler?.()
      }}
      activeIndex={table.getState().pagination.pageIndex}
      pageItems={Array.from({ length: table.getPageCount() }, (_, index) => index)}
      onPageItemClick={(pageIndex: number) => {
        table.setPageIndex(pageIndex)
        paginationClickHandler?.()
      }}
    />
  )
}

export interface DataTableProps<TData extends RowData> extends HTMLAttributes<HTMLDivElement> {
  table: TanstackTable<TData>
  rowClickHandler?: (row: Row<TData>) => void
  rowClassNames?: (row: Row<TData>) => string | undefined
  paginationClickHandler?: () => void
  resetFiltersClickHandler?: () => void
  tableWrapperClassName?: string
  isLoading?: boolean
  emptyState?: React.ReactNode
  hidePagination?: boolean
}

export const DataTable = <TData,>({
  table,
  rowClickHandler,
  rowClassNames,
  paginationClickHandler,
  resetFiltersClickHandler,
  className,
  tableWrapperClassName,
  isLoading,
  emptyState,
  hidePagination = false,
  ...props
}: DataTableProps<TData>) => {
  const activeColumnsCount = table.getVisibleLeafColumns().length

  return (
    <div className={className} {...props}>
      <div className={cn(['bg-white relative w-full overflow-auto', tableWrapperClassName])}>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow noHover key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={header.id}
                      style={{
                        minWidth: header.column.getSize(),
                        maxWidth: header.column.getSize(),
                      }}
                    >
                      {header.column.getCanSort() ? (
                        <button
                          className={
                            'group/sort-button flex gap-1 h-4 items-center focus-visible:outline-none'
                          }
                          onClick={() => header.column.toggleSorting()}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                          <div className={'h-4'}>
                            {{
                              asc: (
                                <IonIcon
                                  name='arrow-up'
                                  className={
                                    'w-4 h-4 text-primary-s5 group-hover/sort-button:text-primary-s6'
                                  }
                                />
                              ),
                              desc: (
                                <IonIcon
                                  name='arrow-down'
                                  className={
                                    'w-4 h-4 text-primary-s5 group-hover/sort-button:text-primary-s6'
                                  }
                                />
                              ),
                            }[header.column.getIsSorted() as SortDirection] ?? (
                              <div>
                                <IonIcon
                                  name='arrow-down'
                                  className={
                                    'w-4 h-4 text-grey-s4 group-hover/sort-button:text-grey-s6'
                                  }
                                />
                              </div>
                            )}
                          </div>
                        </button>
                      ) : header.isPlaceholder ? null : (
                        flexRender(header.column.columnDef.header, header.getContext())
                      )}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>

          <TableBody style={{ background: '4px solid black' }}>
            {isLoading ? (
              <>
                {Array.from({ length: 8 }).map((_, index) => (
                  <TableRow className='h-[73px]' key={index}>
                    {Array.from({ length: activeColumnsCount - 1 }).map((_, index) => (
                      <TableCell key={index}>
                        <Skeleton className='h-4' />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </>
            ) : (
              <>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && 'selected'}
                      onClick={rowClickHandler ? () => rowClickHandler(row) : undefined}
                      className={cn({ 'cursor-pointer': !!rowClickHandler }, rowClassNames?.(row))}
                      noHover={!rowClickHandler}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell
                          className={
                            // Allow cellClass to be amended via columenDef.meta
                            cell.column.columnDef.meta
                              ? (cell.column.columnDef.meta as unknown as { cellClass: string })
                                  .cellClass
                              : undefined
                          }
                          key={cell.id}
                          style={{
                            minWidth: cell.column.getSize(),
                            maxWidth: cell.column.getSize(),
                          }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))
                ) : (
                  <TableRow noHover>
                    <TableCell
                      colSpan={table.getVisibleLeafColumns()?.length}
                      className='h-24 text-center text-sm'
                    >
                      <div className={'flex flex-col items-center justify-center p-2'}>
                        {emptyState ? (
                          emptyState
                        ) : (
                          <EmptyState icon={'search'} iconSize={'small'}>
                            <EmptyStateHeader>
                              <EmptyStateTitle>No matches found!</EmptyStateTitle>
                              <EmptyStateDescription>
                                Try changing or clearing out your filters.
                              </EmptyStateDescription>
                            </EmptyStateHeader>
                            <EmptyStateAction onClick={() => resetFiltersClickHandler?.()}>
                              Reset filter
                            </EmptyStateAction>
                          </EmptyState>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </div>
      {table.getPageCount() > 1 && !hidePagination && (
        <DataTablePagination
          className={'my-3'}
          table={table}
          paginationClickHandler={paginationClickHandler}
        />
      )}
    </div>
  )
}
