import { cn } from '@/utils/Cn'
import { TeamUnion } from '@valuecase/common'
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuContentScrollable,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@valuecase/ui-components'
import { FC } from 'react'

const TeamSelector: FC<{
  selectedTeamIds: Set<string> | undefined
  teams: TeamUnion[] | undefined
  setSelectedTeamIds: React.Dispatch<React.SetStateAction<Set<string> | undefined>>
  tenantName?: string
}> = function ({ selectedTeamIds, teams, setSelectedTeamIds, tenantName }) {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger className='' asChild>
        <DropdownMenuButton
          buttonSize={'large'}
          className={cn('w-100 flex flex-grow', {
            'text-grey-s4': !selectedTeamIds,
          })}
        >
          {!selectedTeamIds
            ? 'Select team'
            : selectedTeamIds.size === 0
              ? `Everyone in ${tenantName}`
              : teams
                  ?.filter((team) => selectedTeamIds?.has(team.id))
                  .map((team) => team.name)
                  .join(', ')}
        </DropdownMenuButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent align='start' className='z-[500]'>
        <DropdownMenuContentScrollable className='w-[662px] max-w-[90vw]'>
          <DropdownMenuCheckboxItem
            checked={selectedTeamIds && !selectedTeamIds.size}
            onCheckedChange={(checked) => {
              if (checked) {
                setSelectedTeamIds(new Set()) // Empty Set = select whole tenant
              } else {
                setSelectedTeamIds(undefined)
              }
            }}
          >
            Everyone in {tenantName}
          </DropdownMenuCheckboxItem>
          <DropdownMenuSeparator text='Or' />
          {teams?.map((team) => (
            <DropdownMenuCheckboxItem
              key={team.id}
              checked={selectedTeamIds?.has(team.id)}
              onCheckedChange={(checked) => {
                if (checked) {
                  if (!selectedTeamIds) {
                    setSelectedTeamIds(new Set([team.id]))
                  } else {
                    setSelectedTeamIds(new Set([...selectedTeamIds, team.id]))
                  }
                } else {
                  selectedTeamIds?.delete(team.id)
                  if (selectedTeamIds?.size === 0) {
                    setSelectedTeamIds(undefined)
                  } else {
                    setSelectedTeamIds(new Set(selectedTeamIds))
                  }
                }
              }}
            >
              {team.name}
            </DropdownMenuCheckboxItem>
          ))}
        </DropdownMenuContentScrollable>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export default TeamSelector
