import { ChangeEventHandler, FC, useCallback, useState } from 'react'
import UserProfileEditorView from './UserProfileEditorView'
import { UserProfileEditorProps } from '../types/UserProfileEditorProps'
import { useUpdateUserProfile } from '../hooks/useUpdateUserProfile'
import { useReadUserProfile } from '../hooks/useReadUserProfile'
import { useNotifications } from '../../../utils/Notifications/Notifications'
import { Auth } from '../../../auth/auth'
import { VLoaderAnimationFullSizeCentered } from '../../../ui-components/VLoader/VLoader'

export const ProfileUpdateSuccessQueryParamKey = 'profileUpdateSuccess'

const UserProfileEditor: FC<UserProfileEditorProps> = ({ setIsModalPersistent }) => {
  const { userProfile, isLoading, error: readUserProfileError } = useReadUserProfile()
  const [newFirstName, setNewFirstName] = useState(userProfile?.firstName || '')
  const [newLastName, setNewLastName] = useState(userProfile?.lastName || '')
  const [newTitleDescription, setNewTitleDescription] = useState(userProfile?.position || '')
  const [newPhone, setNewPhone] = useState(userProfile?.phoneNumber || '')
  const [newPhoto, setNewPhoto] = useState<FileList | null>(null)
  const [newMeetingLink, setNewMeetingLink] = useState(userProfile?.spacesProfile?.meetingUrl || '')
  const [newContactEmail, setNewContactEmail] = useState(
    userProfile?.spacesProfile?.email || userProfile?.spacesProfile?.contactEmail || '',
  )
  const handleProfilePhotoSelected = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    setNewPhoto(event.target.files)
  }, [])
  const { asyncNotification, error } = useNotifications()
  const {
    updateUserProfileAsync: doUpdateUserProfile,
    error: updateUserProfileError,
    isLoading: isUpdateLoading,
  } = useUpdateUserProfile()

  const updateUserProfile = useCallback(() => {
    asyncNotification(
      doUpdateUserProfile({
        firstName: newFirstName,
        lastName: newLastName,
        phoneNumber: newPhone || undefined,
        position: newTitleDescription || undefined,
        spacesProfile: {
          meetingUrl: newMeetingLink || undefined,
          photo: (newPhoto && newPhoto[0]) || undefined,
          currentPhoto: userProfile?.spacesProfile?.photo,
          email: userProfile?.spacesProfile?.email,
          contactEmail: newContactEmail || undefined,
        },
      }).then(() => {
        Auth.login(window.location.pathname + `?${ProfileUpdateSuccessQueryParamKey}=true`)
      }),
      'Your profile information is now up to date.',
      'Updating your profile information failed. Please try again later.',
    )
  }, [
    asyncNotification,
    doUpdateUserProfile,
    newFirstName,
    newLastName,
    newPhone,
    newTitleDescription,
    newMeetingLink,
    newPhoto,
    userProfile?.spacesProfile?.photo,
    userProfile?.spacesProfile?.email,
    newContactEmail,
  ])

  return (
    <form
      onSubmit={(event) => {
        // Form element used for browser semantics, but submission
        // actually handled via createNewTemplate callback
        event.preventDefault()
      }}
    >
      {(isLoading || isUpdateLoading) && <VLoaderAnimationFullSizeCentered />}
      {!isLoading && !isUpdateLoading && (
        <UserProfileEditorView
          error={readUserProfileError || updateUserProfileError}
          firstName={newFirstName}
          setFirstName={setNewFirstName}
          lastName={newLastName}
          setLastName={setNewLastName}
          titleDescription={newTitleDescription}
          setTitleDescription={setNewTitleDescription}
          phone={newPhone}
          setPhone={setNewPhone}
          meetingLink={newMeetingLink}
          setMeetingLink={setNewMeetingLink}
          contactEmail={newContactEmail}
          setContactEmail={setNewContactEmail}
          updateUserProfile={updateUserProfile}
          handleProfilePhotoSelected={handleProfilePhotoSelected}
          profilePhoto={newPhoto ? newPhoto.item(0) ?? undefined : undefined}
          resetProfilePhoto={() => {
            setNewPhoto(null)
          }}
        />
      )}
    </form>
  )
}

export default UserProfileEditor
