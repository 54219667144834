import { useMemo, forwardRef, ButtonHTMLAttributes, MouseEventHandler } from 'react'
import CheckMarkIcon from './CheckMark.svg?react'
import EmptyCheckMarkIcon from './EmptyCheckMark.svg?react'
import DisabledCheckMarkIcon from './DisabledCheckMark.svg?react'
import DisabledSelectedCheckMarkIcon from './DisabledSelectedCheckMark.svg?react'

import { cn } from '../../../lib/utils'

interface Props extends ButtonHTMLAttributes<HTMLDivElement> {
  checked: boolean
  onClick?: MouseEventHandler<HTMLDivElement> | undefined
  disabled?: boolean
}

export const CheckMark = forwardRef<HTMLDivElement, Props>(
  ({ checked, disabled, className, onClick }, ref) => {
    const handleClick = useMemo(() => {
      if (!onClick || disabled) {
        return undefined
      }
      return onClick
    }, [onClick, disabled])

    const checkmark = useMemo(() => {
      if (disabled) {
        return checked ? <DisabledSelectedCheckMarkIcon /> : <DisabledCheckMarkIcon />
      }

      if (checked) {
        return <CheckMarkIcon />
      }
      return <EmptyCheckMarkIcon />
    }, [checked, disabled])

    return (
      <div
        ref={ref}
        className={cn('flex items-center w-4 h-5 text-primary-s5', className)}
        onClick={handleClick}
      >
        {checkmark}
      </div>
    )
  },
)

CheckMark.displayName = 'CheckMark'
