import { FC, MouseEventHandler } from 'react'
import { IonIcon } from '../../Icons/IonIcon'
import { cn } from '../../lib'
import { OneLineTruncatedText } from './text'

export const Counter: FC<{
  id?: string
  active: boolean
  iconName: string
  iconClassName?: string
  label: string
  counter: number
  isLoading?: boolean
  onClick: MouseEventHandler
}> = ({ id, active, iconName, iconClassName, label, counter, isLoading, onClick }) => {
  return (
    <div
      id={id}
      className={cn(
        'border py-[7px] px-6 rounded-lg flex gap-2 justify-between bg-white/85',
        'hover:bg-white/100 cursor-pointer transition w-full hover:shadow-text-input',
        {
          'border-white': !active,
          'border-blue-s4': active,
          'bg-white/100': active,
          'shadow-text-input': active,
        },
      )}
      onClick={onClick}
    >
      <div className={cn('flex gap-2 items-center')}>
        <IonIcon className={cn('min-w-4 max-w-4', iconClassName)} name={iconName} />
        <OneLineTruncatedText className='text-xs text-grey-s6 font-normal'>
          {label}
        </OneLineTruncatedText>
      </div>
      <div
        className={cn('text-xl font-bold', {
          invisible: isLoading,
        })}
      >
        {counter}
      </div>
    </div>
  )
}
