import { Button } from '@valuecase/ui-components'
import { ReactNode } from 'react'
import { VLoaderAnimationFullSizeCentered } from './VLoader/VLoader'
import SimpleModal2 from './SimpleModal2'
import SimpleModal2Heading from './SimpleModal2Heading'
import SimpleModal2Content from './SimpleModal2Content'

const ConfirmDeleteModal = ({
  isShown,
  confirmationMessage,
  confirmButtonText,
  footer,
  onClose,
  onConfirm,
  showLoader,
  title,
  onExit,
}: {
  isShown: boolean
  confirmationMessage: ReactNode
  confirmButtonText?: string
  footer?: ReactNode
  loadingMessage?: string
  onClose: () => void
  onConfirm: () => Promise<void>
  showLoader?: boolean
  title: string
  onExit?: () => void
}) => {
  return (
    <SimpleModal2 isShown={isShown} onClose={onClose} preventClosing={showLoader} onExit={onExit}>
      <SimpleModal2Heading title={title} />
      <div className='relative'>
        <SimpleModal2Content>
          <>
            <div className='flex flex-col items-start gap-2 relative self-stretch w-full flex-[0_0_auto]'>
              {confirmationMessage}
            </div>
            <div className='flex justify-center'>
              <div className={'grid grid-cols-2 gap-2 mt-14'}>
                <Button
                  cssStyle={{ minWidth: '156px', padding: '0 8px' }}
                  onClick={() => {
                    onClose()
                  }}
                  label={'Cancel'}
                  icon={'close-circle-outline'}
                  style={'gray-outlined'}
                />
                <Button
                  onClick={async () => {
                    onConfirm()
                  }}
                  cssStyle={{ marginTop: '0', minWidth: '156px' }}
                  label={confirmButtonText || 'Delete'}
                  icon={'trash-outline'}
                  style={'warning'}
                />
              </div>
            </div>
            {footer}
            {showLoader && (
              <div
                className='absolute inset-0 bg-white flex items-center pb-11 rounded-b-lg'
                onClick={(event) => event.stopPropagation()}
              >
                <VLoaderAnimationFullSizeCentered />
              </div>
            )}
          </>
        </SimpleModal2Content>
      </div>
    </SimpleModal2>
  )
}

export default ConfirmDeleteModal
