import { useReadHubSpotCurrentUser } from '../../../integrations/hubspot/useReadHubSpotCurrentUser'
import { SpacesListDto } from '../spacesList/spacesList'
import { Spinner } from '@valuecase/ui-components'
import { SpaceToHubSpotLinker } from './SpaceToHubSpotLinker'

/**
 * Wrapper for SpaceToHubSpotLinker that handles loading of HubSpot data
 */
export function SpaceToHubSpotLinkerWrapper({ space }: { space: SpacesListDto }) {
  const { hubSpotCurrentUser, isLoading: isLoadingHubSpotCurrentUserQuery } =
    useReadHubSpotCurrentUser({
      enabled: true,
    })
  if (isLoadingHubSpotCurrentUserQuery) {
    return (
      <div className='flex justify-center items-center p-[200px]'>
        <Spinner />
      </div>
    )
  }

  if (!hubSpotCurrentUser) {
    return (
      <div className='flex justify-center items-center p-[200px]'>
        <div>Could not connect to HubSpot</div>
      </div>
    )
  }

  return <SpaceToHubSpotLinker space={space} hubSpotCurrentUser={hubSpotCurrentUser} />
}
