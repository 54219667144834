import { CommonTranslations } from '../CommonTranslations'
import en from './common-en'

const override: any = {
  root: {
    utils: {
      date: {
        inXDays: 'Over {{n}} dagen',
        tomorrow: 'Morgen',
        today: 'Vandaag',
        yesterday: 'Gisteren',
        xYearsAgo: '{{n}} jaar geleden',
        oneYearAgo: 'een jaar geleden',
        xMonthsAgo: '{{n}} maanden geleden',
        oneMonthAgo: 'een maand geleden',
        xDaysAgo: '{{n}} dagen geleden',
        oneDayAgo: 'een dag geleden',
        xHoursAgo: '{{n}} uur geleden',
        oneHourAgo: 'een uur geleden',
        xMinutesAgo: '{{n}} minuten geleden',
        oneMinuteAgo: 'een minuut geleden',
        xSecondsAgo: '{{n}} seconden geleden',
        oneSecondAgo: 'een seconde geleden',
      },
    },
  },
}

const nl: CommonTranslations = {
  ...en,
  ...override,
}

export default nl
