import { spacesApi } from '@/api/spacesApi/spacesAPIclient'
import { useMutation, useQuery } from '@tanstack/react-query'
import {
  Button2,
  FilterDropdown,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  extractTitleFromQuillObject,
  Input2,
  Switch,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
  useToggleIdSelection,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  UTCRangeCalendar,
  DateRangePickerProps,
  Skeleton,
  cn,
} from '@valuecase/ui-components'
import { DateTime } from 'luxon'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useReadTemplates } from '../templates/hooks/useReadTemplates'
import { ActionPlanAnalyticsTableGroup } from './ActionPlanGroup'
import { useReadAvailableSpaceOwners } from '../spaces/hooks/useReadAvailableSpaceOwners'
import {
  ActionPlanAnalyticsQueryFilter,
  CellDetailsConfig,
  TemplateActionPlansAnalyticRecord,
  useActionPlanAnalyticsQuery,
  useActionPlanTasksOverTimeQuery,
} from './action-plan-analytics.helpers'
import { AverageCompletionDaysChart } from './charts/AverageCompletionDaysChart'
import { GroupBy, TaskCompletionChart } from './TaskCompletionChart'
import { ActionPlanAnalyticsCellDialog } from './ActionPlanAnalyticsCellDialog'
import { ActionPlanAnalyticsTableHeader } from './ActionPlanAnalyticsTableHeader'
import { Label } from '@valuecase/ui-components/src/components/ui/label'
import { useLDflags } from '@/launchdarkly/Launchdarkly'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'

const analyticsTableGridClass =
  'grid grid-cols-[1fr_max-content_max-content_max-content_max-content_max-content_max-content]'
const lastWeek = 'Last week' as const
const lastTwoWeeks = 'Last 2 weeks' as const
const thisMonth = 'This month' as const
const lastMonth = 'Last month' as const
const lastThreeMonths = 'Last 3 months' as const
const thisQuarter = 'This quarter' as const
const lastQuarter = 'Last quarter' as const
const lastTwelveMonths = 'Last 12 months' as const

type TimeRangePeriod =
  | typeof lastWeek
  | typeof lastTwoWeeks
  | typeof thisMonth
  | typeof lastMonth
  | typeof lastThreeMonths
  | typeof thisQuarter
  | typeof lastQuarter
  | typeof lastTwelveMonths
  | 'custom'

type TimeRange = {
  greaterThanOrEqualTo: DateTime
  lessThan: DateTime | undefined
}

const ActionPlanAnalytics = () => {
  /**
   * Basic state variables for the analytics page
   */
  const [search, setSearch] = useState('')
  const [timeRangePeriod, setTimeRangePeriod] = useState<TimeRangePeriod | undefined>(
    lastThreeMonths,
  )
  const defaultStartDateTime = useMemo(
    () => DateTime.now().setZone('UTC', { keepLocalTime: true }).minus({ months: 3 }),
    [],
  )
  const defaultEndDateTime = useMemo(
    () => DateTime.now().setZone('UTC', { keepLocalTime: true }),
    [],
  )
  const [timeRange, setTimeRange] = useState<TimeRange | undefined>({
    greaterThanOrEqualTo: defaultStartDateTime,
    lessThan: defaultEndDateTime,
  })
  const flagsQuery = useLDflags()
  const [selectedTimeRange, setSelectedTimeRange] = useState<
    Parameters<DateRangePickerProps['onChange']>[0]
  >({
    startDate: defaultStartDateTime.toJSDate(),
    endDate: defaultEndDateTime.toJSDate(),
  })
  const handleTimePeriodSelection = useCallback(
    (value: TimeRangePeriod | undefined) => {
      let greaterThanOrEqualTo: DateTime | undefined = undefined
      let lessThan: DateTime | undefined = undefined
      switch (value) {
        case lastWeek:
          greaterThanOrEqualTo = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .startOf('day')
            .minus({ days: 6 })
            .startOf('day')
          lessThan = DateTime.now().setZone('UTC', { keepLocalTime: true }).startOf('day')
          break
        case lastTwoWeeks:
          greaterThanOrEqualTo = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .startOf('day')
            .minus({ days: 13 })
            .startOf('day')
          lessThan = DateTime.now().setZone('UTC', { keepLocalTime: true }).startOf('day')
          break
        case thisMonth:
          greaterThanOrEqualTo = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .startOf('month')
          lessThan = DateTime.now().setZone('UTC', { keepLocalTime: true }).startOf('day')
          break
        case lastMonth:
          greaterThanOrEqualTo = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .startOf('month')
            .minus({ months: 1 })
          lessThan = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .startOf('month')
            .minus({ days: 1 })
          break
        case lastThreeMonths:
          greaterThanOrEqualTo = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .minus({ months: 3 })
          lessThan = DateTime.now().setZone('UTC', { keepLocalTime: true }).startOf('day')
          break
        case thisQuarter:
          greaterThanOrEqualTo = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .startOf('quarter')
          lessThan = DateTime.now().setZone('UTC', { keepLocalTime: true }).startOf('day')
          break
        case lastQuarter:
          greaterThanOrEqualTo = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .startOf('quarter')
            .minus({ quarters: 1 })
          lessThan = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .startOf('quarter')
            .minus({ day: 1 })
          break
        case lastTwelveMonths:
          greaterThanOrEqualTo = DateTime.now()
            .setZone('UTC', { keepLocalTime: true })
            .minus({ months: 12 })
          lessThan = DateTime.now().setZone('UTC', { keepLocalTime: true }).startOf('month')
          break
        default:
          break
      }
      if (!greaterThanOrEqualTo || !lessThan) {
        return
      }
      setTimeRangePeriod(value)
      setTimeRange({ greaterThanOrEqualTo, lessThan })
      setSelectedTimeRange({
        startDate: greaterThanOrEqualTo.toJSDate(),
        endDate: lessThan.toJSDate(),
      })
    },
    [setTimeRange],
  )
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [groupBy, setGroupBy] = useState<GroupBy>('month')
  const [includeArchivedSpaces, setIncludeArchivedSpaces] = useState(true)
  const [includeArchivedTemplates, setIncludeArchivedTemplates] = useState(false)
  const [selectedOwnerIds, setSelectedOwnerIds] = useState<Set<string> | undefined>(undefined)
  const toggleOwnerSelection = useToggleIdSelection({
    selectedIds: selectedOwnerIds,
    setSelectedIds: setSelectedOwnerIds,
  })
  const [selectedTemplateIds, setSelectedTemplateIds] = useState<Set<string> | undefined>(undefined)
  const toggleTemplateSelection = useToggleIdSelection({
    selectedIds: selectedTemplateIds,
    setSelectedIds: setSelectedTemplateIds,
  })
  const [selectedActionPlanIds, setSelectedActionPlanIds] = useState<Set<string> | undefined>(
    undefined,
  )
  const toggleActionPlanSelection = useToggleIdSelection({
    selectedIds: selectedActionPlanIds,
    setSelectedIds: setSelectedActionPlanIds,
  })
  const [selectedMilestoneIds, setSelectedMilestoneIds] = useState<Set<string> | undefined>(
    undefined,
  )
  const toggleMilestoneSelection = useToggleIdSelection({
    selectedIds: selectedMilestoneIds,
    setSelectedIds: setSelectedMilestoneIds,
  })
  const [isCellDetailsDialogOpen, setIsCellDetailsDialogOpen] = useState(false)
  const [cellDetailsConfig, setCellDetailsConfig] = useState<CellDetailsConfig | undefined>(
    undefined,
  )
  const [groupingLevel, setGroupingLevel] = useState<
    'template' | 'action plan' | 'milestone' | 'task'
  >('template')
  const spaceCreatedAfter = useMemo(() => timeRange?.greaterThanOrEqualTo.toJSDate(), [timeRange])
  const spaceCreatedBefore = useMemo(() => {
    if (!timeRangePeriod) {
      return undefined
    }
    // This dates only have end dates for calendar view purposes
    const skipEndDate = [
      lastWeek,
      lastTwoWeeks,
      thisMonth,
      lastThreeMonths,
      thisQuarter,
      lastTwelveMonths,
    ].includes(timeRangePeriod as any)
    if (skipEndDate) {
      return undefined
    }
    return timeRange?.lessThan?.plus({ days: 1 }).toJSDate()
  }, [timeRange, timeRangePeriod])

  const { trackEvent } = useTrackEvent()

  useEffect(() => {
    trackEvent({
      event: 'dashboard-visit',
      eventProperties: {
        indexPageName: 'Action Plan Analytics page',
      },
    })
  }, [trackEvent])

  /**
   * Variables set in connection with querying the backend
   */
  // Owner data from the backend
  const { availableSpaceOwners } = useReadAvailableSpaceOwners()
  const sortedOwners = useMemo(() => {
    if (!availableSpaceOwners || availableSpaceOwners.length === 0) {
      return []
    }
    return [...availableSpaceOwners]
      .map((owner) => ({
        id: owner.id,
        title: `${owner.firstName} ${owner.lastName}`,
        email: owner.email,
      }))
      .sort((a, b) => a.title.localeCompare(b.title))
  }, [availableSpaceOwners])

  // Template data from the backend
  const { templates: templatesResponse } = useReadTemplates()
  const templates = useMemo(() => templatesResponse || [], [templatesResponse])

  // Action plan data from the backend
  const actionPlanTemplatesQuery = useQuery({
    queryKey: ['actionPlanTemplates', includeArchivedTemplates],
    queryFn: async () => {
      const res = await spacesApi.get(
        `/action-plans/templates?includeArchived=${includeArchivedTemplates}`,
      )
      const data = res.data as { items: { id: string; title: string }[] }
      if (data && typeof data === 'object' && Array.isArray(data.items)) {
        data.items = data.items.map((item) => ({
          ...item,
          title: extractTitleFromQuillObject(item.title) || '',
        }))
      }
      return data
    },
    staleTime: 0,
  })
  const actionPlans = useMemo(() => {
    return actionPlanTemplatesQuery.data?.items || []
  }, [actionPlanTemplatesQuery])

  // Milestone data from the backend (if enabled)
  const milestonesFilterEnabled = useMemo(() => {
    return !!selectedTemplateIds || !!selectedActionPlanIds
  }, [selectedTemplateIds, selectedActionPlanIds])
  const milestoneTemplatesQuery = useQuery({
    queryKey: [
      'milestoneTemplates',
      selectedTemplateIds,
      selectedActionPlanIds,
      includeArchivedTemplates,
    ],
    queryFn: async () => {
      if (!selectedTemplateIds && !selectedActionPlanIds) {
        return { items: [] }
      }
      const params = new URLSearchParams()
      if (selectedTemplateIds) {
        params.set('templateIds', Array.from(selectedTemplateIds).join(','))
      }
      if (selectedActionPlanIds) {
        params.set('actionPlanNodeIds', Array.from(selectedActionPlanIds).join(','))
      }
      if (includeArchivedTemplates) {
        params.set('includeArchived', includeArchivedTemplates.toString())
      }
      const res = await spacesApi.get(`/action-plan-milestones/templates?${params.toString()}`)
      const data = res.data as { items: { id: string; title: string }[] }
      if (data?.items) {
        data.items = data.items.map((item) => ({
          ...item,
          title: extractTitleFromQuillObject(item.title) || '',
        }))
      }
      return data
    },
    enabled: milestonesFilterEnabled,
    staleTime: 0,
  })
  const milestones = useMemo(() => {
    return milestoneTemplatesQuery.data?.items || []
  }, [milestoneTemplatesQuery.data])

  // Task analytics data from the backend
  const filter: ActionPlanAnalyticsQueryFilter = useMemo(
    () => ({
      selectedTemplateIds,
      selectedActionPlanIds,
      selectedMilestoneIds,
      search,
      groupingLevel,
      selectedOwnerIds,
      includeArchivedTemplates,
      includeArchivedSpaces,
      spaceCreatedAfter,
      spaceCreatedBefore,
    }),
    [
      selectedTemplateIds,
      selectedActionPlanIds,
      selectedMilestoneIds,
      search,
      groupingLevel,
      selectedOwnerIds,
      includeArchivedTemplates,
      includeArchivedSpaces,
      spaceCreatedAfter,
      spaceCreatedBefore,
    ],
  )
  const taskAnalyticsQuery = useActionPlanAnalyticsQuery({ filter })
  const taskAnalytics = useMemo(
    () =>
      (taskAnalyticsQuery.data?.data || { items: [], meta: { lastRefreshed: undefined } }) as {
        items: TemplateActionPlansAnalyticRecord[]
        meta: {
          lastRefreshed: string | undefined
        }
      },
    [taskAnalyticsQuery.data],
  )

  // Tasks over time data from the backend
  const actionPlanTasksOverTimeQuery = useActionPlanTasksOverTimeQuery({
    filter,
    groupBy,
    enabled: groupingLevel === 'template',
  })
  const totalTasksCompleted = useMemo(() => {
    if (!actionPlanTasksOverTimeQuery) {
      return 0
    }
    return (
      (actionPlanTasksOverTimeQuery?.data?.data as { date: string; count: number }[])?.reduce(
        (acc, curr) => acc + curr.count,
        0,
      ) ?? 0
    )
  }, [actionPlanTasksOverTimeQuery])

  // Request to trigger a refresh of the analytics data via a mutation
  const refreshMutation = useMutation({
    mutationFn: async () => {
      return spacesApi.post('/action-plan-analytics/refresh')
    },
  })

  const timeRangeButtonLabelText = useMemo(() => {
    switch (timeRangePeriod) {
      case lastWeek:
        return 'Spaces created last week'
      case lastTwoWeeks:
        return 'Spaces created in the last 2 weeks'
      case thisMonth:
        return 'Spaces created this month'
      case lastMonth:
        return 'Spaces created last month'
      case lastThreeMonths:
        return 'Spaces created in the last 3 months'
      case thisQuarter:
        return 'Spaces created this quarter'
      case lastQuarter:
        return 'Spaces created last quarter'
      case lastTwelveMonths:
        return 'Spaces created in the last 12 months'
      default:
        return 'All time'
    }
  }, [timeRangePeriod])

  const handleCellClick = useCallback(
    ({
      originNodeId,
      cellType,
    }: {
      originNodeId: string
      cellType: 'space count' | 'completed' | 'days to completion' | 'quickest' | 'slowest'
    }) => {
      if (!originNodeId || !cellType) {
        return
      }
      switch (cellType) {
        case 'quickest':
          setCellDetailsConfig({
            title: 'Quickest Quartile',
            description: `The quartile of spaces that took the shortest time to complete this ${groupingLevel}`,
            originNodeId,
            cellType,
          })
          break
        case 'slowest':
          setCellDetailsConfig({
            title: 'Slowest Quartile',
            description: `The quartile of spaces that took the longest time to complete this ${groupingLevel}`,
            originNodeId,
            cellType,
          })
          break
        case 'days to completion':
          setCellDetailsConfig({
            title: 'Days to completion',
            description: `The number of days it took to complete this ${groupingLevel}`,
            originNodeId,
            cellType,
          })
          break
        case 'completed':
          setCellDetailsConfig({
            title: 'Completed',
            description: `Spaces which have completed this ${groupingLevel}`,
            originNodeId,
            cellType,
          })
          break
        case 'space count':
          setCellDetailsConfig({
            title: 'Spaces',
            description: `Spaces containing this ${groupingLevel}`,
            originNodeId,
            cellType,
          })
          break
      }
      setIsCellDetailsDialogOpen(true)
    },
    [groupingLevel, setCellDetailsConfig, setIsCellDetailsDialogOpen],
  )

  const spacesCompletedCount = useMemo(
    () => taskAnalytics?.items?.reduce((acc, curr) => acc + (curr?.completedCount || 0), 0),
    [taskAnalytics],
  )
  const spaceCount = useMemo(
    () => taskAnalytics?.items?.reduce((acc, curr) => acc + (curr?.spaceCount || 0), 0) ?? 0,
    [taskAnalytics],
  )
  const totalAvgCompletionDays = useMemo(() => {
    if (!taskAnalytics || !taskAnalytics.items) {
      return 0
    }
    const filteredData = taskAnalytics.items.filter(
      (item) => typeof item.avgCompletionDays === 'number',
    )
    const sum = filteredData.reduce((acc, curr) => acc + (curr.avgCompletionDays || 0), 0)
    return taskAnalytics.items.length > 0 ? Math.ceil(sum / filteredData.length) : 0
  }, [taskAnalytics])

  return (
    <div className='py-11 w-full flex flex-col'>
      {/* // When clicking on, for example, the lower quartile cell for a task, this dialog
       lists the bottom 25% of spaces that took the longest to complete the task */}
      <ActionPlanAnalyticsCellDialog
        open={isCellDetailsDialogOpen}
        onOpenChange={setIsCellDetailsDialogOpen}
        cellDetailsConfig={cellDetailsConfig}
        filters={filter}
      />
      <div className={'flex flex-row justify-between'}>
        <div className={'flex flex-col w-full'}>
          <h1 className={'text-2xl font-bold'}>Analytics</h1>
          <Tabs
            value={groupingLevel}
            onValueChange={(value) => {
              if (search) {
                setSearch('')
              }
              setGroupingLevel(value as typeof groupingLevel)
              trackEvent({
                event: 'analytics-action_plan-tab',
                eventProperties: {
                  actionPlanTab:
                    value === 'template'
                      ? 'Summary'
                      : value.charAt(0).toUpperCase() + value.slice(1),
                },
              })
            }}
          >
            <div className='flex flex-row justify-between mt-6 mb-4 border-b border-grey-s2'>
              <TabsList className='h-7 gap-3 pl-0'>
                <TabsTrigger className='pt-0 pb-2 px-1 text-grey-s5 font-semibold' value='template'>
                  Summary
                </TabsTrigger>
                <TabsTrigger
                  className='pt-0 pb-2 px-1 text-grey-s5 font-semibold'
                  value='action plan'
                >
                  Action Plan
                </TabsTrigger>
                <TabsTrigger
                  className='pt-0 pb-2 px-1 text-grey-s5 font-semibold'
                  value='milestone'
                >
                  Milestones
                </TabsTrigger>
                <TabsTrigger className='pt-0 pb-2 px-1 text-grey-s5 font-semibold' value='task'>
                  Tasks
                </TabsTrigger>
              </TabsList>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button2
                    variant={'plain'}
                    size={'small'}
                    trailingIcon='chevron-down'
                    className='
                  text-grey-s5 font-semibold'
                  >
                    {timeRangeButtonLabelText}
                  </Button2>
                </DropdownMenuTrigger>
                <DropdownMenuContent align='end' className='pr-0'>
                  <div>
                    <div className='flex flex-row justify-end items-center pt-2 pb-4 pr-4'>
                      <Label className='ml-auto mr-2 text-xs font-semibold text-grey-s4'>
                        Start date
                      </Label>
                      <Input2
                        className='w-auto mr-8'
                        id='spaces-created-after-date'
                        type='date'
                        value={DateTime.fromJSDate(
                          selectedTimeRange.startDate || new Date(),
                        ).toFormat('yyyy-MM-dd')}
                        onChange={(e) => {
                          if (!e.target.value) {
                            return
                          }
                          setTimeRangePeriod('custom')
                          setSelectedTimeRange({
                            ...selectedTimeRange,
                            startDate: DateTime.fromISO(e.target.value, {
                              zone: 'UTC',
                            }).toJSDate(),
                          })
                        }}
                      />
                      <Label className='mr-2 text-xs font-semibold text-grey-s4'>End date</Label>
                      <Input2
                        className='w-auto'
                        id='spaces-created-before-date'
                        type='date'
                        value={DateTime.fromJSDate(
                          selectedTimeRange.endDate || new Date(),
                        ).toFormat('yyyy-MM-dd')}
                        onChange={(e) => {
                          if (!e.target.value) {
                            return
                          }
                          setTimeRangePeriod('custom')
                          setSelectedTimeRange({
                            ...selectedTimeRange,
                            endDate: DateTime.fromISO(e.target.value, {
                              zone: 'UTC',
                            }).toJSDate(),
                          })
                        }}
                      />
                    </div>
                    <div className='flex flew-row'>
                      <div className='pr-4 pt-4'>
                        <DropdownMenuRadioGroup
                          value={timeRangePeriod}
                          onValueChange={(value) =>
                            handleTimePeriodSelection(value as TimeRangePeriod)
                          }
                        >
                          <DropdownMenuRadioItem value={lastWeek}>Last week</DropdownMenuRadioItem>
                          <DropdownMenuRadioItem value={lastTwoWeeks}>
                            Last 2 weeks
                          </DropdownMenuRadioItem>
                          <DropdownMenuRadioItem value={thisMonth}>
                            This month
                          </DropdownMenuRadioItem>
                          <DropdownMenuRadioItem value={lastMonth}>
                            Last month
                          </DropdownMenuRadioItem>
                          <DropdownMenuRadioItem value={lastThreeMonths}>
                            Last 3 months
                          </DropdownMenuRadioItem>
                          <DropdownMenuRadioItem value={thisQuarter}>
                            This quarter
                          </DropdownMenuRadioItem>
                          <DropdownMenuRadioItem value={lastQuarter}>
                            Last quarter
                          </DropdownMenuRadioItem>
                          <DropdownMenuRadioItem value={lastTwelveMonths}>
                            Last 12 months
                          </DropdownMenuRadioItem>
                        </DropdownMenuRadioGroup>
                      </div>
                      <div>
                        <div className='p-4 bg-background'>
                          <div className='bg-white rounded-lg p-4 overflow-hidden'>
                            <UTCRangeCalendar
                              startDate={selectedTimeRange.startDate}
                              endDate={selectedTimeRange.endDate}
                              defaultMonth={
                                timeRange?.greaterThanOrEqualTo.toJSDate() ||
                                DateTime.now().minus({ months: 1 }).toJSDate()
                              }
                              numberOfMonths={2}
                              onChange={(value) => {
                                setSelectedTimeRange(value)
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-row justify-end p-4 pb-2'>
                      <Button2
                        size={'small'}
                        leadingIcon='checkmark'
                        disabled={!selectedTimeRange.startDate || !selectedTimeRange.endDate}
                        onClick={() => {
                          if (!selectedTimeRange.startDate || !selectedTimeRange.endDate) {
                            return
                          }
                          setTimeRangePeriod('custom')
                          setTimeRange({
                            greaterThanOrEqualTo: DateTime.fromJSDate(selectedTimeRange.startDate),
                            lessThan: DateTime.fromJSDate(selectedTimeRange.endDate),
                          })
                        }}
                      >
                        Apply Changes
                      </Button2>
                    </div>
                  </div>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
            <div className='flex flex-col items-start gap-3 relative mt-6 w-full mb-7'>
              <div className='flex items-center gap-2 relative self-stretch w-full flex-[0_0_auto]'>
                <div className='gap-2 flex items-center relative w-full'>
                  <Input2
                    leadingIcon='search'
                    className='max-w-[200px]'
                    placeholder='Search'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  {selectedTemplateIds && (
                    <FilterDropdown
                      items={templates}
                      selectedIds={selectedTemplateIds}
                      toggleSelection={toggleTemplateSelection}
                      setSelectedIds={setSelectedTemplateIds}
                      searchPlaceholder='Search templates'
                      labelForNoSelection='All templates'
                      labelForSelection='Templates'
                    />
                  )}
                  {selectedActionPlanIds && (
                    <FilterDropdown
                      items={actionPlans}
                      selectedIds={selectedActionPlanIds}
                      toggleSelection={toggleActionPlanSelection}
                      setSelectedIds={setSelectedActionPlanIds}
                      searchPlaceholder='Search action plans'
                      labelForNoSelection='All action plans'
                      labelForSelection='Action plans'
                    />
                  )}
                  {selectedOwnerIds && (
                    <FilterDropdown
                      items={sortedOwners}
                      selectedIds={selectedOwnerIds}
                      toggleSelection={toggleOwnerSelection}
                      setSelectedIds={setSelectedOwnerIds}
                      searchPlaceholder='Search owners'
                      labelForNoSelection='All owners'
                      labelForSelection='Owners'
                    />
                  )}
                  {selectedMilestoneIds && (
                    <FilterDropdown
                      items={milestones}
                      selectedIds={selectedMilestoneIds}
                      toggleSelection={toggleMilestoneSelection}
                      setSelectedIds={setSelectedMilestoneIds}
                      searchPlaceholder='Search milestones'
                      labelForNoSelection='All milestones'
                      labelForSelection='Milestones'
                    />
                  )}
                  <div className='ml-auto'>
                    {(selectedTemplateIds ||
                      selectedMilestoneIds ||
                      selectedActionPlanIds ||
                      selectedOwnerIds) && (
                      <Button2
                        variant={'outlined'}
                        leadingIcon='refresh'
                        className='mr-2 text-grey-s5 font-semibold'
                        onClick={() => {
                          setSelectedTemplateIds(undefined)
                          setSelectedMilestoneIds(undefined)
                          setSelectedActionPlanIds(undefined)
                          setSelectedOwnerIds(undefined)
                        }}
                      >
                        Reset
                      </Button2>
                    )}
                    <DropdownMenu open={filtersOpen} onOpenChange={setFiltersOpen}>
                      <DropdownMenuTrigger asChild>
                        <Button2 variant={'solid'} leadingIcon='options' className='ml-auto'>
                          Filters
                        </Button2>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent
                        className='w-[276px] flex flex-col gap-4 pt-4 pb-4 px-3'
                        align='end'
                      >
                        <FilterDropdown
                          items={sortedOwners}
                          selectedIds={selectedOwnerIds}
                          toggleSelection={toggleOwnerSelection}
                          setSelectedIds={setSelectedOwnerIds}
                          searchPlaceholder='Search owners'
                          labelForNoSelection='All owners'
                          labelForSelection='Owners'
                        />
                        <FilterDropdown
                          items={templates}
                          selectedIds={selectedTemplateIds}
                          toggleSelection={toggleTemplateSelection}
                          setSelectedIds={setSelectedTemplateIds}
                          searchPlaceholder='Search templates'
                          labelForNoSelection='All templates'
                          labelForSelection='Templates'
                        />
                        <FilterDropdown
                          items={actionPlans}
                          selectedIds={selectedActionPlanIds}
                          toggleSelection={toggleActionPlanSelection}
                          setSelectedIds={setSelectedActionPlanIds}
                          searchPlaceholder='Search action plans'
                          labelForNoSelection='All action plans'
                          labelForSelection='Action plans'
                        />
                        {milestonesFilterEnabled && (
                          <FilterDropdown
                            items={milestones}
                            selectedIds={selectedMilestoneIds}
                            toggleSelection={toggleMilestoneSelection}
                            setSelectedIds={setSelectedMilestoneIds}
                            searchPlaceholder='Search milestones'
                            labelForNoSelection='All milestones'
                            labelForSelection='Milestones'
                          />
                        )}
                        <div className='w-full flex justify-between flex-row px-1 py-1 items-center'>
                          <span className='text-xs font-semibold'>Include Archived Spaces</span>
                          <Switch
                            checked={includeArchivedSpaces}
                            onChange={setIncludeArchivedSpaces}
                          />
                        </div>
                        <div className='w-full flex justify-between flex-row px-1 py-1 items-center'>
                          <span className='text-xs font-semibold'>Show Archived Templates</span>
                          <Switch
                            checked={includeArchivedTemplates}
                            onChange={setIncludeArchivedTemplates}
                          />
                        </div>
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </div>
                </div>
              </div>
            </div>
            <TabsContent value='template'>
              <div className='rounded-lg flex flex-col gap-6'>
                <div className='flex flex-col gap-4 h-full'>
                  <span className='font-bold text-grey-s6'>Performance</span>

                  <div className='grid grid-cols-[144px_1fr_1fr] gap-4'>
                    {/* First column */}
                    <div className='space-y-4'>
                      <div className='bg-white p-4 rounded-lg flex flex-col'>
                        <div className='text-xl font-bold'>
                          {taskAnalyticsQuery.isLoading ? '\u00A0' : spaceCount}
                        </div>
                        <div className='text-xxs leading-[14px] font-normal text-grey-s5 m-0'>
                          Total Spaces
                        </div>
                      </div>
                      <div className='bg-white p-4 rounded-lg flex flex-col'>
                        <div className='text-xl font-bold m-0'>
                          {taskAnalyticsQuery.isLoading ? '\u00A0' : spacesCompletedCount}
                        </div>
                        <div className='text-xxs leading-[14px] font-normal text-grey-s5 m-0'>
                          Total Spaces completed
                        </div>
                      </div>
                    </div>

                    {/* Second column */}
                    <div className='flex flex-col bg-white rounded-lg gap-4 p-4'>
                      <div className='flex justify-between items-center'>
                        <div className='flex flex-col'>
                          <span className='text-xl font-bold'>
                            {taskAnalyticsQuery.isLoading ? '\u00A0' : totalTasksCompleted}
                          </span>
                          <span className='text-xxs leading-[14px] font-normal text-grey-s5'>
                            Total tasks completed
                          </span>
                        </div>

                        <div className='flex items-center'>
                          <Select
                            value={groupBy}
                            onValueChange={(value: GroupBy) => setGroupBy(value)}
                          >
                            <SelectTrigger className='w-[180px]'>
                              <SelectValue placeholder='Group by' />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value='biweek'>Bi-week</SelectItem>
                              <SelectItem value='month'>Month</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                      <TaskCompletionChart groupBy={groupBy} filter={filter} />
                    </div>

                    <div className='flex flex-col  gap-4 bg-white rounded-lg p-4'>
                      <div className='flex justify-between flex-col'>
                        <span className='text-xl font-bold'>
                          {taskAnalyticsQuery.isLoading ? '\u00A0' : totalAvgCompletionDays}
                        </span>
                        <span className='text-xxs leading-[14px] font-normal text-grey-s5'>
                          Average days to complete a space
                        </span>
                      </div>
                      {!taskAnalyticsQuery.isLoading && (
                        <AverageCompletionDaysChart data={taskAnalytics.items ?? []} />
                      )}
                      {taskAnalyticsQuery.isLoading && (
                        <Skeleton className='h-[144px] rounded-lg' />
                      )}
                    </div>
                  </div>
                </div>
                <div className={analyticsTableGridClass}>
                  <ActionPlanAnalyticsTableHeader
                    startLabel='Template Name'
                    className='bg-white rounded-t border-t border-l border-r border-grey-s2'
                  />
                  <ActionPlanAnalyticsTableGroup
                    isLoading={taskAnalyticsQuery.isLoading}
                    searchTerm={search}
                    templateTitle={undefined}
                    tables={[
                      {
                        id: 'template',
                        column1Label: 'Template Name',
                        hideHeaderRow: true,
                        rows:
                          taskAnalytics?.items?.map((template) => ({
                            id: template.templateId,
                            originNodeId: template.templateNodeId,
                            title: template.title,
                            spaceCount: template.spaceCount,
                            completedCount: template.completedCount,
                            avgCompletionDays: template.avgCompletionDays,
                            lowerQuartileDays: template.lowerQuartileDays,
                            upperQuartileDays: template.upperQuartileDays,
                            handleOpenDetailsClick: () => {
                              setSearch('')
                              setGroupingLevel('action plan')
                              setSelectedTemplateIds(new Set([template.templateId]))
                            },
                            handleCellClick,
                          })) || [],
                      },
                    ]}
                  />
                </div>
              </div>
            </TabsContent>
            <TabsContent value='action plan'>
              <div className={analyticsTableGridClass}>
                <ActionPlanAnalyticsTableHeader className='bg-background' />
                {taskAnalyticsQuery.isLoading && (
                  <div
                    className={cn(
                      'rounded-lg bg-white/65 grid grid-cols-subgrid col-span-full mb-4 border border-grey-s2',
                    )}
                  >
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg w-[118px]' />
                    </div>
                  </div>
                )}
                {taskAnalytics?.items?.map((template) => (
                  <ActionPlanAnalyticsTableGroup
                    isLoading={taskAnalyticsQuery.isLoading}
                    key={template.templateId}
                    templateTitle={template.title}
                    actionPlanBlockId={template.templateId}
                    searchTerm={groupingLevel === 'action plan' ? search : ''}
                    actionPlanTitle={template.title}
                    tables={[
                      {
                        id: template.templateId,
                        column1Label: 'Action Plan Name',
                        rows: template.actionPlans.map((actionPlan) => ({
                          id: actionPlan.originNodeId,
                          originNodeId: actionPlan.originNodeId,
                          title: actionPlan.title,
                          spaceCount: actionPlan.spaceCount,
                          completedCount: actionPlan.completedCount,
                          avgCompletionDays: actionPlan.avgCompletionDays,
                          lowerQuartileDays: actionPlan.lowerQuartileDays,
                          upperQuartileDays: actionPlan.upperQuartileDays,
                          handleOpenDetailsClick: () => {
                            setSearch('')
                            setGroupingLevel('milestone')
                            setSelectedActionPlanIds(new Set([actionPlan.originNodeId]))
                          },
                          handleCellClick,
                        })),
                      },
                    ]}
                  />
                ))}
              </div>
            </TabsContent>
            <TabsContent value='milestone'>
              <div className={analyticsTableGridClass}>
                <ActionPlanAnalyticsTableHeader className='bg-background' />
                {taskAnalyticsQuery.isLoading && (
                  <div
                    className={cn(
                      'rounded-lg bg-white/65 grid grid-cols-subgrid col-span-full mb-4 border border-grey-s2',
                    )}
                  >
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg w-[118px]' />
                    </div>
                  </div>
                )}
                {!taskAnalyticsQuery.isLoading &&
                  taskAnalytics?.items?.map((template) =>
                    template.actionPlans.map((actionPlan) => (
                      <ActionPlanAnalyticsTableGroup
                        isLoading={taskAnalyticsQuery.isLoading}
                        key={actionPlan.originNodeId}
                        searchTerm={groupingLevel === 'milestone' ? search : ''}
                        actionPlanTitle={actionPlan.title}
                        templateTitle={template.title}
                        actionPlanBlockId={actionPlan.originNodeId}
                        tables={[
                          {
                            id: actionPlan.originNodeId,
                            column1Title: undefined,
                            column1Label: 'Milestone Name',
                            rows: actionPlan.milestones.map((milestone) => ({
                              id: milestone.originNodeId,
                              originNodeId: milestone.originNodeId,
                              title: milestone.title,
                              spaceCount: milestone.spaceCount,
                              completedCount: milestone.completedCount,
                              avgCompletionDays: milestone.avgCompletionDays,
                              lowerQuartileDays: milestone.lowerQuartileDays,
                              upperQuartileDays: milestone.upperQuartileDays,
                              handleOpenDetailsClick: () => {
                                setSearch('')
                                setGroupingLevel('task')
                                setSelectedMilestoneIds(new Set([milestone.originNodeId]))
                              },
                              handleCellClick,
                            })),
                          },
                        ]}
                      />
                    )),
                  )}
              </div>
            </TabsContent>
            <TabsContent value='task'>
              <div className={analyticsTableGridClass}>
                <ActionPlanAnalyticsTableHeader className='bg-background' />
                {taskAnalyticsQuery.isLoading && (
                  <div
                    className={cn(
                      'rounded-lg bg-white/65 grid grid-cols-subgrid col-span-full mb-4 border border-grey-s2',
                    )}
                  >
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg' />
                    </div>
                    <div className='p-4'>
                      <Skeleton className='h-4 rounded-lg w-[118px]' />
                    </div>
                  </div>
                )}
                {!taskAnalyticsQuery.isLoading &&
                  taskAnalytics?.items?.map((template) =>
                    template.actionPlans.map((actionPlan) => (
                      <ActionPlanAnalyticsTableGroup
                        isLoading={taskAnalyticsQuery.isLoading}
                        searchTerm={groupingLevel === 'task' ? search : ''}
                        key={actionPlan.originNodeId}
                        actionPlanTitle={actionPlan.title}
                        templateTitle={template.title}
                        actionPlanBlockId={actionPlan.originNodeId}
                        tables={actionPlan.milestones.map((milestone) => ({
                          id: milestone.originNodeId,
                          column1Title: `Milestone: ${milestone.title}`,
                          column1Label: 'Task Name',
                          defaultOpenActionLabel: 'Show in Action Plan',
                          rows: milestone.tasks.map((task) => ({
                            id: task.originNodeId,
                            originNodeId: task.originNodeId,
                            title: task.title,
                            spaceCount: task.spaceCount,
                            completedCount: task.completedCount,
                            avgCompletionDays: task.avgCompletionDays,
                            lowerQuartileDays: task.lowerQuartileDays,
                            upperQuartileDays: task.upperQuartileDays,
                            handleOpenDetailsClick: () => {
                              const url = new URL(location.origin)
                              url.pathname = `/spaces/${template.templateNodeId}`
                              url.searchParams.set('block', actionPlan.originNodeId)
                              window.open(url.toString(), '_blank', 'noopener,noreferrer')
                            },
                            handleCellClick,
                          })),
                        }))}
                      />
                    )),
                  )}
              </div>
            </TabsContent>
          </Tabs>
          {!!flagsQuery.flags?.['dashboard-action-plan-analytics-refresh'] && (
            <div className='flex justify-end pb-7'>
              <Button2
                className='text-grey-s5 font-normal'
                size={'small'}
                variant={'plain'}
                onClick={() => refreshMutation.mutate()}
              >
                Last refresh:{' '}
                {taskAnalytics?.meta?.lastRefreshed
                  ? DateTime.fromISO(taskAnalytics.meta.lastRefreshed).toLocaleString(
                      DateTime.DATETIME_SHORT,
                    )
                  : 'never'}
              </Button2>
            </div>
          )}
          {!flagsQuery.flags?.['dashboard-action-plan-analytics-refresh'] && (
            <div className='flex justify-end pb-7 text-grey-s5 font-normal'>
              <div>Last refresh: {taskAnalytics?.meta?.lastRefreshed || 'never'}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ActionPlanAnalytics
