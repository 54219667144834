import { TLinkThumbnailResponse } from '@valuecase/ui-components'
import { spacesApi } from '@/api/spacesApi/spacesAPIclient'

export function useGenerateLinkThumbnail() {
  return {
    generateLinkThumbnail: async (
      linkUrl: string,
      useIframely?: boolean,
    ): Promise<TLinkThumbnailResponse> => {
      const { data } = await spacesApi.post<TLinkThumbnailResponse>('/files/thumbnail', {
        linkUrl: linkUrl.includes('://') ? linkUrl : `https://${linkUrl}`,
        useIframely,
      })
      return data
    },
  }
}
