import { useMemo } from 'react'
import { useChangeSpacedArchivedStatus } from '../../hooks/useChangeSpacedArchivedStatus'
import { Button } from '@valuecase/ui-components'
import { useNotifications } from '../../../../utils/Notifications/Notifications'
import { VLoaderAnimationFullSizeCentered } from '../../../../ui-components/VLoader/VLoader'
import { useTrackEvent } from '@/mixpanel/useTrackEvent'

export interface SpaceArchivingFlowProps {
  spaceId: string
  companyName: string
  spaceUrlWithToken: string
  isCurrentlyArchived: boolean
  onClose?: () => void
}

export function SpaceArchivingFlow({
  spaceId,
  companyName,
  spaceUrlWithToken,
  isCurrentlyArchived,
  onClose,
}: SpaceArchivingFlowProps) {
  const url = useMemo(() => `${spaceUrlWithToken}&force-no-access-view=true`, [spaceUrlWithToken])
  const { asyncNotification } = useNotifications()
  const { trackEvent } = useTrackEvent()
  const { changeSpaceArchivedStatus, isLoading, isError, error } = useChangeSpacedArchivedStatus()
  if (isLoading) {
    return <VLoaderAnimationFullSizeCentered />
  }
  return (
    <div>
      <p className={'text-grey-s5 text-center mt-2 text-sm leading-5'}>
        {!isCurrentlyArchived && (
          <>
            You&apos;re about to archive the space <b className={'font-bold'}>{companyName}</b>
            .
            <br />
            This disables space access and stops active email notifications to customers. Do you
            want to proceed?
          </>
        )}
        {isCurrentlyArchived && (
          <>
            You&apos;re about to unarchive the space{' '}
            <b className={'font-extrabold'}>{companyName}</b>.<br />
            This action will restore space access for buyers.
            <br />
            Do you want to proceed?
          </>
        )}
      </p>
      <div className={'flex justify-center gap-2 mt-14'}>
        <Button
          cssStyle={{ width: '156px' }}
          onClick={() => {
            onClose?.()
          }}
          label={'Cancel'}
          icon={'close-circle-outline'}
          style={'gray-outlined'}
        />
        <Button
          onClick={async () => {
            const spaceWillBeArchived = !isCurrentlyArchived
            await asyncNotification(
              changeSpaceArchivedStatus(spaceId, spaceWillBeArchived),
              spaceWillBeArchived ? 'Space successfully archived' : 'Space successfully unarchived',
              spaceWillBeArchived
                ? 'There was an error archiving the space. Please try again later.'
                : 'There was an error unarchiving the space. Please try again later.',
            )

            trackEvent({
              event: 'spaces-archive-toggle',
              eventProperties: {
                spaceId,
                action: spaceWillBeArchived ? 'archive' : 'unarchive',
              },
            })
            onClose?.()
          }}
          cssStyle={{ width: '155px', padding: '10px 12px 10px 12px' }} // The px is needed for IE11
          label={isCurrentlyArchived ? 'Yes, unarchive' : 'Yes, archive'}
          icon={'file-tray-outline'}
        />
      </div>
    </div>
  )
}
