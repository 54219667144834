import { useMutation, useQueryClient } from '@tanstack/react-query'
import UserManagementApi from '../../../api/resources/UserManagementApi'
import { InviteUserBody } from '@valuecase/seller-backend'

export async function updateUserRoleFn(user: InviteUserBody) {
  await UserManagementApi.inviteUser(user)
}

export function useInviteUser(onSuccess?: () => void) {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: updateUserRoleFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['readUsers'] })
      onSuccess?.()
    },
  })
  return {
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    inviteUser: (user: InviteUserBody) => mutation.mutate(user),
  }
}
