import { useRef } from 'react'
import { ModalControls, SimpleModal } from '@valuecase/ui-components'
import styled from 'styled-components'
import { SpaceCreationFlowWrapper } from './components/spaceCreationFlow/SpaceCreationFlowWrapper'
import './assets/modalStyles.css'

// Set flex grow
// (Unable to set classes on it directly)
const Wrapper = styled.div`
  & > div {
    flex-grow: 1;
  }
`

export default function EmbeddedSpaceCreate() {
  const controls = useRef<ModalControls | null>(null)

  return (
    <Wrapper className='min-h-screen flex flex-col'>
      <SimpleModal
        controlsRef={controls}
        additionalStyleClasses={{
          layout: 'spacecreationflow-modal',
          baseModal: 'spacecreationflow-modal__base',
        }}
        body={<SpaceCreationFlowWrapper />}
        maxWidth='978px'
        wide={true}
      />
    </Wrapper>
  )
}
