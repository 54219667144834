import styled from 'styled-components'

export const SelectListUnorderedList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  row-gap: '.5em';
`

export const SelectListItem = styled.li`
  margin: 0 10px 10px 12px;
  max-width: calc(100% - 22px);
  overflow: hidden;

  &:first-child {
    margin-top: 17px;
  }

  &:last-child {
    margin-bottom: 17px;
  }
`

export const SelectListRadio = styled.input`
  display: none;
`

export const SelectListLabel = styled.label`
  font-weight: 600;
  color: ${({ theme }) => theme.grey.s6};
  display: block;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 0 12px;
  min-height: 50px;
  cursor: pointer;
  border-radius: 10px;

  &:hover {
    background-color: ${({ theme }) => theme.grey.s2};
  }
`

export const SelectListItemIcon = styled.span`
  font-size: 21px;
  margin-right: 11px;
`
export const SelectListItemText = styled.span`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const SelectListItemCheckmark = styled.span`
  margin-left: auto;
  font-size: 24px;
  width: 24px;
  color: ${({ theme }) => theme.primary.s5};
  display: flex;
  align-items: center;
  margin-right: 8px;
`
