import { useMutation } from '@tanstack/react-query'
import UserManagementApi from '../../../../api/resources/UserManagementApi'
import { queryClient } from '../../../../main'

export default function useChangeUserStatus() {
  return useMutation({
    mutationFn: (props: { userId: string; newStatus: 'active' | 'inactive' }) => {
      return UserManagementApi.changeUserStatus(props.userId, { newStatus: props.newStatus })
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['readUsers'] })
    },
  })
}
