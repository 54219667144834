import { FC, useCallback } from 'react'
import { useModalControls } from '@valuecase/ui-components'
import styled from 'styled-components'
import { useDeleteTemplate } from '../hooks/useDeleteTemplate'
import TemplateDeleteView from './TemplateDeleteView'
import { useNotifications } from '../../../utils/Notifications/Notifications'
import { TemplateCreatorTemplateDetails } from '../types/TemplateCreatorTemplateDetails'

export interface TemplateDeleteProps {
  setIsModalPersistent: React.Dispatch<React.SetStateAction<boolean>>
  template: TemplateCreatorTemplateDetails
  onSuccessCallback?: () => void
}

export const TemplateDeleteForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: 0 72px;

  .button-container {
    display: flex;
    gap: 8px;
    justify-content: center;

    button:first-child {
      color: ${({ theme }) => theme.grey.s6};
      background: transparent;
      border-color: ${({ theme }) => theme.grey.s6};

      &:hover {
        background: ${({ theme }) => theme.grey.s1};
      }
    }
  }
`

const TemplateDelete: FC<TemplateDeleteProps> = ({
  setIsModalPersistent,
  template,
  onSuccessCallback,
}) => {
  const { close } = useModalControls()
  const { success, error: errorNotification } = useNotifications()
  const { deleteTemplate, error } = useDeleteTemplate({
    onSuccess: () => {
      if (onSuccessCallback) {
        onSuccessCallback()
      }
      setIsModalPersistent(false)
      success('Template deleted successfully')
      close({ ignorePersistence: true })
    },
    onError: () => {
      setIsModalPersistent(false)
      errorNotification('Template could not be deleted')
    },
  })

  const editTemplate = useCallback(() => {
    setIsModalPersistent(true)
    deleteTemplate({
      templateId: template.rootNodeId,
    })
  }, [setIsModalPersistent, deleteTemplate, template.rootNodeId])

  return (
    <TemplateDeleteForm
      onSubmit={(event) => {
        // Form element used for browser semantics, but submission
        // actually handled via createNewTemplate callback
        event.preventDefault()
      }}
    >
      <TemplateDeleteView
        error={error}
        editTemplate={editTemplate}
        onCancel={() => close()}
        template={template}
      />
    </TemplateDeleteForm>
  )
}

export default TemplateDelete
