import styled from 'styled-components'
import classNames from 'classnames'

interface DotsPageIndicatorProps {
  numberOfItems: number
  activeIndex: number
  onIndicatorClicked: (index: number) => void
}
export function DotsPageIndicator({
  numberOfItems,
  activeIndex,
  onIndicatorClicked,
}: DotsPageIndicatorProps) {
  let index = 0
  const indicators = []
  while (index < numberOfItems) {
    const currentIndex = index
    indicators.push(
      <StyledIndicator
        key={index}
        className={classNames({
          active: index === activeIndex,
        })}
        onClick={() => {
          onIndicatorClicked(currentIndex)
        }}
      />,
    )
    index += 1
  }
  return (
    <StyledIndicatorContainer data-test={'carousel-navigation-dots'}>
      {indicators}
    </StyledIndicatorContainer>
  )
}

const StyledIndicatorContainer = styled.div`
  display: flex;
  gap: 12px;
`

const StyledIndicator = styled.div`
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background: var(--theme-primary-s3);
  &.active {
    background: var(--theme-primary-s5);
  }
`
