import styled from 'styled-components'

const NoSpacesFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 104px;
  margin-top: 104px;
  padding-bottom: 104px;

  .spaces-empty-state-placeholder {
    display: block;
    width: 100%;
  }

  button.templates-button {
    display: flex;
    align-items: center;
    padding: 12px 14px 12px 12px;
    gap: 8px;

    width: 176px;
    height: 46px;

    background: var(--theme-primary-s1);
    color: var(--theme-primary-s5);
    border: 1px solid var(--theme-primary-s5);
    border-radius: 12px;
    &:hover {
      background: var(--theme-primary-s2);
      cursor: pointer;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
    }
  }
  p.templates-description {
    margin-top: 16px;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: var(--theme-grey-s5);
    &.cannotManageTemplates {
      font-weight: 500;
      font-size: 14px;
    }
  }
  div.centered {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`
export const S = { NoSpacesFound }
