import { MenuDropdownItem, ModalControls, SimpleModal, useOverlay } from '@valuecase/ui-components'
import { useRef } from 'react'
import SalesforceIcon from '../../assets/salesforceLogo.svg?react'
import { SpacesListDto } from '../spacesList/spacesList'
import '../../assets/modalStyles.css'
import { SpaceToSalesforceLinker } from './SpaceToSalesforceLinker'

/**
 * Shows a menu item that opens the Space-to-Salesforce linker modal.
 */
export function LinkToSalesforceTrigger({ space }: { space: SpacesListDto }) {
  const overlay = useOverlay()
  const controls = useRef<ModalControls | null>(null)
  function onClick() {
    overlay.show(
      <SimpleModal
        controlsRef={controls}
        additionalStyleClasses={{
          layout: 'spacecreationflow-modal',
          baseModal: 'spacecreationflow-modal__base',
        }}
        body={<SpaceToSalesforceLinker space={space} />}
        maxWidth='978px'
        wide={true}
      />,
    )
  }

  return <MenuDropdownItem LeftIcon={SalesforceIcon} label='Link to Salesforce' onClick={onClick} />
}
