import styled from 'styled-components'

const Dropdown = styled.div`
  position: relative;
  width: fit-content;
`

const Trigger = styled.div`
  width: fit-content;
  height: fit-content;
`

const Menu = styled.div`
  box-shadow: 0 6px 16px -4px rgba(0, 0, 0, 0.1), 0 2px 6px -2px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  padding: 6px;
  width: fit-content;
  z-index: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid var(--theme-grey-s2);
  font-size: 12px;
`

const MenuItem = styled.div<{ $disabled?: boolean; $hoverIconColor?: string }>`
  white-space: nowrap;
  z-index: 1;
  border-radius: 8px;

  padding: 6px;
  background: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  min-width: 240px;

  .left-content {
    display: flex;
    align-items: center;
    & > *:first-child {
      margin-right: 8px;
    }

    svg {
      height: 20px;
    }

    .ionicon {
      font-size: 20px;
    }
  }

  .right-content {
    display: flex;
    align-items: center;

    svg {
      height: 20px;
    }

    .ionicon {
      font-size: 20px;
    }
  }

  &.default {
    color: var(--theme-grey-s6);
    .left-content,
    .right-content {
      path {
        fill: var(--theme-grey-s4);
      }

      .ionicon {
        color: var(--theme-grey-s4);
      }
    }
  }

  &.success {
    color: var(--theme-success-s5);
    .left-content,
    .right-content {
      path {
        fill: var(--theme-success-s4);
      }

      .ionicon {
        color: var(--theme-success-s4);
      }
    }
  }

  &.danger {
    color: var(--theme-warning-s5);
    .left-content,
    .right-content {
      path {
        fill: var(--theme-warning-s4);

        .ionicon {
          color: var(--theme-warning-s4);
        }
      }
    }
  }

  &:hover {
    cursor: pointer;
    background: var(--theme-grey-s1);
    .left-content,
    .right-content {
      path {
        fill: ${({ $hoverIconColor: hoverIconColor }) => hoverIconColor};
      }
    }
    path {
      fill: ${({ $hoverIconColor: hoverIconColor }) => hoverIconColor};
    }
  }

  &[disabled] {
    color: var(--theme-grey-s3);
    cursor: not-allowed;
    .left-content,
    .right-content {
      path {
        fill: var(--theme-grey-s4);
      }
    }
    path {
      fill: var(--theme-grey-s4);
    }
    .ionicon {
      color: var(--theme-grey-s3) !important; /* Sorry! Gave up and used !important */
    }
  }
`

export const S = { Menu, MenuItem, Trigger, Dropdown }
