import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'
import { AuthState, useAuthState } from '../../../auth/auth'
import { useQuery } from '@tanstack/react-query'

export interface Seller {
  firstName: string
  lastName: string
  photo_s3ObjectUrl: string // URL in Bubble's S3 bucket
  title: string
  meetingLink: string
  phoneNumber: string
  email: string
  contactEmail: string
  tenantId: string
  notificationsSettings: NotificationsSettingsDTO | null | undefined
}

export interface NotificationsSettingsDTO {
  newComments: boolean
  firstVisit: boolean
  actionPlanActivities: boolean
}

async function readSeller(id: string): Promise<Seller> {
  const { data } = await spacesApi.get<Seller>(`/sellers/${id}`)
  return data
}

function createReadSellerMeFn(auth: AuthState) {
  return () => {
    if (!auth.authorized) throw new Error('Unauthorized')
    const sub = auth.sub.startsWith('bubble') ? auth.sub.split('|')[1] : auth.sub
    return readSeller(sub)
  }
}

export function useReadSeller() {
  const auth = useAuthState()
  const query = useQuery({
    queryKey: ['readSeller'],
    queryFn: createReadSellerMeFn(auth),
  })
  return {
    isLoading: query.isLoading,
    isError: query.isError,
    error: query.error,
    seller: query.data,
    isFetched: query.isFetched,
  }
}
