import { useMutation, useQueryClient } from '@tanstack/react-query'
import UserManagementApi from '../../../api/resources/UserManagementApi'

export async function updateUserRoleFn(userId: string) {
  await UserManagementApi.deleteInvitedUser(userId)
}

export function useCancelUser(onSuccess: () => void) {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: updateUserRoleFn,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['readUsers'] })
      if (onSuccess) onSuccess()
    },
  })
  return {
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    cancelUser: (userId: string) => mutation.mutate(userId),
  }
}
