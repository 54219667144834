import React, { ReactNode } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import { useBasePortalContainer } from './BasePortalContainerProvider'
import ZIndices from '../../../global-styles/ZIndices'
import { useThemeAsCssVars } from '../../../global-styles/ValuecaseThemeProvider'

interface BasePortalProps {
  portalId: string
  children?: ReactNode
  portalMountingPoint?: HTMLElement
  tag?: keyof HTMLElementTagNameMap
  zIndex?: number
  ignoreBasePortalProviderContainer?: boolean
}

export function BasePortal({
  portalId,
  children,
  portalMountingPoint = document.body,
  tag = 'div',
  zIndex = ZIndices.basePortalDefault,
  ignoreBasePortalProviderContainer = false,
}: BasePortalProps) {
  const themeCssVars = useThemeAsCssVars()

  const basePortalProviderContainer = useBasePortalContainer().container
  let portalContainer = document.getElementById(portalId)
  let isProviderContainer = false

  if (basePortalProviderContainer && !ignoreBasePortalProviderContainer) {
    portalContainer = basePortalProviderContainer
    isProviderContainer = true
  }

  if (!portalContainer && !isProviderContainer) {
    portalContainer = document.createElement(tag)
    portalContainer.id = portalId

    portalContainer.style.zIndex = `${zIndex}`
    portalContainer.style.position = 'absolute'
    portalContainer.style.top = '0'
    portalContainer.style.left = '0'
    portalContainer.style.width = '100%'
    portalContainer.style.height = '100%'
    portalContainer.style.overflow = 'hidden'
    portalContainer.style.pointerEvents = 'none'

    if (portalMountingPoint.childNodes.length > 0) {
      portalMountingPoint.insertBefore(portalContainer, document.body.childNodes[0])
    } else {
      portalMountingPoint.appendChild(portalContainer)
    }
  }

  if (!portalContainer) {
    return <></>
  }

  return createPortal(
    <PortalContentWrapper style={{ zIndex: zIndex }} $cssVars={themeCssVars}>
      {children}
    </PortalContentWrapper>,
    portalContainer,
    portalId,
  )
}

const PortalContentWrapper = styled.div<{ $cssVars: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;

  ${({ $cssVars }) => {
    return $cssVars
  }}
`
