import ThreeDotsVertical from './../assets/three-dots-vertical.svg?react'
import ThreeDotsHorizontal from './../assets/three-dots-horizontal.svg?react'
import styled from 'styled-components'

type GrayIntensity = '1' | '2' | '3' | '4' | '5' | '6'

export interface ThreeDotsDropdownTriggerProps {
  layout?: 'vertical' | 'horizontal'
  disabled?: boolean
  grayTypeForHover?: GrayIntensity
  gray?: GrayIntensity
}

export function ThreeDotsDropdownTrigger({
  layout = 'vertical',
  disabled,
  grayTypeForHover,
}: ThreeDotsDropdownTriggerProps) {
  return (
    <StyledThreeDotsDropdownTrigger $disabled={disabled} $grayTypeForHover={grayTypeForHover}>
      {layout === 'horizontal' ? <ThreeDotsHorizontal /> : <ThreeDotsVertical />}
    </StyledThreeDotsDropdownTrigger>
  )
}

const StyledThreeDotsDropdownTrigger = styled.span<{
  $disabled?: boolean
  $grayTypeForHover?: GrayIntensity
  $gray?: GrayIntensity
}>`
  border-radius: 8px;
  padding: 8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  color: ${({ $disabled: disabled, $gray: gray }) =>
    disabled ? 'var(--theme-grey-s2)' : `var(--theme-grey-s${gray ?? '4'})`};
  ${({ $disabled: disabled, $grayTypeForHover: grayTypeForHover }) =>
    !disabled
      ? `
      &:hover {
        background: var(--theme-grey-s${grayTypeForHover ?? '1'});
        cursor: pointer;
      }
  `
      : ''}
`
