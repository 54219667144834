import { TeamWithMembers } from '@valuecase/common'
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
} from '@valuecase/ui-components'
import useUpdateTeam from '../hooks/useUpdateTeam'

const RemoveUserFromTeamDialog = ({
  team,
  userId,
  onClose,
}: {
  team: TeamWithMembers
  userId: string | null
  onClose: (open: boolean) => void
}) => {
  const updateTeam = useUpdateTeam()

  const handleRemoveUser = async () => {
    const updatedMemberIds = team.memberIds.filter((id) => id !== userId)
    await updateTeam.mutateAsync({ ...team, memberIds: updatedMemberIds })
    onClose(false)
  }

  return (
    <AlertDialog open={!!userId} onOpenChange={onClose}>
      <AlertDialogContent>
        <AlertDialogHeader iconName='trash'>Remove user?</AlertDialogHeader>
        <AlertDialogDescription>
          The user will no longer be part of this team and will lose access to the spaces and
          templates belonging to it.
        </AlertDialogDescription>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction onClick={handleRemoveUser}>Remove user from team</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default RemoveUserFromTeamDialog
