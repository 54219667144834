import { useRouter } from '@tanstack/react-router'

export const useCurrentLocation = ():
  | 'spaces'
  | 'assetLibrary'
  | 'templates'
  | 'taskDashboard'
  | 'taskList'
  | 'profileAccount'
  | 'mailNotifications'
  | 'integrations'
  | 'administration'
  | 'companySettings'
  | 'userManagement'
  | 'teamManagement'
  | 'spaceProperties'
  | 'helpCenter'
  | 'imprintAndPrivacy'
  | 'activityFeed'
  | 'actionPlanAnalytics'
  | 'teamActivityAnalytics'
  | undefined => {
  const { state: routerState } = useRouter()
  switch (routerState.location?.pathname) {
    case '/dashboard':
      return 'spaces'
    case '/dashboard/':
      return 'spaces'
    case '/dashboard/spaces':
      return 'spaces'
    case '/dashboard/templates':
      return 'templates'
    case '/dashboard/tasks':
      return 'taskDashboard'
    case '/dashboard/tasks/dashboard':
      return 'taskDashboard'
    case '/dashboard/tasks/list':
      return 'taskList'
    case '/dashboard/assetLibrary':
      return 'assetLibrary'
    case '/dashboard/mySettings': {
      return 'profileAccount'
    }
    case '/dashboard/mySettings/profileAccount':
      return 'profileAccount'
    case '/dashboard/mySettings/mailNotifications':
      return 'mailNotifications'
    case '/dashboard/mySettings/integrations':
      return 'integrations'

    case '/dashboard/administration': {
      return 'administration'
    }
    case '/dashboard/administration/companySettings': {
      return 'companySettings'
    }
    case '/dashboard/administration/userManagement': {
      return 'userManagement'
    }
    case '/dashboard/administration/teamManagement': {
      return 'teamManagement'
    }
    case '/dashboard/administration/spaceProperties': {
      return 'spaceProperties'
    }
    case '/dashboard/analytics/actionPlanAnalytics': {
      return 'actionPlanAnalytics'
    }
    case '/dashboard/analytics/teamActivityAnalytics': {
      return 'teamActivityAnalytics'
    }
    case '/dashboard/helpCenter': {
      return 'helpCenter'
    }
    case '/dashboard/imprintAndPrivacy': {
      return 'imprintAndPrivacy'
    }
    case '/dashboard/activityFeed': {
      return 'activityFeed'
    }
    default:
      return undefined
  }
}
