import { CommonTranslations } from '../CommonTranslations'
import en from './common-en'

const override: any = {
  root: {
    utils: {
      date: {
        inXDays: 'Dans {{n}} jours',
        tomorrow: 'Demain',
        today: "Aujourd'hui",
        yesterday: 'Hier',
        xYearsAgo: 'il y a {{n}} ans',
        oneYearAgo: 'il y a 1 an',
        xMonthsAgo: 'il y a {{n}} mois',
        oneMonthAgo: 'il y a 1 mois',
        xDaysAgo: 'il y a {{n}} jours',
        oneDayAgo: 'il y a 1 jour',
        xHoursAgo: 'il y a {{n}} heures',
        oneHourAgo: 'il y a 1 heure',
        xMinutesAgo: 'il y a {{n}} minutes',
        oneMinuteAgo: 'il y a 1 minute',
        xSecondsAgo: 'il y a {{n}} secondes',
        oneSecondAgo: 'il y a 1 seconde',
      },
    },
  },
}

const fr: CommonTranslations = {
  ...en,
  ...override,
}

export default fr
