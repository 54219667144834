import { getPersonalisationValue, PlaceholdableProps } from './PlaceholderValuePair'

type Operation = { insert: object | string | null | undefined }

const parseContent = (content: string): { ops: Operation[] } =>
  JSON.parse(content.replaceAll('\n', '\\n'))

export class RichTextFormatHelper {
  static isContentEmpty(content: string): boolean {
    if (content.length === 0) {
      return true
    }
    try {
      const { ops } = parseContent(content)
      return RichTextFormatHelper.isEmptyOps(ops)
    } catch (_e: unknown) {
      return false
    }
  }

  static isEmptyOps(ops: Operation[]): boolean {
    return ops.every((operation) => RichTextFormatHelper.isEmptyOperation(operation))
  }

  static isEmptyOperation({ insert }: Operation): boolean {
    if (!insert) {
      return true
    }
    if (typeof insert === 'object') {
      return false
    }
    return insert.trim().length === 0
  }

  static getPlainTextFromContent(content: string, placeholderProps: PlaceholdableProps): string {
    try {
      const { ops } = parseContent(content)
      return ops
        .map(({ insert }) => {
          if (!insert) {
            return ''
          }
          if (typeof insert === 'string') {
            return insert
          }
          if (typeof insert === 'object') {
            return RichTextFormatHelper.getPlainTextFromBlot(insert, placeholderProps)
          }
        })
        .join('')
        .trim()
    } catch (_e: unknown) {
      return content
    }
  }

  static getPlainTextFromBlot(blot: any, placeholderProps: PlaceholdableProps): string {
    if ('mention' in blot) {
      const placeholderKey = blot.mention.id
      const pair = placeholderProps.placeholderValues.find(({ key }) => key === placeholderKey)
      if (!pair) {
        return placeholderKey
      }
      if (placeholderProps.renderActualValues) {
        return getPersonalisationValue(pair.key, pair.value, placeholderProps.renderActualValues)
      }
      return `{${getPersonalisationValue(
        pair.key,
        pair.value,
        placeholderProps.renderActualValues,
      )}}`
    }
    throw new Error(`Unhandled blob type with the following keys: ${Object.keys(blot)}`)
  }

  static isEqual(content1: string, content2: string): boolean {
    const plainText1 = RichTextFormatHelper.getPlainTextFromContent(content1, {
      placeholderValues: [],
      renderActualValues: false,
    })
    const plainText2 = RichTextFormatHelper.getPlainTextFromContent(content2, {
      placeholderValues: [],
      renderActualValues: false,
    })
    return plainText1 === plainText2
  }
}
