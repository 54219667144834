import styled, { keyframes } from 'styled-components'

const SpinnerRotation = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`

/**
 * The `size` prop sets both the CSS height and width of the spinner.
 * If the size prop is not provided, the default size is 2rem.
 * The `color` prop sets the color of the spinner.
 * If the color prop is not provided, the default color is var(--theme-primary-s5).
 */
export const Spinner = styled.span<{ $size?: string; $color?: string }>`
  width: ${({ $size: size }) => (typeof size === 'string' ? size : '2rem')};
  height: ${({ $size: size }) => (typeof size === 'string' ? size : '2rem')};
  border-radius: 100%;
  border: 2px solid ${({ $color: color }) => (color ? color : 'var(--theme-primary-s4)')};
  animation: ${SpinnerRotation} 0.75s cubic-bezier(0.25, 0.1, 0.52, 1) infinite;
  clip-path: polygon(0% 50%, 50% 50%, 50% 100%, 0% 100%);
  display: inline-block;
`
