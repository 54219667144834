import { Button, useModalControls } from '@valuecase/ui-components'
import { useEffect, useState } from 'react'
import { useNotifications } from '../../utils/Notifications/Notifications'
import { useCompanyImprint } from './hooks/useCompanyImprint'
import { useCompanyPrivacyPolicy } from './hooks/useCompanyPrivacyPolicy'
import { useTrackEvent } from '../../mixpanel/useTrackEvent'

export const PrivacyPolicyImprintEditModal = () => {
  const { success } = useNotifications()
  const [localPrivacy, setLocalPrivacy] = useState('')
  const [localImprint, setLocalImprint] = useState('')
  const { close } = useModalControls()
  const { trackEvent } = useTrackEvent()

  const { companyImprint, updateCompanyImprint } = useCompanyImprint({
    onUpdateSuccess: () => {
      success('Company imprint updated')
    },
  })

  const { companyPrivacyPolicy, updateCompanyPrivacyPolicy } = useCompanyPrivacyPolicy({
    onUpdateSuccess: () => {
      success('Company privacy policy updated')
    },
  })

  function handleSave() {
    trackEvent({
      event: 'settings-privacy_imprint-confirm',
      eventProperties: {
        indexPageName: 'Company Settings',
      },
    })
    if (localImprint !== companyImprint?.content) {
      updateCompanyImprint({ content: localImprint })
    }
    if (localPrivacy !== companyPrivacyPolicy?.content) {
      updateCompanyPrivacyPolicy({ content: localPrivacy })
    }
    close()
  }

  useEffect(() => {
    if (companyImprint) {
      setLocalImprint(companyImprint.content)
    }
    if (companyPrivacyPolicy) {
      setLocalPrivacy(companyPrivacyPolicy.content)
    }
  }, [companyImprint, companyPrivacyPolicy])

  return (
    <div className='flex flex-col'>
      <div className='flex flex-col items-center justify-center w-full pb-8 gap-2'>
        <h1 className='font-extrabold text-2xl text-grey-s6'>Legal</h1>
        <p className='text-grey-s5 text-sm'>
          Please provide your privacy policy and imprint below.
        </p>
      </div>

      <div className='bg-grey-s1 border-t border-b border-grey-s2 flex flex-col gap-4 p-10 -ml-8 w-[calc(100%+64px)]'>
        <div className='flex'>
          <p className='w-3/12 font-semibold text-sm text-grey-s6'>Privacy Policy</p>
          <textarea
            placeholder='Please provide your privacy policy here.'
            className='hover:shadow-text-input text-sm focus:shadow-text-input transition-all w-9/12 h-48 bg-white rounded-xl border border-grey-s2 outline-none focus:border-primary-s4 px-8 py-4 resize-none'
            value={localPrivacy}
            onChange={(e) => setLocalPrivacy(e.target.value)}
          />
        </div>
        <div className='flex'>
          <p className='w-3/12 text-sm font-semibold text-grey-s6'>Imprint</p>
          <textarea
            placeholder='Please provide your imprint here.'
            className='hover:shadow-text-input text-sm focus:shadow-text-input transition-all w-9/12 h-48 bg-white rounded-xl border border-grey-s2 outline-none focus:border-primary-s4 px-8 py-4 resize-none'
            value={localImprint}
            onChange={(e) => setLocalImprint(e.target.value)}
          />
        </div>
      </div>

      <div className='flex w-full justify-end py-8'>
        <Button
          icon='chevron-forward-outline'
          iconPosition='right'
          label='Apply changes'
          onClick={handleSave}
        />
      </div>
    </div>
  )
}
