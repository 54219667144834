import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'
import { useMutation, useQueryClient } from '@tanstack/react-query'

type UpdateSpaceTeamsInput = {
  spaceId: string
  teamIds: string[]
}
const changeSpaceTeams = async ({ spaceId, teamIds }: UpdateSpaceTeamsInput) =>
  await spacesApi.patch(`/spaces/${spaceId}/change-teams`, { teamIds })

export const useUpdateSpaceTeams = () => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: changeSpaceTeams,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['spaces'] })
    },
  })
  return {
    isSuccess: mutation.isSuccess,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    updateTeams: mutation.mutateAsync,
  }
}
