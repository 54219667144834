import { useTrackEvent } from '@/mixpanel/useTrackEvent'
import { Role, useReadAvailableRoles } from '@/modules/auth/hooks/useReadAvailableRoles'
import TeamSelection from '@/modules/teams/dialogs/TeamSelection'
import { cn } from '@/utils/Cn'
import { TeamWithMembers, User } from '@valuecase/common'
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IonIcon,
  MenuDropdown,
  MenuDropdownSeparator,
} from '@valuecase/ui-components'
import { Button2 } from '@valuecase/ui-components/src/components/ui/button'
import { useEffect, useState } from 'react'
import { useUpdateUserRole } from '../../hooks/useUpdateUserRole'
import { useUpdateUserTeams } from '../../hooks/useUpdateUserTeams'
import useSelectedTeams from '@/modules/teams/hooks/useSelectedTeams'
import { useLDflags } from '@/launchdarkly/Launchdarkly'

export const EditUserModal = ({
  user,
  teams,
  onClose,
}: {
  user: User | null
  teams: TeamWithMembers[]
  onClose: (open: boolean) => void
}) => {
  const { trackEvent } = useTrackEvent()
  const { roles } = useReadAvailableRoles()
  const { updateUserRole } = useUpdateUserRole()
  const { flags } = useLDflags()
  const getInitialSelectedTeams = (user: User | null, teams: TeamWithMembers[]) => {
    if (!user || !teams.length) return []
    const selectedTeamsArray: TeamWithMembers[] = []
    teams?.forEach((team) => {
      if (team.memberIds.includes(user.id)) {
        selectedTeamsArray.push(team)
      }
    })
    return selectedTeamsArray
  }
  const [initialSelectedTeams, setInitialSelectedTeams] = useState<TeamWithMembers[]>([])
  const { selectedTeams, toggleTeam } = useSelectedTeams(initialSelectedTeams)
  const { updateUserTeams } = useUpdateUserTeams()

  useEffect(() => {
    setInitialSelectedTeams(getInitialSelectedTeams(user, teams))
  }, [user, teams, setInitialSelectedTeams])

  const getInitialRole = (user: User | null, roles: Role[]) => {
    if (!user || !user.roleId) {
      return null
    }
    const userRole = roles.find((role) => role.id === user.roleId)
    return userRole || null
  }

  const [selectedRole, setRole] = useState<Role | null>(null)
  const [isOpen, toggle] = useState(false)

  useEffect(() => {
    setRole(getInitialRole(user, roles ?? []))
  }, [roles, user])

  const handleSaveChanges = async () => {
    if (!user || !selectedRole) return

    if (user.roleId !== selectedRole.id) {
      await updateUserRole({
        userId: user?.id,
        roleId: selectedRole?.id,
      })
    }

    const teamIds = [...selectedTeams].map((team) => team.id)
    await updateUserTeams({
      userId: user.id,
      teamIds,
    })
    onClose(false)
  }
  if (!user) return null

  return (
    <Dialog open={!!user} onOpenChange={onClose}>
      <DialogContent className='flex flex-col items-center p-8'>
        <DialogTitle>Edit user permissions</DialogTitle>
        <div className='flex flex-col gap-8 w-full'>
          <div className='flex flex-col gap-4'>
            <div className='flex flex-col gap-4'>
              <p className='text-xs font-semibold text-grey-s4'>Role</p>
              <div className='relative w-full text-sm'>
                <MenuDropdown
                  Trigger={
                    <div
                      className='w-full border border-grey-s2 bg-white py-3 pr-3 pl-5 rounded-lg flex items-center justify-between
                    hover:shadow-text-input transition-all'
                    >
                      <p className={cn('text-grey-s4 text-sm', { 'text-grey-s6': selectedRole })}>
                        {!selectedRole ? 'Select a role' : selectedRole.name}
                      </p>
                      <IonIcon
                        className={'text-grey-s4'}
                        name={isOpen ? 'chevron-up-outline' : 'chevron-down-outline'}
                      />
                    </div>
                  }
                  placement={'bottom-start'}
                  onOpenStateChange={toggle}
                >
                  <div className='w-[366px]'>
                    {!!roles &&
                      roles.map((role, i) => (
                        <div key={role.id} className='text-left w-full'>
                          {i !== 0 && <MenuDropdownSeparator />}
                          <button
                            onClick={() => {
                              trackEvent({
                                event: 'users-newuser-select_role',
                                eventProperties: {
                                  indexPageName: 'User Management',
                                  permissionTypeSet: role.name,
                                },
                              })
                              setRole(role)
                            }}
                            className='w-full bg-transparent hover:bg-grey-s1 px-4 py-3 rounded-lg'
                          >
                            <p className='font-medium text-left text-sm text-grey-s6'>
                              {role.name}
                              {selectedRole && role.id === selectedRole.id && (
                                <IonIcon
                                  className={'text-grey-s4'}
                                  name='checkmark'
                                  style={{
                                    height: '20px',
                                    width: '20px',
                                    marginLeft: '8px',
                                    position: 'relative',
                                    top: '4px',
                                  }}
                                />
                              )}
                            </p>
                            <p className='relative text-grey-s4 text-left text-xs text-ellipsis max-w-[350px] mt-1 overflow-hidden'>
                              {role.description}
                            </p>
                          </button>
                        </div>
                      ))}
                  </div>
                </MenuDropdown>
              </div>
            </div>
          </div>

          {flags && flags['teams-management'] && (
            <TeamSelection
              isOpen={isOpen}
              toggleTeam={toggleTeam}
              teams={teams}
              toggle={toggle}
              selectedTeams={selectedTeams}
            />
          )}

          <div className='flex w-full justify-center'>
            <div className='flex flex-row gap-3 items-center w-full px-5'>
              <Button2
                leadingIcon='close-circle-outline'
                variant={'outlined'}
                onClick={() => onClose(false)}
                className='w-full'
              >
                Cancel
              </Button2>
              <Button2 className='w-full ' onClick={handleSaveChanges}>
                Save changes
              </Button2>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default EditUserModal
