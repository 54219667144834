import { useMutation, useQueryClient } from '@tanstack/react-query'
import UserManagementApi from '../../../api/resources/UserManagementApi'

async function updateUserTeams({ userId, teamIds }: { userId: string; teamIds: string[] }) {
  return await UserManagementApi.updateUserTeams(userId, { teamIds })
}

export function useUpdateUserTeams() {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: updateUserTeams,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['teams'] })
    },
  })
  return {
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    updateUserTeams: ({ userId, teamIds }: { userId: string; teamIds: string[] }) =>
      mutation.mutateAsync({ userId, teamIds }),
  }
}
