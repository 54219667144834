import { useMutation, useQueryClient } from '@tanstack/react-query'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'

const deleteSpace = (spaceId: string) => spacesApi.delete(`/spaces/${spaceId}`)

export const useDeleteSpace = (props?: { onSuccess: (() => void) | undefined }) => {
  const queryClient = useQueryClient()
  const mutation = useMutation({
    mutationFn: deleteSpace,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['spaces'] })
      queryClient.invalidateQueries({ queryKey: ['tasks'] })

      if (props?.onSuccess) {
        props.onSuccess()
      }
    },
  })

  return {
    isSuccess: mutation.isSuccess,
    isLoading: mutation.isLoading,
    isError: mutation.isError,
    error: mutation.error,
    deleteSpace: (spaceId: string) => mutation.mutate(spaceId),
    deleteSpaceAsync: async (spaceId: string) => await mutation.mutateAsync(spaceId),
  }
}
