import HubSpotLogo from '../../assets/hubspot-icon.svg?react'
import { IntegrationBadge } from './IntegrationBadge'

export const HubSpotBadge = () => {
  return (
    <IntegrationBadge
      LogoSvg={HubSpotLogo}
      backgroundColor='#ff7a5933'
      color='#ff7a59'
      text='Connected'
    />
  )
}
