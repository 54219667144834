import styled from 'styled-components'
import { IonIcon } from '../../../Icons/IonIcon'

export interface SectionIconProps {
  icon: string
  iconColor?: 'primary' | 'grey' | 'warning' | 'success'
}

export default function SectionIcon({ icon, iconColor = 'primary' }: SectionIconProps) {
  return (
    <IconWrapper $iconColor={iconColor}>
      <IonIcon name={icon} />
    </IconWrapper>
  )
}

interface IconWrapperProps {
  $iconColor: 'primary' | 'grey' | 'warning' | 'success'
}

const IconWrapper = styled.span<IconWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 44px;
  height: 44px;

  border-radius: 8px;

  .ionicon {
    font-size: 24px;
  }

  ${({ $iconColor: iconColor }) => {
    return `
      background: var(--theme-${iconColor}-s1);
      .ionicon {
        color: var(--theme-${iconColor}-s4);
      }    
    `
  }}
`
