import { useQuery } from '@tanstack/react-query'
import { spacesApi } from '../../../api/spacesApi/spacesAPIclient'
import { TimeRange } from './types'

function getChartQueryFn<T>(chartType: string, range: TimeRange) {
  return () =>
    spacesApi.get(`/analytics/get-chart/${chartType}?range=${range}`).then((res) => res.data as T)
}

export function useGetChart<T>(chartType: string, range: TimeRange) {
  const query = useQuery({
    queryKey: ['chart', chartType, range],
    queryFn: getChartQueryFn<T>(chartType, range),
  })

  return {
    isLoading: query.isLoading,
    isReloading: query.isRefetching && query.isStale,
    isError: query.isError,
    status: query.status,
    error: query.error,
    chartData: query.data,
    isFetched: query.isFetched,
  }
}
